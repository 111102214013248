import { Form } from 'antd'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import BzSelect from '../BzSelect/BzSelect'
import { BzSelectOption } from '../BzSelect/BzSelectTypes'

export type ValidTimeRangePickerTime = `${
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'}:${'00' | '15' | '30' | '45'} ${'A' | 'P'}M`

type TimeRangePickerProps = {
  range?: { start: ValidTimeRangePickerTime; end: ValidTimeRangePickerTime }
  setRange: (range: {
    start: ValidTimeRangePickerTime
    end: ValidTimeRangePickerTime
  }) => void
  firstValidStartTime?: ValidTimeRangePickerTime
  lastValidEndTime?: ValidTimeRangePickerTime
  className?: string
  canBeEqual?: boolean
  withLabels?: boolean
}
export const TimeRangePicker = React.memo<TimeRangePickerProps>(
  ({
    range,
    setRange,
    firstValidStartTime,
    lastValidEndTime,
    className,
    canBeEqual,
    withLabels,
  }) => {
    const [validTimeOptions, timeToMinutes] = useMemo(() => {
      const times: BzSelectOption[] = []
      // If there IS a supplied valid start time, then I haven't seen it yet. If there isn't, then I "have" seen it.
      let seenValidFirst = !firstValidStartTime
      let seenValidLast = false

      let totalMinutes = 0
      const timeToMinutes: Partial<Record<ValidTimeRangePickerTime, number>> =
        {}
      for (let hour24 = 0; hour24 <= 23; hour24++) {
        const am = hour24 < 12
        let hour = hour24

        const amPm = am ? 'AM' : 'PM'
        if (!am && hour !== 12) {
          hour -= 12
        }
        if (hour === 0) {
          hour = 12
        }
        for (const minute of ['00', '15', '30', '45'] as const) {
          const time = `${hour}:${minute} ${amPm}` as ValidTimeRangePickerTime
          timeToMinutes[time] = totalMinutes
          totalMinutes += 30
          if (time === firstValidStartTime) {
            seenValidFirst = true
          }
          if (seenValidFirst && !seenValidLast) {
            times.push({
              value: time,
              label: time,
            })
          }
          // We could return at this time, but I'm choosing not to in order to construct the full `timeToMinutes` map
          if (time === lastValidEndTime) {
            seenValidLast = true
          }
        }
      }

      return [times, timeToMinutes]
    }, [firstValidStartTime, lastValidEndTime])

    const [pickerStartTime, setPickerStartTime] = useState<
      ValidTimeRangePickerTime | undefined
    >(range?.start)
    const [pickerEndTime, setPickerEndTime] = useState<
      ValidTimeRangePickerTime | undefined
    >(range?.end)

    const isInvalid = useMemo(() => {
      if (!pickerStartTime || !pickerEndTime) {
        return false
      }
      const startMinutes = timeToMinutes[pickerStartTime] ?? Infinity
      const endMinutes = timeToMinutes[pickerEndTime] ?? -Infinity

      if (canBeEqual) {
        return startMinutes > endMinutes
      }
      return startMinutes >= endMinutes
    }, [canBeEqual, pickerEndTime, pickerStartTime, timeToMinutes])

    useEffect(() => {
      if (pickerStartTime && pickerEndTime && !isInvalid) {
        setRange({
          start: pickerStartTime,
          end: pickerEndTime,
        })
      }
    }, [isInvalid, pickerEndTime, pickerStartTime, setRange])

    const startContent = (
      <BzSelect
        title="Start Time"
        placeholder="Start"
        showSearch
        size="middle"
        value={pickerStartTime}
        onChange={setPickerStartTime}
        options={validTimeOptions}
      />
    )

    const endContent = (
      <BzSelect
        title="End Time"
        placeholder="End"
        showSearch
        size="middle"
        value={pickerEndTime}
        onChange={setPickerEndTime}
        options={validTimeOptions}
      />
    )

    return (
      <div className={classNames('w-full min-w-[230px]', className)}>
        <div className="flex flex-1 flex-row items-start space-x-2 *:min-w-0 *:flex-1">
          {withLabels ? (
            <Form.Item required label="Start Time" className="mb-0">
              {startContent}
            </Form.Item>
          ) : (
            startContent
          )}
          {withLabels ? (
            <Form.Item required label="End Time" className="mb-0">
              {endContent}
            </Form.Item>
          ) : (
            endContent
          )}
        </div>
        {isInvalid && (
          <div className="text-bz-error">End time must be after start time</div>
        )}
      </div>
    )
  },
)
