import {
  Account,
  BzAddress,
  BzDateFns,
  CompanyLeadSource,
  ESTIMATED_EQUIPMENT_AGE_OPTIONS,
  EquipmentType,
  EquipmentTypeJobLink,
  InstallProjectType,
  JobClass,
  JobToEquipmentRelationshipType,
  JobType,
  OfficeRoutes,
  existingEquipmentMayRequireDeactivation,
  formatEquipmentType,
  fuzzyMatch,
  installProjectTypeRequiredForJobClass,
  isNullish,
  newEquipementRequiredForJobClass,
} from '@breezy/shared'
import {
  Button,
  ConfigProvider,
  Divider,
  Form,
  Input,
  Radio,
  Select,
  Typography,
} from 'antd'
import { FormInstance, FormProps, useWatch } from 'antd/lib/form/Form'
import TextArea from 'antd/lib/input/TextArea'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import DatePicker from '../../components/DatePicker/DatePicker'
import BzSelect from '../../elements/BzSelect/BzSelect'
import { Link } from '../../elements/Link/Link'
import ThinDivider from '../../elements/ThinDivider'
import { BusinessUnitSimple } from '../../hooks/fetch/useBusinessUnits'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { InvoiceTemplateItems } from '../../hooks/useInvoiceTemplates'
import { useIsSmallScreen } from '../../hooks/useIsMobile'
import {
  useExpectedCompany,
  useExpectedCompanyEquipmentTypes,
  useExpectedCompanyInstallProjectTypes,
} from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'
import { BehindFeatureFlag } from '../BehindFeatureFlag'
import LeadSourceAttributionDescriptionFormItem from '../LeadSourceAttributionDescriptionFormItem/LeadSourceAttributionDescriptionFormItem'
import LeadSourceReferringContactFormItem from '../LeadSourceReferringContactFormItem/LeadSourceReferringContactFormItem'
import LeadSourceSelectFormItem from '../LeadSourceSelectFormItem/LeadSourceSelectFormItem'
import { Tag } from '../Tags'
import { LinkJobDataFormSection } from './components/LinkJobDataFormSection'
import { CreateOrEditJobMaintenancePlanVisitsField } from './CreateOrEditJobMaintenancePlanVisitsField'
import { CreateOrEditNewJobFormSchema } from './CreateOrEditNewJobFormSchema'
import { JobRevenuePotentialForm } from './JobRevenuePotentialForm'

const ESTIMATED_EQUIPMENT_AGE_OPTIONS_SELECT_OPTIONS =
  ESTIMATED_EQUIPMENT_AGE_OPTIONS.map(value => ({ value }))

const DEFAULT_VALUES: Partial<CreateOrEditNewJobFormSchema> = {
  preexistingEquipmentInfo: [],
  isOpportunity: false,
  isHotLead: false,
  isMembershipOpportunity: false,
  tags: [],
  useMaintenancePlanCredit: false,
  maintenancePlanVisitGuid: undefined,
}

const MAX_PO_NUMBER_LENGTH = 30

export type onFormSubmitValues = CreateOrEditNewJobFormSchema

type BaseCreateOrEditJobFormProps = {
  companyGuid: string
  account?: Account
  onCancel: () => void
  onFormSubmit?: (values: onFormSubmitValues) => void
  initialValues?: Partial<CreateOrEditNewJobFormSchema>
  hiddenFields?: Array<keyof CreateOrEditNewJobFormSchema>
  labelClassName?: string
  flexRowSpaceX?: string
  justifyFormCancelSubmitButtons?: 'start' | 'end'
  showDivider?: boolean
  createOrEditJobForm: FormInstance<CreateOrEditNewJobFormSchema>
  showFormCancelSubmitButtons?: boolean
  jobTypes: JobType[]
  jobTags: { tagGuid: string; name: string; color: string }[]
  leadSources: CompanyLeadSource[]
  linkedJobGuid?: string
  invoiceTemplates: InvoiceTemplateItems
  businessUnits: BusinessUnitSimple[] | null
  businessUnitsRequired: boolean
}

type EditJobFormProps = {
  formMode: 'edit'
  creatingJobForNewAccount?: undefined
  initialValues?: Partial<CreateOrEditNewJobFormSchema>
} & BaseCreateOrEditJobFormProps

type CreateJobFormProps = {
  formMode: 'create'
  creatingJobForNewAccount: boolean
} & BaseCreateOrEditJobFormProps

type CreateOrEditJobFormProps = EditJobFormProps | CreateJobFormProps

const mapRelationshipTypeFromJobClassForPreexistingEquipment = (
  jobClass: JobClass,
): JobToEquipmentRelationshipType => {
  return jobClass
    ? jobClass === JobClass.SERVICE
      ? 'SERVICING'
      : jobClass === JobClass.INSTALL
      ? 'DEACTIVATING'
      : jobClass === JobClass.MAINTENANCE
      ? 'MAINTAINING'
      : 'LINKED'
    : 'LINKED'
}

export const makeEquipmentTypeJobLinks = (
  values: CreateOrEditNewJobFormSchema,
  jobType: JobType,
): EquipmentTypeJobLink[] => {
  const preexistingEquipment: EquipmentTypeJobLink[] =
    values.preexistingEquipmentInfo?.map(equipmentType => ({
      equipmentType,
      estimatedEquipmentAge: values[`equipment-age-${equipmentType}`],
      relationshipType: mapRelationshipTypeFromJobClassForPreexistingEquipment(
        jobType.jobClass,
      ),
    })) || []

  const newEquipment: EquipmentTypeJobLink[] =
    values.newEquipmentInfo?.map(equipmentType => ({
      equipmentType,
      relationshipType: 'INSTALLING',
    })) || []

  return [...preexistingEquipment, ...newEquipment]
}

export const CreateOrEditJobForm = ({
  formMode,
  account,
  onCancel,
  onFormSubmit,
  initialValues,
  hiddenFields = [],
  labelClassName = '',
  flexRowSpaceX = 'space-x-4',
  justifyFormCancelSubmitButtons = 'end',
  showDivider = true,
  createOrEditJobForm,
  showFormCancelSubmitButtons = true,
  creatingJobForNewAccount,
  jobTypes,
  jobTags,
  leadSources,
  linkedJobGuid,
  invoiceTemplates,
  businessUnits,
  businessUnitsRequired,
}: CreateOrEditJobFormProps) => {
  const companyEquipmentTypes = useExpectedCompanyEquipmentTypes()
  const companyInstallProjectTypes = useExpectedCompanyInstallProjectTypes()

  const onFinish = useCallback(
    (formValues: CreateOrEditNewJobFormSchema) => {
      onFormSubmit?.({
        ...formValues,
        linkedJobGuid,
      })
    },
    [linkedJobGuid, onFormSubmit],
  )

  return (
    <CreateOrEditJobFormInner
      form={createOrEditJobForm}
      onFinish={onFinish}
      onCancel={onCancel}
      hiddenFields={hiddenFields}
      flexRowSpaceX={flexRowSpaceX}
      labelClassName={labelClassName}
      account={account}
      companyEquipmentTypes={companyEquipmentTypes}
      companyInstallProjectTypes={companyInstallProjectTypes}
      companyLeadSources={leadSources}
      showDivider={showDivider}
      showFormCancelSubmitButtons={showFormCancelSubmitButtons}
      formMode={formMode}
      justifyFormCancelSubmitButtons={justifyFormCancelSubmitButtons}
      initialValues={initialValues}
      creatingJobForNewAccount={creatingJobForNewAccount}
      jobTypes={jobTypes}
      jobTags={jobTags}
      linkedJobGuid={linkedJobGuid}
      invoiceTemplates={invoiceTemplates}
      businessUnits={businessUnits}
      businessUnitsRequired={businessUnitsRequired}
    />
  )
}

type CreateOrEditJobFormInnerProps = {
  form: FormInstance<CreateOrEditNewJobFormSchema>
  onFinish: (formValues: CreateOrEditNewJobFormSchema) => void
  onCancel: () => void
  hiddenFields: Array<keyof CreateOrEditNewJobFormSchema>
  flexRowSpaceX: string
  labelClassName: string
  account?: Account
  companyEquipmentTypes: EquipmentType[]
  companyInstallProjectTypes: InstallProjectType[]
  companyLeadSources: CompanyLeadSource[]
  showDivider: boolean
  showFormCancelSubmitButtons: boolean
  formMode: 'edit' | 'create'
  justifyFormCancelSubmitButtons: string
  initialValues?: Partial<Omit<CreateOrEditNewJobFormSchema, 'equipmentTypes'>>
  creatingJobForNewAccount: boolean | undefined
  jobTypes: {
    jobTypeGuid: string
    name: string
    defaultSummary?: string
    jobClass: JobClass
    archivedAt?: string
  }[]
  jobTags: { tagGuid: string; name: string; color: string }[]
  linkedJobGuid?: string
  invoiceTemplates: InvoiceTemplateItems
  businessUnits: BusinessUnitSimple[] | null
  businessUnitsRequired: boolean
}

const calculatePreexistingEquipmentTypeLabel = (jobClass: JobClass) => {
  switch (jobClass) {
    case JobClass.SERVICE:
      return 'Equipment to service'
    case JobClass.MAINTENANCE:
      return 'Equipment to maintain'
    case JobClass.ESTIMATE_REPAIR:
      return 'Equipment to repair'
    case JobClass.ESTIMATE_REPLACE:
    case JobClass.INSTALL:
    case JobClass.SALES:
      return 'Equipment to replace'
    case JobClass.WARRANTY:
    case JobClass.CALLBACK:
    case JobClass.UNKNOWN:
      return 'What type of equipment?'
  }
}

const CreateOrEditJobFormInner = m<CreateOrEditJobFormInnerProps>(props => {
  const {
    form,
    onFinish,
    hiddenFields,
    flexRowSpaceX,
    labelClassName,
    account,
    companyEquipmentTypes,
    companyLeadSources,
    showDivider,
    showFormCancelSubmitButtons,
    formMode,
    onCancel,
    justifyFormCancelSubmitButtons,
    initialValues,
    creatingJobForNewAccount,
    jobTypes,
    jobTags,
    linkedJobGuid,
    invoiceTemplates,
  } = props
  const { companyGuid, timezone } = useExpectedCompany()
  const purchaseOrdersEnabled = useFeatureFlag('purchase-orders')
  const defaultJobSummaryEnabled = useFeatureFlag('default-job-summary')
  const isEdit = formMode === 'edit'

  const selectedLeadSourceGuid = useWatch('leadSourceGuid', form)
  const jobTypeGuid = useWatch('jobTypeGuid', form)
  const summary = useWatch('summary', form)
  const jobType = useMemo(
    () =>
      jobTypeGuid
        ? jobTypes.find(jt => jt.jobTypeGuid === jobTypeGuid)
        : undefined,
    [jobTypeGuid, jobTypes],
  )

  const handleJobTypeChange = useCallback(
    (jobTypeGuid: string) => {
      const jobType = jobTypes.find(jt => jt.jobTypeGuid === jobTypeGuid)
      form.setFieldValue('jobTypeGuid', jobTypeGuid)
      if (defaultJobSummaryEnabled && jobType?.defaultSummary && !summary) {
        form.setFieldValue('summary', jobType?.defaultSummary)
      }
    },
    [jobTypes, form, summary, defaultJobSummaryEnabled],
  )

  const jobClass = useMemo(() => jobType?.jobClass, [jobType?.jobClass])
  const selectedLocationGuid = useMemo(
    () => initialValues?.locationGuid,
    [initialValues?.locationGuid],
  )
  const installProjectType = useWatch('installProjectType', form)
  const preexistingEquipmentInfo = useWatch('preexistingEquipmentInfo', form)
  const shouldCreateInvoiceFromTemplateAfterwards = useWatch(
    'shouldCreateInvoiceFromTemplateAfterwards',
    form,
  )
  const selectedInvoiceTemplateGuid = useWatch(
    'selectedInvoiceTemplateGuid',
    form,
  )

  const selectedLeadSource = companyLeadSources.find(
    source => source.companyLeadSourceGuid === selectedLeadSourceGuid,
  )
  const pointOfContactGuidValue = useWatch('pointOfContactGuid', form)
  const purchaseOrderNumbers = useWatch('purchaseOrderNumbers', form)

  const showPreexistingEquipmentTypesField = useMemo(() => {
    if (!jobClass || !jobTypeGuid) return false
    if (!installProjectTypeRequiredForJobClass(jobClass)) return true
    if (
      installProjectType &&
      existingEquipmentMayRequireDeactivation(installProjectType)
    )
      return true

    return false
  }, [installProjectType, jobClass, jobTypeGuid])

  const preexistingEquipmentTypeLabel = useMemo(
    () => (jobClass ? calculatePreexistingEquipmentTypeLabel(jobClass) : ''),
    [jobClass],
  )

  // This is a hack to force the JobRevenuePotentialForm to re-render whenever an equipment-age form field changes
  const [
    forceJobRevenuePotentialRerender,
    setForceJobRevenuePotentialRerender,
  ] = useState(0)

  const onValuesChange: FormProps<CreateOrEditNewJobFormSchema>['onValuesChange'] =
    changedValues => {
      if (changedValues['leadSourceGuid']) {
        form.setFieldValue('leadSourceAttributionDescription', null)
        form.setFieldValue('leadSourceReferringContactGuid', null)
      }

      if (changedValues['equipmentTypes']) {
        for (const equipmentType of changedValues['equipmentTypes']) {
          // If the old equipment types didn't include this type (meaning it's new),
          // then reset the age of it. It's possible we added it before, deleted it,
          // then added it back.
          if (!preexistingEquipmentInfo.includes(equipmentType)) {
            form.setFieldValue(`equipment-age-${equipmentType}`, null)
          }
        }
      }

      // If any equipment-age field changes, force the JobRevenuePotentialForm to re-render. The
      // JobRevenuePotentialForm component contains logic that needs to run whenever an equipment-age
      // value changes
      const equipmentAgeChanged = Object.keys(changedValues).find(key =>
        key.startsWith('equipment-age'),
      )
      if (!isNullish(equipmentAgeChanged)) {
        setForceJobRevenuePotentialRerender(prev => prev + 1)
      }
    }

  const effectiveInitialValues: Partial<CreateOrEditNewJobFormSchema> = {
    ...DEFAULT_VALUES,
    ...(initialValues ?? {}),
    leadSourceGuid: !creatingJobForNewAccount
      ? companyLeadSources.find(
          cls => cls.isDefaultWhenCreatingJobForExistingAccount,
        )?.companyLeadSourceGuid
      : initialValues?.leadSourceGuid,
  }

  const isSmallScreen = useIsSmallScreen()
  const selectedTags = useWatch('tags', form)

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      validateTrigger={['onChange', 'onBlur']}
      onValuesChange={onValuesChange}
      initialValues={effectiveInitialValues}
    >
      {!hiddenFields.includes('locationGuid') && (
        <>
          <div className={`flex ${flexRowSpaceX}`}>
            <div className="w-full">
              <Form.Item
                name="locationGuid"
                label="Location"
                rules={[
                  {
                    required: true,
                    message: 'Location is required',
                  },
                ]}
                className={classNames('mb-1', labelClassName)}
              >
                <BzSelect
                  title="Select a Location"
                  sheetSize="half"
                  placeholder="Select a Location"
                  value={selectedLocationGuid}
                  options={(account?.accountLocations ?? []).map(
                    accountLocation => ({
                      value: accountLocation.location.locationGuid,
                      label: BzAddress.formatAddressSingleLine(
                        accountLocation.location.address,
                      ),
                    }),
                  )}
                  onChange={locationGuid => {
                    form.setFieldsValue({ locationGuid })
                  }}
                  disabled={formMode === 'edit'}
                />
              </Form.Item>
              {formMode === 'edit' && (
                <Typography.Text type="secondary">
                  Location cannot be changed because of impact on scheduling and
                  financial reporting.
                </Typography.Text>
              )}
            </div>
          </div>
          <ThinDivider />
        </>
      )}

      <BehindFeatureFlag
        enabledFeatureFlag="linkedJobs"
        render={
          !isNullish(linkedJobGuid) && (
            <div className="flex flex-col gap-6">
              <LinkJobDataFormSection
                form={form}
                companyGuid={companyGuid}
                linkedJobGuid={linkedJobGuid}
              />

              <Divider className="m-0 mb-6" />
            </div>
          )
        }
      />

      {!hiddenFields.includes('jobTypeGuid') && (
        <JobTypeFormItem {...props} onJobTypeChange={handleJobTypeChange} />
      )}

      {!hiddenFields.includes('businessUnitGuid') && (
        <BehindFeatureFlag
          enabledFeatureFlag="businessUnits"
          render={<BusinessUnitFormItem {...props} />}
        />
      )}

      {showPreexistingEquipmentTypesField && (
        <>
          <div className={`flex ${flexRowSpaceX}`}>
            <div className="w-full">
              <Form.Item
                name="preexistingEquipmentInfo"
                label={preexistingEquipmentTypeLabel}
                rules={[
                  {
                    required: false,
                  },
                ]}
                preserve={false}
                className={classNames('mb-1', labelClassName)}
              >
                <BzSelect
                  mode="multiple"
                  title={preexistingEquipmentTypeLabel}
                  showSearch
                  allowClear
                  filterOption={(inputValue, option) => {
                    return !![option?.children, option?.value, option?.label]
                      .filter(Boolean)
                      .map(v => `${v}`)
                      .find(v => fuzzyMatch(v, inputValue))
                  }}
                  values={preexistingEquipmentInfo}
                  placeholder="Select Equipment Type(s)"
                  options={companyEquipmentTypes.map(name => ({
                    value: name,
                    label: formatEquipmentType(name),
                  }))}
                  onChange={values => {
                    form.setFieldsValue({ preexistingEquipmentInfo: values })
                  }}
                />
              </Form.Item>
              {preexistingEquipmentInfo?.length ? (
                <div className="mb-6">
                  {preexistingEquipmentInfo.map(equipmentType => (
                    <EquipmentLinkFormLine
                      key={equipmentType}
                      form={form}
                      equipmentType={equipmentType}
                    />
                  ))}
                </div>
              ) : null}
            </div>
          </div>
          <ThinDivider />
        </>
      )}
      {jobClass && newEquipementRequiredForJobClass(jobClass) && (
        <NewEquipmentFormItem {...props} jobClass={jobClass} />
      )}

      {/* Hidden form field */}
      {/* TODO: this "name" is causing a console error. I'm not sure this hidden field even does anything, and if it
            does we're probably doing something wrong. */}
      <Form.Item
        hidden
        name="equipmentTypeJobLinks"
        className={labelClassName}
      />

      {account &&
        selectedLocationGuid &&
        !hiddenFields.includes('maintenancePlanVisitGuid') &&
        formMode === 'create' && (
          <CreateOrEditJobMaintenancePlanVisitsField
            form={form}
            account={account}
            jobClass={jobClass}
            locationGuid={selectedLocationGuid}
          />
        )}

      {!hiddenFields.includes('summary') && (
        <>
          <div className={`flex ${flexRowSpaceX}`}>
            <div className="w-full">
              <Form.Item
                name="summary"
                label="Summary"
                className={classNames('mb-1', labelClassName)}
                rules={[
                  {
                    required: true,
                    message: 'Summary is required',
                  },
                ]}
              >
                <TextArea
                  allowClear
                  rows={5}
                  placeholder="Additional information about the customer request"
                />
              </Form.Item>
            </div>
          </div>
          <ThinDivider />
        </>
      )}

      {jobType && (
        <JobRevenuePotentialForm
          account={account}
          jobEquipmentAgeChanged={forceJobRevenuePotentialRerender}
          initialValues={{
            isOpportunity: effectiveInitialValues.isOpportunity ?? false,
            isHotLead: effectiveInitialValues.isHotLead ?? false,
            isMembershipOpportunity:
              effectiveInitialValues.isMembershipOpportunity ?? false,
            isMembershipRenewalOpportunity:
              effectiveInitialValues.isMembershipRenewalOpportunity ?? false,
          }}
          form={form}
          formMode={formMode}
          jobType={jobType}
          jobLocationGuid={selectedLocationGuid}
        />
      )}

      <Form.Item
        label="Job Tags"
        name="tags"
        data-form-id="jobTagsSelect"
        className={classNames('mb-1', labelClassName)}
      >
        <BzSelect
          mode="multiple"
          title="Job Tags"
          placeholder="Please select"
          allowClear
          values={selectedTags ?? []}
          style={{ width: '100%' }}
          options={jobTags.map(tag => ({
            value: tag.tagGuid,
            label: tag.name,
          }))}
          tagRender={(props: { value: string; onClose: () => void }) => {
            const tagToRender = jobTags.find(tag => props.value === tag.tagGuid)
            if (!tagToRender) {
              return <></>
            }
            return <Tag tag={tagToRender} onClose={props.onClose} />
          }}
          filterOption={(input, option) => {
            if (!option || !option?.label) {
              return false
            }

            return option.label
              .toString()
              .toLowerCase()
              .includes(input.toLowerCase())
          }}
          onChange={(tags: string[]) => {
            form.setFieldsValue({ tags })
          }}
        />
      </Form.Item>

      <ThinDivider />

      {!hiddenFields.includes('pointOfContactGuid') && (
        <>
          <div className={`flex ${flexRowSpaceX}`}>
            <div className="w-full">
              <Form.Item
                name="pointOfContactGuid"
                label="Point of Contact"
                rules={[
                  {
                    required: true,
                    message: 'Point of Contact is required',
                  },
                ]}
                className={classNames('mb-1', labelClassName)}
              >
                <BzSelect
                  title="Point of Contact"
                  sheetSize="half"
                  value={pointOfContactGuidValue}
                  options={(account?.accountContacts ?? [])
                    .filter(
                      accountContact =>
                        !accountContact.archived ||
                        accountContact.contact.contactGuid ===
                          pointOfContactGuidValue,
                    )
                    .map(({ contact }) => ({
                      value: contact.contactGuid,
                      label: `${contact.firstName} ${contact.lastName}`,
                    }))}
                  onChange={(contactGuid: string) => {
                    form.setFieldsValue({ pointOfContactGuid: contactGuid })
                  }}
                />
              </Form.Item>
            </div>
          </div>
          <ThinDivider />
        </>
      )}
      {!hiddenFields.includes('leadSourceGuid') && (
        <>
          <LeadSourceSelectFormItem
            form={form}
            className={flexRowSpaceX}
            labelClassName={labelClassName}
            companyLeadSources={companyLeadSources}
            required
          />
          <ThinDivider />
        </>
      )}
      {selectedLeadSource?.attributionLinkingStrategy ===
        'REQUIRE_CONTACT_GUID' &&
        !hiddenFields.includes('leadSourceReferringContactGuid') && (
          <>
            <LeadSourceReferringContactFormItem
              className={flexRowSpaceX}
              initialContactGuid={initialValues?.leadSourceReferringContactGuid}
              onContactUpdated={contact => {
                form.setFieldsValue({
                  leadSourceReferringContactGuid:
                    contact?.contactGuid ?? undefined,
                })
              }}
            />
            <ThinDivider />
          </>
        )}
      {selectedLeadSource?.attributionLinkingStrategy ===
        'REQUIRE_ATTRIBUTION_DESCRIPTION' &&
        !hiddenFields.includes('leadSourceAttributionDescription') && (
          <>
            <LeadSourceAttributionDescriptionFormItem
              className={flexRowSpaceX}
              leadSource={selectedLeadSource}
              defaultValue={initialValues?.leadSourceAttributionDescription}
            />
            <ThinDivider />
          </>
        )}

      <Form.Item
        label="Customer PO Number"
        rules={[
          {
            max: MAX_PO_NUMBER_LENGTH,
            message: `PO number must be less than ${MAX_PO_NUMBER_LENGTH} characters`,
          },
        ]}
        name="customerPurchaseOrderNumber"
        className={classNames('mb-1', labelClassName)}
      >
        <Input
          size={isSmallScreen ? 'large' : 'middle'}
          count={{ max: MAX_PO_NUMBER_LENGTH }}
        />
      </Form.Item>

      {purchaseOrdersEnabled && isEdit && (
        <>
          <ThinDivider />
          <ConfigProvider
            renderEmpty={() => (
              <div className="p-2">Type a Purchase Order Number</div>
            )}
          >
            <Form.Item
              label="Purchase Order Numbers"
              name="purchaseOrderNumbers"
              className={classNames('mb-1', labelClassName)}
            >
              <Select
                size={isSmallScreen ? 'large' : 'middle'}
                mode="tags"
                placeholder="Add a Purchase Order Number"
                popupMatchSelectWidth={false}
                filterSort={(optA, optB) => {
                  if (
                    optA.value &&
                    purchaseOrderNumbers?.includes(optA.value.toString())
                  )
                    return 1
                  if (
                    optB.value &&
                    purchaseOrderNumbers?.includes(optB.value.toString())
                  )
                    return -1
                  return 0
                }}
                optionRender={option => {
                  const includes = purchaseOrderNumbers?.includes(
                    option.data.label,
                  )
                  return (
                    <div className="w-full">
                      {includes
                        ? option.data.label
                        : `+ Add "${option.data.label}"`}
                    </div>
                  )
                }}
              />
            </Form.Item>
          </ConfigProvider>
        </>
      )}

      {initialValues?.workCompletedAt && (
        <>
          {/* This is a hack. The DatePicker strictly requires a Date as input and we want our APIs to use
          IsoDateStrings. Further antd requires an element on the page with a label corresponding to the
          form value for it to keep that value in sync */}
          <Form.Item
            label="Hidden Work Completed On"
            name="workCompletedAt"
            hidden
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Work Completed On"
            className={classNames('mb-1', labelClassName)}
          >
            <DatePicker
              className="w-full"
              format="MMMM D, YYYY"
              defaultValue={BzDateFns.parseISO(
                initialValues.workCompletedAt,
                timezone,
              )}
              onChange={(_, dateString) => {
                if (dateString) {
                  const newDate = BzDateFns.parse(dateString, 'MMMM d, yyyy')
                  if (initialValues.workCompletedAt) {
                    const oldDate = new Date(initialValues.workCompletedAt)
                    newDate.setHours(oldDate.getHours())
                    newDate.setMinutes(oldDate.getMinutes())
                    newDate.setSeconds(oldDate.getSeconds())
                    newDate.setMilliseconds(oldDate.getMilliseconds())
                  } else {
                    newDate.setHours(12)
                    newDate.setMinutes(0)
                    newDate.setSeconds(0)
                    newDate.setMilliseconds(0)
                  }

                  // setWorkCompletedAt(newDate.toISOString())
                  form.setFieldsValue({
                    workCompletedAt: newDate.toISOString(),
                  })
                }
              }}
            />
          </Form.Item>
        </>
      )}

      {!hiddenFields.includes('shouldCreateAppointmentAfterwards') && (
        <>
          <ThinDivider />
          <div className={`flex ${flexRowSpaceX}`}>
            <div className="w-full">
              <Form.Item
                name="shouldCreateAppointmentAfterwards"
                label="Create Appointment After Job Creation?"
                className={classNames('mb-1', labelClassName)}
              >
                <Radio.Group optionType="button">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>
        </>
      )}

      {!hiddenFields.includes('shouldCreateInvoiceFromTemplateAfterwards') && (
        <>
          <div className={`flex ${flexRowSpaceX} my-6`}>
            <div className="w-full">
              <Form.Item
                name="shouldCreateInvoiceFromTemplateAfterwards"
                label="Create Invoice from Template After Job Creation?"
                className={classNames('mb-1', labelClassName)}
              >
                <Radio.Group optionType="button">
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
          </div>

          {shouldCreateInvoiceFromTemplateAfterwards && (
            <div className={`flex ${flexRowSpaceX}`}>
              <div className="w-full">
                <Form.Item
                  name="selectedInvoiceTemplateGuid"
                  label="Select Invoice Template"
                  rules={[
                    {
                      required: !!shouldCreateInvoiceFromTemplateAfterwards,
                    },
                  ]}
                  className={classNames('mb-1', labelClassName)}
                >
                  <BzSelect
                    title="Select Invoice Template"
                    value={selectedInvoiceTemplateGuid}
                    placeholder="Please select"
                    allowClear
                    showSearch
                    disabled={invoiceTemplates.length === 0}
                    filterOption={(inputValue, option) => {
                      return !![option?.children, option?.value, option?.label]
                        .filter(Boolean)
                        .map(v => `${v}`)
                        .find(v => fuzzyMatch(v, inputValue))
                    }}
                    options={invoiceTemplates.map(t => ({
                      value: t.invoiceTemplateGuid,
                      label: t.templateName,
                    }))}
                    onChange={(value: string) => {
                      form.setFieldsValue({
                        selectedInvoiceTemplateGuid: value,
                      })
                    }}
                  />
                  <div>
                    {invoiceTemplates.length === 0 && (
                      <span className="text-[14px] leading-[22px] text-[#8C8C8C]">
                        You currently have no saved templates.{' '}
                      </span>
                    )}
                    <Link
                      to={OfficeRoutes.INVOICE_TEMPLATES.path}
                      target="_blank"
                      className="mt-[2px] font-[14px] leading-[22px]"
                    >
                      Manage invoice templates
                    </Link>
                  </div>
                </Form.Item>
              </div>
            </div>
          )}
        </>
      )}

      {showDivider && <Divider />}
      {showFormCancelSubmitButtons && (
        <>
          <div className={`flex ${flexRowSpaceX}`}>
            <div className="w-full">
              <div
                className={classNames(
                  `flex justify-${justifyFormCancelSubmitButtons} ${flexRowSpaceX}`,
                  {
                    'flex flex-1 flex-row justify-end space-x-2': isSmallScreen,
                  },
                )}
              >
                <Form.Item
                  className={classNames(labelClassName, {
                    'flex-1': isSmallScreen,
                  })}
                >
                  <Button
                    onClick={onCancel}
                    block={isSmallScreen}
                    size={isSmallScreen ? 'large' : 'middle'}
                  >
                    Cancel
                  </Button>
                </Form.Item>
                <Form.Item
                  className={classNames(labelClassName, {
                    'flex-1': isSmallScreen,
                  })}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    block={isSmallScreen}
                    size={isSmallScreen ? 'large' : 'middle'}
                  >
                    {formMode === 'create' ? 'Create New job' : 'Update job'}
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
          <ThinDivider />
        </>
      )}
    </Form>
  )
})

type EquipmentLinkFormLineProps = {
  form: FormInstance<CreateOrEditNewJobFormSchema>
  equipmentType: EquipmentType
}

const EquipmentLinkFormLine = m<EquipmentLinkFormLineProps>(
  ({ form, equipmentType }) => {
    const fieldValue = useWatch(`equipment-age-${equipmentType}`, form)
    return (
      <div className="mb-2 flex items-center px-2">
        <div className="required mr-2">
          Age of{' '}
          <span className="font-bold">
            {formatEquipmentType(equipmentType)}
          </span>
          :
        </div>
        <Form.Item
          name={`equipment-age-${equipmentType}`}
          rules={[
            {
              required: true,
              message: 'Age is required',
            },
          ]}
          className="m-0"
        >
          <BzSelect
            className="lg:min-w-[120px]"
            title="Age"
            sheetSize="half"
            size="middle"
            value={fieldValue}
            placeholder="Age"
            options={ESTIMATED_EQUIPMENT_AGE_OPTIONS_SELECT_OPTIONS.map(
              opt => ({
                label: opt.value,
                value: opt.value,
              }),
            )}
            onChange={(age: string) => {
              form.setFieldsValue({ [`equipment-age-${equipmentType}`]: age })
            }}
          />
        </Form.Item>
      </div>
    )
  },
)

const NewEquipmentFormItem = React.memo<
  CreateOrEditJobFormInnerProps & { jobClass?: JobClass }
>(props => {
  const newEquipmentInfo = useWatch('newEquipmentInfo', props.form)

  const newEquipmentFormLabel = useMemo(() => {
    switch (props.jobClass) {
      case JobClass.SALES:
        return 'Equipment interested in'
      default:
        return 'Equipment to Install'
    }
  }, [props.jobClass])

  return (
    <>
      <div className={`flex ${props.flexRowSpaceX}`}>
        <div className="w-full">
          <Form.Item
            name="newEquipmentInfo"
            label={newEquipmentFormLabel}
            className={classNames('mb-1', props.labelClassName)}
            preserve={false}
          >
            <BzSelect
              mode="multiple"
              title={newEquipmentFormLabel}
              showSearch
              placeholder="Select Equipment Type(s)"
              allowClear
              values={newEquipmentInfo ?? []}
              options={props.companyEquipmentTypes.map(name => ({
                value: name,
                label: formatEquipmentType(name),
              }))}
              filterOption={(inputValue, option) => {
                return option?.children
                  ? fuzzyMatch(`${option?.children}`, inputValue)
                  : false
              }}
              onChange={values => {
                props.form.setFieldsValue({ newEquipmentInfo: values })
              }}
            />
          </Form.Item>
        </div>
      </div>
      <ThinDivider />
    </>
  )
})

type JobTypeFormItemProps = CreateOrEditJobFormInnerProps & {
  onJobTypeChange: (jobTypeGuid: string) => void
}

const JobTypeFormItem = React.memo<JobTypeFormItemProps>(props => {
  const jobTypeOptions = useMemo(
    () =>
      props.jobTypes.map(({ name, jobTypeGuid, archivedAt }) => ({
        value: jobTypeGuid,
        label: isNullish(archivedAt) ? name : `${name} (archived)`,
      })) ?? [],
    [props.jobTypes],
  )

  const selectedJobTypeGuid = useWatch('jobTypeGuid', props.form)
  const selectedJobType = useMemo(
    () => jobTypeOptions.find(option => option.value === selectedJobTypeGuid),
    [jobTypeOptions, selectedJobTypeGuid],
  )

  return (
    <>
      <div className={`flex ${props.flexRowSpaceX}`}>
        <div className="w-full">
          <Form.Item
            name="jobTypeGuid"
            label="Job Type"
            rules={[
              {
                required: true,
                message: 'Job Type is required',
              },
            ]}
            className={classNames('mb-1', props.labelClassName)}
          >
            <BzSelect
              title="Job Type"
              value={selectedJobType?.value ?? ''}
              disabled={!props.jobTypes.length}
              placeholder="Select a Job Type"
              allowClear
              showSearch
              optionFilterProp="label"
              options={jobTypeOptions}
              onChange={props.onJobTypeChange}
            />
          </Form.Item>
        </div>
      </div>
      <ThinDivider />
    </>
  )
})

const BusinessUnitFormItem = m<CreateOrEditJobFormInnerProps>(props => {
  const businessUnitOptions = useMemo(
    () =>
      props.businessUnits?.map(({ name, businessUnitGuid }) => ({
        value: businessUnitGuid,
        label: name,
      })),
    [props.businessUnits],
  )

  const selectedBusinessUnitGuid = useWatch('businessUnitGuid', props.form)
  const selectedBusinessUnit = useMemo(
    () =>
      businessUnitOptions?.find(
        option => option.value === selectedBusinessUnitGuid,
      ),
    [businessUnitOptions, selectedBusinessUnitGuid],
  )

  if (!businessUnitOptions) return null

  return (
    <>
      <div className={`flex ${props.flexRowSpaceX}`}>
        <div className="w-full">
          <Form.Item
            name="businessUnitGuid"
            label="Business Unit"
            className={classNames('mb-1', props.labelClassName)}
            rules={[
              {
                required: props.businessUnitsRequired,
                message: 'Business Unit is required',
              },
            ]}
          >
            <BzSelect
              title="Business Unit"
              value={selectedBusinessUnit?.value ?? ''}
              disabled={!props.businessUnits?.length}
              placeholder="Select a Business Unit"
              allowClear
              showSearch
              optionFilterProp="label"
              options={businessUnitOptions}
              onChange={(businessUnitGuid: string) => {
                props.form.setFieldsValue({ businessUnitGuid })
              }}
            />
          </Form.Item>
        </div>
      </div>
      <ThinDivider />
    </>
  )
})
