import {
  BzDateFns,
  OfficeRoutes,
  formatMoney,
  usCentsToUsd,
} from '@breezy/shared'
import { Modal } from 'antd'
import React, { useState } from 'react'
import { useQuery } from 'urql'
import { CardBody } from '../../elements/Card/Card'
import { EmDash } from '../../elements/EmDash/EmDash'
import { Link } from '../../elements/Link/Link'
import { gql } from '../../generated'
import { UnpaidInvoicesV2Query } from '../../generated/user/graphql'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { Tile } from './Tile'

const CELL_CLASS = 'h-[68px]'

const UNPAID_INVOICES_V2_QUERY = gql(/* GraphQL */ `
  query UnpaidInvoicesV2($where: AggregatableInvoicesBoolExp) {
    aggregatableInvoices(where: { dueUsc: { _gt: 0 } }) {
      invoiceGuid
      displayIdV2
      dueUsc
      dueAt
      paidUsc
      totalUsc
      invoice {
        billingContact {
          fullName
        }
        account {
          accountGuid
          accountDisplayName
        }
      }
    }
    aggregatableInvoicesAggregate(where: { dueUsc: { _gt: 0 } }) {
      aggregate {
        count
        sum {
          totalUsc
        }
      }
    }
  }
`)

const useUnpaidInvoicesV2 = (): {
  unpaidInvoices: UnpaidInvoicesV2Query['aggregatableInvoices']
  count: number
  fetching: boolean
} => {
  const [{ data, fetching }] = useQuery({
    query: UNPAID_INVOICES_V2_QUERY,
  })

  const count = data?.aggregatableInvoicesAggregate.aggregate?.count ?? 0
  return {
    unpaidInvoices: data?.aggregatableInvoices ?? [],
    count,
    fetching,
  }
}

export const UnpaidInvoicesV2Tile = React.memo(() => {
  const { unpaidInvoices, count, fetching } = useUnpaidInvoicesV2()

  const [modalOpen, setModalOpen] = useState(false)

  return (
    <>
      <Tile
        title="Unpaid Invoices"
        loading={fetching}
        footerText="These are invoices that have an outstanding balance and are not marked as 'void' or 'uncollectable'."
        onClick={() => {
          if (count) {
            setModalOpen(true)
          }
        }}
      >
        {count}
      </Tile>
      {modalOpen && (
        <Modal
          open
          closeIcon={false}
          onCancel={() => setModalOpen(false)}
          footer={null}
          width="50%"
        >
          <CardBody
            hideTitleDivider
            title="Unpaid Invoices"
            className="mx-[-1.5rem] mb-[-1rem]"
          >
            <div className="max-h-[75vh] overflow-auto px-6">
              <table className="w-full">
                <thead>
                  <tr className="h-5">
                    {[
                      'Inv. #',
                      'Billing Name',
                      'Due Date',
                      'Balance',
                      'Paid',
                      'Total',
                    ].map(header => (
                      <th
                        key={header}
                        className="pb-4 text-left text-sm font-semibold uppercase text-bz-gray-800"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {unpaidInvoices.map(invoice => (
                    <UnpaidInvoiceTableRow
                      key={invoice.invoiceGuid}
                      invoice={invoice}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Modal>
      )}
    </>
  )
})

type UnpaidInvoiceTableRowProps = {
  invoice: UnpaidInvoicesV2Query['aggregatableInvoices'][number]
}

const UnpaidInvoiceTableRow = React.memo<UnpaidInvoiceTableRowProps>(
  ({ invoice }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const dueUsc = invoice.dueUsc ?? 0
    const paidUsc = invoice.paidUsc ?? 0
    const totalUsc = invoice.totalUsc ?? 0

    return (
      <tr
        key={invoice.invoiceGuid}
        className="border-0 border-t border-solid border-y-bz-gray-400 text-sm last:border-b"
      >
        <td className={CELL_CLASS}>
          <Link to={`/invoice/${invoice.invoiceGuid}`}>
            {invoice.displayIdV2}
          </Link>
        </td>
        <td className={CELL_CLASS}>
          <Link
            to={OfficeRoutes.ACCOUNT_DETAILS.build({
              params: {
                accountGuid: invoice.invoice?.account.accountGuid ?? '',
              },
            })}
          >
            {invoice.invoice?.billingContact?.fullName ??
              invoice.invoice?.account?.accountDisplayName}
          </Link>
        </td>
        <td className={CELL_CLASS}>
          {invoice.dueAt ? (
            BzDateFns.formatFromISO(invoice.dueAt, 'MMM d, yyyy', tzId)
          ) : (
            <EmDash />
          )}
        </td>
        <td className={CELL_CLASS}>{formatMoney(usCentsToUsd(dueUsc))}</td>
        <td className={CELL_CLASS}>{formatMoney(usCentsToUsd(paidUsc))}</td>
        <td className={CELL_CLASS}>{formatMoney(usCentsToUsd(totalUsc))}</td>
      </tr>
    )
  },
)
