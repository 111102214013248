import { PageHeader } from '@ant-design/pro-components'
import { BzDateFns, IsoDateString } from '@breezy/shared'
import { faReceipt, faTrash } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Row } from 'antd'
import { useCallback, useMemo } from 'react'
import { useMutation } from 'urql'
import {
  ConfirmationModal,
  useConfirmationModal,
} from '../../adam-components/OnsiteModal/useConfirmationModal'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { ReadEstimateTemplatesQuery } from '../../generated/user/graphql'
import { useEstimateTemplates } from '../../hooks/useEstimateTemplates'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { DELETE_ESTIMATE_TEMPLATE_MUTATION } from './EstimateTemplates.gql'

export const NumberLabelPair = ({
  kpi,
  description,
}: {
  kpi: string
  description: string
}) => (
  <div className={`flex w-[140px] flex-col`}>
    <div className="text-sm font-semibold leading-[22px] text-[#1F1F1F]">
      {kpi}
    </div>
    <div className="text-sm font-normal leading-[22px] text-[#8C8C8C]">
      {description}
    </div>
  </div>
)

type EstimateTemplateItemProps = {
  estimateTemplateGuid: string
  templateName: string
  updatedAt: IsoDateString
  numOptions: number
  onMutate: () => void
}

const EstimateTemplateItem = ({
  estimateTemplateGuid,
  templateName,
  updatedAt,
  numOptions,
  onMutate,
}: EstimateTemplateItemProps) => {
  const tzId = useExpectedCompanyTimeZoneId()
  const formattedUpdatedAt = useMemo(
    () => BzDateFns.format(BzDateFns.parseISO(updatedAt, tzId), 'MM/dd/yy'),
    [updatedAt, tzId],
  )

  const [{ fetching: deleteEstimateTemplateFetching }, deleteEstimateTemplate] =
    useMutation(DELETE_ESTIMATE_TEMPLATE_MUTATION)

  const [withDeleteConfirmation, deleteConfirmProps] = useConfirmationModal()

  const deleteTemplate = useCallback(async () => {
    await deleteEstimateTemplate({ estimateTemplateGuid })
    onMutate()
  }, [deleteEstimateTemplate, estimateTemplateGuid, onMutate])

  return (
    <>
      <Row className="row center-children-v flex-between flex w-full gap-x-8 rounded-lg border border-solid border-[#D9D9D9] px-6 py-4">
        <div className="center-children-v flex flex-1 flex-row gap-x-4">
          <div className="center-children-vh flex h-9 w-9 items-center rounded-full bg-daybreak-blue-100">
            <FontAwesomeIcon
              icon={faReceipt}
              className="h-4 w-4 text-[#002C8C]"
            />
          </div>

          <div className="my-auto text-base font-semibold leading-6 text-[#1F1F1F]">
            {templateName}
          </div>
        </div>

        <NumberLabelPair
          kpi={`${numOptions}`}
          description={`Option${numOptions === 1 ? '' : 's'}`}
        />

        <NumberLabelPair
          kpi={`${formattedUpdatedAt}`}
          description="Last updated"
        />

        <div className="flex flex-row gap-2">
          <Button
            danger
            onClick={() => withDeleteConfirmation(deleteTemplate)}
            icon={<FontAwesomeIcon icon={faTrash} />}
            loading={deleteEstimateTemplateFetching}
          />
        </div>
      </Row>
      <ConfirmationModal
        {...deleteConfirmProps}
        danger
        header="Delete estimate template?"
        confirmText="Yes, Delete"
        cancelText="Cancel"
      >
        Are you sure you want to delete "
        <span className="font-semibold">{templateName}</span>"? This action
        cannot be undone.
      </ConfirmationModal>
    </>
  )
}

type EstimateTemplateListProps = {
  templates: EstimateTemplates
  onMutate: () => void
}

const EstimateTemplateList = ({
  templates,
  onMutate,
}: EstimateTemplateListProps) => {
  if (templates.length === 0) {
    return (
      <div className="flex min-h-[300px] flex-col items-center justify-center bg-[#FAFAFA]">
        <div className="flex flex-col items-center">
          <div className="mb-4 flex h-[56px] w-[56px] items-center rounded-[100px] border border-solid border-[#F5F5F5] bg-white p-2 shadow-[0px_1px_2px_0px_rgba(37,37,37,0.15)]">
            <FontAwesomeIcon
              icon={faReceipt}
              className="m-auto h-[24px] w-[24px] text-daybreak-blue-400"
            />
          </div>
          <div className="mb-2 text-center text-[20px] font-semibold leading-[28px] text-[#434343]">
            No estimate templates
          </div>
          <div className="mb-6 max-w-[512px] text-center text-[14px] font-normal leading-[22px] tracking-[-0.14px] text-[#595959]">
            Estimate templates will be displayed here
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      {templates.map(template => (
        <EstimateTemplateItem
          key={template.estimateTemplateGuid}
          {...template}
          numOptions={template.estimateOptions.length}
          onMutate={onMutate}
        />
      ))}
    </div>
  )
}

type EstimateTemplates = NonNullable<
  ReadEstimateTemplatesQuery['estimateTemplates']
>

export const EstimateTemplatesPage = () => {
  const [templates, fetching, refetch] = useEstimateTemplates()

  return (
    <div className="flex w-full min-w-[700px] flex-col">
      <PageHeader
        title={<PageTitle title="Estimate Templates" icon={faReceipt} />}
        className="mb-2"
      />
      {fetching && templates.length < 1 ? (
        <LoadingSpinner />
      ) : (
        <div className="flex min-h-0 flex-col gap-y-6">
          <div className="card flex min-h-0 w-full flex-1 flex-col overflow-auto py-8">
            <div className="mb-6 max-w-[512px] text-sm font-normal leading-[22px] text-bz-gray-700">
              Estimate templates allow you to create, customize, and manage
              standardized templates to streamline your sales process.
            </div>
            <EstimateTemplateList templates={templates} onMutate={refetch} />
          </div>
        </div>
      )}
    </div>
  )
}

export default EstimateTemplatesPage
