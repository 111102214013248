import { BzDateFns, formatUsc, IsoDateString, TimeZoneId } from '@breezy/shared'
import { useQuery, useSubscription } from 'urql'

import { useMemo } from 'react'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../../providers/PrincipalUser'
import {
  AggregatableInvoice,
  OPEN_ACCOUNT_BALANCE_SUBSCRIPTION,
  OVERDUE_ACCOUNT_BALANCE_CONFIG_QUERY,
} from './AccountDetailsBanner.gql'

/** @deprecated use getOpenAccountBalance and add aggregatableInvoicesAggregate to the account query instead */
export type OpenAccountBalanceOld = {
  overdueBalanceUsc: number
  overdueBalanceFormatted: string | undefined
  ageOfOldestOpenInvoice: number | undefined
  openInvoices: AggregatableInvoice[] | undefined
  getAgeOfInvoice: (invoice: AggregatableInvoice) => number | undefined
}

export type OpenAccountBalance = {
  overdueBalanceUsc: number
  overdueBalanceFormatted: string
  ageOfInvoice: number
}

/** @deprecated use getOpenAccountBalance and add aggregatableInvoicesAggregate to the account query instead */
export const useOpenAccountBalance = (
  accountGuid: string,
): undefined | OpenAccountBalanceOld => {
  const tzId = useExpectedCompanyTimeZoneId()
  const overdueAccountBalanceEnabled = useOverdueAccountBalanceEnabled()
  const [{ data, fetching }] = useSubscription({
    query: OPEN_ACCOUNT_BALANCE_SUBSCRIPTION,
    variables: { accountGuid },
    pause: !overdueAccountBalanceEnabled,
  })
  if (!overdueAccountBalanceEnabled) return undefined
  if (fetching) return undefined
  if (!data) return undefined
  const { aggregatableInvoicesAggregate } = data
  const openInvoices = aggregatableInvoicesAggregate?.nodes ?? undefined

  const overdueBalanceUsc =
    aggregatableInvoicesAggregate?.nodes?.reduce(
      (acc, node) => acc + (node.dueUsc ?? 0),
      0,
    ) ?? 0

  const oldestOpenInvoice =
    overdueBalanceUsc > 0
      ? openInvoices?.sort(
          (a, b) =>
            new Date(a.dueAt ?? '').getTime() -
            new Date(b.dueAt ?? '').getTime(),
        )[0] ?? undefined
      : undefined
  const endOfToday = BzDateFns.endOfDay(BzDateFns.now(tzId))
  const startOfToday = BzDateFns.startOfDay(endOfToday)

  const getAgeOfInvoice = (invoice: AggregatableInvoice) => {
    const age = invoice.dueAt
      ? BzDateFns.differenceInDays(
          startOfToday,
          BzDateFns.parseISO(invoice.dueAt, tzId),
        )
      : 0
    return age
  }
  const ageOfOldestOpenInvoice = oldestOpenInvoice
    ? getAgeOfInvoice(oldestOpenInvoice)
    : undefined

  return {
    overdueBalanceUsc,
    overdueBalanceFormatted:
      overdueBalanceUsc > 0 ? formatUsc(overdueBalanceUsc) : undefined,
    ageOfOldestOpenInvoice,
    openInvoices,
    getAgeOfInvoice,
  }
}

export const getOpenAccountBalance = (
  tzId: TimeZoneId,
  aggregatableInvoice?:
    | {
        status: 'OPEN' | string
        dueUsc?: number | undefined
        dueAt?: IsoDateString | undefined
      }
    | undefined,
): undefined | OpenAccountBalance => {
  if (!aggregatableInvoice) return undefined
  const { status, dueUsc, dueAt } = aggregatableInvoice
  if (status !== 'OPEN' || !dueAt || !dueUsc || dueUsc <= 0) return undefined
  const endOfToday = BzDateFns.endOfDay(BzDateFns.now(tzId))
  const startOfToday = BzDateFns.startOfDay(endOfToday)
  const ageOfInvoice = dueAt
    ? BzDateFns.differenceInDays(startOfToday, BzDateFns.parseISO(dueAt, tzId))
    : 0
  return {
    overdueBalanceUsc: dueUsc,
    overdueBalanceFormatted: formatUsc(dueUsc),
    ageOfInvoice,
  }
}

export const useOverdueAccountBalanceEnabled = () => {
  const overdueAccountBalanceFFEnabled = useFeatureFlag(
    'overdue-account-balance',
  )
  const companyGuid = useExpectedCompanyGuid()
  const [{ data }] = useQuery({
    query: OVERDUE_ACCOUNT_BALANCE_CONFIG_QUERY,
    variables: { companyGuid },
  })
  const overdueAccountBalanceConfigEnabled = useMemo(
    () => data?.companyConfig[0]?.overdueAccountBalanceEnabled ?? false,
    [data],
  )

  return useMemo(
    () => overdueAccountBalanceFFEnabled && overdueAccountBalanceConfigEnabled,
    [overdueAccountBalanceFFEnabled, overdueAccountBalanceConfigEnabled],
  )
}
