import { z } from 'zod'
import { AsyncFn } from '../../../common'
import { guidSchema } from '../../../contracts/_common'
import { bzOptional } from '../../common-schemas'
import { PurchaseOrder } from '../PurchaseOrder'

const FindAllPurchaseOrdersForAJobQuerySchema = z.object({
  jobGuid: guidSchema,
  limit: bzOptional(z.number()),
  offset: bzOptional(z.number()),
})

export type FindAllPurchaseOrdersForAJobQuery = z.infer<typeof FindAllPurchaseOrdersForAJobQuerySchema>

export const PurchaseOrdersQuerySchema = FindAllPurchaseOrdersForAJobQuerySchema
export type PurchaseOrdersQuery = z.infer<typeof PurchaseOrdersQuerySchema>

export type PurchaseOrderQuerierResult = {
  purchaseOrders: Array<PurchaseOrder>
  totalCount?: number
}

export type IPurchaseOrderQuerier = AsyncFn<PurchaseOrdersQuery, PurchaseOrderQuerierResult>

export type IFindAllPurchaseOrdersForAJobQuerier = AsyncFn<
  FindAllPurchaseOrdersForAJobQuery,
  PurchaseOrderQuerierResult
>
