import { AddressGuid } from '@breezy/shared'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, MenuProps } from 'antd'
import { useCallback, useMemo } from 'react'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { EmDash } from 'src/elements/EmDash/EmDash'
import { Link } from 'src/elements/Link/Link'
import StatusTag from '../../elements/StatusTag/StatusTag'

export type LocationCardLocation = {
  locationGuid: string
  yearBuilt?: string
  squareFootage?: number
  displayName?: string
  address: {
    addressGuid: string
    line1: string
    city: string
    stateAbbreviation: string
    zipCode: string
  }
}
export interface LocationCardProps {
  location: LocationCardLocation
  billingAddressGuid?: AddressGuid
  onEdit?: (location: LocationCardLocation) => void
  onMakeBillingAddress?: (location: LocationCardLocation) => void
}

export const LocationCard = (props: LocationCardProps) => {
  const { location } = props
  const address = `${location.address.line1}, ${location.address.city}, ${location.address.stateAbbreviation} ${location.address.zipCode}`

  const dropdownItems = useMemo<NonNullable<MenuProps['items']>>(() => {
    const items: MenuProps['items'] = []

    if (props.onEdit) {
      items.push({
        key: `${location.locationGuid}-edit`,
        label: 'Edit',
      })
    }

    if (props.onMakeBillingAddress) {
      items.push({
        key: `${location.locationGuid}-make-billing-address`,
        label: 'Make Billing Address',
      })
    }

    return items
  }, [location.locationGuid, props.onEdit, props.onMakeBillingAddress])

  const onDropdownClick: NonNullable<MenuProps['onClick']> = useCallback(
    ({ key }) => {
      switch (key) {
        case `${location.locationGuid}-edit`:
          props.onEdit?.(location)
          break
        case `${location.locationGuid}-make-billing-address`:
          props.onMakeBillingAddress?.(location)
          break
        default:
          break
      }
    },
    [location, props],
  )

  return (
    <SectionedCard
      small
      dashed
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div
              className="flex flex-row items-center justify-between"
              data-testid="location-card-display-name"
            >
              <Link
                to={`/locations/${location.locationGuid}`}
                bold
                className="text-sm"
              >
                {location.displayName ?? address}
              </Link>

              <div className="flex flex-row items-center gap-2">
                {props.billingAddressGuid === location.address.addressGuid && (
                  <StatusTag color="darkGray" text="Billing" border="strong" />
                )}

                {dropdownItems.length > 0 && (
                  <Dropdown
                    menu={{ items: dropdownItems, onClick: onDropdownClick }}
                  >
                    <FontAwesomeIcon
                      icon={faEllipsis}
                      className="cursor-pointer"
                      data-testid="location-card-dropdown-icon"
                    />
                  </Dropdown>
                )}
              </div>
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Address',
                <Link
                  to={`/locations/${location.locationGuid}`}
                  className="text-bz-primary no-underline"
                >
                  {address}
                </Link>,
                'Year Built',
                location.yearBuilt ? location.yearBuilt : <EmDash />,
                'Square Footage',
                location.squareFootage ? (
                  `${location.squareFootage} sq. ft`
                ) : (
                  <EmDash />
                ),
              ]}
            />
          ),
        },
      ]}
    />
  )
}
