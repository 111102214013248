import {
  BzDateFns,
  CalculatePaths,
  formatMoney,
  isNullish,
  IsoDateString,
  LoanRecord,
  PaymentMethod,
  paymentMethodDisplayName,
  PaymentStatus,
  SharedRoutes,
  usCentsToUsd,
} from '@breezy/shared'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { Link } from 'src/elements/Link/Link'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import LoanDetailsLink from '../Financing/LoanDetailsLink/LoanDetailsLink'
import { JobDetailsLink } from '../Links/EntityLinks'
import PaymentStatusTag from '../Payments/PaymentStatusTag'
export type PaymentCardPayment = {
  paymentRecordGuid: string
  paymentMethod: PaymentMethod
  amountUsd: number
  referenceNumber: string
  status: PaymentStatus
  occurredAt: IsoDateString
  displayId?: string
  jobGuid?: string
  invoiceGuid?: string
  // TODO: Refactor so we are not relying on the full LoanRecord object
  loanRecord?: LoanRecord
  invoicePayments: {
    appliedAmountUsc: number
    invoiceGuid: string
    displayId: string
  }[]
}

export interface PaymentCardProps {
  payment: PaymentCardPayment

  /**
   * Should the job link be shown? Defaults to true
   */
  showJobLink?: boolean
}

export const PaymentCard = (props: PaymentCardProps) => {
  const { payment } = props
  const tzId = useExpectedCompanyTimeZoneId()
  const showJobLink = isNullish(props.showJobLink) ? true : props.showJobLink

  return (
    <SectionedCard
      small
      dashed
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div className="flex flex-row items-center justify-between">
              <Link
                to={`${SharedRoutes.PAYMENT_DETAILS.build({
                  params: { paymentRecordGuid: payment.paymentRecordGuid },
                })}`}
                bold
                className="text-sm"
              >
                {formatMoney(payment.amountUsd)} (
                {paymentMethodDisplayName(payment.paymentMethod)})
              </Link>

              <PaymentStatusTag status={payment.status} />
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Date',
                BzDateFns.formatFromISO(
                  payment.occurredAt,
                  'MMM d, yyyy',
                  tzId,
                ),
                ...payment.invoicePayments
                  .map(ip => {
                    return [
                      `Invoice ${ip.displayId}`,
                      <Link
                        to={CalculatePaths.invoiceOverview({
                          invoiceGuid: ip.invoiceGuid,
                        })}
                      >
                        {formatMoney(usCentsToUsd(ip.appliedAmountUsc))}{' '}
                        {usCentsToUsd(ip.appliedAmountUsc) < payment.amountUsd
                          ? ' Applied'
                          : ''}
                      </Link>,
                    ]
                  })
                  .flat(),
                ...(showJobLink && payment.jobGuid
                  ? ['Job', <JobDetailsLink guid={payment.jobGuid} />]
                  : []),
                ...(payment.loanRecord
                  ? [
                      'Loan Details',
                      // TODO: Refactor LoanDetailsLink so it takes the minimal
                      // amount of data
                      <LoanDetailsLink record={payment.loanRecord} />,
                    ]
                  : []),
              ]}
            />
          ),
        },
      ]}
    />
  )
}
