import {
  CartItem,
  CartItemType,
  PricebookItemTypeEnum,
  R,
  bzOptional,
  displayNameForCartItemType,
  guidSchema,
  htmlStringSchema,
  nextGuid,
} from '@breezy/shared'
import { faTags } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'urql'
import { z } from 'zod'
import {
  OnsiteModalContent,
  OnsiteModalFooter,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import { CloseConfirmModal } from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import { NumberField } from '../../elements/Forms/NumberField'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../elements/Forms/SelectField'
import { SwitchField } from '../../elements/Forms/SwitchField'
import { TextAreaField } from '../../elements/Forms/TextAreaField'
import { TextField } from '../../elements/Forms/TextField'
import { useReactHookFormSubmit } from '../../elements/Forms/useReactHookFormSubmit'
import { SetIsDirty, useExternalIsDirty } from '../../hooks/useExternalIsDirty'
import useIsMobile from '../../hooks/useIsMobile'
import { useIsPricebookPhotosEnabled } from '../../hooks/useIsPricebookPhotosEnabled'
import {
  PricebookItems,
  PricebookPickerCategory,
  PricebookPickerItem,
  usePricebook,
} from '../../providers/PricebookProvider'
import {
  useExpectedCompanyGuid,
  useExpectedUserGuid,
} from '../../providers/PrincipalUser'
import { useModalState } from '../../utils/react-utils'
import { BehindFeatureFlag } from '../BehindFeatureFlag'
import {
  AsyncPhotoUploadWithThumbnail,
  OnPhotoUploadChange,
} from '../Upload/AsyncPhotoUpload'
import { useSynchronousUpload } from '../Upload/Upload'
import { WysiwygEditor } from '../WysiwygEditor/WysiwygEditor'
import { INSERT_PRICEBOOK_ITEM } from './PricebookPickers.gql'

type PricebookOption = {
  label: string
  value: string
}

const makeCategoryOptions = (items: PricebookItems): PricebookOption[] => {
  const options: PricebookOption[] = []

  const drillDown = (
    categoryOrItem: PricebookPickerItem | PricebookPickerCategory,
    prefix: string,
  ) => {
    if ('items' in categoryOrItem) {
      const newPrefix = prefix
        ? `${prefix} > ${categoryOrItem.name}`
        : categoryOrItem.name
      options.push({ label: newPrefix, value: categoryOrItem.id })
      for (const item of categoryOrItem.items) {
        drillDown(item, newPrefix)
      }
    }
  }

  for (const item of items) {
    drillDown(item, '')
  }

  return options
}

const CART_ITEM_TYPE_OPTIONS = Object.values(CartItemType).map(value => ({
  value,
  label: displayNameForCartItemType(value),
}))

const lineItemFormSchema = z
  .object({
    name: z.string().min(1, "Name can't be empty"),
    itemType: z.nativeEnum(CartItemType),
    unitPriceUsd: z.number(),
    quantity: z.number().nonnegative(),
    isTaxable: z.boolean(),
    isDiscountable: z.boolean(),
    description: bzOptional(htmlStringSchema),
    saveToPricebook: bzOptional(z.boolean()),
    pricebookCategoryGuid: bzOptional(guidSchema),
    photoGuid: bzOptional(guidSchema),
    photoCdnUrl: bzOptional(z.string()),
    cartItemCode: bzOptional(z.string()),
  })
  .refine(
    data => {
      if (
        data.saveToPricebook &&
        data.pricebookCategoryGuid &&
        !Object.values(PricebookItemTypeEnum).includes(
          data.itemType as unknown as PricebookItemTypeEnum,
        )
      ) {
        return false
      }
      return true
    },
    {
      message:
        "A pricebook item must have a type that's one of: Service, Material, Equipment, or Labor.",
      path: ['pricebookCategoryGuid'],
    },
  )

type LineItemFormSchema = z.infer<typeof lineItemFormSchema>

type LineItemFormProps = {
  onCancel: () => void
  onBack?: () => void
  onSave: (item: CartItem & { savedToPricebook?: boolean }) => void
  onRemove?: () => void
  setIsDirty?: SetIsDirty
  defaultValues?: Partial<CartItem>
  title?: string
}

export const LineItemForm = React.memo<LineItemFormProps>(
  ({
    onCancel,
    onSave,
    onBack,
    setIsDirty,
    defaultValues = {
      quantity: 1,
      isTaxable: true,
      isDiscountable: true,
    },
    title = 'Create line item',
    onRemove,
  }) => {
    const companyGuid = useExpectedCompanyGuid()
    const userGuid = useExpectedUserGuid()
    const isMobile = useIsMobile()
    const { pricebookPhotosEnabled } = useIsPricebookPhotosEnabled()

    const { items, loaded } = usePricebook()

    const categoryOptions = useMemo(() => {
      const categories = makeCategoryOptions(items)
      return R.sortBy(R.prop('label'), categories)
    }, [items])

    const {
      formState: { errors, isDirty },
      control,
      handleSubmit,
      watch,
      setValue,
    } = useForm<LineItemFormSchema>({
      resolver: zodResolver(lineItemFormSchema),
      defaultValues,
    })

    useExternalIsDirty(setIsDirty, isDirty)

    const saveToPricebook = watch('saveToPricebook')
    const photoCdnUrl = watch('photoCdnUrl')
    const [{ fetching: insertingItem }, executeInsertMutation] = useMutation(
      INSERT_PRICEBOOK_ITEM,
    )

    const onSubmit = useCallback(
      async ({
        saveToPricebook,
        pricebookCategoryGuid,
        ...data
      }: LineItemFormSchema) => {
        const newPricebookItemGuid = nextGuid()
        const itemGuid = defaultValues.itemGuid ?? newPricebookItemGuid
        if (saveToPricebook) {
          await executeInsertMutation({
            pricebookItem: {
              pricebookItemGuid: newPricebookItemGuid,
              createdByUserGuid: userGuid,
              companyGuid,
              costUsd: 0,
              // TODO: https://getbreezyapp.atlassian.net/browse/BZ-3170 and https://getbreezyapp.atlassian.net/browse/BZ-3188
              description: (data.description ?? '') as unknown as string,
              isDiscountable: data.isDiscountable,
              isTaxable: data.isTaxable,
              // I validate in zod that the itemType (which is a cart item type, which has more values than a pricebook item type) is limited to pricebook item values.
              itemType: data.itemType as unknown as PricebookItemTypeEnum,
              name: data.name,
              priceUsd: data.unitPriceUsd,
              pricebookCategoryGuid,
              sourcePhotoGuid: data.photoGuid,
              photoGuid: data.photoGuid,
              pricebookItemCode: data.cartItemCode,
            },
          })
        }
        onSave({
          itemGuid,
          ...data,
          description: data.description ?? '',
          itemType: data.itemType as CartItemType,
          savedToPricebook: saveToPricebook,
        })
      },
      [
        companyGuid,
        defaultValues.itemGuid,
        executeInsertMutation,
        onSave,
        userGuid,
      ],
    )

    const [submitElement, triggerSubmit] = useReactHookFormSubmit()

    const [confirmCancelOpen, openConfirmCancel, closeConfirmCancel] =
      useModalState()

    const onCancelWithConfirm = useCallback(() => {
      if (isDirty) {
        openConfirmCancel()
      } else {
        onCancel()
      }
    }, [isDirty, onCancel, openConfirmCancel])

    const onBackWithConfirm = useMemo(
      () =>
        onBack
          ? () => {
              if (isDirty) {
                openConfirmCancel()
              } else {
                onBack()
              }
            }
          : undefined,
      [isDirty, onBack, openConfirmCancel],
    )

    const onUploadChange: OnPhotoUploadChange = useSynchronousUpload(record => {
      setValue('photoGuid', record.photoGuid)
      setValue('photoCdnUrl', record.cdnUrl)
    })

    const onRemovePhoto = useCallback(() => {
      setValue('photoGuid', undefined)
      setValue('photoCdnUrl', undefined)
    }, [setValue])

    return (
      <OnsiteModalContent
        unpadBottom
        header={title}
        onClose={onCancelWithConfirm}
        onBack={onBackWithConfirm}
        footer={
          <OnsiteModalFooter
            onCancel={onCancelWithConfirm}
            onSubmit={triggerSubmit}
            loading={insertingItem}
          />
        }
      >
        <Form onSubmitCapture={handleSubmit(onSubmit)} layout="vertical">
          {pricebookPhotosEnabled && (
            <div className="mb-4">
              <div className="flex items-center gap-3">
                <AsyncPhotoUploadWithThumbnail
                  thumbnail={{
                    width: 104,
                    height: 104,
                  }}
                  sourcePhotoUrl={photoCdnUrl}
                  onPhotoUploadChange={onUploadChange}
                  onRemovePhoto={onRemovePhoto}
                />
                <div className="flex max-w-[400px] flex-col">
                  <div className="text-sm font-semibold leading-[22px]">
                    Item Image
                  </div>
                  <div className="text-sm text-bz-text-secondary">
                    Square aspect ratio, .PNG and .JPG file types, maximum file
                    size 25 MB.
                  </div>
                </div>
              </div>
            </div>
          )}

          <ReactHookFormItem
            required
            control={control}
            name="name"
            label="Name"
            errors={errors}
            render={({ field }) => <TextField {...field} />}
          />
          <ReactHookFormItem
            required
            control={control}
            name="itemType"
            label="Type"
            errors={errors}
            render={({ field }) => (
              <SelectField
                allowClear
                options={CART_ITEM_TYPE_OPTIONS}
                {...field}
                size="large"
                title="Item Type"
                sheetSize="half"
              />
            )}
          />
          <ReactHookFormItem
            control={control}
            name="cartItemCode"
            label="Item Code"
            errors={errors}
            render={({ field }) => <TextField {...field} />}
          />

          <div className="mb-4 grid grid-cols-2 gap-x-4 border-0 border-b border-solid border-bz-gray-400">
            <ReactHookFormItem
              required
              control={control}
              name="unitPriceUsd"
              label="Unit Price (USD)"
              errors={errors}
              render={({ field }) => <NumberField isMoney min={0} {...field} />}
            />
            <ReactHookFormItem
              required
              control={control}
              name="quantity"
              label="Quantity"
              errors={errors}
              render={({ field }) => <NumberField min={0} {...field} />}
            />
            <ReactHookFormItem
              required
              control={control}
              name="isTaxable"
              label="Taxable?"
              errors={errors}
              render={({ field }) => <SwitchField {...field} />}
            />
            <ReactHookFormItem
              required
              control={control}
              name="isDiscountable"
              label="Discountable?"
              errors={errors}
              render={({ field }) => <SwitchField {...field} />}
            />
          </div>
          <ReactHookFormItem
            className="border-0 border-b border-solid border-bz-gray-400 pb-4"
            control={control}
            name="description"
            label="Description"
            errors={errors}
            render={({ field }) => (
              <BehindFeatureFlag
                enabledFeatureFlag="pricebook-rich-text-formatting"
                render={
                  <WysiwygEditor
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                  />
                }
                fallback={
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  /// @ts-ignore
                  <TextAreaField {...field} />
                }
              />
            )}
          />

          <div className="items-top mb-4 flex flex-row rounded-lg border border-solid border-bz-gray-500 p-4 shadow-sm">
            {!isMobile && (
              <div className="mr-3 flex h-11 w-11 items-center justify-center rounded-full bg-bz-gray-300 text-base text-bz-gray-900">
                <FontAwesomeIcon icon={faTags} />
              </div>
            )}
            <div className="flex flex-1 flex-col">
              <div className="flex flex-row">
                <div className="mr-4">
                  <div className="text-base font-semibold">
                    Save to pricebook
                  </div>
                  <div>
                    Add this item to the pricebook to reuse for future estimates
                    or invoices.
                  </div>
                </div>
                <ReactHookFormItem
                  required
                  control={control}
                  name="saveToPricebook"
                  errors={errors}
                  render={({ field }) => <SwitchField {...field} />}
                />
              </div>

              {saveToPricebook && (
                <ReactHookFormItem
                  noBottomMargin
                  className="mt-3 border-0 border-t border-solid border-bz-gray-400 pt-3"
                  control={control}
                  name="pricebookCategoryGuid"
                  label={
                    <div>
                      Item Category{' '}
                      <span className="font-normal text-bz-gray-700">
                        (optional)
                      </span>
                    </div>
                  }
                  errors={errors}
                  render={({ field }) => (
                    <SelectField
                      showSearch
                      optionFilterProp="label"
                      disabled={!loaded}
                      loading={!loaded}
                      placeholder={
                        loaded
                          ? 'Select item category'
                          : 'Loading categories...'
                      }
                      allowClear
                      options={categoryOptions}
                      {...field}
                      title="Pricebook Category"
                    />
                  )}
                />
              )}
            </div>
          </div>
          {onRemove && (
            <Button
              danger
              size="large"
              className="mb-6 w-full flex-1"
              onClick={onRemove}
            >
              Remove Line Item
            </Button>
          )}
          {submitElement}
          <div className="max-h-0 overflow-hidden">
            <ReactHookFormItem
              control={control}
              name="photoGuid"
              errors={errors}
              render={({ field }) => <input type="hidden" {...field} />}
            />
            <ReactHookFormItem
              control={control}
              name="photoCdnUrl"
              errors={errors}
              render={({ field }) => <input type="hidden" {...field} />}
            />
          </div>
        </Form>
        {confirmCancelOpen && (
          <CloseConfirmModal
            onCancel={closeConfirmCancel}
            onConfirm={() => {
              closeConfirmCancel()
              onBack ? onBack() : onCancel()
            }}
          />
        )}
      </OnsiteModalContent>
    )
  },
)
