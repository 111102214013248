import { bzExpect } from '@breezy/shared'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { AccountDetailsBanner } from '../../components/Accounts/AccountDetailsBanner/AccountDetailsBanner'
import { LayoutPage } from '../../components/Layout/LayoutPage'
import { LayoutSection } from '../../components/Layout/LayoutSection'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Page } from '../../components/Page/Page'
import { useCanManageAccount } from '../../hooks/permission/useCanManageAccount'
import { useCanViewAccount } from '../../hooks/permission/useCanViewAccount'
import { Placeholder } from '../../utils/Placeholder'
import { AccountDetailsLeftPane } from './AccountDetailsLeftPane'
import { AccountDetailsMiddlePane } from './AccountDetailsMiddlePane'
import { AccountDetailsRightSidePane } from './AccountDetailsRightPane'
import {
  AccountWrapper,
  useUpdateViewedAtTimeForUser,
} from './accountDetailsV2Utils'
import { useAccountMetadata } from './WithAccountMetadata'

export const ACCOUNT_DETAILS_TAB_KEY = 'tab'

const AccountDetailsPageAuthWrapper = () => {
  const accountGuid = bzExpect(
    useParams().accountGuid,
    'accountGuid',
    'Account Guid is required',
  )
  const { canView, isLoading } = useCanViewAccount(accountGuid)

  const { accountMetadata, fetching, refetch } = useAccountMetadata(accountGuid)

  return (
    <Page requiresCompanyUser disableLayout>
      {isLoading || fetching ? (
        <div className="flex h-full w-full items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : !accountMetadata ? (
        <div>Account not found</div>
      ) : canView ? (
        <AccountDetailsPage meta={accountMetadata} refetch={refetch} />
      ) : (
        <div className="flex h-full w-full items-center justify-center">
          <Placeholder>Unauthorized to view this account</Placeholder>
        </div>
      )}
    </Page>
  )
}

type AccountDetailsPageProps = AccountWrapper

const AccountDetailsPage = React.memo<AccountDetailsPageProps>(props => {
  const { meta } = props
  const { accountGuid } = meta

  const { canManage } = useCanManageAccount(accountGuid)
  const [paymentsRefetchNonce, setPaymentsRefetchNonce] = useState(0)

  useUpdateViewedAtTimeForUser(accountGuid)

  return (
    <>
      <AccountDetailsBanner
        account={meta}
        className="mt-4"
        itemClassName="mx-4 items-center"
        onPaymentsSuccess={() => {
          setPaymentsRefetchNonce(paymentsRefetchNonce + 1)
        }}
      />
      <LayoutPage padded>
        <LayoutSection size={{ xs: 12, md: 6, xl: 12 * 0.3 }}>
          <AccountDetailsLeftPane {...props} editable={canManage} />
        </LayoutSection>
        <LayoutSection
          size={{ xs: 12, md: 12, xl: 12 * 0.4 }}
          order={{ xs: 100, xl: 'DEFAULT' }}
          card
        >
          <AccountDetailsMiddlePane {...props} editable={canManage} />
        </LayoutSection>
        <LayoutSection size={{ xs: 12, md: 6, xl: 12 * 0.3 }} card>
          <AccountDetailsRightSidePane
            {...props}
            editable={canManage}
            key={paymentsRefetchNonce}
          />
        </LayoutSection>
      </LayoutPage>
    </>
  )
})

export default AccountDetailsPageAuthWrapper
