import { InfoCircleOutlined } from '@ant-design/icons'
import {
  isNullish,
  nextGuid,
  usCentsToUsd,
  uscMultiply,
  usdToUsCents,
} from '@breezy/shared'
import { faTrash } from '@fortawesome/pro-regular-svg-icons'
import { Button, Input, message, Table, Tooltip } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { useMutation } from 'urql'
import { FaIconButtonWithConfirm } from '../../elements/FaIconButtonWithConfirm/FaIconButtonWithConfirm'
import { NumberField } from '../../elements/Forms/NumberField'
import ThinDivider from '../../elements/ThinDivider'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { usePricebookAdmin } from './hooks/usePricebookAdmin'
import {
  DELETE_PRICEBOOK_MARKUP_TIER_MUTATION,
  PricebookMarkupTier,
  UPSERT_PRICEBOOK_MARKUP_TIER_MUTATION,
} from './PricebookAdmin.gql'

type PricebookMarkupTierWithProfit = PricebookMarkupTier & {
  profitPercentage: number
}

const calculateProfitPercentage = (markupRate: number | undefined) => {
  const costRate = 1
  const impliedMarkupRate = markupRate ?? 0
  const profitRate = impliedMarkupRate / (costRate + impliedMarkupRate)
  return profitRate * 100
}

// Convert markup rate to percentage for display
const markupRateToPercentage = (markupRate: number) => {
  return Number((markupRate * 100).toFixed(2))
}

// Convert percentage back to markup rate for storage
const percentageToMarkupRate = (percentage: number) => {
  return Number((percentage / 100).toFixed(2))
}

const PricebookMarkups = () => {
  const companyGuid = useExpectedCompanyGuid()
  const { markupTiers, closeDrawer, pricebookMarkupTiersMutated } =
    usePricebookAdmin()

  const [, upsertMarkupTiers] = useMutation(
    UPSERT_PRICEBOOK_MARKUP_TIER_MUTATION,
  )

  const [, deletePricebookMarkupTier] = useMutation(
    DELETE_PRICEBOOK_MARKUP_TIER_MUTATION,
  )

  // Split markup tiers into regular and fallback
  const [editingData, setEditingData] = useState<
    PricebookMarkupTierWithProfit[]
  >(
    markupTiers
      ?.filter(tier => !isNullish(tier.costRangeEndInclusiveUsc))
      .map(tier => ({
        ...tier,
        profitPercentage: calculateProfitPercentage(tier.markupRate),
      })) || [],
  )

  const [fallbackTier, setFallbackTier] =
    useState<PricebookMarkupTierWithProfit | null>(() => {
      const tier = markupTiers?.find(tier =>
        isNullish(tier.costRangeEndInclusiveUsc),
      )
      return tier
        ? {
            ...tier,
            profitPercentage: calculateProfitPercentage(tier.markupRate),
          }
        : null
    })

  const handleSave = useCallback(
    async (item: PricebookMarkupTierWithProfit) => {
      try {
        await upsertMarkupTiers({
          input: {
            pricebookMarkupTierGuid: item.pricebookMarkupTierGuid,
            companyGuid: item.companyGuid,
            costRangeEndInclusiveUsc: item.costRangeEndInclusiveUsc,
            markupRate: item.markupRate,
            updatedAt: new Date().toISOString(),
          },
        })
      } catch (error) {
        message.error('Failed to save markup')
      }
    },
    [upsertMarkupTiers],
  )

  const handleFallbackChange = useCallback(
    async (value: string | number) => {
      const percentageValue = parseFloat(value.toString())
      const markupRate = percentageToMarkupRate(percentageValue)

      let updatedTier: PricebookMarkupTierWithProfit
      if (!fallbackTier) {
        updatedTier = {
          pricebookMarkupTierGuid: nextGuid(),
          companyGuid,
          costRangeEndInclusiveUsc: undefined,
          markupRate,
          profitPercentage: calculateProfitPercentage(markupRate),
        }
      } else {
        updatedTier = {
          ...fallbackTier,
          markupRate,
          profitPercentage: calculateProfitPercentage(markupRate),
        }
      }

      setFallbackTier(updatedTier)
      await handleSave(updatedTier)
    },
    [fallbackTier, companyGuid, handleSave],
  )

  const handleInputChange = useCallback(
    async (
      value: string | number,
      key: keyof PricebookMarkupTier,
      index: number,
    ) => {
      const newData = [...editingData]
      const percentageValue = parseFloat(value.toString())
      const markupRate = percentageToMarkupRate(percentageValue)
      const currentItem = newData[index]

      // Only update if value changed
      if (currentItem[key] === markupRate) {
        return
      }

      const updatedItem = {
        ...currentItem,
        [key]: markupRate,
        profitPercentage:
          key === 'markupRate'
            ? calculateProfitPercentage(markupRate)
            : currentItem.profitPercentage,
      }
      newData[index] = updatedItem
      setEditingData(newData)
      await handleSave(updatedItem)
    },
    [editingData, handleSave],
  )

  const handleCostRangeEndChange = useCallback(
    async (value: string, index: number) => {
      const newData = [...editingData]
      const costRangeEnd = parseFloat(value)
      const markupRate = newData[index].markupRate
      const updatedItem = {
        ...newData[index],
        costRangeEndInclusiveUsc: costRangeEnd,
        markupRate,
        profitPercentage: calculateProfitPercentage(markupRate),
      }
      newData[index] = updatedItem
      setEditingData(newData)
      await handleSave(updatedItem)
    },
    [editingData, handleSave],
  )

  const handleRemoveRow = useCallback(
    async (index: number) => {
      const pricebookMarkupTierGuid = editingData[index].pricebookMarkupTierGuid
      if (pricebookMarkupTierGuid) {
        try {
          await deletePricebookMarkupTier({ pricebookMarkupTierGuid })
          const newData = editingData.filter((_, i) => i !== index)
          setEditingData(newData)
        } catch (error) {
          console.error(error)
          message.error('Failed to delete markup')
        }
      }
    },
    [editingData, deletePricebookMarkupTier],
  )

  const handleClose = useCallback(() => {
    pricebookMarkupTiersMutated()
    closeDrawer()
  }, [pricebookMarkupTiersMutated, closeDrawer])

  const columns = useMemo(
    () => [
      {
        title: (
          <span>
            Cost From
            <Tooltip title="The starting cost range for this markup tier">
              <InfoCircleOutlined className="ml-2" />
            </Tooltip>
          </span>
        ),
        dataIndex: 'costRangeFrom',
        key: 'costRangeFrom',
        render: (
          _: unknown,
          record: PricebookMarkupTierWithProfit,
          index: number,
        ) => {
          const previousCost =
            index > 0 ? editingData[index - 1].costRangeEndInclusiveUsc : 0
          return (
            <NumberField
              name="costRangeFrom"
              value={usCentsToUsd((previousCost ?? 0) + 1)}
              onBlur={() => {}}
              onChange={() => {}}
              disabled={true}
              isMoney
            />
          )
        },
      },
      {
        title: (
          <span>
            Cost To
            <Tooltip title="The ending cost range for this markup tier">
              <InfoCircleOutlined className="ml-2" />
            </Tooltip>
          </span>
        ),
        dataIndex: 'costRangeEndInclusiveUsc',
        key: 'costRangeEndInclusiveUsc',
        render: (
          text: string,
          record: PricebookMarkupTierWithProfit,
          index: number,
        ) => (
          <NumberField
            name="costRangeEndInclusiveUsc"
            min={0.01}
            value={usCentsToUsd(parseFloat(text))}
            onChange={e =>
              handleCostRangeEndChange(
                usdToUsCents(e.target.value).toString(),
                index,
              )
            }
            onBlur={() => {}}
            isMoney
          />
        ),
      },
      {
        title: (
          <span>
            Markup (%)
            <Tooltip title="The percentage markup applied to the cost to determine the final price">
              <InfoCircleOutlined className="ml-2" />
            </Tooltip>
          </span>
        ),
        dataIndex: 'markupRate',
        key: 'markupRate',
        render: (
          text: string,
          record: PricebookMarkupTierWithProfit,
          index: number,
        ) => (
          <NumberField
            name="markupRate"
            min={0}
            value={markupRateToPercentage(record.markupRate)}
            onChange={e =>
              handleInputChange(e.target.value, 'markupRate', index)
            }
            onBlur={() => {}}
            selectOnFocus
          />
        ),
      },
      {
        title: (
          <span>
            Profit (%)
            <Tooltip title="The calculated profit percentage based on the markup rate">
              <InfoCircleOutlined className="ml-2" />
            </Tooltip>
          </span>
        ),
        dataIndex: 'profitPercentage',
        key: 'profitPercentage',
        render: (
          text: string,
          record: PricebookMarkupTierWithProfit,
          index: number,
        ) => (
          <Input
            className="min-h-[38px]"
            value={record.profitPercentage.toFixed(2)}
            onChange={e =>
              handleInputChange(e.target.value, 'markupRate', index)
            }
            readOnly
            disabled
          />
        ),
      },
      {
        title: 'Action',
        key: 'action',
        render: (
          _: unknown,
          record: PricebookMarkupTierWithProfit,
          index: number,
        ) => (
          <FaIconButtonWithConfirm
            icon={faTrash}
            onClick={() => handleRemoveRow(index)}
            confirmModalTitle="Remove Markup Tier"
            confirmModalOkButtonText="Remove"
            confirmModalPromptText="Are you sure you want to remove this markup tier?"
          />
        ),
      },
    ],
    [editingData, handleCostRangeEndChange, handleInputChange, handleRemoveRow],
  )

  const sortedData = useMemo(() => {
    return [...(editingData || [])].sort(
      (a, b) =>
        (a.costRangeEndInclusiveUsc ?? Number.MAX_SAFE_INTEGER) -
        (b.costRangeEndInclusiveUsc ?? Number.MAX_SAFE_INTEGER),
    )
  }, [editingData])

  const handleAddRow = useCallback(async () => {
    const lastMarkupRate = Math.max(
      1.0,
      sortedData.length > 0
        ? sortedData[sortedData.length - 1].markupRate
        : 1.2,
    )
    const highestCostRangeEndUsc = Math.max(
      ...editingData.map(item => item.costRangeEndInclusiveUsc || 100),
    )
    const newMarkupRate = sortedData.length > 0 ? lastMarkupRate : 2.0
    const newRow: PricebookMarkupTierWithProfit = {
      pricebookMarkupTierGuid: nextGuid(),
      companyGuid,
      costRangeEndInclusiveUsc: Math.max(
        uscMultiply(highestCostRangeEndUsc, 5),
        1000,
      ),
      markupRate: newMarkupRate,
      profitPercentage: calculateProfitPercentage(newMarkupRate),
    }
    const newData = [...editingData, newRow].sort(
      (a, b) =>
        (a.costRangeEndInclusiveUsc ?? Number.MAX_SAFE_INTEGER) -
        (b.costRangeEndInclusiveUsc ?? Number.MAX_SAFE_INTEGER),
    )
    setEditingData(newData)
    await handleSave(newRow)
  }, [sortedData, editingData, handleSave, companyGuid])

  return (
    <div>
      <div className="mb-6">
        <h3 className="mb-2 text-lg font-medium">Markup Tiers</h3>
        <p className="font-extra-light mb-4 text-gray-400">
          Configure markup rates based on cost ranges to automatically calculate
          selling prices for Materials and Equipment.
          <br />
          <br />
          This powerful feature helps protect your margins as supplier costs
          change. When costs are updated, your selling prices will automatically
          adjust based on these markup tiers.
        </p>
      </div>
      <ThinDivider widthPx={1} styleOverrides={{ marginBottom: 0 }} />
      <div className="mb-6">
        <Table
          className="mb-4"
          dataSource={[
            {
              key: 'fallback',
              title: (
                <div className="max-w-[160px]">
                  <span>
                    Fallback
                    <br />
                    Markup (%)
                    <Tooltip title="This markup will be used when an item's cost doesn't fall into any of the ranges in the tiers below">
                      <InfoCircleOutlined className="ml-2" />
                    </Tooltip>
                  </span>
                </div>
              ),
              markupRate: (
                <NumberField
                  name="fallbackMarkupRate"
                  min={0}
                  value={markupRateToPercentage(
                    fallbackTier?.markupRate ?? 2.0,
                  )}
                  onChange={e => handleFallbackChange(e.target.value)}
                  onBlur={() => {}}
                  selectOnFocus
                />
              ),
              profitLabel: (
                <span>
                  Profit (%)
                  <Tooltip title="The profit percentage based on the markup rate">
                    <InfoCircleOutlined className="ml-2" />
                  </Tooltip>
                </span>
              ),
              profitPercentage: (
                <Input
                  className="min-h-[38px]"
                  value={(
                    fallbackTier?.profitPercentage ??
                    calculateProfitPercentage(2.0)
                  ).toFixed(2)}
                  readOnly
                  disabled
                />
              ),
            },
          ]}
          columns={[
            {
              title: '',
              dataIndex: 'title',
              key: 'title',
              width: '25%',
            },
            {
              title: 'Markup Rate',
              dataIndex: 'markupRate',
              key: 'markupRate',
              width: '25%',
            },
            {
              title: 'Profit (%)',
              dataIndex: 'profitLabel',
              key: 'profitLabel',
              width: '25%',
            },
            {
              title: 'Profit (%)',
              dataIndex: 'profitPercentage',
              key: 'profitPercentage',
              width: '25%',
            },
          ]}
          pagination={false}
          showHeader={false}
        />
      </div>

      <Table
        dataSource={sortedData}
        columns={columns}
        rowKey="pricebookMarkupTierGuid"
        pagination={false}
      />

      <div className="mt-4 flex justify-end gap-2">
        <Button onClick={handleAddRow}>Add Row</Button>
        <Button type="primary" onClick={handleClose}>
          Done
        </Button>
      </div>
    </div>
  )
}

export default PricebookMarkups
