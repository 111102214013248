import { Col, Row } from 'antd'
import React from 'react'
import RenderIf from '../../../elements/RenderIf/RenderIf'
import ThinDivider from '../../../elements/ThinDivider'
import { useQbdConductorEndUserId } from '../../../providers/CompanyFinancialConfigWrapper'
import { useIsImpersonating } from '../../../providers/PrincipalUser'
import { useQuickbooksDesktopState } from '../../../providers/QuickbooksDesktopOnlineWrapper'
import { Page } from '../../Page/Page'
import { AccountingCartItemTypeAccountsConfigPanel } from '../AccountingCartItemTypeAccountsConfigPanel'
import AccountingOptionCheckboxes from '../AccountingOptionCheckboxes'
import { AccountingPayoutAccountsConfigPanel } from '../AccountingPayoutAccountsConfigPanel'

export const QuickbooksDesktopConfigPage = React.memo(() => {
  const qbdConductorEndUserId = useQbdConductorEndUserId()
  const isConnected = !!qbdConductorEndUserId
  const isImpersonating = useIsImpersonating()
  const { isOnline } = useQuickbooksDesktopState()

  return (
    <Page requiresCompanyUser={true} className="p-0">
      <div className="card overflow-y-scroll">
        <Col>
          <Row className="mb-2">
            <img
              alt="Sync with Quickbooks Desktop"
              src="https://d3j5nzmmhjc8v6.cloudfront.net/quickbooks_logo.png"
              className="m-1 h-6 w-6"
            />
            <h2 className="card-title-lg ml1 semibold_20_28 grey9">
              Quickbooks Desktop
            </h2>
          </Row>
          <ThinDivider />
          {isConnected ? (
            <div className="text-lg font-semibold">
              Connected
              <RenderIf if={isImpersonating}>
                <span className="text-sm">
                  &nbsp;- Conductor End User Id:&nbsp;
                </span>
                <span
                  className="cursor-pointer text-sm text-blue-500"
                  onClick={() =>
                    navigator.clipboard.writeText(qbdConductorEndUserId)
                  }
                >
                  {qbdConductorEndUserId}
                </span>
              </RenderIf>
            </div>
          ) : (
            <div className="text-lg">Not Connected</div>
          )}
          <ThinDivider />
          {isConnected && (
            <>
              <div className="pb-2 text-lg font-semibold">Sync Options</div>
              <AccountingOptionCheckboxes hideDynamicTaxRateCalculationOption />
              <ThinDivider />
              <RenderIf if={isOnline}>
                <AccountingCartItemTypeAccountsConfigPanel type="qbd" />
                <ThinDivider />
                <AccountingPayoutAccountsConfigPanel />
              </RenderIf>
              <RenderIf if={!isOnline}>
                <div className="rounded-md border border-gray-200 p-4 pl-0">
                  <div className="text-lg font-semibold">
                    QuickBooks Desktop Connection Status
                  </div>
                  <div className="mt-4">
                    <div className="font-medium text-red-600">
                      Connection is currently offline
                    </div>
                    <div className="mt-2 text-gray-700">
                      Account configuration is not available while the
                      connection is offline. Please wait for the connection to
                      be restored.
                    </div>
                    <div className="mt-2 text-sm italic text-gray-500">
                      (Connection status will update automatically within 5
                      minutes)
                    </div>
                  </div>
                </div>
              </RenderIf>
            </>
          )}
        </Col>
      </div>
    </Page>
  )
})
