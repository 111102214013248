import DOMPurify from 'isomorphic-dompurify'
import { times } from './lodash-helpers'
import { HtmlString } from './type-utils'

export const upperPascalCaseToHumanReadable = (s: string): string => {
  return s
    .toLowerCase()
    .split('_')
    .join(' ')
    .replace(/\w\S*/g, (txt: string) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}
export const toTitleCase = (s: string): string => {
  return s.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const fromCamelCaseToPascalCase = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const withSpacesBetweenWords = (s: string): string => {
  return s.replace(/([A-Z])/g, ' $1').trim()
}

/** Function that count occurrences of a substring in a string;
 * @param {String} string               The string
 * @param {String} subString            The sub string to search for
 * @param {Boolean} [allowOverlapping]  Optional. (Default:false)
 *
 * @author Vitim.us https://gist.github.com/victornpb/7736865
 * @see Unit Test https://jsfiddle.net/Victornpb/5axuh96u/
 * @see https://stackoverflow.com/a/7924240/938822
 */
export function occurrences(string: string, subString: string, allowOverlapping = false): number {
  string += ''
  subString += ''
  if (subString.length <= 0) return string.length + 1

  const step = allowOverlapping ? 1 : subString.length
  let n = 0,
    pos = 0,
    goOn = true

  while (goOn) {
    pos = string.indexOf(subString, pos)
    if (pos >= 0) {
      ++n
      pos += step
    } else {
      goOn = false
    }
  }
  return n
}

export const stringToCharArray = (str: string) => {
  if (!str) return [] as string[] // resilient to null, undefined, and empty string

  return times(str.length, i => str.charAt(i)).reduce((memo, c) => [...memo, c], [] as string[])
}

export const containsAnyCase = (target: string | undefined, search: string) =>
  (search ?? '') === '' || (target || '').toLowerCase().indexOf(search.toLowerCase()) > -1

export const normalizeString = (str: string): string => {
  if (!str) return ''
  return str
    .toLowerCase() // Convert the entire string to lowercase
    .replace(/[^a-z0-9]/gim, ' ') // Replace all non-alphanumeric characters with a space
    .replace(/\s+/g, '') // Replace all spaces
}

export const normalizeStringIncludingMoney = (str: string): string => {
  return str
    .toLowerCase() // Convert the entire string to lowercase
    .replace(/[^a-z0-9.$]/gim, ' ') // Replace all non-alphanumeric characters (excluding periods and dollar symbols) with a space
    .replace(/\s+/g, '') // Replace all spaces
}

export const toPlural = (count: number, word: string, pluralForm?: string) => {
  if (count === 1) {
    return word
  }
  return pluralForm || `${word}s`
}

export const aOrAn = (word: string, capitalize?: boolean) => {
  const firstLetter = capitalize ? 'A' : 'a'
  if (['a', 'e', 'i', 'o', 'u'].includes(word.charAt(0).toLowerCase())) {
    return `${firstLetter}n`
  }
  return firstLetter
}

export const capitalizeFirstLetter = (str: string): string => {
  if (!str) return ''

  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

type URLConfig = {
  scheme: 'https' | 'http'
  host: string
  port: number | undefined
}

export const makeBaseUrlFromConfig = ({ scheme, host, port }: URLConfig) =>
  `${scheme}://${host}${port ? `:${port}` : ''}`

// Thanks ChatGPT
export const getSuffixForNumber = (number: number): string => {
  const onesDigit = number % 10
  const tensDigit = number % 100
  if (onesDigit === 1 && tensDigit !== 11) {
    return `${number}st`
  }
  if (onesDigit === 2 && tensDigit !== 12) {
    return `${number}nd`
  }
  if (onesDigit === 3 && tensDigit !== 13) {
    return `${number}rd`
  }
  return `${number}th`
}

export const withArticlePrefix = (word: string): string => {
  if (!word) return ''

  return `${getArticleForNoun(word)} ${word}`
}

const getArticleForNoun = (noun: string): string => {
  if (!noun) return ''

  // Regular expression checks for vowel sounds at the start of the string
  const vowels = /^[aeiouAEIOU]/
  // Special cases: if the item starts with "eu" or "un" followed by a vowel (e.g., "European" or "uninteresting"),
  // we use "a" instead of "an". You can expand on these rules if needed.
  const specialCases = /^(eu[aeiou]|un[aeiou])/i

  if (vowels.test(noun) && !specialCases.test(noun)) {
    return 'an'
  } else {
    return 'a'
  }
}

export const truncate = (str: string, maxLength: number, truncationPostFix = ''): string => {
  if (str.length <= maxLength) return str
  return str.substring(0, maxLength - truncationPostFix.length) + truncationPostFix
}

export const toCamelCase = (str: string, separator = ' '): string => {
  return str
    .split(separator)
    .map((word, idx) => {
      if (idx === 0) {
        return word.charAt(0).toLowerCase() + word.substring(1)
      }

      return word.charAt(0).toUpperCase() + word.substring(1)
    })
    .join('')
}

export const stripHtml = (html: HtmlString): string => {
  const preProcessed = (html as string)
    .replace(/<br\s*\/?>/gi, ' ')
    .replace(/<\/p>/gi, ' ')
    .replace(/<p>/gi, '')
    .replace(/&nbsp;/gi, ' ')
  return DOMPurify.sanitize(preProcessed, { ALLOWED_TAGS: [] })
}

export const sanitizeHtml = (html: HtmlString): string => {
  return DOMPurify.sanitize(html as string)
}
