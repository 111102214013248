import { useFeatureFlag } from './useFeatureFlags'

import { useAccountingIntegrationType } from '../providers/CompanyFinancialConfigWrapper'

import { useMemo } from 'react'

export const useImportPaymentFromAccountingEnabled = () => {
  const isQbdImportFFEnabled = useFeatureFlag('qbd-import-payment')
  const accountingIntegrationType = useAccountingIntegrationType()
  const isQbd = useMemo(
    () => accountingIntegrationType === 'QBD',
    [accountingIntegrationType],
  )
  return useMemo(() => {
    return isQbdImportFFEnabled && isQbd
  }, [isQbdImportFFEnabled, isQbd])
}
