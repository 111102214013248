import { gql } from '../../../generated'

export const ACCOUNT_DROPDOWN_OPTIONS_QUERY = gql(/* GraphQL */ `
  query AccountDropdownOptionsQuery($where: AccountsBoolExp!) {
    accounts(where: $where) {
      accountGuid
      archived
      doNotService
      doNotServiceReason
      accountDisplayName
      accountDisplayNameSetBySystem
      accountContacts(where: { primary: { _eq: true } }) {
        contact {
          fullName
          primaryPhoneNumber {
            phoneNumber
          }
          primaryEmailAddress {
            emailAddress
          }
        }
      }
      accountLocations(where: { isArchived: { _eq: false } }) {
        locationGuid
        location {
          address {
            canonicalFullAddress
            line1
            line2
            city
            stateAbbreviation
            zipCode
          }
        }
      }
      maintenancePlans(limit: 1) {
        maintenancePlanDefinition {
          marketingInfo {
            name
          }
        }
      }
    }
  }
`)
