import { DynamicPricingType, formatUsc } from '@breezy/shared'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { tailwindColors } from '../../../../../tailwind.config'
import { OptionDiscount } from '../../../estimatesFlowUtils'
import { PDFDiscountIcon } from './PDFDiscountIcon'

const styles = StyleSheet.create({
  container: {
    padding: '16px 16px 16px 16px',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 12,
    lineHeight: '20px',
    fontWeight: 'semibold',
    color: tailwindColors['bz-gray'][1000],
  },
  value: {
    fontSize: 12,
    lineHeight: '20px',
    color: tailwindColors['bz-gray'][1000],
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomColor: tailwindColors['bz-gray'][500],
    borderBottomStyle: 'dashed',
    marginVertical: 8,
  },
  total: {
    fontSize: 14,
    color: tailwindColors['bz-gray'][1000],
    fontWeight: 'semibold',
  },
})

type PDFTotalsSectionProps = {
  subtotalUsc: number
  taxUsc: number
  totalUsc: number
  discountAmountUsc: number
  discounts?: OptionDiscount[]
  dynamicPricingType?: DynamicPricingType
}

export const PDFTotalsSection = ({
  subtotalUsc,
  taxUsc,
  totalUsc,
  discountAmountUsc,
  discounts = [],
  dynamicPricingType,
}: PDFTotalsSectionProps) => {
  return (
    <View style={styles.container} wrap={false}>
      {/* <View style={{ flexDirection: 'column', marginBottom: 4 }}> */}
      <View style={styles.row}>
        <Text style={styles.label}>Subtotal</Text>
        <Text style={styles.value}>{formatUsc(subtotalUsc)}</Text>
      </View>

      {discountAmountUsc > 0 && (
        <View style={styles.row}>
          <View style={{ flexDirection: 'row', gap: 8, alignItems: 'center' }}>
            <Text style={[styles.label]}>Discount</Text>
            <PDFDiscountIcon
              width={16}
              height={16}
              discountType={discounts[0].type}
              style={{ marginBottom: 6 }}
            />
          </View>
          <Text style={styles.value}>-{formatUsc(discountAmountUsc)}</Text>
        </View>
      )}

      <View style={styles.row}>
        <Text style={styles.label}>Tax (8.625%)</Text>
        <Text style={styles.value}>+{formatUsc(taxUsc)}</Text>
      </View>
      {/* </View> */}

      <View style={styles.divider} />

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Text style={[styles.label, styles.total]}>Total</Text>
        <Text style={[styles.value, styles.total]}>{formatUsc(totalUsc)}</Text>
      </View>
    </View>
  )
}
