import {
  JOB_CLASSES_THAT_SUPPORT_OPPORTUNITIES_AND_HOT_LEADS,
  JOB_TYPE_DURATION_MINUTES_OPTIONS,
  JobClass,
  JobType,
  JobTypeDurationMinutesDisplayNames,
  isNullish,
  nextGuid,
  noOp,
  usCentsToUsd,
  usdToUsCents,
} from '@breezy/shared'
import { faBriefcase } from '@fortawesome/pro-light-svg-icons'
import { TRPCClientError } from '@trpc/client'
import { Alert, Button, Form, Input, InputNumber, Radio, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useWatch } from 'antd/lib/form/Form'
import cn from 'classnames'
import React, { useCallback, useContext, useMemo } from 'react'
import { useMessage } from 'src/utils/antd-utils'
import { ItemPriceFormItem } from '../../components/form-fields/ItemPriceFormItem'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { FormDescription } from '../../elements/Forms/FormDescription'
import FormHeader from '../../elements/Forms/FormHeader'
import ThinDivider from '../../elements/ThinDivider'
import { useCanManageTechnicianPerformance } from '../../hooks/permission/useCanManageTechnicianPerformance'
import { useCanViewTechnicianPerformance } from '../../hooks/permission/useCanViewTechnicianPerformance'
import { trpc } from '../../hooks/trpc'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { JobTypesSettingsContext } from './jobTypesSettingsUtils'

const JOB_CLASS_SELECT_OPTIONS = [
  {
    label: 'Install',
    value: JobClass.INSTALL,
  },
  {
    label: 'Service',
    value: JobClass.SERVICE,
  },
  {
    label: 'Maintenance',
    value: JobClass.MAINTENANCE,
  },
  {
    label: 'Sales',
    value: JobClass.SALES,
  },
]

const VALIDATE_MESSAGES = {
  // This is the format antd wants
  // eslint-disable-next-line no-template-curly-in-string
  required: '${label} is required.',
}

const DEFAULT_OPPORTUNITY_CONVERSION_THRESHOLD_USC = 100
const DEFAULT_HOT_LEAD_MINIMUM_EQUIPMENT_AGE_YEARS = 10

type JobTypeForm = Omit<JobType, 'opportunityConversionThresholdUsc'> & {
  opportunityConversionThresholdUsd?: number
}

type EditJobTypeSidebarProps = {
  initialJobType: Partial<JobType>
  initialJobClass?: JobClass
  onClose: () => void
}

export const EditJobTypeSidebar = React.memo<EditJobTypeSidebarProps>(
  ({ onClose, initialJobType, initialJobClass }) => {
    const defaultJobSummaryEnabled = useFeatureFlag('default-job-summary')
    const message = useMessage()
    const { refetch } = useContext(JobTypesSettingsContext)
    const canViewTechnicianPerformance = useCanViewTechnicianPerformance()
    const canManageTechnicianPerformance = useCanManageTechnicianPerformance()
    const drawerItem = useMemo(() => ({ onCancel: onClose }), [onClose])

    const [form] = Form.useForm<JobTypeForm>()

    const isOpportunityValue = useWatch('isOpportunity', form)
    const isPotentialHotLeadValue = useWatch('isPotentialHotLead', form)
    const jobClassValue = useWatch('jobClass', form)

    const showTechnicianPerformanceFields = useMemo(() => {
      if (!canViewTechnicianPerformance) {
        return false
      }

      return JOB_CLASSES_THAT_SUPPORT_OPPORTUNITIES_AND_HOT_LEADS.includes(
        jobClassValue,
      )
    }, [canViewTechnicianPerformance, jobClassValue])

    const isNew = useMemo(
      () => !initialJobType.jobTypeGuid,
      [initialJobType.jobTypeGuid],
    )

    const initialValues = useMemo(
      () => ({
        ...initialJobType,
        opportunityConversionThresholdUsd: usCentsToUsd(
          initialJobType.opportunityConversionThresholdUsc ??
            DEFAULT_OPPORTUNITY_CONVERSION_THRESHOLD_USC,
        ),
        hotLeadMinimumEquipmentAgeYears:
          initialJobType.hotLeadMinimumEquipmentAgeYears ??
          DEFAULT_HOT_LEAD_MINIMUM_EQUIPMENT_AGE_YEARS,
        jobClass:
          initialJobType.jobClass || initialJobClass || JobClass.INSTALL,
        durationMinutes: initialJobType.durationMinutes || 90,
      }),
      [initialJobType, initialJobClass],
    )

    const currentJobLifecycleGuid = useWatch('jobLifecycleGuid', form)
    const jobLifecycleFieldDirty = useMemo(
      () => currentJobLifecycleGuid !== initialValues.jobLifecycleGuid,
      [currentJobLifecycleGuid, initialValues.jobLifecycleGuid],
    )

    const lifecycleQuery = trpc.jobLifecycles['job-lifecycles:get'].useQuery()

    const lifecycleOptions = useMemo(() => {
      if (!lifecycleQuery.data || lifecycleQuery.isLoading) {
        return
      }
      return lifecycleQuery.data.map(lifecycle => ({
        label: lifecycle.name,
        value: lifecycle.jobLifecycleGuid,
      }))
    }, [lifecycleQuery.data, lifecycleQuery.isLoading])

    const upsertJobTypeMutation = trpc.jobTypes['job-types:upsert'].useMutation(
      {
        onSuccess: async () => {
          refetch()
          onClose()
        },
        onError: noOp,
      },
    )

    const onSave = useCallback(async () => {
      try {
        await form.validateFields()
      } catch (e) {
        return
      }

      const formFields: JobTypeForm = form.getFieldsValue(true)

      const {
        isOpportunity,
        opportunityConversionThresholdUsd,
        isPotentialHotLead,
        hotLeadMinimumEquipmentAgeYears,
        ...restFormFields
      } = formFields

      // We have to do this because fucking Antd sets the initial values to undefined if the form element doesn't
      // exist on the DOM. Fucking stupid ass form ass fucking library
      const actualIsOpportunity = isNullish(isOpportunity)
        ? false
        : isOpportunity

      const actualIsPotentialHotLeadValue = isNullish(isPotentialHotLead)
        ? false
        : isPotentialHotLead

      // Ideally we'd take advantage of antd's preserve={false} prop to "remove" data when the corresponding
      // form component is unmounted, but it doesn't seem to work. e.g, if the "Flag as Hot Lead" input
      // is not rendered, its value is still persisted in the form data above even when preserve={false}.
      // So instead, we'll add this ugly logic here that properly sets the opportunity/hot-lead-related
      // fields

      const validatedIsOpportunity = showTechnicianPerformanceFields
        ? actualIsOpportunity
        : false
      const validatedIsPotentialHotLead = validatedIsOpportunity
        ? actualIsPotentialHotLeadValue
        : false

      if (
        validatedIsPotentialHotLead &&
        isNullish(hotLeadMinimumEquipmentAgeYears)
      ) {
        throw new Error(
          `Expected Minimum Equipment Age to have value when Job Type is flagged as a Hot Lead`,
        )
      }

      const validatedOpportunityConversionThresholdUsc =
        !isNullish(opportunityConversionThresholdUsd) &&
        !isNaN(opportunityConversionThresholdUsd)
          ? usdToUsCents(opportunityConversionThresholdUsd as number)
          : DEFAULT_OPPORTUNITY_CONVERSION_THRESHOLD_USC
      const validatedHotLeadMinimumEquipmentAgeYears =
        validatedIsPotentialHotLead
          ? hotLeadMinimumEquipmentAgeYears
          : undefined

      try {
        await upsertJobTypeMutation.mutateAsync({
          ...restFormFields,
          isOpportunity: validatedIsOpportunity,
          isPotentialHotLead: validatedIsPotentialHotLead,
          opportunityConversionThresholdUsc:
            validatedOpportunityConversionThresholdUsc,
          hotLeadMinimumEquipmentAgeYears:
            validatedHotLeadMinimumEquipmentAgeYears,
          jobTypeGuid: initialJobType.jobTypeGuid ?? nextGuid(),
        })

        if (isNew) {
          message.success('Job Type has been successfully created!')
        } else {
          message.success('Job Type has been successfully updated!')
        }
      } catch (err) {
        if (err instanceof TRPCClientError) {
          message.error(err.message)
        } else {
          message.error('Unknown Error')
        }
      }
    }, [
      form,
      showTechnicianPerformanceFields,
      upsertJobTypeMutation,
      initialJobType.jobTypeGuid,
      isNew,
      message,
    ])

    return (
      <BzDrawer
        title={`${isNew ? 'New' : 'Edit'} Job Type`}
        icon={faBriefcase}
        item={drawerItem}
        preferredWidth={480}
        footer={
          <div
            className={cn('flex flex-row justify-between py-4', {
              'pointer-events-none opacity-50': upsertJobTypeMutation.isLoading,
            })}
          >
            <span />
            <div className="flex flex-row space-x-2">
              <Button size="large" onClick={onClose}>
                Cancel
              </Button>
              <Button type="primary" size="large" onClick={onSave}>
                {isNew ? 'Create Job Type' : 'Save changes'}
              </Button>
            </div>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          requiredMark="optional"
          initialValues={initialValues}
          validateMessages={VALIDATE_MESSAGES}
          disabled={upsertJobTypeMutation.isLoading}
        >
          <FormHeader className="mb-4">Job Type Details</FormHeader>
          <Form.Item
            label="Name"
            name="name"
            required
            rules={[{ required: true }]}
          >
            <Input placeholder="Job type name" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            extra="An internal description to keep your job types organized"
          >
            <TextArea
              placeholder="Purpose of the job type (optional)"
              rows={6}
            />
          </Form.Item>
          {defaultJobSummaryEnabled && (
            <Form.Item
              label="Default Job Summary"
              name="defaultSummary"
              extra="This will be the default job summary for jobs created with this job type"
            >
              <TextArea placeholder="Default job summary (optional)" rows={6} />
            </Form.Item>
          )}
          <Form.Item
            label="Job Class"
            name="jobClass"
            required
            rules={[{ required: true }]}
          >
            <Select
              options={JOB_CLASS_SELECT_OPTIONS}
              popupMatchSelectWidth={false}
            />
          </Form.Item>
          <Form.Item
            label="Pipeline"
            className="mb-0"
            name="jobLifecycleGuid"
            required
            rules={[{ required: true }]}
            extra="Choose the pipeline for jobs created with this type"
          >
            {lifecycleOptions ? (
              <Select
                options={lifecycleOptions}
                popupMatchSelectWidth={false}
                placeholder="Choose a lifecycle"
              />
            ) : (
              <Input readOnly placeholder="Loading..." />
            )}
          </Form.Item>
          {!isNew && jobLifecycleFieldDirty && (
            <Alert
              type="warning"
              className="mb-2"
              showIcon
              message="Updating the job pipeline will only apply to new jobs created with this job type. Existing jobs will not be affected."
            />
          )}

          <ThinDivider
            widthPx={1}
            styleOverrides={{ marginTop: 24, marginBottom: 24 }}
          />
          <FormHeader className="mb-4">Scheduling</FormHeader>
          <Form.Item
            name="durationMinutes"
            label="Duration (Assigned Time)"
            className="mb-0"
            required
            rules={[{ required: true }]}
          >
            <Select
              className="w-1/2"
              options={JOB_TYPE_DURATION_MINUTES_OPTIONS.map(minutes => ({
                label: JobTypeDurationMinutesDisplayNames[minutes],
                value: minutes,
              }))}
            />
          </Form.Item>
          <FormDescription>
            This is the duration of the job assignment for the technician.
          </FormDescription>

          {showTechnicianPerformanceFields && (
            <>
              <ThinDivider
                widthPx={1}
                styleOverrides={{ marginTop: 24, marginBottom: 24 }}
              />
              <FormHeader className="mb-4">Technician Performance</FormHeader>
              <ItemPriceFormItem
                name="opportunityConversionThresholdUsd"
                label="Conversion Threshold"
                min={0}
                extra="This is the dollar amount that must be exceeded on an invoice in order for the job to count as converted. An example might be to set this as +$1 above your dispatch fee."
                disabled={!canManageTechnicianPerformance}
              />
              <Form.Item
                name="isOpportunity"
                label="Flag as Opportunity"
                rules={[{ required: true, message: 'Please select Yes or No' }]}
                extra="Any job that is this job type will be flagged as an opportunity. This will be used for calculating job conversion rate."
              >
                <Radio.Group
                  optionType="button"
                  buttonStyle="solid"
                  disabled={!canManageTechnicianPerformance}
                >
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
              {isOpportunityValue && (
                <Form.Item
                  name="isPotentialHotLead"
                  label={`Flag as a "Hot Lead" Based on Equipment Age`}
                  rules={[
                    {
                      required: true,
                      message: 'Please select Yes or No',
                    },
                  ]}
                >
                  <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    disabled={!canManageTechnicianPerformance}
                  >
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              )}
              {isPotentialHotLeadValue && (
                <Form.Item
                  name="hotLeadMinimumEquipmentAgeYears"
                  label="Minimum Equipment Age (yrs)"
                  required
                  extra="Jobs created with this job class and equipment age will be flagged as an Opportunity and a Hot Lead"
                >
                  <InputNumber
                    min={0}
                    max={15}
                    step={1}
                    disabled={!canManageTechnicianPerformance}
                  />
                </Form.Item>
              )}
            </>
          )}
        </Form>
      </BzDrawer>
    )
  },
)
