import { z } from 'zod'
import { AsyncFn, formatNumberAsNth, IsoDateString, R, TimeZoneId } from '../../common'
import {
  guidSchema,
  isoDateStringSchema,
  MaintenancePlanCancellationReasonType,
  MaintenancePlanConfiguredEventData,
  MaintenancePlanPaymentInterval,
} from '../../contracts'
import { AccountGuid } from '../Accounts/Account'
import { Address } from '../Address/Address'
import { BusinessUnit } from '../BusinessUnits/BusinessUnits'
import { CompanyGuid, CompanyGuidContainer, ForCompany } from '../Company/Company'
import { EquipmentType } from '../Equipment/EquipmentType'
import { BillingInfo } from '../Finance/InvoicePayments/InvoicePaymentTypes'
import {
  PaymentSubscriptionGuid,
  PaymentSubscriptionGuidContainer,
  PaymentSubscriptionInterval,
  PaymentSubscriptionRecord,
} from '../Finance/PaymentSubscriptions/PaymentSubscriptionTypes'
import { PaymentMethod } from '../Finance/Payments/PaymentTypes'
import { CartOrderSummaryUsc } from '../Finance/Transactions/TransactionTypes'
import { InstalledEquipmentGuid, InstalledEquipmentSummary } from '../InstalledEquipment/InstalledEquipment'
import { JobClass } from '../Job'
import { LocationGuid } from '../Locations/Location'
import { PricebookTaxRateGuid } from '../Pricebook/PricebookTypes'
import { Tag } from '../Tags'
import { UserGuid } from '../Users/User'
import { bzOptional, Guid } from '../common-schemas'
import { Contact } from '../contacts/Contact'
import { MaintenancePlan } from './MaintenancePlan'
import {
  MaintenancePlanBaseDefinition,
  MaintenancePlanDefinition,
  MaintenancePlanDefinitionFlare,
  MaintenancePlanDefinitionGuid,
} from './MaintenancePlanConfigTypes'
import { VisitViewModel } from './Visits'

export const MaintenancePlanEntityTypeName = 'MaintenancePlan' as const

export type MaintenancePlanGuid = Guid
export type MaintenancePlanGuidContainer = {
  readonly maintenancePlanGuid: MaintenancePlanGuid
}

export const maintenancePlanGuidContainerSchema = z.object({
  maintenancePlanGuid: guidSchema,
})

export enum MaintenancePlanStatus {
  ACTIVE = 'ACTIVE',
  LAPSED = 'LAPSED',
  CANCELED = 'CANCELED',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  NONE = 'NONE',
}

export const maintenancePlanStatusDisplayNames = {
  [MaintenancePlanStatus.ACTIVE]: 'Active',
  [MaintenancePlanStatus.LAPSED]: 'Lapsed',
  [MaintenancePlanStatus.CANCELED]: 'Canceled',
  [MaintenancePlanStatus.PENDING]: 'Payment Pending',
  [MaintenancePlanStatus.EXPIRED]: 'Expired',
  [MaintenancePlanStatus.NONE]: 'None',
}

export enum MaintenancePlanPaymentFlow {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
  NONE = 'NONE',
}

export const maintenancePlanPaymentFlowDisplayNames = {
  [MaintenancePlanPaymentFlow.NONE]: 'None',
  [MaintenancePlanPaymentFlow.AUTO]: 'Auto',
  [MaintenancePlanPaymentFlow.MANUAL]: 'Manual',
}

export type MaintenancePlanCancellationInfo = {
  readonly canceledAt: IsoDateString
  readonly cancellationReasonType: MaintenancePlanCancellationReasonType
  readonly cancellationReason: string

  readonly suppressCancellationEmail?: boolean
  readonly shouldExpireVisitsImmediately?: boolean
}

export type BasicMaintenancePlanViewModel = MaintenancePlanMetadata &
  CartOrderSummaryUsc & {
    readonly configuration?: MaintenancePlanConfiguredEventData
    readonly numConfigurationsEverSet: number
    readonly paymentSubscriptionGuid?: Guid
    readonly status: MaintenancePlanStatus
    readonly businessUnitGuid?: Guid

    readonly lapsedAt?: IsoDateString
    readonly activatedAt?: IsoDateString
    readonly expiredAt?: IsoDateString
    readonly customBillingStartAt?: IsoDateString
    readonly billingStartAt?: IsoDateString
    readonly terminatesAt?: IsoDateString
    readonly cancellation?: MaintenancePlanCancellationInfo

    readonly isPricingInitialized: boolean
    readonly paymentFlow: MaintenancePlanPaymentFlow
    readonly paymentInterval?: MaintenancePlanPaymentInterval
    readonly yearlyPriceUsc: number
    readonly isFreePlan: boolean

    readonly taxRate?: MaintenancePlanTaxRate

    readonly payments: Record<Guid, number>
    readonly totalPaymentsReceivedUsc: number
    readonly totalPaymentsEverReceivedUsc: number

    readonly createdByUserGuid: UserGuid
    readonly isMigratedToStaticPricing: boolean
  }

export type BasicTemporalMaintenancePlanViewModel = BasicMaintenancePlanViewModel

export type MaintenancePlanDetailsViewModel = Omit<MaintenancePlanQueryableRecord, 'coveredInstalledEquipmentGuids'> & {
  readonly primaryContactDisplayName: string
  readonly primaryContactEmail?: string
  readonly locationAddress: Address
  readonly planDefinition?: MaintenancePlanDefinition
  /** @deprecated MP V3 - Doesn't care about "Covered Equipment" */
  readonly coveredEquipment?: InstalledEquipmentSummary[]
  readonly paymentSubscription?: PaymentSubscriptionRecord
  readonly visits?: VisitViewModel[]
  readonly isFreePlan: boolean
  readonly businessUnit?: BusinessUnit
}

export type MaintenancePlanPaymentViewModel = BasicTemporalMaintenancePlanViewModel & {
  readonly defaultBillingInfo: BillingInfo
  readonly paymentSubscription?: PaymentSubscriptionRecord
}

/** @deprecated This field is deprecated. MP V3 uses Visits */
export type MaintenancePlanCredit = {
  readonly issuedAt: IsoDateString
  readonly expiresAt: IsoDateString
  readonly fulfilledAt?: IsoDateString
  /** This denotes the credit having been migrated */
  readonly visitGuid?: Guid
}

export type MaintenancePlanMetadata = MaintenancePlanGuidContainer & {
  readonly maintenancePlanVersion: number
  readonly companyGuid: CompanyGuid
  readonly accountGuid: AccountGuid
  readonly locationGuid: LocationGuid
}

export type MaintenancePlanTaxRate = {
  readonly taxRateGuid: Guid
  readonly rate: number
}

export const MaintenancePlanPaymentIntervalDisplayNames = {
  [MaintenancePlanPaymentInterval.YEARLY]: 'Yearly',
  [MaintenancePlanPaymentInterval.MONTHLY]: 'Monthly',
  [MaintenancePlanPaymentInterval.QUARTERLY]: 'Quarterly',
  [MaintenancePlanPaymentInterval.UPFRONT]: 'Upfront',
}

export const MaintenancePlanPaymentIntervalAbbreviation = {
  [MaintenancePlanPaymentInterval.YEARLY]: '/yr',
  [MaintenancePlanPaymentInterval.QUARTERLY]: '/3mo',
  [MaintenancePlanPaymentInterval.MONTHLY]: '/mo',
  [MaintenancePlanPaymentInterval.UPFRONT]: ' upfront',
}

export type MaintenancePlanCreditsContainer = {
  readonly credits: CreditRecord[]
}

export type MaintenancePlanDiscount = {
  readonly discountJobClass: JobClass
  readonly discountRate: number
}

export type MaintenancePlanDiscountArray = MaintenancePlanDiscount[]

export type MaintenancePlanQueryableRecord = {
  readonly maintenancePlanGuid: MaintenancePlanGuid
  readonly companyGuid: CompanyGuid
  readonly accountGuid: AccountGuid
  readonly locationGuid: LocationGuid
  readonly businessUnitGuid?: Guid
  readonly pricebookTaxRateGuid?: PricebookTaxRateGuid
  readonly status: MaintenancePlanStatus
  readonly activatedAt?: IsoDateString
  readonly lapsedAt?: IsoDateString
  readonly expiredAt?: IsoDateString
  readonly canceledAt?: IsoDateString
  readonly cancellationReason?: string
  readonly cancellationReasonType?: MaintenancePlanCancellationReasonType
  readonly maintenancePlanDefinitionGuid?: MaintenancePlanDefinitionGuid
  readonly discounts: MaintenancePlanDiscount[]
  readonly numCreditsNeededToMigrate?: number
  readonly paymentSubscriptionGuid?: PaymentSubscriptionGuid
  readonly paymentInterval?: MaintenancePlanPaymentInterval
  readonly paymentAmountUsc: number
  readonly yearlyPriceUsc: number
  readonly totalPaymentsReceivedUsc: number
  readonly createdByUserGuid: UserGuid
  readonly paymentFlow: MaintenancePlanPaymentFlow
  readonly createdAt: IsoDateString
  readonly updatedAt: IsoDateString
  /** @deprecated MP V3 - Doesn't care about "Covered Equipment" */
  readonly oldestCoveredEquipmentAgeYears?: number
  readonly upchargeAmountUsc: number
  readonly discountAmountUsc: number
  /** NOTE: Human readable... something like "1st of the month" */
  readonly billingAnchor?: string
  readonly billingStartAt?: IsoDateString
  readonly terminatesAt?: IsoDateString

  /** @deprecated MP V3 - Visits are externalized */
  readonly lastVisitedAt?: IsoDateString
  /** @deprecated MP V3 - Visits are externalized */
  readonly numAvailableVisitCredits: number
  /** @deprecated MP V3 - Doesn't care about "Covered Equipment" */
  readonly coveredInstalledEquipmentGuids: InstalledEquipmentGuid[]
  /** @deprecated MP V3 - This field is deprecated. MP V3 uses Visits */
  readonly credits: CreditRecord[]
}

export type MaintenancePlanQueryableRecordsReader = AsyncFn<
  CompanyGuidContainer,
  MaintenancePlanQueryableRecordViewModel[]
>

export type MaintenancePlanQueryableRecordViewModel = MaintenancePlanQueryableRecord & {
  readonly planTypeName?: string
  readonly planTypeFlare?: MaintenancePlanDefinitionFlare
  readonly accountDisplayName: string
  readonly locationAddress: Address
  readonly contacts: Contact[]
  readonly accountPrimaryContactGuid?: Guid
  readonly accountTags: Tag[]
  readonly visits: VisitViewModel[]
  readonly maintenancePlanDefinition?: MaintenancePlanBaseDefinition
}

export type CreditRecord = {
  readonly issuedAt: IsoDateString
  readonly expiresAt?: IsoDateString
  readonly fulfilledAt?: IsoDateString
}

export type CreditRecordArray = CreditRecord[]

export type MaintenancePlanCollapsibleViewModel = MaintenancePlanMinimalInfo & {
  yearlyPriceUsc: number
  visits: VisitViewModel[]
  discounts: MaintenancePlanDiscount[]
  coveredInstalledEquipmentGuids: InstalledEquipmentGuid[]
  locationGuid: LocationGuid
  locationAddress: Pick<Address, 'line1'>
  activatedAt?: IsoDateString
  billingStartAt?: IsoDateString
  terminatesAt?: IsoDateString
  billingAnchor?: string
  paymentInterval?: string
}

export type MaintenancePlanMinimalInfo = {
  readonly maintenancePlanGuid: MaintenancePlanGuid
  readonly status: MaintenancePlanStatus
  readonly paymentFlow: MaintenancePlanPaymentFlow
  readonly planTypeName: string
  readonly planTypeFlare?: MaintenancePlanDefinitionFlare
}

export type MaintenancePlanCreditConsumptionInfo = {
  readonly maintenancePlanGuid: MaintenancePlanGuid
  readonly accountGuid: AccountGuid
  readonly locationGuid: LocationGuid
  readonly status: MaintenancePlanStatus
  readonly coveredEquipmentTypes: EquipmentType[]
  readonly credits: CreditRecord[]
}

export type MaintenancePlanOpportunityMinimalInfo = {
  maintenancePlanGuid: MaintenancePlanGuid
  accountGuid: AccountGuid
  locationGuid: LocationGuid
  status: MaintenancePlanStatus
  planTypeName: string
  numVisitCreditsPerYear: number
  lastVisitedAt?: IsoDateString
}

export type MaintenancePlanCollapsibleAndConsumptionViewModel = MaintenancePlanCollapsibleViewModel &
  MaintenancePlanCreditConsumptionInfo & {
    readonly definition: MaintenancePlanDefinition
  }

export type ReadMaintenancePlanByPaymentSubscription = AsyncFn<
  ForCompany<PaymentSubscriptionGuidContainer>,
  MaintenancePlan
>

export type MaintenancePlanDetailsViewModelReader = AsyncFn<
  ForCompany<MaintenancePlanGuidContainer>,
  MaintenancePlanDetailsViewModel
>

export const getLastVisitedAt = (mp: MaintenancePlanCreditsContainer): IsoDateString | undefined =>
  R.sortBy(R.prop('fulfilledAt'))(mp.credits.filter(x => !!x.fulfilledAt))
    // NOTE: Dear EsLint, you are illiterate.
    // I'm filtering out nullish, and you're still complaining about nullish.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map(x => x.fulfilledAt!)[0]

export const getMostRelevantMaintenancePlanMinimal = (
  mps: MaintenancePlanMinimalInfo[] | undefined,
): MaintenancePlanMinimalInfo | undefined => {
  const collection = mps ?? []
  const activePlans = collection.filter(mp => mp.status === MaintenancePlanStatus.ACTIVE)
  const otherPlans = collection.filter(mp => mp.status !== MaintenancePlanStatus.ACTIVE)
  const sortedPlans = [...activePlans, ...otherPlans]

  return sortedPlans.length > 0 ? sortedPlans[0] : undefined
}

export const paymentIntervalToSubscriptionInterval = (
  paymentInterval: MaintenancePlanPaymentInterval,
): PaymentSubscriptionInterval => {
  switch (paymentInterval) {
    case MaintenancePlanPaymentInterval.MONTHLY:
      return PaymentSubscriptionInterval.MONTHLY
    case MaintenancePlanPaymentInterval.QUARTERLY:
      return PaymentSubscriptionInterval.QUARTERLY
    case MaintenancePlanPaymentInterval.YEARLY:
      return PaymentSubscriptionInterval.YEARLY
    case MaintenancePlanPaymentInterval.UPFRONT:
      return PaymentSubscriptionInterval.NOT_APPLICABLE
  }
}

export type LocationMaintenancePlanViewModel = {
  maintenancePlanGuid: string
  status: MaintenancePlanStatus
  terminatesAt?: IsoDateString
  lastVisitedAt?: IsoDateString
  activatedAt?: IsoDateString
  maintenancePlanDefinition?: {
    marketingInfo?: {
      name?: string
    }
  }
  visits: VisitViewModel[]
}

const intervalTextLabelSuffix: Record<MaintenancePlanPaymentInterval, string> = {
  [MaintenancePlanPaymentInterval.MONTHLY]: '',
  [MaintenancePlanPaymentInterval.QUARTERLY]: ', every 3 months',
  [MaintenancePlanPaymentInterval.YEARLY]: ', every 12 months',
  [MaintenancePlanPaymentInterval.UPFRONT]: '',
}

export const getBillingAnchorDayLabel = (n: number, interval: MaintenancePlanPaymentInterval | undefined): string => {
  if (interval === MaintenancePlanPaymentInterval.UPFRONT) {
    return 'Not Applicable'
  }
  const suffix = interval ? intervalTextLabelSuffix[interval] : ''
  return `${formatNumberAsNth(n)} of the month${suffix}`
}

export type MaintenancePlanLapsedPaymentEmailMetadata = {
  breezyAppUrl: string
  timezone: TimeZoneId
  contactName: string
  customer: {
    firstName: string
    lastName: string
    phoneNumber?: string
    emailAddress?: string
  }
  maintenancePlan: {
    maintenancePlanGuid: string
    serviceLocation: string
    planType: string
    billingFrequency: string
    planStartDate: IsoDateString
  }
  payment: {
    missedPaymentDate: IsoDateString
    paymentAmountUsd: number
    paymentMethod: PaymentMethod
  }
}

export const maintenancePlanLapsedPaymentMailerInputSchema = z.object({
  companyGuid: guidSchema,
  maintenancePlanGuid: guidSchema,
  overrideMaintenancePlanStatus: bzOptional(z.boolean()),
  overrideLapsedDate: bzOptional(isoDateStringSchema),
})

export type MaintenancePlanLapsedPaymentMailerInput = z.infer<typeof maintenancePlanLapsedPaymentMailerInputSchema>

export type MaintenancePlanLapsedPaymentMailer = AsyncFn<MaintenancePlanLapsedPaymentMailerInput>
