import { BzDateFns, isNullish, IsoDateString, LocalDateString, nextGuid, TimeZoneId } from '../../common'
import { MaintenancePlanPaymentInterval } from '../../contracts'
import { getAffinityDateByVisitIndex } from '../AffinityDate/AffinityDate'
import {
  MaintenancePlanPaymentFlow,
  MaintenancePlanQueryableRecordViewModel,
  MaintenancePlanStatus,
} from './MaintenancePlanTypes'
import { VisitRequest, VisitViewModel } from './Visits'

export const isRenewalAnchorOverOneYearAgo = (
  renewalAnchor: IsoDateString,
  currentDate: IsoDateString,
  tzId: TimeZoneId,
): boolean => {
  const parsedRenewalAnchor = BzDateFns.parseISO(renewalAnchor, tzId)
  const parsedCurrentDate = BzDateFns.parseISO(currentDate, tzId)
  return BzDateFns.differenceInYears(parsedCurrentDate, parsedRenewalAnchor) >= 1
}

export const convertRenewalAnchorToCurrentYear = (
  renewalAnchor: IsoDateString,
  currentDate: IsoDateString,
  tzId: TimeZoneId,
): IsoDateString => {
  const billingDate = BzDateFns.parseISO(renewalAnchor, tzId)
  const current = BzDateFns.parseISO(currentDate, tzId)

  let convertedDate = BzDateFns.setYear(billingDate, BzDateFns.getYear(current))

  // Set the time to the beginning of the day (midnight)
  convertedDate = BzDateFns.startOfDay(convertedDate)

  // If the converted date is in the future, subtract a year
  if (BzDateFns.isAfter(convertedDate, current)) {
    convertedDate = BzDateFns.subYears(convertedDate, 1)
  }

  return BzDateFns.formatISO(convertedDate, tzId)
}

export const isDateWithinLast24Hours = (date: IsoDateString, currentDate: IsoDateString, tzId: TimeZoneId): boolean => {
  const parsedDate = BzDateFns.parseISO(date, tzId)
  const parsedCurrentDate = BzDateFns.parseISO(currentDate, tzId)
  const differenceInHours = BzDateFns.differenceInHours(parsedCurrentDate, parsedDate)
  return differenceInHours <= 24 && differenceInHours >= 0
}

export const isRenewalAnchorWithinLast24Hours = ({
  renewalAnchor,
  tzId,
}: {
  renewalAnchor?: IsoDateString
  tzId: TimeZoneId
}): boolean => {
  if (isNullish(renewalAnchor)) {
    return false
  }

  const now = BzDateFns.formatISO(BzDateFns.now(tzId), tzId)
  if (!isRenewalAnchorOverOneYearAgo(renewalAnchor, now, tzId)) {
    return false
  }

  const currentYearRenewalAnchor = convertRenewalAnchorToCurrentYear(renewalAnchor, now, tzId)
  return isDateWithinLast24Hours(currentYearRenewalAnchor, now, tzId)
}

export const futureCloneVisits = (
  visits: VisitViewModel[] | undefined,
  activationDate: LocalDateString,
  tzId: TimeZoneId,
): VisitRequest[] => {
  return (visits ?? [])
    .map((v, i) => ({
      // NOTE: New IDs needed, since these are new future visits
      visitGuid: nextGuid(),
      name: v.name,
      affinityDate: getAffinityDateByVisitIndex(i, activationDate, tzId),
      installedEquipmentGuids: v.visitEquipment.map(e => e.installedEquipmentGuid),

      // NOTE: These will be fresh visits since this is a new plan or a plan that has been renewed
      completedAtOverride: undefined,
      isCompletedOverride: false,
      shouldLinkToJob: false,
      jobGuid: undefined,
    }))
    .sort((a, b) => a.affinityDate.localeCompare(b.affinityDate))
}

export const getVisitsFromPastYear = (
  visits: VisitViewModel[],
  lastYearRenewalAnchor: IsoDateString,
  currentYearRenewalAnchor: IsoDateString,
  tzId: TimeZoneId,
): VisitViewModel[] => {
  return visits.filter(
    visit =>
      BzDateFns.isAfter(BzDateFns.parseISO(visit.issuedAt, tzId), BzDateFns.parseISO(lastYearRenewalAnchor, tzId)) &&
      BzDateFns.isBefore(BzDateFns.parseISO(visit.issuedAt, tzId), BzDateFns.parseISO(currentYearRenewalAnchor, tzId)),
  )
}

export const renewMaintenancePlanVisits = (visitsFromLastYear: VisitViewModel[], tzId: TimeZoneId): VisitRequest[] => {
  const now = BzDateFns.formatISO(BzDateFns.now(tzId), tzId)
  const renewalActivationDate = BzDateFns.formatLocalDate(BzDateFns.parseISO(now, tzId))
  return futureCloneVisits(visitsFromLastYear, renewalActivationDate, tzId)
}

export const getMaintenancePlanRenewalAnchor = (mp: {
  yearlyPriceUsc: number
  activatedAt?: IsoDateString
  billingStartAt?: IsoDateString
}): IsoDateString | undefined => {
  // for Free plans, return the activatedAt date since the billingStartAt is not set
  if (mp.yearlyPriceUsc === 0) {
    return mp.activatedAt
  }

  return mp.billingStartAt
}

export const isMaintenancePlanAutoRenewable = (mp: {
  paymentFlow: MaintenancePlanPaymentFlow
  status: MaintenancePlanStatus
  paymentInterval?: MaintenancePlanPaymentInterval
  visits?: VisitViewModel[]
  yearlyPriceUsc: number
  activatedAt?: IsoDateString
  billingStartAt?: IsoDateString
}): boolean => {
  return (
    mp.paymentFlow === MaintenancePlanPaymentFlow.AUTO &&
    mp.status === MaintenancePlanStatus.ACTIVE &&
    !isNullish(mp.paymentInterval) &&
    !isNullish(getMaintenancePlanRenewalAnchor(mp)) &&
    !isNullish(mp.visits) &&
    mp.visits.length > 0
  )
}

export const getRenewalAnchors = (
  mp: {
    yearlyPriceUsc: number
    activatedAt?: IsoDateString
    billingStartAt?: IsoDateString
  },
  now: IsoDateString,
  tzId: TimeZoneId,
) => {
  const currentYearRenewalAnchor = convertRenewalAnchorToCurrentYear(
    getMaintenancePlanRenewalAnchor(mp) ?? now,
    now,
    tzId,
  )
  const lastYearRenewalAnchor = BzDateFns.formatISO(
    BzDateFns.subYears(BzDateFns.parseISO(currentYearRenewalAnchor, tzId), 1),
    tzId,
  )
  return { currentYearRenewalAnchor, lastYearRenewalAnchor }
}

export const getExistingVisitsForCurrentPeriod = (
  visits: VisitViewModel[],
  currentYearRenewalAnchor: IsoDateString,
  tzId: TimeZoneId,
) => {
  return visits.filter(visit =>
    BzDateFns.isAfter(BzDateFns.parseISO(visit.issuedAt, tzId), BzDateFns.parseISO(currentYearRenewalAnchor, tzId)),
  )
}

export const processMaintenancePlanVisitRenewals = (
  maintenancePlans: MaintenancePlanQueryableRecordViewModel[],
  tzId: TimeZoneId,
): { maintenancePlanGuid: string; newVisits: VisitRequest[] }[] => {
  const now = BzDateFns.formatISO(BzDateFns.now(tzId), tzId)

  return maintenancePlans
    .filter(mp => isMaintenancePlanAutoRenewable(mp))
    .filter(mp => {
      const renewalAnchor = getMaintenancePlanRenewalAnchor({
        yearlyPriceUsc: mp.yearlyPriceUsc,
        activatedAt: mp.activatedAt,
        billingStartAt: mp.billingStartAt,
      })
      return isRenewalAnchorWithinLast24Hours({ renewalAnchor, tzId })
    })
    .map(mp => {
      if (!mp.visits) {
        return { maintenancePlanGuid: mp.maintenancePlanGuid, newVisits: [] }
      }

      const { currentYearRenewalAnchor, lastYearRenewalAnchor } = getRenewalAnchors(mp, now, tzId)
      const existingVisitsForCurrentPeriod = getExistingVisitsForCurrentPeriod(
        mp.visits,
        currentYearRenewalAnchor,
        tzId,
      )

      if (existingVisitsForCurrentPeriod.length > 0) {
        // Visits for the current renewal period already exist, return empty array
        return { maintenancePlanGuid: mp.maintenancePlanGuid, newVisits: [] }
      }
      const visitsFromPastYear = getVisitsFromPastYear(mp.visits, lastYearRenewalAnchor, currentYearRenewalAnchor, tzId)
      const newVisits = renewMaintenancePlanVisits(visitsFromPastYear, tzId)

      return { maintenancePlanGuid: mp.maintenancePlanGuid, newVisits }
    })
    .filter(result => result.newVisits.length > 0)
}

export const getRenewalDueAt = (
  maintenancePlan: {
    yearlyPriceUsc: number
    activatedAt?: IsoDateString
    billingStartAt?: IsoDateString
    terminatesAt?: IsoDateString
    configuration?: { numDaysUntilVisitCreditExpiration?: number }
  },
  tzId: TimeZoneId,
) => {
  // If terminatesAt is set, use it directly
  if (maintenancePlan.terminatesAt) {
    return maintenancePlan.terminatesAt
  }

  const now = BzDateFns.formatISO(BzDateFns.now(tzId), tzId)
  const currentYearRenewalAnchor = convertRenewalAnchorToCurrentYear(
    getMaintenancePlanRenewalAnchor(maintenancePlan) ?? now,
    now,
    tzId,
  )
  const numDaysUntilExpiration = maintenancePlan.configuration?.numDaysUntilVisitCreditExpiration ?? 365

  return BzDateFns.withTimeZone(currentYearRenewalAnchor, tzId, date => BzDateFns.addDays(date, numDaysUntilExpiration))
}
