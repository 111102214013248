import { PageHeader } from '@ant-design/pro-components'
import {
  BzDateTime,
  InvoiceV2Status,
  PermissionV2,
  isNullish,
} from '@breezy/shared'
import { faTableCells } from '@fortawesome/pro-light-svg-icons'
import { Input } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { useQuery } from 'urql'
import { Page } from '../../components/Page/Page'
import { Authorized } from '../../components/Permissions/Authorized/Authorized'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { InvoicesBoolExp, InvoicesOrderBy } from '../../generated/user/graphql'
import { useDebouncedSearchText } from '../../hooks/useDebouncedSearchText'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import {
  BOOKKEEPER_INVOICE_V2_QUERY,
  BookkeeperInvoiceV2QueryResult,
} from './Bookkeeper.gql'
import { BookkeeperTable, PayoutItem, TableBookkeeper } from './BookkeeperTable'

export const BookkeeperListPage = () => {
  const companyGuid = useExpectedCompanyGuid()
  const companyTimeZoneId = useExpectedCompanyTimeZoneId()
  const today = BzDateTime.startOfToday(companyTimeZoneId)

  const [pageNum, setPageNum] = useState(1)
  const [numMaxItems, setNumMaxItems] = useState(30)
  const [where, setWhere] = useState<InvoicesBoolExp>({})

  const [orderBy, setOrderBy] = useState<InvoicesOrderBy[]>([
    { issuedAt: 'DESC' },
    { account: { accountDisplayName: 'ASC' } },
  ])

  const { searchText, onSearch, debouncedSearchText } = useDebouncedSearchText({
    wait: 500,
  })

  const displayIdSearch = useMemo<number | null>(() => {
    const searchText = debouncedSearchText.trim()
    if (searchText === '') {
      return null
    }

    const value = Number(searchText)
    if (Number.isNaN(value)) {
      return null
    }

    return value
  }, [debouncedSearchText])

  const query = useQuery({
    query: BOOKKEEPER_INVOICE_V2_QUERY,
    variables: {
      where: {
        companyGuid: { _eq: companyGuid },
        issuedAt: {
          _gte: today.plusYears(-10).toIsoString(),
          _lte: today.plusDays(1).toIsoString(),
        },
        _or: [
          {
            account: {
              accountDisplayName: { _ilike: `%${debouncedSearchText.trim()}%` },
            },
          },
          { status: { _ilike: `%${debouncedSearchText.trim()}%` } },

          // We need to do this because if displayIdSearch is not a number, it will throw an error.
          // For some reason, _eq does not treat undefined as empty but as a nullish value
          ...(!isNullish(displayIdSearch)
            ? [{ displayId: { _eq: displayIdSearch } }]
            : []),
          ...(!isNullish(displayIdSearch)
            ? [{ jobLink: { job: { displayId: { _eq: displayIdSearch } } } }]
            : []),
        ],
        ...where,
      },
      orderBy: orderBy,
      limit: numMaxItems,
      offset: (pageNum - 1) * numMaxItems,
    },
  })

  const onPaginationChanged = useCallback(
    (page: number, pageSize: number) => {
      setPageNum(page)
      setNumMaxItems(pageSize)
      query[1]()
    },
    [query],
  )

  const onPaginationShowSizeChanged = useCallback(
    (current: number, size: number) => {
      setPageNum(current)
      setNumMaxItems(size)
      query[1]()
    },
    [query],
  )

  const onTableChanged = useCallback(
    (values: {
      filters: { field: string; filterValues: string[] }[]
      sorters: { field: string; ascending: boolean }[]
    }) => {
      const { filters, sorters } = values
      const where: InvoicesBoolExp = {
        companyGuid: { _eq: companyGuid },
      }
      filters.forEach(({ field, filterValues }) => {
        switch (field) {
          case 'status':
            where.status = {
              ...where.status,
              _in: filterValues as InvoiceV2Status[],
            }
            break
          case 'Quickbooks Sync':
            where.qboStaleInfo = {
              ...where.qboStaleInfo,
              isQboStale: {
                _in: filterValues.map(value => value === 'Out of sync'),
              },
            }
            break
          case 'QBD':
            where.accountingStaleInfo = {
              ...where.accountingStaleInfo,
              isStale: {
                _in: filterValues.map(value => value === 'Out of sync'),
              },
            }
            break
          default:
            break
        }
      })

      const orderBy: InvoicesOrderBy[] = []
      sorters.forEach(({ field, ascending }) => {
        switch (field) {
          case 'invoiceNumber':
            orderBy.push({ displayIdV2: ascending ? 'ASC' : 'DESC' })
            break
          case 'totalUsc':
            orderBy.push({ totalUsc: ascending ? 'ASC' : 'DESC' })
            break
          case 'issuedAt':
            orderBy.push({ issuedAt: ascending ? 'ASC' : 'DESC' })
            break
          default:
            break
        }
      })

      setWhere(where)
      setOrderBy(
        orderBy.length > 0
          ? orderBy
          : [{ issuedAt: 'DESC' }, { account: { accountDisplayName: 'ASC' } }],
      )
      setPageNum(1)
    },
    [companyGuid],
  )

  return (
    <Page requiresCompanyUser>
      <div className="card-no-fixed-height pt-2">
        <PageHeader
          className="p-2"
          title={<PageTitle title="Bookkeeping" icon={faTableCells} />}
          extra={
            <Authorized
              to={PermissionV2.OFFICE_FINANCIAL_INFORMATION_INVOICES_VIEW}
            >
              <div className="flex flex-row space-x-2">
                <Input
                  placeholder="Search"
                  prefix={<AiOutlineSearch />}
                  value={searchText}
                  onChange={event => {
                    onSearch(event)
                    setPageNum(1)
                  }}
                  style={{ minWidth: 500 }}
                  allowClear
                />
              </div>
            </Authorized>
          }
        />
        <Authorized
          to={PermissionV2.OFFICE_FINANCIAL_INFORMATION_INVOICES_VIEW}
        >
          <>
            <BookkeeperTable
              loading={query[0].fetching}
              refetch={query[1]}
              items={
                query[0].data
                  ? query[0].data.invoices.map(iq => mapItem(iq))
                  : []
              }
              page={pageNum}
              perPage={numMaxItems}
              total={query[0].data?.invoicesAggregate.aggregate?.count ?? 0}
              onPaginationChanged={onPaginationChanged}
              onPaginationShowSizeChanged={onPaginationShowSizeChanged}
              onTableChanged={onTableChanged}
            />
          </>
        </Authorized>
      </div>
    </Page>
  )
}

export const mapItem = (
  item: BookkeeperInvoiceV2QueryResult['invoices'][number],
): TableBookkeeper => {
  const transformedPayments: TableBookkeeper['successfulPayments'] =
    item.invoicePayments.map(p => {
      return {
        paymentRecordGuid: p.paymentRecordGuid,
        paymentMethod: p.paymentRecord.paymentMethod,
        occurredAt: p.paymentRecord.occurredAt,
        paymentStatuses: p.paymentRecord.paymentStatuses,
        amountUsd: p.paymentRecord.amountUsd,
        payoutItem: p.paymentRecord.payoutItem,
        qboSync: p.paymentRecord.qboSync?.qboId
          ? {
              qboId: p.paymentRecord.qboSync?.qboId,
            }
          : undefined,
      }
    }) ?? []

  const successfulPayments: TableBookkeeper['successfulPayments'] =
    transformedPayments.filter(
      p =>
        (p.paymentStatuses ?? []).length > 0 &&
        p.paymentStatuses[0].paymentStatus !== 'FAILED' &&
        p.paymentStatuses[0].paymentStatus !== 'CANCELED',
    )

  const payouts = successfulPayments
    .filter(p => !!p.payoutItem?.payout)
    .map(p => p.payoutItem?.payout)
    .filter((value): value is PayoutItem => !!value)
    .filter(
      (value, index, self) =>
        self.findIndex(s => s.payoutGuid === value.payoutGuid) === index,
    )

  const payoutFeesUsc = item.invoicePayments
    .map(p => p.paymentRecord ?? [])
    .reduce(
      (acc, curr) =>
        acc +
        (curr.payoutItem?.childPayoutItems ?? []).reduce(
          (acc, curr) => acc + curr.itemAmountUsc,
          0,
        ),
      0,
    )

  const dueUsc = item.aggregatableInvoice?.dueUsc ?? 0

  return {
    ...item,
    issuedAt: item.issuedAt ?? item.createdAt,
    status: item.status,
    dueUsc,
    qboStale: {
      ...item.qboStaleInfo,
      stale: item.qboStaleInfo?.stale ?? false,
      updatedAt: item.qboStaleInfo?.updatedAt ?? item.updatedAt,
    },
    displayId: item.displayIdV2 ?? item.displayId.toString(),
    accountDisplayName:
      item.account?.accountDisplayName ?? 'Missing Account Display Name',
    successfulPayments,
    payments: transformedPayments,
    payouts: payouts,
    accountingStaleInfo: item.accountingStaleInfo,
    payoutFeesUsc,
    paidUsc: item.aggregatableInvoice?.paidUsc ?? 0,
    lastPaidAt: item.aggregatableInvoice?.lastPaidAt,
  }
}
