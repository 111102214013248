import { CalculatePaths, formatUsc, SimpleInvoicePayment } from '@breezy/shared'
import { Popover } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { EmDash } from '../../elements/EmDash/EmDash'

type InvoiceIdListWithPopoverProps = {
  invoicePayments: SimpleInvoicePayment[]
}

const maxListSize = 3

export const InvoiceIdListWithPopover =
  React.memo<InvoiceIdListWithPopoverProps>(({ invoicePayments }) => {
    const invoicePaymentsToShow = invoicePayments.slice(0, maxListSize)
    const hiddenInvoicePaymentsCount = invoicePayments.length - maxListSize

    const invoiceLinks = invoicePaymentsToShow.reduce(
      (acc, invoicePayment, idx) => {
        acc.push(
          <React.Fragment key={invoicePayment.invoiceGuid}>
            {idx > 0 && ', '}
            <Link
              key={invoicePayment.invoiceGuid}
              to={CalculatePaths.invoiceOverview({
                invoiceGuid: invoicePayment.invoiceGuid,
              })}
            >
              {invoicePayment.displayId}
            </Link>
          </React.Fragment>,
        )
        return acc
      },
      [] as React.ReactNode[],
    )

    return invoiceLinks.length > 0 ? (
      <>
        {invoiceLinks.map((n, ni) => {
          return (
            <React.Fragment key={invoicePayments[ni].invoiceGuid}>
              {n}
            </React.Fragment>
          )
        })}
        {hiddenInvoicePaymentsCount > 0 && (
          <Popover
            title={`Invoices`}
            content={
              <>
                <div>
                  {invoicePayments.map(ip => (
                    <div key={ip.invoiceGuid}>
                      <Link
                        to={CalculatePaths.invoiceOverview({
                          invoiceGuid: ip.invoiceGuid,
                        })}
                      >
                        Invoice {ip.displayId} ({formatUsc(ip.appliedAmountUsc)}{' '}
                        applied)
                      </Link>
                    </div>
                  ))}
                </div>
              </>
            }
          >
            <span className="ml-2 text-gray-500">
              +{hiddenInvoicePaymentsCount} more
            </span>
          </Popover>
        )}
      </>
    ) : (
      <EmDash />
    )
  })
