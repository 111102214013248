import {
  AccountGuid,
  AccountingPaymentImport,
  InvoiceGuid,
  PaymentRecordGuid,
  usdToUsCents,
} from '@breezy/shared'
import { Alert } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useQuery } from 'urql'
import { trpc } from '../../hooks/trpc'
import { LoadingSpinner } from '../LoadingSpinner'
import { FETCH_PAYABLE_INVOICES_FOR_ACCOUNT_QUERY } from './BulkPayment.gql'
import { BulkPaymentViewInner } from './BulkPaymentView'

type ImportAccountingPaymentViewProps = {
  accountGuid: AccountGuid
  payment: AccountingPaymentImport
  onSuccess: () => void
}

export const ImportAccountingPaymentView =
  React.memo<ImportAccountingPaymentViewProps>(
    ({ accountGuid, payment, onSuccess }) => {
      const linkPaymentToQbdMutation =
        trpc.accounting[
          'accounting-app:link-payment-to-accounting'
        ].useMutation()
      const [payableInvoicesQuery] = useQuery({
        query: FETCH_PAYABLE_INVOICES_FOR_ACCOUNT_QUERY,
        variables: {
          accountGuid,
        },
      })

      // Create mapping of invoice guid to QBD payment amount
      const qbdPaymentMap = useMemo(() => {
        const result: Record<InvoiceGuid, number> = {}

        payment.appliedPayments.forEach(p => {
          if (p.breezyInvoiceGuid && !p.breezyPaymentRecordGuid) {
            result[p.breezyInvoiceGuid] = p.amountUsd
          }
        })

        return result
      }, [payment.appliedPayments])

      // Calculate initial payment data
      const initialPaymentData = {
        paymentAmountUsd: payment.appliedPayments.reduce(
          (sum, p) => sum + p.amountUsd,
          0,
        ),
        paymentMethod: payment.paymentMethod,
        externalPaymentId: payment.paymentReferenceNumber,
        invoiceGuidToAppliedPaymentAmountUsd: qbdPaymentMap,
        referenceNumber: payment.paymentReferenceNumber,
      }

      const payableInvoices = useMemo(() => {
        return (
          payableInvoicesQuery.data?.aggregatableInvoices.map(i => ({
            ...i,
            paidUsc: usdToUsCents(qbdPaymentMap[i.invoiceGuid] ?? 0),
            readonly: !!qbdPaymentMap[i.invoiceGuid],
          })) ?? []
        )
      }, [payableInvoicesQuery.data, qbdPaymentMap])

      const onPaymentRecordCreated = useCallback(
        (paymentRecordGuid: PaymentRecordGuid) => {
          linkPaymentToQbdMutation.mutate({
            accountGuid,
            paymentRecordGuid,
            externalCustomerId: payment.externalCustomerId,
            externalPaymentId: payment.externalPaymentId,
          })
        },
        [
          accountGuid,
          linkPaymentToQbdMutation,
          payment.externalCustomerId,
          payment.externalPaymentId,
        ],
      )

      if (payableInvoicesQuery.fetching) {
        return (
          <div className="flex h-full min-h-[400px] w-full items-center justify-center">
            <LoadingSpinner />
          </div>
        )
      }

      if (payableInvoicesQuery.error) {
        return (
          <div>
            <Alert
              message="There was an error fetching payable invoices. Please try again later."
              description={payableInvoicesQuery.error.message}
              type="error"
            />
          </div>
        )
      }

      if (payableInvoices.length === 0) {
        return (
          <div>
            <Alert message="No payable invoices found." type="info" />
          </div>
        )
      }

      return (
        <BulkPaymentViewInner
          accountGuid={accountGuid}
          payableInvoices={payableInvoices}
          onChange={() => {}}
          onSuccess={onSuccess}
          initialPaymentData={initialPaymentData}
          onSubmitSuccess={onPaymentRecordCreated}
          mode="import"
        />
      )
    },
  )
