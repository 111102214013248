import classNames from 'classnames'
import React from 'react'
import useIsMobile from '../../hooks/useIsMobile'
import { OnsitePageSection } from './OnsitePageSection'

type OnsiteWhiteLabelSectionProps = {
  companyBlurb: string
  companyName: string
  logoUrl: string
}

export const OnsiteWhiteLabelSection = React.memo<OnsiteWhiteLabelSectionProps>(
  ({ companyBlurb, companyName, logoUrl }) => {
    const isMobile = useIsMobile()

    return (
      <OnsitePageSection title={companyName}>
        <div
          className={classNames(
            'align-start flex',
            isMobile ? 'flex-col' : 'flex-row',
          )}
        >
          <div
            className={classNames(isMobile ? 'mb-6' : 'mr-6', {
              'whitespace-pre-wrap': true,
            })}
          >
            {companyBlurb}
          </div>
          <div>
            <img
              src={logoUrl}
              alt={companyName}
              className="h-[72px] min-h-[72px] w-[174px] min-w-[174px] object-contain object-right-top"
            />
          </div>
        </div>
      </OnsitePageSection>
    )
  },
)
