import { search as fuzzySearch } from 'fast-fuzzy'
import { z } from 'zod'
import { AsyncFn, isNullish, normalizeString } from '../../common'
import { guidSchema } from '../../contracts/_common'
import type { CompanyGuidContainer, ForCompanyUser } from '../Company/Company'
import { JobClass } from '../Job/JobClass'
import { bzOptional, Guid } from '../common-schemas'
export type JobTypeGuid = Guid

export const JOB_TYPE_DURATION_MINUTES_OPTIONS = [
  60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360, 390, 420, 450, 480, 510, 540, 570, 600, 630, 660, 690, 720,
] as const

export type JobTypeDurationMinutes = (typeof JOB_TYPE_DURATION_MINUTES_OPTIONS)[number]

export const JobTypeDurationMinutesDisplayNames: Record<JobTypeDurationMinutes, string> = {
  60: '1 hour',
  90: '1.5 hours',
  120: '2 hours',
  150: '2.5 hours',
  180: '3 hours',
  210: '3.5 hours',
  240: '4 hours',
  270: '4.5 hours',
  300: '5 hours',
  330: '5.5 hours',
  360: '6 hours',
  390: '6.5 hours',
  420: '7 hours',
  450: '7.5 hours',
  480: '8 hours',
  510: '8.5 hours',
  540: '9 hours',
  570: '9.5 hours',
  600: '10 hours',
  630: '10.5 hours',
  660: '11 hours',
  690: '11.5 hours',
  720: '12 hours',
}

export const upsertJobTypeRequestSchema = z
  .object({
    jobTypeGuid: guidSchema,
    name: z.string(),
    description: bzOptional(z.string()),
    defaultSummary: bzOptional(z.string()),
    jobClass: z.nativeEnum(JobClass),
    jobLifecycleGuid: guidSchema,
    isOpportunity: z.boolean(),
    // BZ-2708: Refactor this as "conversionThresholdUsc"
    opportunityConversionThresholdUsc: bzOptional(z.number().int().gte(0)),
    isPotentialHotLead: z.boolean(),
    hotLeadMinimumEquipmentAgeYears: bzOptional(z.number().int().gte(0)),
    durationMinutes: z
      .number()
      .refine(val => JOB_TYPE_DURATION_MINUTES_OPTIONS.includes(val as JobTypeDurationMinutes)),
  })
  .refine(
    data => {
      if (!isNullish(data.isOpportunity)) {
        return !isNullish(data.opportunityConversionThresholdUsc)
      } else {
        return true
      }
    },
    { message: 'Job Type must have an Opportunity Conversion Threshold' },
  )
  .refine(
    data => {
      if (data.isPotentialHotLead) {
        return !isNullish(data.hotLeadMinimumEquipmentAgeYears)
      } else {
        return isNullish(data.hotLeadMinimumEquipmentAgeYears)
      }
    },
    {
      message:
        "Job Type must have a Hot Lead Minimum Equipment Age if and only if it's flagged as a Potential Hot Lead",
    },
  )

export type JobType = z.infer<typeof upsertJobTypeRequestSchema>

export type JobTypeMinimal = Omit<
  JobType,
  'isOpportunity' | 'opportunityConversionThresholdUsc' | 'isPotentialHotLead' | 'hotLeadMinimumEquipmentAgeYears'
>

export type UpsertJobTypeRequest = JobType

export type JobTypeUpserter = AsyncFn<ForCompanyUser<JobType>>

export type FullJobType = JobType & {
  companyGuid: string
  lastModifiedBy: string
  createdAt: string
  updatedAt: string
  archivedAt?: string
}

export type JobTypesReader = AsyncFn<CompanyGuidContainer, FullJobType[]>

export const tryMatchJobType = (jobTypes: JobType[], searchTerm: string): JobType | undefined => {
  if (jobTypes.length === 0 || !searchTerm || searchTerm.trim().length === 0) {
    return undefined
  }

  return fuzzySearch(normalizeString(searchTerm), jobTypes, {
    keySelector: jobType => jobType.name,
  })[0]
}
