import { PageHeader } from '@ant-design/pro-components'
import { BzDateFns, bzOptional } from '@breezy/shared'
import { faMessageSms } from '@fortawesome/pro-light-svg-icons'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Form } from 'antd'
import Mustache from 'mustache'
import { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'urql'
import { z } from 'zod'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'

import { CategorySection } from '../../components/Settings/SettingsBuilderComponents'
import { getConfig } from '../../config'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { TextAreaField } from '../../elements/Forms/TextAreaField'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import { SettingsPage } from '../../elements/SettingsPage/SettingsPage'
import { gql } from '../../generated/user'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'

const MAX_SMS_LENGTH = 160

const config = getConfig()

export const smsSettingsFormSchema = z.object({
  smsAppointmentTemplate: bzOptional(z.string().max(MAX_SMS_LENGTH)),
})

export type SMSSettingsFormData = z.infer<typeof smsSettingsFormSchema>

export const SMSSettingsPage = () => {
  const { companyGuid } = useExpectedCompany()

  const smsSettingsQuery = useQuery({
    query: SMS_SETTINGS_QUERY,
    variables: { companyGuid },
  })

  return (
    <GqlQueryLoader
      query={smsSettingsQuery}
      render={data => (
        <SMSSettingsPageInner
          smsSettings={{
            smsAppointmentTemplate:
              data.companyConfig?.[0]?.smsAppointmentTemplate,
          }}
        />
      )}
    />
  )
}

const SMSSettingsPageInner = ({
  smsSettings,
}: {
  smsSettings: {
    smsAppointmentTemplate: string | undefined
  }
}) => {
  const { name: companyName, companyGuid } = useExpectedCompany()

  const defaultTemplate = `✅ Your HVAC visit with ${companyName} on {{visitDate}} is confirmed.`

  const message = useMessage()
  const {
    formState: { errors, isDirty },
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm<SMSSettingsFormData>({
    resolver: zodResolver(smsSettingsFormSchema),
    defaultValues: {
      smsAppointmentTemplate:
        smsSettings?.smsAppointmentTemplate || defaultTemplate,
    },
    reValidateMode: 'onChange',
  })

  const smsAppointmentTemplate = watch('smsAppointmentTemplate') || ''
  const exampleMessage = useMemo(() => {
    return Mustache.render(smsAppointmentTemplate || defaultTemplate, {
      visitDate: BzDateFns.format(new Date(), 'EEE, MMM d'),
    })
  }, [smsAppointmentTemplate, defaultTemplate])

  const [{ fetching: submitting }, executeMutation] = useMutation(
    UPDATE_SMS_SETTINGS_MUTATION,
  )

  const onSubmit = handleSubmit(async data => {
    try {
      await executeMutation({
        companyGuid,
        smsAppointmentTemplate: data.smsAppointmentTemplate,
      })
      message.success('SMS settings updated')
      reset(data)
    } catch (e) {
      message.error('Failed to update SMS settings')
    }
  })

  return (
    <SettingsPage requiresCompanyUser>
      <div className="w-1/2 content-center">
        <PageHeader
          title={<PageTitle title="SMS Settings" icon={faMessageSms} />}
        />
      </div>

      <ScrollCard
        className="mt-auto flex h-full w-full flex-col gap-3"
        hasPageHeading
      >
        <Form onSubmitCapture={onSubmit} layout="vertical">
          <div className="flex flex-row items-start justify-between border-0 border-b border-solid border-b-bz-gray-400 pb-4">
            <div className="max-w-[768px] text-bz-gray-900">
              Customize the messages sent to your clients. SMS templates allow
              you to create consistent messaging while including personalized
              information. Leave blank to use the default template.
            </div>
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              disabled={!isDirty}
            >
              Save Changes
            </Button>
          </div>
          <div className="divide-y divide-solid divide-bz-gray-400">
            <CategorySection
              title="Visits"
              subtitle="Configure job types, technician schedules, and booking preferences to streamline your operations."
              layout={{ leftContainerFlex: 1, rightContainerFlex: 2 }}
            >
              <div className="mb-6 border-0 pt-6">
                <div className="mb-4 flex items-start justify-between">
                  <div>
                    <div className="text-base font-semibold text-bz-gray-1000">
                      Visit Booking Confirmation
                    </div>
                    <div className="text-bz-gray-900">
                      The message sent to the client when a visit is booked.
                    </div>
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        setValue('smsAppointmentTemplate', defaultTemplate, {
                          shouldDirty: true,
                        })
                      }}
                    >
                      Reset to Default
                    </Button>
                  </div>
                </div>
                <div className="space-y-4">
                  <ReactHookFormItem
                    control={control}
                    name="smsAppointmentTemplate"
                    errors={errors}
                    label="Message Text"
                    render={({ field }) => (
                      <TextAreaField
                        placeholder={defaultTemplate}
                        disabled={submitting}
                        {...field}
                      />
                    )}
                  />
                  <div className="mt-4 flex w-full flex-col items-stretch">
                    <div className="semibold_14_22 mb-2">Example Message</div>
                    <div className="flex items-end justify-end gap-2 rounded-lg bg-gray-100 p-3">
                      <div>
                        <div className="mb-1 flex items-center justify-end">
                          <span className="regular_12_20 mr-1 text-bz-text-secondary">
                            {config.twilioFromNumber || companyName}
                          </span>
                        </div>
                        <div className="flex items-end">
                          <div className="max-w-xs rounded-2xl bg-blue-500 px-3 py-2 text-white">
                            <p className="text-sm">{exampleMessage}</p>
                          </div>
                        </div>
                      </div>
                      <div className="semibold_14_22 flex h-8 w-8 items-center justify-center rounded-full bg-blue-100 text-xs text-bz-blue-600">
                        <FontAwesomeIcon icon={faUser} />
                      </div>
                    </div>
                    <div className="mt-2 text-xs text-bz-gray-600">
                      <p>
                        Available variables: <code>{`{{ visitDate }}`}</code> -
                        Formatted as "Mon, Jan 1"
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </CategorySection>
          </div>
        </Form>
      </ScrollCard>
    </SettingsPage>
  )
}

const UPDATE_SMS_SETTINGS_MUTATION = gql(/* GraphQL */ `
  mutation UpdateSMSSettings(
    $companyGuid: uuid!
    $smsAppointmentTemplate: String
  ) {
    updateCompanyConfig(
      where: { companyGuid: { _eq: $companyGuid } }
      _set: { smsAppointmentTemplate: $smsAppointmentTemplate }
    ) {
      __typename
    }
  }
`)

const SMS_SETTINGS_QUERY = gql(/* GraphQL */ `
  query SMSSettings($companyGuid: uuid!) {
    companyConfig(where: { companyGuid: { _eq: $companyGuid } }) {
      smsAppointmentTemplate
    }
  }
`)
