import { IsoDateString } from '@breezy/shared'
import { DocumentType, gql } from '../../generated'

export const PRICEBOOK_ITEMS_ADMIN_QUERY = gql(/* GraphQL */ `
  query GetPricebookItemsAdmin($companyGuid: uuid!) {
    pricebookItems(
      where: { companyGuid: { _eq: $companyGuid }, isDeleted: { _eq: false } }
    ) {
      pricebookItemGuid
      companyGuid
      name
      description
      itemType
      imageUrl
      costUSD: costUsd
      priceUSD: priceUsd
      isTaxable
      version
      isActive
      isDiscountable
      sourcePhotoGuid
      pricebookItemCode
      sourcePhoto {
        cdnUrl
      }
      photoGuid
      photo {
        cdnUrl
      }
      pricebookCategory {
        pricebookCategoryGuid
        name
        parentCategoryGuid
      }
      qboIncomeAccount: pricebookItemsQboIncomeAccount {
        qboIncomeAccountId
      }
    }
    pricebookCategories(where: { companyGuid: { _eq: $companyGuid } }) {
      pricebookCategoryGuid
      name
      parentCategoryGuid
      sourcePhotoGuid
      sourcePhoto {
        cdnUrl
      }
      photoGuid
      photo {
        cdnUrl
      }
    }
  }
`)

export type PricebookItemsAdmin = DocumentType<
  typeof PRICEBOOK_ITEMS_ADMIN_QUERY
>

export const PRICEBOOK_CATEGORIES_ADMIN_QUERY = gql(/* GraphQL */ `
  query GetPricebookCategoriesAdmin($companyGuid: uuid!) {
    pricebookCategories(where: { companyGuid: { _eq: $companyGuid } }) {
      pricebookCategoryGuid
      name
      parentCategoryGuid
      parentPricebookCategory {
        name
        pricebookCategoryGuid
        parentCategoryGuid
      }
      sourcePhotoGuid
      sourcePhoto {
        cdnUrl
      }
      photoGuid
      photo {
        cdnUrl
      }
    }
  }
`)

type PricebookCategories = DocumentType<
  typeof PRICEBOOK_CATEGORIES_ADMIN_QUERY
>['pricebookCategories']
export type PricebookCategoriesItem = DocumentType<
  typeof PRICEBOOK_CATEGORIES_ADMIN_QUERY
>['pricebookCategories'][number]
export type PricebookCategory = PricebookCategoriesItem & {
  children?: PricebookCategories
}

export const PRICEBOOK_TAX_RATES_ADMIN_QUERY = gql(/* GraphQL */ `
  query GetPricebookTaxRatesAdmin {
    pricebookTaxRates {
      pricebookTaxRateGuid
      companyGuid
      name
      rate
      isActive
      version
      accountTypes
      zipCodes
    }
    billingProfiles {
      defaultPricebookTaxRateGuid
    }
  }
`)

export type PricebookTaxRate = DocumentType<
  typeof PRICEBOOK_TAX_RATES_ADMIN_QUERY
>['pricebookTaxRates'][number]

export const PRICEBOOK_DISCOUNTS_ADMIN_QUERY = gql(/* GraphQL */ `
  query GetPricebookDiscountsAdmin($companyGuid: uuid!) {
    pricebookDiscounts(where: { companyGuid: { _eq: $companyGuid } }) {
      pricebookDiscountGuid
      companyGuid
      name
      type
      discountAmountUsd
      discountRate
      description
      isActive
      version
    }
  }
`)

export type PricebookDiscount = DocumentType<
  typeof PRICEBOOK_DISCOUNTS_ADMIN_QUERY
>['pricebookDiscounts'][number]

export const PRICEBOOK_MARKUP_TIERS_QUERY = gql(/* GraphQL */ `
  query GetPricebookMarkupTiers($companyGuid: uuid!) {
    pricebookMarkupTiers(where: { companyGuid: { _eq: $companyGuid } }) {
      pricebookMarkupTierGuid
      companyGuid
      costRangeEndInclusiveUsc
      markupRate
      createdAt
      updatedAt
    }
  }
`)

export type PricebookMarkupTier = DocumentType<
  typeof PRICEBOOK_MARKUP_TIERS_QUERY
>['pricebookMarkupTiers'][number]

export const UPSERT_PRICEBOOK_MARKUP_TIER_MUTATION = gql(/* GraphQL */ `
  mutation UpsertPricebookMarkupTier($input: PricebookMarkupTiersInsertInput!) {
    insertPricebookMarkupTiersOne(
      object: $input
      onConflict: {
        constraint: pricebook_markup_tiers_pkey
        updateColumns: [costRangeEndInclusiveUsc, markupRate, updatedAt]
      }
    ) {
      pricebookMarkupTierGuid
      companyGuid
      costRangeEndInclusiveUsc
      markupRate
      createdAt
      updatedAt
    }
  }
`)

export const DELETE_PRICEBOOK_MARKUP_TIER_MUTATION = gql(/* GraphQL */ `
  mutation DeletePricebookMarkupTier($pricebookMarkupTierGuid: uuid!) {
    deletePricebookMarkupTiersByPk(
      pricebookMarkupTierGuid: $pricebookMarkupTierGuid
    ) {
      pricebookMarkupTierGuid
    }
  }
`)

export type PricebookMarkupTierInput = {
  pricebookMarkupTierGuid?: string
  companyGuid: string
  costRangeEndInclusiveUsc?: number
  markupRate: number
  updatedAt: IsoDateString
}
