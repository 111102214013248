import {
  BzDateFns,
  CompanyLeadSource,
  CompanyLeadSourceGuid,
  INVOICE_V2_FRIENDLY_STATUS_NAMES,
  JOBS_V2_CLASSES,
  UserGuid,
  isNullish,
  jobClassDisplayNames,
  streetAddressLine1And2Condensed,
  usdToUsCents,
} from '@breezy/shared'
import React, { useCallback, useMemo } from 'react'
import { useQuery } from 'urql'
import { ListPageContainer } from '../../adam-components/ListPage/ListPageContainer'
import { ListPageFilter } from '../../adam-components/ListPage/ListPageFilter'
import { ListPageFilterBar } from '../../adam-components/ListPage/ListPageFilterBar'
import { usePagination } from '../../adam-components/Pagination/usePagination'
import { Page } from '../../components/Page/Page'
import {
  AggregatableInvoicesBoolExp,
  AggregatableInvoicesOrderBy,
} from '../../generated/user/graphql'
import { trpc } from '../../hooks/trpc'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import {
  useQueryParamState,
  useQueryParamStateArray,
  useQueryParamStateEnumArray,
  useQueryParamStateWithOptions,
  useStrictContext,
} from '../../utils/react-utils'
import {
  BUSINESS_UNITS_QUERY,
  COMPANY_CONFIG_QUERY,
  INVOICES_V2_QUERY,
  INVOICE_LISTING_USERS_QUERY,
  INVOICE_V2_AVG_UNPAID_DAYS_QUERY,
  INVOICE_V2_SUMMARY_STATISTICS_AVG_VALUE_QUERY,
  INVOICE_V2_SUMMARY_STATISTICS_QUERY,
  InvoiceListPageBusinessUnit,
} from './InvoiceListPage.gql'
import {
  InvoiceListingContext,
  InvoiceListingDataContext,
} from './InvoiceListPageContexts'
import {
  FILTER_LABELS,
  MoreFilters,
  computeHasFilters,
} from './InvoiceListPageFilters'
import { InvoiceListTable } from './InvoiceListPageTable'
import {
  InvoiceListingDataContextType,
  InvoiceListingInvoice,
  SORT_TYPES,
  SortType,
  UserMap,
} from './InvoiceListPageTypes'
import { InvoiceSummaryStats } from './InvoiceSummaryStats'

type LeadSourceMap = Record<
  CompanyLeadSourceGuid,
  CompanyLeadSource | undefined
>

type BusinessUnitsMap = Record<string, InvoiceListPageBusinessUnit>

type InvoicesV2DataWrapperProps = React.PropsWithChildren

const InvoicesV2DataWrapper = React.memo<InvoicesV2DataWrapperProps>(
  ({ children }) => {
    const {
      pagination: { limit, offset },
      sortType,
      searchTerm,
      startDate,
      endDate,
      selectedJobClasses,
      selectedV2Statuses,
      selectedUserGuids,
      selectedCompanyLeadSourceGuids,
      selectedAccountManagerUserGuids,
      selectedBusinessUnitGuids,
      showMPInvoices,
    } = useStrictContext(InvoiceListingContext)

    const disableHidingMPInvoicesFromInvoiceList = useFeatureFlag(
      'disable-hiding-mp-invoices-from-invoice-list',
    )

    const orderByClause = useMemo<
      AggregatableInvoicesOrderBy | AggregatableInvoicesOrderBy[]
    >(() => {
      switch (sortType) {
        case 'NEWEST':
          return { issuedOrCreatedAt: 'DESC' }
        case 'OLDEST':
          return { issuedOrCreatedAt: 'ASC' }
        case 'OVERDUE':
          return [
            { isTerminalStatus: 'ASC' },
            { numDaysOverdue: 'DESC_NULLS_LAST' },
          ]
        case 'TOTAL':
          return { totalUsc: 'DESC' }
        case 'CREATED_BY':
          return { createdByUser: { firstName: 'ASC' } }
      }
    }, [sortType])

    const whereClause = useMemo<AggregatableInvoicesBoolExp>(() => {
      if (searchTerm) {
        const _ilike = `%${searchTerm}%`

        return {
          _or: [
            {
              displayIdString: { _ilike },
            },
            {
              displayIdV2: { _ilike },
            },
            {
              totalUscString: { _ilike },
            },
            {
              jobLink: { job: { displayIdString: { _ilike } } },
            },
            {
              jobLink: {
                job: {
                  pointOfContact: {
                    fullName: { _ilike },
                  },
                },
              },
            },
            {
              jobLink: {
                job: {
                  jobType: {
                    name: { _ilike },
                  },
                },
              },
            },
            {
              jobLink: {
                job: {
                  location: {
                    address: {
                      canonicalFullAddress: { _ilike },
                    },
                  },
                },
              },
            },
            {
              createdByUser: {
                fullName: { _ilike },
              },
            },
            {
              createdByUser: {
                emailAddress: { _ilike },
              },
            },
            {
              account: {
                accountContacts: {
                  contact: {
                    fullName: { _ilike },
                  },
                },
              },
            },
          ],
        }
      }
      const whereClause: AggregatableInvoicesBoolExp = {}
      if (startDate) {
        whereClause.issuedOrCreatedAt = { _gte: startDate }
      }
      if (endDate) {
        whereClause.issuedOrCreatedAt = {
          ...whereClause.issuedOrCreatedAt,
          _lte: endDate,
        }
      }
      if (selectedJobClasses.length) {
        whereClause.jobLink = {
          job: {
            jobType: {
              jobClass: { _in: selectedJobClasses },
            },
          },
        }
      }
      if (selectedV2Statuses.length) {
        whereClause.status = { _in: selectedV2Statuses }
      }
      if (selectedUserGuids.length) {
        whereClause.createdBy = { _in: selectedUserGuids }
      }
      if (!showMPInvoices && !disableHidingMPInvoicesFromInvoiceList) {
        whereClause._not = {
          maintenancePlanLink: {
            maintenancePlanGuid: { _isNull: false },
          },
        }
      }
      if (selectedCompanyLeadSourceGuids.length) {
        whereClause.jobLink = whereClause.jobLink || {}
        whereClause.jobLink.job = whereClause.jobLink.job || {}
        whereClause.jobLink.job.jobLeadSource =
          whereClause.jobLink.job.jobLeadSource || {}
        whereClause.jobLink.job.jobLeadSource.companyLeadSourceGuid = {
          _in: selectedCompanyLeadSourceGuids,
        }
      }
      if (selectedAccountManagerUserGuids.length) {
        whereClause.account = whereClause.account || {}
        whereClause.account.accountManagerUserGuid = {
          _in: selectedAccountManagerUserGuids,
        }
      }

      if (selectedBusinessUnitGuids.length) {
        whereClause.jobLink = whereClause.jobLink || {}
        whereClause.jobLink.job = whereClause.jobLink.job || {}
        whereClause.jobLink.job.businessUnitGuid = {
          _in: selectedBusinessUnitGuids,
        }
      }
      return whereClause
    }, [
      searchTerm,
      startDate,
      endDate,
      selectedJobClasses,
      selectedV2Statuses,
      selectedUserGuids,
      showMPInvoices,
      disableHidingMPInvoicesFromInvoiceList,
      selectedCompanyLeadSourceGuids,
      selectedAccountManagerUserGuids,
      selectedBusinessUnitGuids,
    ])

    const [{ data, fetching }, refetchInvoiceList] = useQuery({
      query: INVOICES_V2_QUERY,
      variables: {
        limit,
        offset,
        orderBy: orderByClause,
        where: whereClause,
      },
    })

    const totalItems = data?.aggregatableInvoicesAggregate.aggregate?.count ?? 0

    const [
      {
        data: outstandingSummaryStats,
        fetching: fetchingOutstandingSummaryStats,
      },
    ] = useQuery({
      query: INVOICE_V2_SUMMARY_STATISTICS_QUERY,
      variables: {
        where: {
          _and: [
            whereClause,
            {
              status: {
                _eq: 'OPEN',
              },
            },
          ],
        },
      },
    })

    const [
      { data: overdueSummaryStats, fetching: fetchingOverdueSummaryStats },
    ] = useQuery({
      query: INVOICE_V2_SUMMARY_STATISTICS_QUERY,
      variables: {
        where: {
          _and: [
            whereClause,
            {
              numDaysOverdue: { _gt: 0 },
              isTerminalStatus: { _eq: false },
            },
          ],
        },
      },
    })

    const [
      { data: fullyPaidSummaryStats, fetching: fetchingFullyPaidSummaryStats },
    ] = useQuery({
      query: INVOICE_V2_SUMMARY_STATISTICS_QUERY,
      variables: {
        where: {
          _and: [
            whereClause,
            {
              status: {
                _eq: 'PAID',
              },
            },
          ],
        },
      },
    })

    const [
      { data: avgValueSummaryStats, fetching: fetchingAvgValueSummaryStats },
    ] = useQuery({
      query: INVOICE_V2_SUMMARY_STATISTICS_AVG_VALUE_QUERY,
      variables: {
        where: {
          _and: [
            whereClause,
            {
              status: {
                _neq: 'DRAFT',
              },
            },
          ],
        },
      },
    })

    const [{ data: avgUnpaidDays, fetching: fetchingAvgUnpaidDays }] = useQuery(
      {
        query: INVOICE_V2_AVG_UNPAID_DAYS_QUERY,
        variables: {
          where: {
            _and: [
              whereClause,
              {
                status: {
                  _in: ['OPEN', 'PAID'],
                },
              },
            ],
          },
        },
      },
    )

    const summaryStatsLoading =
      fetchingOutstandingSummaryStats ||
      fetchingOverdueSummaryStats ||
      fetchingFullyPaidSummaryStats ||
      fetchingAvgValueSummaryStats ||
      fetchingAvgUnpaidDays

    const tzId = useExpectedCompanyTimeZoneId()

    const value = useMemo<InvoiceListingDataContextType>(
      () => ({
        listDataFetching: fetching,
        refetch: refetchInvoiceList,
        data:
          data?.aggregatableInvoices.map<InvoiceListingInvoice>(
            ({
              invoiceGuid,
              accountGuid,
              locationLink,
              jobLink,
              displayId,
              displayIdV2,
              status,
              totalUsc,
              paidUsc,
              processingUsc,
              account,
              issuedAt,
              dueAt,
              numDaysOverdue,
              isTerminalStatus,
              createdBy,
              maintenancePlanLink,
              businessUnitLink,
            }) => {
              const contactName =
                jobLink?.job.pointOfContact.fullName ??
                account?.accountContacts[0].contact.fullName ??
                'Unknown'

              const contactPhone =
                jobLink?.job.pointOfContact.primaryPhoneNumber ??
                account?.accountContacts[0]?.contact.primaryPhoneNumber

              const contactEmail =
                jobLink?.job.pointOfContact.primaryEmailAddress?.emailAddress ??
                account?.accountContacts[0]?.contact.primaryEmailAddress
                  ?.emailAddress

              // Because of business logic I know that this will always be defined btw
              const address = locationLink?.location.address.line1
                ? streetAddressLine1And2Condensed(
                    locationLink?.location.address.line1,
                    locationLink?.location.address.line2,
                  )
                : ''

              const fullyPaid = status === 'PAID'

              return {
                invoiceGuid: invoiceGuid,
                accountGuid: accountGuid,
                locationGuid: locationLink?.locationGuid,
                job: jobLink && {
                  jobGuid: jobLink.jobGuid,
                  displayId: jobLink.job.displayId,
                  jobType: jobLink.job.jobType.name,
                },
                maintenancePlan: maintenancePlanLink && {
                  maintenancePlanGuid: maintenancePlanLink.maintenancePlanGuid,
                  name:
                    maintenancePlanLink.maintenancePlan
                      ?.maintenancePlanDefinition?.marketingInfo?.name ??
                    'Maintenance Plan',
                },
                displayId: displayIdV2 ?? displayId.toString(),
                address,
                fullyPaid,
                totalUsc,
                paidUsc: paidUsc ?? 0,
                processingPaymentsUsc: processingUsc ?? 0,
                status,
                contact: {
                  contactName,
                  contactPhoneNumber: contactPhone?.phoneNumber,
                  contactPhoneType: contactPhone?.type,
                  contactEmail,
                },
                issuedAt,
                dueAt: dueAt
                  ? BzDateFns.formatFromISO(dueAt, 'MMM d, yyyy', tzId)
                  : undefined,
                numDaysOverdue,
                isTerminalStatus,
                createdByUserGuid:
                  maintenancePlanLink?.maintenancePlan?.createdByUserGuid ??
                  createdBy,
                leadSource: jobLink?.job.jobLeadSource?.length
                  ? {
                      companyLeadSourceGuid:
                        jobLink?.job.jobLeadSource[0].companyLeadSource
                          .companyLeadSourceGuid,
                      name:
                        jobLink.job.jobLeadSource[0].companyLeadSource
                          .canonicalLeadSourceNameOverride ??
                        jobLink.job.jobLeadSource[0].companyLeadSource
                          .canonicalLeadSourceName,
                    }
                  : undefined,
                accountManagerUserGuid:
                  account?.accountManagerUserGuid ?? undefined,
                businessUnitLink:
                  businessUnitLink &&
                  businessUnitLink.businessUnitGuid &&
                  businessUnitLink.sourceGuid &&
                  businessUnitLink.sourceType &&
                  businessUnitLink.businessUnit &&
                  businessUnitLink.businessUnit.name
                    ? {
                        businessUnitGuid: businessUnitLink.businessUnitGuid,
                        sourceType: businessUnitLink.sourceType,
                        sourceGuid: businessUnitLink.sourceGuid,
                        businessUnit: {
                          name: businessUnitLink.businessUnit.name,
                        },
                      }
                    : undefined,
              }
            },
          ) ?? [],
        totalItems,
        summaryStatsLoading,
        summaryStats: {
          outstanding: {
            count:
              outstandingSummaryStats?.aggregatableInvoicesAggregate.aggregate
                ?.count ?? 0,
            amountUsc:
              (outstandingSummaryStats?.aggregatableInvoicesAggregate.aggregate
                ?.sum?.totalUsc ?? 0) -
              usdToUsCents(
                outstandingSummaryStats?.aggregatableInvoicesAggregate.aggregate
                  ?.sum?.paidUsd ?? 0,
              ) -
              usdToUsCents(
                outstandingSummaryStats?.aggregatableInvoicesAggregate.aggregate
                  ?.sum?.processingUsd ?? 0,
              ),
          },
          overdue: {
            count:
              overdueSummaryStats?.aggregatableInvoicesAggregate.aggregate
                ?.count ?? 0,
            amountUsc:
              (overdueSummaryStats?.aggregatableInvoicesAggregate.aggregate?.sum
                ?.totalUsc ?? 0) -
              usdToUsCents(
                overdueSummaryStats?.aggregatableInvoicesAggregate.aggregate
                  ?.sum?.paidUsd ?? 0,
              ) -
              usdToUsCents(
                overdueSummaryStats?.aggregatableInvoicesAggregate.aggregate
                  ?.sum?.processingUsd ?? 0,
              ),
          },
          fullyPaid: {
            count:
              fullyPaidSummaryStats?.aggregatableInvoicesAggregate.aggregate
                ?.count ?? 0,
            amountUsc:
              fullyPaidSummaryStats?.aggregatableInvoicesAggregate.aggregate
                ?.sum?.totalUsc ?? 0,
          },
          avgValueUsc:
            avgValueSummaryStats?.aggregatableInvoicesAggregate.aggregate?.avg
              ?.totalUsc ?? 0,

          avgDaysToPay: Math.round(
            avgUnpaidDays?.aggregatableInvoicesAggregate.aggregate?.avg
              ?.daysUnpaid ?? 0,
          ),
        },
      }),
      [
        avgUnpaidDays?.aggregatableInvoicesAggregate.aggregate?.avg?.daysUnpaid,
        avgValueSummaryStats?.aggregatableInvoicesAggregate.aggregate?.avg
          ?.totalUsc,
        data?.aggregatableInvoices,
        fetching,
        fullyPaidSummaryStats?.aggregatableInvoicesAggregate.aggregate?.count,
        fullyPaidSummaryStats?.aggregatableInvoicesAggregate.aggregate?.sum
          ?.totalUsc,
        outstandingSummaryStats?.aggregatableInvoicesAggregate.aggregate?.count,
        outstandingSummaryStats?.aggregatableInvoicesAggregate.aggregate?.sum
          ?.totalUsc,
        overdueSummaryStats?.aggregatableInvoicesAggregate.aggregate?.count,
        overdueSummaryStats?.aggregatableInvoicesAggregate.aggregate?.sum
          ?.totalUsc,
        outstandingSummaryStats?.aggregatableInvoicesAggregate.aggregate?.sum
          ?.paidUsd,
        outstandingSummaryStats?.aggregatableInvoicesAggregate.aggregate?.sum
          ?.processingUsd,
        overdueSummaryStats?.aggregatableInvoicesAggregate.aggregate?.sum
          ?.paidUsd,
        overdueSummaryStats?.aggregatableInvoicesAggregate.aggregate?.sum
          ?.processingUsd,
        refetchInvoiceList,
        summaryStatsLoading,
        totalItems,
        tzId,
      ],
    )
    return (
      <InvoiceListingDataContext.Provider value={value}>
        {children}
      </InvoiceListingDataContext.Provider>
    )
  },
)

const INVOICE_V2_STATUSES = [
  'DRAFT',
  'OPEN',
  'PAID',
  'VOIDED',
  'UNCOLLECTABLE',
] as const

const INVOICE_V2_STATUS_OPTIONS = INVOICE_V2_STATUSES.map(key => ({
  key,
  label: INVOICE_V2_FRIENDLY_STATUS_NAMES[key],
}))

const PRETTY_SORT_TYPE_MAP: Record<SortType, string> = {
  NEWEST: 'Issue Date (Newest)',
  OLDEST: 'Issue Date (Oldest)',
  OVERDUE: 'Overdue (Oldest)',
  TOTAL: 'Invoice Amount (Highest)',
  CREATED_BY: 'Created By (A-Z)',
}

const START_DATE_TYPES = [
  'YESTERDAY',
  'TODAY',
  'LAST_7_DAYS',
  'LAST_30_DAYS',
  'LAST_90_DAYS',
  'LAST_6_MONTHS',
  'ALL_TIME',
] as const

type StartDateType = (typeof START_DATE_TYPES)[number]

const PRETTY_START_DATE_MAP: Record<StartDateType, string> = {
  YESTERDAY: 'Yesterday',
  TODAY: 'Today',
  LAST_7_DAYS: 'Last 7 days',
  LAST_30_DAYS: 'Last 30 days',
  LAST_90_DAYS: 'Last 90 days',
  LAST_6_MONTHS: 'Last 6 months',
  ALL_TIME: 'All time',
}

const SORT_TYPE_OPTIONS = SORT_TYPES.map(type => ({
  key: type,
  label: PRETTY_SORT_TYPE_MAP[type],
}))

const START_DATE_OPTIONS = START_DATE_TYPES.map(type => ({
  key: type,
  label: PRETTY_START_DATE_MAP[type],
}))

type WithTotalItemsProps = {
  renderChildren: (totalItems: number) => JSX.Element
}

const WithTotalItems = React.memo<WithTotalItemsProps>(({ renderChildren }) => {
  const { totalItems } = useStrictContext(InvoiceListingDataContext)
  return renderChildren(totalItems)
})

export const InvoiceListPageV2 = React.memo(() => {
  const companyGuid = useExpectedCompanyGuid()

  const pagination = usePagination('50')

  const { resetPage } = pagination

  const [startDateOption, setStartDateOption] = useQueryParamStateWithOptions(
    'created',
    'LAST_90_DAYS',
    START_DATE_TYPES,
  )

  const startDate = useMemo(
    () =>
      startDateOption === 'ALL_TIME'
        ? undefined
        : BzDateFns.nowUtcTransform(date => {
            switch (startDateOption) {
              case 'YESTERDAY':
                return BzDateFns.startOfDay(BzDateFns.subDays(date, 1))
              case 'TODAY':
                return BzDateFns.startOfDay(date)
              case 'LAST_7_DAYS':
                return BzDateFns.startOfDay(BzDateFns.subDays(date, 7))
              case 'LAST_30_DAYS':
                return BzDateFns.startOfDay(BzDateFns.subDays(date, 30))
              case 'LAST_90_DAYS':
                return BzDateFns.startOfDay(BzDateFns.subDays(date, 90))
              case 'LAST_6_MONTHS':
                return BzDateFns.startOfDay(BzDateFns.subMonths(date, 6))
            }
          }),
    [startDateOption],
  )

  const endDate = useMemo(
    () =>
      startDateOption === 'YESTERDAY'
        ? BzDateFns.nowUtcTransform(date =>
            BzDateFns.endOfDay(BzDateFns.subDays(date, 1)),
          )
        : undefined,
    [startDateOption],
  )

  const [searchTerm, setSearchTerm] = useQueryParamState('search', '')

  const disableHidingMPInvoicesFromInvoiceList = useFeatureFlag(
    'disable-hiding-mp-invoices-from-invoice-list',
  )

  const [showMPInvoices, setShowMPInvoices] = useQueryParamState(
    'mps',
    disableHidingMPInvoicesFromInvoiceList ? true : false,
    {
      encode: (val: boolean) => `${val}`,
      decode: (str: string) =>
        str === 'true' || disableHidingMPInvoicesFromInvoiceList,
    },
  )

  const [selectedJobClasses, setSelectedJobClasses] =
    useQueryParamStateEnumArray('jobClass', [], JOBS_V2_CLASSES)

  const [selectedV2Statuses, setSelectedV2Statuses] =
    useQueryParamStateEnumArray('statusv2', [], INVOICE_V2_STATUSES)

  const [selectedUserGuids, setSelectedUserGuids] =
    useQueryParamStateArray<UserGuid>('users', [])

  const [selectedCompanyLeadSourceGuids, setSelectedCompanyLeadSourceGuids] =
    useQueryParamStateArray<CompanyLeadSourceGuid>('leadSources', [])

  const [selectedAccountManagerUserGuids, setSelectedAccountManagerUserGuids] =
    useQueryParamStateArray<UserGuid>('acctManagers', [])

  const [selectedBusinessUnitGuids, setSelectedBusinessUnitGuids] =
    useQueryParamStateArray<string>('businessUnits', [])

  const [sortType, setSortTypeRaw] = useQueryParamStateWithOptions(
    'sort',
    SORT_TYPES[0],
    SORT_TYPES,
  )

  const setSortType = useCallback(
    (type: SortType) => {
      setSortTypeRaw(type)
      resetPage()
    },
    [resetPage, setSortTypeRaw],
  )

  const [{ data: userData, fetching: fetchingUsers }] = useQuery({
    query: INVOICE_LISTING_USERS_QUERY,
  })

  const userMap = useMemo<UserMap>(
    () =>
      userData?.companyUsers.reduce(
        (map, { userGuid, userByUserGuid }) => ({
          ...map,
          [userGuid]: userByUserGuid,
        }),
        {} as UserMap,
      ) ?? {},
    [userData?.companyUsers],
  )

  const fetchCompanyLeadSourcesQuery = trpc.leadAttribution[
    'company-lead-sources:fetch'
  ].useQuery({ companyGuid })

  const [companyConfigQuery] = useQuery({
    query: COMPANY_CONFIG_QUERY,
    variables: { companyGuid },
  })

  const accountManagerEnabled =
    companyConfigQuery.data?.companyConfigByPk?.accountManagerEnabled ?? false

  const leadSourceMap = useMemo<LeadSourceMap>(
    () =>
      fetchCompanyLeadSourcesQuery.data?.reduce(
        (map, companyLeadSource) => ({
          ...map,
          [companyLeadSource.companyLeadSourceGuid]: companyLeadSource,
        }),
        {} as LeadSourceMap,
      ) ?? {},
    [fetchCompanyLeadSourcesQuery.data],
  )

  const [{ data: businessUnitsData, fetching: businessUnitsFetching }] =
    useQuery({
      query: BUSINESS_UNITS_QUERY,
      variables: { companyGuid },
    })

  const businessUnitsMap = useMemo<BusinessUnitsMap>(() => {
    if (isNullish(businessUnitsData)) {
      return {}
    }

    return businessUnitsData.businessUnits.reduce<BusinessUnitsMap>(
      (acc, curr) => {
        acc[curr.businessUnitGuid] = curr
        return acc
      },
      {},
    )
  }, [businessUnitsData])

  const filterLabels = useMemo<Record<string, string>>(() => {
    return Object.fromEntries(
      Object.entries(FILTER_LABELS).filter(([key]) => {
        if (key === 'showMPInvoices') {
          return !disableHidingMPInvoicesFromInvoiceList
        }
        return true
      }),
    ) as Record<string, string>
  }, [disableHidingMPInvoicesFromInvoiceList])

  const filters = useMemo(() => {
    const filters: { key: string; optionsSelected: string[] }[] = []
    if (showMPInvoices) {
      filters.push({
        key: 'showMPInvoices',
        optionsSelected: ['On'],
      })
    }
    if (selectedJobClasses.length) {
      filters.push({
        key: 'jobClasses',
        optionsSelected: selectedJobClasses.map(
          jobClass => jobClassDisplayNames[jobClass],
        ),
      })
    }

    if (selectedV2Statuses.length) {
      filters.push({
        key: 'status',
        optionsSelected: selectedV2Statuses,
      })
    }

    if (selectedUserGuids.length) {
      filters.push({
        key: 'users',
        optionsSelected: selectedUserGuids.map(
          guid => userMap[guid]?.fullName ?? guid,
        ),
      })
    }

    if (selectedCompanyLeadSourceGuids.length) {
      filters.push({
        key: 'leadSources',
        optionsSelected: selectedCompanyLeadSourceGuids.map(
          guid => leadSourceMap[guid]?.name ?? guid,
        ),
      })
    }

    if (selectedAccountManagerUserGuids.length) {
      filters.push({
        key: 'acctManagers',
        optionsSelected: selectedAccountManagerUserGuids.map(
          guid => userMap[guid]?.fullName ?? guid,
        ),
      })
    }

    if (selectedBusinessUnitGuids.length) {
      filters.push({
        key: 'businessUnits',
        optionsSelected: selectedBusinessUnitGuids.map(
          businessUnitGuid =>
            businessUnitsMap[businessUnitGuid]?.name ?? businessUnitGuid,
        ),
      })
    }

    return filters
  }, [
    showMPInvoices,
    selectedJobClasses,
    selectedV2Statuses,
    selectedUserGuids,
    selectedCompanyLeadSourceGuids,
    selectedAccountManagerUserGuids,
    selectedBusinessUnitGuids,
    userMap,
    leadSourceMap,
    businessUnitsMap,
  ])

  const hasFilters = useMemo(
    () =>
      computeHasFilters(
        showMPInvoices,
        selectedJobClasses,
        selectedUserGuids,
        selectedCompanyLeadSourceGuids,
        selectedAccountManagerUserGuids,
        selectedBusinessUnitGuids,
        disableHidingMPInvoicesFromInvoiceList,
      ),
    [
      showMPInvoices,
      selectedJobClasses,
      selectedUserGuids,
      selectedCompanyLeadSourceGuids,
      selectedAccountManagerUserGuids,
      selectedBusinessUnitGuids,
      disableHidingMPInvoicesFromInvoiceList,
    ],
  )

  const clearAllFilters = useCallback(() => {
    setShowMPInvoices(false)
    setSelectedJobClasses([])
    setSelectedV2Statuses([])
    setSelectedUserGuids([])
    setSelectedCompanyLeadSourceGuids([])
    setSelectedAccountManagerUserGuids([])
    setSelectedBusinessUnitGuids([])
  }, [
    setSelectedAccountManagerUserGuids,
    setSelectedBusinessUnitGuids,
    setSelectedCompanyLeadSourceGuids,
    setSelectedJobClasses,
    setSelectedUserGuids,
    setSelectedV2Statuses,
    setShowMPInvoices,
  ])

  const clearFilter = useCallback(
    (filterKey: string, option: string) => {
      switch (filterKey) {
        case 'showMPInvoices':
          setShowMPInvoices(false)
          break
        case 'jobClasses':
          setSelectedJobClasses(
            selectedJobClasses.filter(
              jc => jobClassDisplayNames[jc] !== option,
            ),
          )
          break
        case 'status':
          setSelectedV2Statuses(
            selectedV2Statuses.filter(status => status !== option),
          )
          break
        case 'users':
          setSelectedUserGuids(
            selectedUserGuids.filter(
              guid => userMap[guid]?.fullName !== option,
            ),
          )
          break
        case 'leadSources':
          setSelectedCompanyLeadSourceGuids(
            selectedCompanyLeadSourceGuids.filter(
              guid => leadSourceMap[guid]?.name !== option,
            ),
          )
          break
        case 'acctManagers':
          setSelectedAccountManagerUserGuids(
            selectedAccountManagerUserGuids.filter(
              guid => userMap[guid]?.fullName !== option,
            ),
          )
          break
        case 'businessUnits':
          setSelectedBusinessUnitGuids(
            selectedAccountManagerUserGuids.filter(
              guid => businessUnitsMap[guid]?.name !== option,
            ),
          )
          break
      }
    },
    [
      businessUnitsMap,
      leadSourceMap,
      selectedAccountManagerUserGuids,
      selectedCompanyLeadSourceGuids,
      selectedJobClasses,
      selectedUserGuids,
      selectedV2Statuses,
      setSelectedAccountManagerUserGuids,
      setSelectedBusinessUnitGuids,
      setSelectedCompanyLeadSourceGuids,
      setSelectedJobClasses,
      setSelectedUserGuids,
      setSelectedV2Statuses,
      setShowMPInvoices,
      userMap,
    ],
  )

  const renderFilter = useCallback(
    (totalItems: number) => (
      <ListPageFilterBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        secondaryFilters={
          <ListPageFilter
            options={START_DATE_OPTIONS}
            value={startDateOption}
            valueLabel={PRETTY_START_DATE_MAP[startDateOption]}
            onChange={setStartDateOption}
          />
        }
        totalItems={totalItems}
        sortByControl={
          <ListPageFilter
            label="Sort by"
            options={SORT_TYPE_OPTIONS}
            value={sortType}
            onChange={setSortType}
          />
        }
        hasFilters={hasFilters}
        filters={filters}
        filterLabels={filterLabels}
        clearAllFilters={clearAllFilters}
        clearFilter={clearFilter}
      >
        <ListPageFilter
          multi
          label="Status"
          options={INVOICE_V2_STATUS_OPTIONS}
          value={selectedV2Statuses}
          onChange={setSelectedV2Statuses}
        />

        <MoreFilters
          showMPInvoices={showMPInvoices}
          setShowMPInvoices={setShowMPInvoices}
          selectedJobClasses={selectedJobClasses}
          setSelectedJobClasses={setSelectedJobClasses}
          selectedUserGuids={selectedUserGuids}
          setSelectedUserGuids={setSelectedUserGuids}
          userData={userData}
          fetchingUsers={fetchingUsers}
          selectedCompanyLeadSourceGuids={selectedCompanyLeadSourceGuids}
          setSelectedCompanyLeadSourceGuids={setSelectedCompanyLeadSourceGuids}
          fetchingCompanyLeadSources={fetchCompanyLeadSourcesQuery.isFetching}
          leadSourceData={fetchCompanyLeadSourcesQuery.data}
          selectedAccountManagerUserGuids={selectedAccountManagerUserGuids}
          accountManagerEnabled={accountManagerEnabled}
          setSelectedAccountManagerUserGuids={
            setSelectedAccountManagerUserGuids
          }
          selectedBusinessUnitGuids={selectedBusinessUnitGuids}
          setSelectedBusinessUnitGuids={setSelectedBusinessUnitGuids}
          businessUnitsData={businessUnitsData?.businessUnits}
          fetchingBusinessUnitsData={businessUnitsFetching}
        />
      </ListPageFilterBar>
    ),
    [
      accountManagerEnabled,
      businessUnitsData?.businessUnits,
      businessUnitsFetching,
      clearAllFilters,
      clearFilter,
      fetchCompanyLeadSourcesQuery.data,
      fetchCompanyLeadSourcesQuery.isFetching,
      fetchingUsers,
      filterLabels,
      filters,
      hasFilters,
      searchTerm,
      selectedAccountManagerUserGuids,
      selectedBusinessUnitGuids,
      selectedCompanyLeadSourceGuids,
      selectedJobClasses,
      selectedUserGuids,
      selectedV2Statuses,
      setSearchTerm,
      setSelectedAccountManagerUserGuids,
      setSelectedBusinessUnitGuids,
      setSelectedCompanyLeadSourceGuids,
      setSelectedJobClasses,
      setSelectedUserGuids,
      setSelectedV2Statuses,
      setShowMPInvoices,
      setSortType,
      setStartDateOption,
      showMPInvoices,
      sortType,
      startDateOption,
      userData,
    ],
  )

  const content = useMemo(
    () => (
      <ListPageContainer
        summaryStats={<InvoiceSummaryStats />}
        filterBar={<WithTotalItems renderChildren={renderFilter} />}
        table={
          <InvoiceListTable accountManagerEnabled={accountManagerEnabled} />
        }
      />
    ),
    [accountManagerEnabled, renderFilter],
  )

  return (
    <Page requiresCompanyUser className="flex min-w-[1460px] overflow-auto">
      <InvoiceListingContext.Provider
        value={{
          pagination,
          userMap,
          hasFilters,
          searchTerm,
          sortType,
          startDate,
          endDate,
          selectedJobClasses,
          selectedV2Statuses,
          selectedUserGuids,
          selectedCompanyLeadSourceGuids,
          selectedAccountManagerUserGuids,
          selectedBusinessUnitGuids,
          showMPInvoices,
        }}
      >
        <InvoicesV2DataWrapper>{content}</InvoicesV2DataWrapper>
      </InvoiceListingContext.Provider>
    </Page>
  )
})
