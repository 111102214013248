import { PricebookItemDescriptionSchema } from '@breezy/shared'
import { Form, Input } from 'antd'
import { useCallback, useState } from 'react'
import { BehindFeatureFlag } from 'src/components/BehindFeatureFlag'
import { WysiwygEditor } from 'src/components/WysiwygEditor/WysiwygEditor'

// https://ant.design/components/form#form-demo-customized-form-controls
export interface PricebookFormItemCustomDescriptionProps {
  id?: string
  value?: string
  onChange?: (value: string) => void
}

export const PricebookFormItemCustomDescription = (
  props: PricebookFormItemCustomDescriptionProps,
) => {
  const { id, value = '', onChange } = props

  const [description, setDescription] = useState<string>(value)

  const triggerChange = useCallback(
    (changedValue: string) => {
      onChange?.(changedValue)
    },
    [onChange],
  )

  const onDescriptionChange = useCallback(
    (value: string) => {
      setDescription(value)

      triggerChange(value)
    },
    [triggerChange],
  )

  return (
    <WysiwygEditor
      id={id}
      value={description}
      onChange={onDescriptionChange}
      placeholder={'Enter description here...'}
    />
  )
}

export const PricebookFormItemDescription = () => (
  // We need to render this component like this because Antd's form expects the
  // child of `Form.Item` to be an Antd form element or a custom Antd form
  // element. Using `BehindFeatureFlag` inside of `Form.Item` prevents Antd
  // from injecting the props directly into the form element.
  <BehindFeatureFlag
    enabledFeatureFlag="pricebook-rich-text-formatting"
    render={
      <Form.Item
        name="description"
        label="Description"
        rules={[
          {
            required: false,
            validator: async (_, value) => {
              const result = PricebookItemDescriptionSchema.safeParse(value)
              if (!result.success) {
                throw new Error(
                  result.error.issues[0]?.message || result.error.message,
                )
              }
            },
          },
        ]}
      >
        <PricebookFormItemCustomDescription />
      </Form.Item>
    }
    fallback={
      <Form.Item
        name="description"
        label="Description"
        rules={[
          {
            required: false,
            validator: async (_, value) => {
              const result = PricebookItemDescriptionSchema.safeParse(value)
              if (!result.success) {
                throw new Error(
                  result.error.issues[0]?.message || result.error.message,
                )
              }
            },
          },
        ]}
      >
        <Input.TextArea rows={6} />
      </Form.Item>
    }
  />
)
