import { mapQueryVisitsToVisitViewModels } from '@breezy/backend/src/application-types'
import { MaintenancePlanCollapsibleViewModel } from '@breezy/shared'
import { useMemo } from 'react'
import { JobsCollapsibleProps } from 'src/components/collapsibles/JobsCollapsible/JobsCollapsible'
import { MaintenancePlanCollapsibleDataFragment } from '../../generated/user/graphql'
import { AccountDetailsQuery } from './AccountDetailsPage.gql'

export const convertMaintenancePlanToCollapsibleData = <
  T extends MaintenancePlanCollapsibleDataFragment = MaintenancePlanCollapsibleDataFragment,
>(
  maintenancePlans: T[],
): (T & MaintenancePlanCollapsibleViewModel)[] => {
  return maintenancePlans.reduce((list, mp) => {
    // Because the table doesn't have real fkeys the codegen doesn't know that the location is always present
    if (!mp.location) {
      return list
    }
    return [
      ...list,
      {
        ...mp,
        planTypeName: mp.maintenancePlanDefinition?.marketingInfo?.name ?? '',
        locationAddress: mp.location.address,
        visits: mapQueryVisitsToVisitViewModels(mp.maintenancePlanVisits),
      },
    ]
  }, [] as (T & MaintenancePlanCollapsibleViewModel)[])
}

export const useMaintenancePlanCollapsibleData = <
  T extends MaintenancePlanCollapsibleDataFragment = MaintenancePlanCollapsibleDataFragment,
>(
  maintenancePlans: T[],
): (T & MaintenancePlanCollapsibleViewModel)[] => {
  const maintenancePlansCollapsibleData = useMemo<
    (T & MaintenancePlanCollapsibleViewModel)[]
  >(() => {
    return convertMaintenancePlanToCollapsibleData(maintenancePlans)
  }, [maintenancePlans])
  return maintenancePlansCollapsibleData
}

export const convertAccountDetailsJobInvoiceToJobCardInvoice = (
  invoice: NonNullable<
    NonNullable<
      AccountDetailsQuery['accountsByPk']
    >['jobs'][number]['appointments']
  >[number]['jobInvoices'][number],
): NonNullable<
  NonNullable<
    NonNullable<
      JobsCollapsibleProps['jobs'][number]['appointments']
    >[number]['invoices']
  >[number]
> => {
  return {
    invoiceGuid: invoice.invoice.invoiceGuid,
    displayId: invoice.invoice.displayId.toString(),
    totalUsc: invoice.invoice.totalUsc,
    payments: invoice.invoice.invoicePayments.map(invoicePayment => ({
      paymentRecordGuid: invoicePayment.paymentRecordGuid,
      paymentMethod: invoicePayment.paymentRecord.paymentMethod,
      amountUsd: invoicePayment.paymentRecord.amountUsd,
    })),
  }
}

export function convertAccountDetailsInvoiceableQueryToJobCardInvoice(
  invoice: NonNullable<
    NonNullable<
      AccountDetailsQuery['accountsByPk']
    >['jobs'][number]['appointments']
  >[number]['invoicesQueryables'][number],
): NonNullable<
  NonNullable<
    NonNullable<
      JobsCollapsibleProps['jobs'][number]['appointments']
    >[number]['invoices']
  >[number]
> {
  return {
    invoiceGuid: invoice.invoiceGuid,
    displayId: invoice.displayId.toString(),
    totalUsc: invoice.totalAmountUsd / 100,
    payments: [],
  }
}
