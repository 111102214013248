import {
  calculateInvoiceTotals,
  DynamicPricingType,
  HtmlString,
} from '@breezy/shared'
import { StyleSheet, Text, View } from '@react-pdf/renderer'

import React from 'react'
import Html from 'react-pdf-html'
import { tailwindColors } from '../../../../../tailwind.config'
import { OptionCartItem, OptionDiscount } from '../../../estimatesFlowUtils'
import { PDFLineItemList } from './PDFLineItemList'
import { PDFTotalsSection } from './PDFTotalsSection'
import { ResizedPdfImage } from './ResizedPdfImage'

type PDFOptionContainerProps = {
  index: number
  header: string
  description: HtmlString
  lineItems: OptionCartItem[]
  discounts?: OptionDiscount[]
  recommended?: boolean
  selected?: boolean
  taxRate: number
  dynamicPricingType?: DynamicPricingType
  featuredPhotoCdnUrl?: string
  singleEstimateOption?: boolean
}

export const styles = StyleSheet.create({
  wrapper: {
    padding: '0 30px',
    flexDirection: 'column',
  },
  container: {
    border: `1px solid ${tailwindColors['bz-gray'][500]}`,
    borderRadius: 12,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  accentBar: {
    height: 8,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
  },
  header: {
    padding: 14,
    backgroundColor: tailwindColors['bz-gray'][200],
    borderBottom: `1px solid ${tailwindColors.bz['border']}`,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerTitle: {
    flexDirection: 'column',
    flex: 1,
    gap: 8,
  },
  headerDescription: {
    fontSize: 10,
    color: tailwindColors['bz-gray'][1000],
  },

  text: {
    fontSize: 12,
    color: tailwindColors.bz.text,
  },
  textSecondary: {
    fontSize: 12,
    color: tailwindColors['bz-gray'][1000],
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    color: tailwindColors.bz.text,
  },
  totalsContainer: {
    flex: 1,
  },
  footerSection: {
    flexDirection: 'row',
    borderTop: `1px solid ${tailwindColors.bz['border']}`,
  },
  footerLeft: {
    flex: 1,
  },
  footerRight: {
    flex: 1,
  },
})

const StatusDisplayNames = {
  recommended: 'Recommended',
  selected: 'Accepted',
}
const StatusChip = ({ status }: { status: 'recommended' | 'selected' }) => {
  const [chipStyles, textStyles] = React.useMemo(() => {
    if (status === 'recommended') {
      return [
        {
          border: `1px solid ${tailwindColors['bz-blue'][700]}`,
          backgroundColor: tailwindColors['bz-blue'][100],
        },
        {
          color: tailwindColors['bz-blue'][700],
        },
      ]
    }
    return [
      {
        border: `1px solid ${tailwindColors['bz-green'][700]}`,
        backgroundColor: tailwindColors['bz-green'][100],
      },
      { color: tailwindColors['bz-green'][700] },
    ]
  }, [status])
  return (
    <View
      style={[
        chipStyles,
        { paddingHorizontal: 6, paddingVertical: 2, borderRadius: 4 },
      ]}
    >
      <Text style={[{ fontSize: 10 }, textStyles]}>
        {StatusDisplayNames[status]}
      </Text>
    </View>
  )
}

export const PDFOptionContainer = ({
  index,
  header,
  description,
  lineItems,
  discounts = [],
  recommended,
  selected,
  taxRate,
  dynamicPricingType,
  featuredPhotoCdnUrl,
  singleEstimateOption = false,
}: PDFOptionContainerProps) => {
  const summary = React.useMemo(
    () =>
      calculateInvoiceTotals(
        lineItems,
        taxRate,
        discounts,
        [],
        dynamicPricingType,
      ),
    [discounts, dynamicPricingType, lineItems, taxRate],
  )

  const accentBarColor = React.useMemo(() => {
    if (selected) {
      return tailwindColors['bz-green'][600]
    }
    if (recommended) {
      return tailwindColors.bz.primary
    }
    return tailwindColors.bz['border-secondary']
  }, [recommended, selected])

  return (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <View
          style={[
            styles.accentBar,
            {
              backgroundColor: accentBarColor,
            },
          ]}
        />

        {/* Header Section */}
        <View style={styles.header}>
          <View
            style={{
              flexDirection: 'row',
              marginRight: 12,
              minWidth: 44,
              minHeight: 44,
              alignItems: 'flex-start',
              height: '100%',
            }}
          >
            <ResizedPdfImage
              cdnUrl={featuredPhotoCdnUrl}
              width={44}
              height={44}
              imageMargin={5}
            />
          </View>
          <View style={styles.headerTitle}>
            <View style={{ flexDirection: 'row', gap: 8 }}>
              <Text style={styles.heading}>
                {singleEstimateOption
                  ? header ?? 'Estimate'
                  : `#${index + 1}: ${header}`}
              </Text>
              {recommended && <StatusChip status="recommended" />}
              {selected && <StatusChip status="selected" />}
            </View>
            {description && (
              <Html style={styles.headerDescription}>
                {description as string}
              </Html>
            )}
          </View>
        </View>

        {/* Content Section - Line Items */}
        {lineItems.length === 0 ? (
          <Text style={styles.textSecondary}>No line items</Text>
        ) : (
          <PDFLineItemList lineItems={lineItems} discounts={discounts} />
        )}

        {/* Footer Section */}
        <View style={styles.footerSection}>
          <View style={styles.footerLeft}>
            {/* Reserved for future content */}
          </View>
          <View style={styles.footerRight}>
            <PDFTotalsSection
              subtotalUsc={summary.subtotalUsc}
              taxUsc={summary.taxAmountUsc}
              totalUsc={summary.totalUsc}
              discountAmountUsc={summary.discountAmountUsc}
              discounts={discounts}
              dynamicPricingType={dynamicPricingType}
            />
          </View>
        </View>
      </View>
    </View>
  )
}
