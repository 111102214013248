import { z } from 'zod'
import { AsyncFn, convertCsvRowsToDataWithHeader, convertDataToCsvRows, createCsvFile, isNullish } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { QboIncomeAccount } from '../Accounting/QboSync/QboSyncTypes'
import { bzOptional } from '../common-schemas'
import { ForCompany } from '../Company/Company'
import {
  BulkPricebookCategory,
  BulkPricebookItemRow,
  convertToBulkPricebookCategories,
  convertToBulkPricebookItemRows,
  getLeafCategoryName,
} from './BulkPricebook'
import { PricebookCategory, PricebookItem } from './PricebookTypes'
import { PricebookVersionGuidContainer } from './PricebookVersion'

// The order matters here for the order of the columns in the CSV
// and to be able to map the columns to the keys in the schema when
// parsing the CSV -> JSON
export const BulkPricebookItemRowCsvColumns: (keyof BulkPricebookItemRow)[] = [
  'name',
  'category',
  'description',
  'itemType',
  'costUsd',
  'priceUsd',
  'isTaxable',
  'isActive',
  'isDiscountable',
  'pricebookItemGuid',
  'pricebookCategoryGuid',
  'sourcePhotoUrl',
  'pricebookItemCode',
]

// The order matters here for the order of the columns in the CSV
// and to be able to map the columns to the keys in the schema when
// parsing the CSV -> JSON
export const BulkPricebookItemRowCsvColumnsQboEnabled: (keyof BulkPricebookItemRow)[] = [
  'name',
  'category',
  'description',
  'itemType',
  'costUsd',
  'priceUsd',
  'isTaxable',
  'isActive',
  'isDiscountable',
  'qboIncomeAccountName',
  'qboIncomeAccountId',
  'pricebookItemGuid',
  'pricebookCategoryGuid',
  'sourcePhotoUrl',
  'pricebookItemCode',
]

export const convertPricebookCategoryCsvRowsToBulkPricebookCategories = (
  rows: PricebookCategoryCsvRow[],
): BulkPricebookCategory[] => {
  return rows.map(row => ({
    concatenatedName: row.name,
    concatenatedParentCategoryName: row.parentCategory,
    name: getLeafCategoryName(row.name),
    parentCategoryName: getLeafCategoryName(row.parentCategory),
    parentCategoryGuid: row.parentCategoryGuid,
    pricebookCategoryGuid: row.pricebookCategoryGuid,
    sourcePhotoUrl: row.sourcePhotoUrl,
  }))
}

export const convertBulkPricebookCategoriesToPricebookCategoryCsvRows = (
  categories: BulkPricebookCategory[],
): PricebookCategoryCsvRow[] => {
  return categories.map(c => ({
    name: c.concatenatedName,
    parentCategory: isNullish(c.concatenatedParentCategoryName) ? '' : c.concatenatedParentCategoryName,
    pricebookCategoryGuid: c.pricebookCategoryGuid,
    parentCategoryGuid: isNullish(c.parentCategoryGuid) || !c.parentCategoryGuid ? '' : c.parentCategoryGuid,
    sourcePhotoUrl: isNullish(c.sourcePhotoUrl) || !c.sourcePhotoUrl ? '' : c.sourcePhotoUrl,
  }))
}

export const PricebookCategoryCsvRowSchema = z.object({
  name: z.string(),
  parentCategory: bzOptional(z.string()),
  pricebookCategoryGuid: guidSchema,
  parentCategoryGuid: bzOptional(guidSchema),
  sourcePhotoUrl: bzOptional(z.string()),
})

export type PricebookCategoryCsvRow = z.infer<typeof PricebookCategoryCsvRowSchema>

// The order matters here for the order of the columns in the CSV
// and to be able to map the columns to the keys in the schema when
// parsing the CSV -> JSON
export const PricebookCategoryCsvColumns: (keyof PricebookCategoryCsvRow)[] = [
  'name',
  'parentCategory',
  'pricebookCategoryGuid',
  'parentCategoryGuid',
  'sourcePhotoUrl',
]

// The order matters here for the order of the columns in the CSV
// and to be able to map the columns to the keys in the schema when
// parsing the CSV -> JSON
export const convertPricebookCategoriesToCsvRows = (categories: PricebookCategory[]): PricebookCategoryCsvRow[] => {
  return convertToBulkPricebookCategories(categories).map(c => ({
    name: c.concatenatedName,
    parentCategory: isNullish(c.concatenatedParentCategoryName) ? undefined : c.concatenatedParentCategoryName,
    pricebookCategoryGuid: c.pricebookCategoryGuid,
    parentCategoryGuid: isNullish(c.parentCategoryGuid) || !c.parentCategoryGuid ? undefined : c.parentCategoryGuid,
    sourcePhotoUrl: isNullish(c.sourcePhotoUrl) || !c.sourcePhotoUrl ? undefined : c.sourcePhotoUrl,
  }))
}

// The following code checks if QuickBooks Online (QBO) integration is enabled.
// If QBO is not enabled, it removes the 'qboIncomeAccountId' and 'qboIncomeAccountName' properties from the item,
// as these properties are only relevant when QBO integration is active.
// This ensures that the resulting CSV file only contains relevant columns for the current configuration.
export const convertPricebookItemsToCsvFile = (
  items: PricebookItem[],
  categories: PricebookCategory[],
  isQuickbooksOnlineEnabled: boolean,
  qboIncomeAccounts: QboIncomeAccount[],
): string => {
  const bulkPricebookItems = convertToBulkPricebookItemRows(items, categories, qboIncomeAccounts).map(i => {
    if (!isQuickbooksOnlineEnabled) {
      const { qboIncomeAccountId, qboIncomeAccountName, ...rest } = i
      return rest
    }

    return i
  })

  return createCsvFile(bulkPricebookItems[0], convertDataToCsvRows(bulkPricebookItems))
}

export const convertPricebookCategoriesToCsvFile = (categories: PricebookCategory[]): string => {
  const pricebookCategoryCsvRows = convertPricebookCategoriesToCsvRows(categories)
  return createCsvFile(pricebookCategoryCsvRows[0], convertDataToCsvRows(pricebookCategoryCsvRows))
}

export const parseBulkPricebookItemRowCsv = (
  csv: string,
  isQuickbooksOnlineEnabled: boolean,
): BulkPricebookItemRow[] => {
  const columns = isQuickbooksOnlineEnabled ? BulkPricebookItemRowCsvColumnsQboEnabled : BulkPricebookItemRowCsvColumns
  return convertCsvRowsToDataWithHeader<BulkPricebookItemRow>(csv, columns).map(i => {
    const baseItem = {
      ...i,
      category: isNullish(i.category) ? '' : i.category,
      description: isNullish(i.description) ? '' : i.description,
      isTaxable: isNullish(i.isTaxable) ? false : i.isTaxable,
      isActive: isNullish(i.isActive) ? true : i.isActive,
      isDiscountable: isNullish(i.isDiscountable) ? true : i.isDiscountable,
      pricebookCategoryGuid: isNullish(i.pricebookCategoryGuid) ? undefined : i.pricebookCategoryGuid,
      sourcePhotoUrl: isNullish(i.sourcePhotoUrl) ? '' : i.sourcePhotoUrl,
      pricebookItemCode: isNullish(i.pricebookItemCode) ? '' : `${i.pricebookItemCode}`,
    }

    if (!isQuickbooksOnlineEnabled) {
      const { qboIncomeAccountName, qboIncomeAccountId, ...rest } = baseItem
      return rest
    }

    return {
      ...baseItem,
      qboIncomeAccountName: isNullish(i.qboIncomeAccountName) ? '' : i.qboIncomeAccountName,
      qboIncomeAccountId: isNullish(i.qboIncomeAccountId) ? '' : `${i.qboIncomeAccountId}`,
    }
  })
}

export const parsePricebookCategoriesCsv = (csv: string): PricebookCategoryCsvRow[] => {
  return convertCsvRowsToDataWithHeader<PricebookCategoryCsvRow>(csv, PricebookCategoryCsvColumns).map(c => ({
    ...c,
    // Since this is used in a restore action, if the parentCategoryGuid is null, it should be undefined and not an empty string
    // to pass the gql uuid validation
    parentCategoryGuid: isNullish(c.parentCategoryGuid) || !c.parentCategoryGuid ? undefined : c.parentCategoryGuid,
    parentCategory: isNullish(c.parentCategory) ? undefined : c.parentCategory,
    sourcePhotoUrl: isNullish(c.sourcePhotoUrl) ? '' : c.sourcePhotoUrl,
  }))
}

export type PricebookVersionCsvReader = AsyncFn<
  ForCompany<PricebookVersionGuidContainer>,
  {
    items: BulkPricebookItemRow[]
    categories?: PricebookCategoryCsvRow[]
  }
>
