import { R } from '../../../common'
import { IInvoiceViewModelReader, InvoiceViewModel } from '../Invoicing/InvoiceTypes'
import {
  LinkedPaymentsViewModelReader,
  PaymentStatus,
  PaymentViewModel,
  paymentMethodDisplayName,
  paymentPendingStatuses,
} from '../Payments/PaymentTypes'
import { IInvoicePaymentsSummaryReader, InvoicePaymentsSummary } from './InvoicePaymentTypes'

const paymentPaidStatuses = new Set([PaymentStatus.PAID])

export class InvoicePaymentsSummaryReader {
  constructor(
    private readonly invoiceViewModels: IInvoiceViewModelReader,
    private readonly linkedPaymentRecordsReader: LinkedPaymentsViewModelReader,
  ) {}

  read: IInvoicePaymentsSummaryReader = async req => {
    const invoiceViewModel = await this.invoiceViewModels(req)
    const payments = await this.linkedPaymentRecordsReader(req)
    return this.getInvoicePaymentSummary(invoiceViewModel, payments)
  }

  private getInvoicePaymentSummary = (
    invoiceViewModel: InvoiceViewModel,
    payments: PaymentViewModel[],
  ): InvoicePaymentsSummary => {
    const totalInvoiceAmountUsd = invoiceViewModel.totalPriceUsd
    const totalPaidAmountUsc = payments
      .filter(p => paymentPaidStatuses.has(p.status))
      .reduce(
        (acc, payment) =>
          acc +
          payment.invoicePayments
            .filter(ip => ip.invoiceGuid === invoiceViewModel.invoiceGuid)
            .reduce((acc, ip) => acc + ip.appliedAmountUsc, 0),
        0,
      )

    const totalPendingAmountUsc = payments
      .filter(p => paymentPendingStatuses.has(p.status))
      .reduce(
        (acc, payment) =>
          acc +
          payment.invoicePayments
            .filter(ip => ip.invoiceGuid === invoiceViewModel.invoiceGuid)
            .reduce((acc, ip) => acc + ip.appliedAmountUsc, 0),
        0,
      )

    const amountDueUsd = totalInvoiceAmountUsd - (totalPaidAmountUsc - totalPendingAmountUsc) * 100

    return {
      invoiceIds: { guid: invoiceViewModel.invoiceGuid, referenceNumber: invoiceViewModel.referenceNumber },
      totalInvoiceAmountUsd,
      totalPaidAmountUsc,
      totalPendingAmountUsc,
      amountDueUsd,
      displayId: invoiceViewModel.displayId.toString(),
      lastPaidAt:
        payments.length > 0
          ? R.reverse(
              R.sortBy(
                p => p,
                payments.map(p => p.occurredAt),
              ),
            )[0]
          : undefined,
      paymentTypesDescription:
        payments.length > 0
          ? [...new Set(payments.map(p => paymentMethodDisplayName(p.paymentMethod)))].join(', ')
          : undefined,
    }
  }
}
