import {
  BulkPricebookCategory,
  BulkPricebookItemRow,
  PricebookCategory,
  PricebookItemTypeEnum,
  QboIncomeAccount,
  bzExpect,
  convertToBulkPricebookCategories,
  convertToPricebookQboIncomeAccounts,
  getLeafCategoryName,
  isNullish,
  parseNumberFromString,
} from '@breezy/shared'
import { Flatfile } from '@flatfile/api'
import {
  FlatfilePricebookCategoryRecord,
  FlatfilePricebookItemRecord,
  FlatfilePricebookItemRecordWithQboIncomeAccount,
  FlatfileQboIncomeAccountRecord,
  isFlatfilePricebookItemRecordWithQboIncomeAccount,
} from './FlatfileTypes'

export const toFlatfilePricebookCategoryRecord = (
  flatfileRecord: Flatfile.RecordWithLinks | undefined,
): FlatfilePricebookCategoryRecord | undefined => {
  if (!flatfileRecord) return undefined

  return {
    name: bzExpect(flatfileRecord.values.name),
    parentCategoryName: bzExpect(flatfileRecord.values.parentCategoryName),
    pricebookCategoryGuid: bzExpect(
      flatfileRecord.values.pricebookCategoryGuid,
    ),
    parentCategoryGuid: bzExpect(flatfileRecord.values.parentCategoryGuid),
    sourcePhotoUrl: bzExpect(flatfileRecord.values.sourcePhotoUrl),
  }
}

export const fromRecordToBulkPricebookCategory = (
  r: FlatfilePricebookCategoryRecord | undefined,
): BulkPricebookCategory | undefined => {
  if (!r) return undefined
  return {
    // The flatfile record name is set to the concatenated name so we need to grab the leaf category name
    name: getLeafCategoryName(bzExpect(r.name.value) as string),
    concatenatedName: bzExpect(r.name.value) as string,
    pricebookCategoryGuid: r.pricebookCategoryGuid.value as string,
    // The flatfile record parentCategoryName is set to the concatenated name so we need to grab the leaf category name
    parentCategoryName: getLeafCategoryName(
      r.parentCategoryName.value as string | undefined,
    ),
    concatenatedParentCategoryName: r.parentCategoryName.value as string,
    parentCategoryGuid: r.parentCategoryGuid.value as string | undefined,
    sourcePhotoUrl: r.sourcePhotoUrl.value as string | undefined,
  }
}

export const toFlatfilePricebookItemRecord = (
  flatfileRecord: Flatfile.RecordWithLinks | undefined,
):
  | FlatfilePricebookItemRecord
  | FlatfilePricebookItemRecordWithQboIncomeAccount
  | undefined => {
  if (!flatfileRecord) return undefined
  const baseRecord: FlatfilePricebookItemRecord = {
    name: bzExpect(flatfileRecord.values.name),
    pricebookItemGuid: bzExpect(flatfileRecord.values.pricebookItemGuid),
    category: bzExpect(flatfileRecord.values.category),
    description: bzExpect(flatfileRecord.values.description),
    itemType: bzExpect(flatfileRecord.values.itemType),
    costUsd: bzExpect(flatfileRecord.values.costUsd),
    priceUsd: bzExpect(flatfileRecord.values.priceUsd),
    isTaxable: bzExpect(flatfileRecord.values.isTaxable),
    isActive: bzExpect(flatfileRecord.values.isActive),
    isDiscountable: bzExpect(flatfileRecord.values.isDiscountable),
    pricebookCategoryGuid: bzExpect(
      flatfileRecord.values.pricebookCategoryGuid,
    ),
    sourcePhotoUrl: bzExpect(flatfileRecord.values.sourcePhotoUrl),
    pricebookItemCode: bzExpect(flatfileRecord.values.pricebookItemCode),
  }
  return flatfileRecord.values.qboIncomeAccountId
    ? {
        ...baseRecord,
        qboIncomeAccountName: bzExpect(
          flatfileRecord.values.qboIncomeAccountName,
        ),
        qboIncomeAccountId: bzExpect(flatfileRecord.values.qboIncomeAccountId),
      }
    : baseRecord
}

// The mappings from this function need to map to the same assertions as toBulkPricebookRow in @breezy/shared
export const fromRecordToBulkPricebookItemRow = (
  r:
    | FlatfilePricebookItemRecord
    | FlatfilePricebookItemRecordWithQboIncomeAccount
    | undefined,
): BulkPricebookItemRow | undefined => {
  if (!r) return undefined
  return {
    name: bzExpect(r.name.value) as string,
    category: isNullish(r.category.value) ? '' : (r.category.value as string),
    description: r.description.value as string | undefined,
    itemType: r.itemType.value as PricebookItemTypeEnum,
    costUsd: parseNumberFromString(String(bzExpect(r.costUsd.value))),
    priceUsd: parseNumberFromString(String(bzExpect(r.priceUsd.value))),
    isTaxable: Boolean(r.isTaxable.value as boolean),
    isActive: Boolean(r.isActive.value as boolean),
    isDiscountable: Boolean(r.isDiscountable.value as boolean),
    qboIncomeAccountName: isFlatfilePricebookItemRecordWithQboIncomeAccount(r)
      ? isNullish(r.qboIncomeAccountName.value)
        ? ''
        : (r.qboIncomeAccountName.value as string)
      : '',
    qboIncomeAccountId: isFlatfilePricebookItemRecordWithQboIncomeAccount(r)
      ? (r?.qboIncomeAccountId?.value as string | undefined)
      : undefined,
    pricebookItemGuid: bzExpect(r.pricebookItemGuid.value) as string,
    pricebookCategoryGuid: r.pricebookCategoryGuid.value as string | undefined,
    sourcePhotoUrl: isNullish(r.sourcePhotoUrl.value)
      ? ''
      : (r.sourcePhotoUrl.value as string),
    pricebookItemCode: isNullish(r.pricebookItemCode.value)
      ? ''
      : (r.pricebookItemCode.value as string),
  }
}

export const fromBulkCategoryToFlatfileRecord = (
  category: BulkPricebookCategory,
): FlatfilePricebookCategoryRecord => {
  return {
    name: {
      valid: true,
      value: category.concatenatedName,
    },
    pricebookCategoryGuid: {
      valid: true,
      value: category.pricebookCategoryGuid,
    },
    parentCategoryName: {
      valid: true,
      value: category.concatenatedParentCategoryName ?? '',
    },
    parentCategoryGuid: {
      valid: true,
      value: category.parentCategoryGuid ?? '',
    },
    sourcePhotoUrl: {
      valid: true,
      value: category.sourcePhotoUrl ?? '',
    },
  }
}

export const convertToFlatfilePricebookCategoryRecords = (
  categories: PricebookCategory[] | undefined,
): FlatfilePricebookCategoryRecord[] => {
  if (!categories) return []
  return convertToBulkPricebookCategories(categories).map(
    fromBulkCategoryToFlatfileRecord,
  )
}

export const convertToFlatfileQboIncomeAccountRecords = (
  qboIncomeAccounts: QboIncomeAccount[] | undefined,
): FlatfileQboIncomeAccountRecord[] => {
  if (!qboIncomeAccounts) return []
  return convertToPricebookQboIncomeAccounts(qboIncomeAccounts).map(a => ({
    qboIncomeAccountId: {
      valid: true,
      value: a.id,
    },
    name: {
      valid: true,
      value: a.displayName,
    },
  }))
}
