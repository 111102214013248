import { AccountingCartItemTypeAccountsConfig, isNullish } from '@breezy/shared'
import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { useCallback } from 'react'
import { useMutation, useQuery } from 'urql'
import { BzTitleAndExplanation } from '../../elements/BzTitleAndExplanation/BzTitleAndExplanation'
import RenderIf from '../../elements/RenderIf/RenderIf'
import { useAccountingAccountTreeData } from '../../hooks/fetch/useFetchAccountingAccountTreeData'
import { useAccountingIntegrationType } from '../../providers/CompanyFinancialConfigWrapper'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { m } from '../../utils/react-utils'
import { FormCancelSubmitButtons } from '../form-fields/FormCancelSubmitButtons/FormCancelSubmitButtons'
import { LoadingSpinner } from '../LoadingSpinner'
import {
  FETCH_ACCOUNTING_CART_ITEM_TYPE_ACCOUNTS_QUERY,
  UPSERT_ACCOUNTING_CART_ITEM_TYPE_ACCOUNTS_CONFIG_MUTATION,
} from './AccountingIntegration.gql'
import { QuickbooksAccountSelectionFormItem } from './QuickbooksOnline/QuickbooksAccountSelectionFormItem'

const UPSERT_SAVE_ERROR_MESSAGE =
  'There was an unexpected error saving the Cart Item Accounts Config'

export const AccountingCartItemTypeAccountsConfigPanel = m(
  ({ type }: { type: 'qbo' | 'qbd' }) => {
    const companyGuid = useExpectedCompanyGuid()
    const message = useMessage()
    const accountingAccounts = useAccountingAccountTreeData()
    const accountingIntegrationType = useAccountingIntegrationType()
    const [{ data, fetching }, refetch] = useQuery({
      query: FETCH_ACCOUNTING_CART_ITEM_TYPE_ACCOUNTS_QUERY,
      variables: { companyGuid },
    })
    const [{ fetching: upserting }, upsert] = useMutation(
      UPSERT_ACCOUNTING_CART_ITEM_TYPE_ACCOUNTS_CONFIG_MUTATION,
    )

    const isAsync = fetching || upserting

    const [form] = useForm<AccountingCartItemTypeAccountsConfig>()
    const onFinish = useCallback(
      async (values: AccountingCartItemTypeAccountsConfig) => {
        try {
          await upsert({
            object: {
              ...values,
              companyGuid,
              updatedAt: new Date().toISOString(),
              accountingIntegrationType,
            },
          })
        } catch (e) {
          console.error(UPSERT_SAVE_ERROR_MESSAGE, e)
          message.error(UPSERT_SAVE_ERROR_MESSAGE)
        } finally {
          refetch()
        }
      },
      [companyGuid, message, refetch, upsert, accountingIntegrationType],
    )

    if (isAsync || isNullish(accountingAccounts))
      return (
        <div className="flex min-h-[320px] min-w-[240px] items-center">
          <LoadingSpinner />
        </div>
      )

    if (!accountingAccounts || accountingAccounts.length < 1)
      return (
        <p>Ledger Accounts not found - Cannot Configure Cart Item Accounts</p>
      )

    return (
      <Form
        layout="vertical"
        form={form}
        initialValues={data?.accCartItemTypeAccountsConfig[0] ?? undefined}
        onFinish={onFinish}
      >
        <BzTitleAndExplanation
          title="Default Pricebook Income Accounts"
          description="Configuring these accounts will provide default Income Accounts for your Pricebook Item Types. These will be used for Pricebook items that don't have an account configured, or for ad-hoc items added to an invoice."
          attentionType="info"
          titleClassName="font-semibold text-lg"
          className="mb-2"
        />
        <QuickbooksAccountSelectionFormItem
          required
          formItemName="defaultItemIncomeAccountId"
          formItemLabel="Default/Fallback Income Account"
          financeAccountsTreeData={accountingAccounts.filter(
            x => x.accountType === 'Income',
          )}
          explanationTooltip="Select the Income Account to be used as a default for all pricebook item types. This account will be used if a more specific account is not defined for a particular item type."
        />
        <QuickbooksAccountSelectionFormItem
          formItemName="equipmentItemDefaultIncomeAccountId"
          formItemLabel="Equipment Item Income Account"
          financeAccountsTreeData={accountingAccounts.filter(
            x => x.accountType === 'Income',
          )}
          explanationTooltip="Select the Income Account specifically for income generated from equipment sales. Overrides the default income account for equipment item types."
        />
        <QuickbooksAccountSelectionFormItem
          formItemName="serviceItemDefaultIncomeAccountId"
          formItemLabel="Service Item Income Account"
          financeAccountsTreeData={accountingAccounts.filter(
            x => x.accountType === 'Income',
          )}
          explanationTooltip="Select the Income Account for earnings from service offerings. Overrides the default Income Account for service-related item types."
        />
        <QuickbooksAccountSelectionFormItem
          formItemName="materialItemDefaultIncomeAccountId"
          formItemLabel="Material Item Income Account"
          financeAccountsTreeData={accountingAccounts.filter(
            x => x.accountType === 'Income',
          )}
          explanationTooltip="Select the Income Account for income from material sales. Overrides the default Income Account for material item types."
        />
        <QuickbooksAccountSelectionFormItem
          formItemName="laborItemDefaultIncomeAccountId"
          formItemLabel="Labor Item Income Account"
          financeAccountsTreeData={accountingAccounts.filter(
            x => x.accountType === 'Income',
          )}
          explanationTooltip="Select the Income Account for labor income. Overrides the default Income Account when labor is the item type."
        />
        <QuickbooksAccountSelectionFormItem
          formItemName="membershipItemDefaultIncomeAccountId"
          formItemLabel="Maintenance Plan Item Income Account"
          financeAccountsTreeData={accountingAccounts.filter(
            x => x.accountType === 'Income',
          )}
          explanationTooltip="Select the Income Account for revenue from maintenance or service plans. Overrides the default Income Account for maintenance plan item types."
        />
        <RenderIf if={type === 'qbd'}>
          <>
            <QuickbooksAccountSelectionFormItem
              formItemName="inventoryAssetAccountId"
              formItemLabel="Inventory Asset Account"
              financeAccountsTreeData={accountingAccounts.filter(
                x => x.accountType !== 'Expense',
              )}
              explanationTooltip="This account is only relevant if you track inventory in your Accounting System. It represents the asset account where inventory value is stored before items are sold."
            />
            <QuickbooksAccountSelectionFormItem
              formItemName="costOfGoodsSoldAccountId"
              formItemLabel="Cost of Goods Sold Account"
              financeAccountsTreeData={accountingAccounts.filter(
                x => x.accountType === 'CostOfGoodsSold',
              )}
              explanationTooltip="This account is only relevant if you track inventory in your Accounting System. It represents the expense account where the cost of inventory items is recorded when they are sold."
            />
          </>
        </RenderIf>
        <FormCancelSubmitButtons />
      </Form>
    )
  },
)
