import { CompanyGuid, InvoiceGuid } from '@breezy/shared'
import { message } from 'antd'
import { useCallback, useState } from 'react'
import { getConfig } from '../config'
import { useDownloadBlob, useOpenBlobInNewTab } from '../hooks/blob-hooks'
import { useAuth } from '../hooks/useAuth'
import { useMessage } from './antd-utils'

const config = getConfig()

export const useFetchPdfBlobUnauth = (companyGuid: CompanyGuid) => {
  const fetchPdfBlob = useCallback(
    async (invoiceGuid: string): Promise<Blob> => {
      const res = await fetch(
        `${config.apiUrl}/unauth/invoice-pdf/${companyGuid}/${invoiceGuid}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            companyGuid,
          }),
        },
      )

      const arrayBuffer = await res.arrayBuffer()
      return new Blob([arrayBuffer], { type: 'application/pdf' })
    },
    [companyGuid],
  )
  return fetchPdfBlob
}

export const useFetchPdfBlob = (companyGuid: CompanyGuid) => {
  const auth = useAuth()

  const fetchPdfBlob = useCallback(
    async (invoiceGuid: string): Promise<Blob> => {
      const headers = await auth.getHeaders()

      const res = await fetch(`${config.apiUrl}/invoice-pdf/${invoiceGuid}`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          companyGuid,
        }),
      })

      const arrayBuffer = await res.arrayBuffer()
      return new Blob([arrayBuffer], { type: 'application/pdf' })
    },
    [auth, companyGuid],
  )
  return fetchPdfBlob
}

export const useDownloadInvoice = (
  companyGuid: CompanyGuid,
  invoiceGuid: InvoiceGuid,
  invoiceDisplayId: string,
  fetchBlob?: (invoiceGuid: InvoiceGuid) => Promise<Blob>,
) => {
  const message = useMessage()
  const [invoiceDownloading, setInvoiceDownloading] = useState(false)

  const downloadBlob = useDownloadBlob()
  const baseFetchPdfBlob = useFetchPdfBlob(companyGuid)
  const fetchPdfBlob = fetchBlob ?? baseFetchPdfBlob
  const downloadInvoicePDF = useCallback(
    async () =>
      await downloadBlob({
        getBlob: async () => await fetchPdfBlob(invoiceGuid),
        downloadFilename: `invoice-${invoiceDisplayId}.pdf`,
        operationText: 'Download Invoice PDF',
        onStarted: () => {
          setInvoiceDownloading(true)
          message.info('Downloading Invoice PDF...')
        },
        onFinished: () => {
          setInvoiceDownloading(false)
        },
      }),
    [downloadBlob, invoiceDisplayId, fetchPdfBlob, invoiceGuid, message],
  )
  return { downloadInvoicePDF, invoiceDownloading }
}

export const useOpenInvoiceInNewTab = (
  invoiceGuid: InvoiceGuid,
  fetchPdfBlob: (invoiceGuid: InvoiceGuid) => Promise<Blob>,
) => {
  const openBlobInNewTab = useOpenBlobInNewTab()
  const [invoiceGenerating, setInvoiceGenerating] = useState(false)
  const beginOpenInvoice = useCallback(
    async () =>
      await openBlobInNewTab({
        getBlob: async () => await fetchPdfBlob(invoiceGuid),
        operationText: 'opening Invoice PDF',
        onStarted: () => {
          message.info('Generating Invoice PDF...')
          setInvoiceGenerating(true)
        },
        onFinished: () => {
          setInvoiceGenerating(false)
        },
      }),
    [openBlobInNewTab, fetchPdfBlob, invoiceGuid],
  )
  return { beginOpenInvoice, invoiceGenerating }
}
