import { useState } from 'react'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { useWisetackEnabled } from '../../providers/CompanyFinancialConfigWrapper'
import { AccountWrapper, Editable } from './accountDetailsV2Utils'
import { AccountEstimatesCollapsible } from './collapsibles/AccountEstimatesCollapsible'
import { AccountInvoicesCollapsible } from './collapsibles/AccountInvoicesCollapsible'
import { AccountJobsCollapsible } from './collapsibles/AccountJobsCollapsible'
import { AccountMaintenancePlansCollapsible } from './collapsibles/AccountMaintenancePlansCollapsible'
import { AccountPaymentsCollapsible } from './collapsibles/AccountPaymentsCollapsible'
import { AccountVisitsCollapsible } from './collapsibles/AccountVisitsCollapsible'
import { AccountWisetackFinancingCollapsible } from './collapsibles/AccountWisetackFinancingCollapsible'

export const AccountDetailsRightSidePane = ({
  meta,
  editable = true,
}: Editable<AccountWrapper>) => {
  const { accountGuid } = meta
  const [paymentsRefetch, setPaymentsRefetch] = useState(0)

  const wisetackEnabled = useWisetackEnabled()

  return (
    <SectionedCard
      sections={[
        {
          content: (
            <>
              <AccountMaintenancePlansCollapsible
                accountGuid={accountGuid}
                className="pt-0"
              />
              <AccountJobsCollapsible
                key={`jobs-collapsible-${paymentsRefetch}`}
                accountGuid={accountGuid}
                editable={editable}
              />
              <AccountVisitsCollapsible
                key={`visits-collapsible-${paymentsRefetch}`}
                accountGuid={accountGuid}
                editable={editable}
              />
              {wisetackEnabled && (
                <AccountWisetackFinancingCollapsible
                  accountGuid={accountGuid}
                />
              )}
              <AccountPaymentsCollapsible
                key={`payments-collapsible-${paymentsRefetch}`}
                accountGuid={accountGuid}
                onPaymentAdded={() => setPaymentsRefetch(prev => prev + 1)}
              />
              <AccountInvoicesCollapsible
                key={`invoices-collapsible-${paymentsRefetch}`}
                accountGuid={accountGuid}
              />
              <AccountEstimatesCollapsible accountGuid={accountGuid} />
            </>
          ),
        },
      ]}
    />
  )
}
