import { createTsForm } from '@ts-react/form'
import {
  FormComponent,
  FormComponentMapping,
} from '@ts-react/form/lib/src/createSchemaForm'
import { z } from 'zod'
import RichTextArea from '../RichTextAreaField/RichTextAreaField'
import { RichTextAreaSchema } from '../RichTextAreaField/RichTextAreaField.schema'
import TextField from '../TextField/TextField'
import { SendAsEmailFormSchema } from './SendEmailForm.schema'

export type SendAsEmailFormData = z.infer<typeof SendAsEmailFormSchema>

type SendEmailFormProps = {
  disabledFields?: string[]
}

const createFieldMapping = (disabledFields: string[] = []) =>
  [
    [
      z.string(),
      props => (
        <TextField {...props} disabled={disabledFields.includes(props.name)} />
      ),
    ],
    [
      RichTextAreaSchema,
      props => (
        <RichTextArea
          {...props}
          disabled={disabledFields.includes(props.name)}
        />
      ),
    ],
  ] as FormComponentMapping

export const createSendEmailForm = (
  form: FormComponent,
  { disabledFields }: SendEmailFormProps = {},
) =>
  createTsForm(createFieldMapping(disabledFields), {
    FormComponent: form,
  })
