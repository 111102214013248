import { Form } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'urql'
import {
  OnsiteModal,
  OnsiteModalContent,
  OnsiteModalFooter,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../../adam-components/OnsiteModal/useCloseConfirmModal'
import { PRIMARY_CONTACT_INFO_FOR_ACCOUNT_QUERY } from '../../../components/Invoicing/SelfServePayments/CollectPaymentPanel/SendInvoiceDrawer.gql'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { createSendEmailForm } from '../../../components/PreviewMessage/SendEmailForm/SendEmailForm'
import {
  SendAsEmailFormData,
  SendAsEmailFormSchema,
} from '../../../components/PreviewMessage/SendEmailForm/SendEmailForm.schema'
import { trpc } from '../../../hooks/trpc'
import { useMessage } from '../../../utils/antd-utils'
import { useStrictContext } from '../../../utils/react-utils'
import { InvoiceContext } from '../invoiceUtils'

type CustomFormProps = React.PropsWithChildren<{
  onSubmit: () => void
  onCancel: () => void
  onBack?: () => void
  isLoading: boolean
}>

// Note: `createTsForm` won't accept something wrapped in `React.memo`
const CustomForm = ({
  children,
  onSubmit,
  onCancel,
  onBack,
  isLoading,
}: CustomFormProps) => {
  return (
    <OnsiteModalContent
      unpadBottom
      header="Preview Message"
      onClose={onCancel}
      onBack={onBack}
      footer={
        <OnsiteModalFooter
          onCancel={onCancel}
          onSubmit={onSubmit}
          submitText="Send"
          loading={isLoading}
        />
      }
    >
      <div>
        <Form
          disabled={isLoading}
          onSubmitCapture={onSubmit}
          layout="vertical"
          className="flex min-h-0 flex-1 flex-col"
          requiredMark={label => (
            <div>
              {label}
              <span className="ml-0.5 text-bz-primary">*</span>
            </div>
          )}
        >
          {children}
        </Form>
      </div>
    </OnsiteModalContent>
  )
}

const SendEmailForm = createSendEmailForm(CustomForm, {
  disabledFields: ['subject', 'body'],
})

type SendInvoiceModalProps = {
  onClose: () => void
  open?: boolean
}

const SendInvoiceForm = React.memo<{
  onClose: () => void
  open?: boolean
  contact?: { primaryEmailAddress?: { emailAddress?: string } }
}>(({ onClose, open = true, contact }) => {
  const message = useMessage()
  const emailInvoiceMut = trpc.invoice['invoicing:email-invoice'].useMutation()
  const { companyName, invoiceGuid, displayId } =
    useStrictContext(InvoiceContext)

  const emailInvoicePDF = useCallback(
    async (to: string) => {
      try {
        await emailInvoiceMut.mutateAsync({
          invoiceGuid,
          to,
        })
        message.success('Successfully Emailed Invoice PDF to customer')
      } catch (err) {
        console.error(
          `There was an unexpected error sending the invoice pdf`,
          err,
        )
      }
    },
    [emailInvoiceMut, invoiceGuid, message],
  )

  const defaultEmailValues = useMemo(() => {
    return {
      emailAddress: contact?.primaryEmailAddress?.emailAddress ?? '',
      subject: `Invoice from ${companyName} - ${displayId}`,
      body: `Attached is your Invoice from ${companyName}`,
    }
  }, [companyName, displayId, contact?.primaryEmailAddress?.emailAddress])

  const emailForm = useForm<SendAsEmailFormData>({
    defaultValues: defaultEmailValues,
  })

  const isDirty = emailForm.formState.isDirty

  const [withConfirmation, closeConfirmProps] = useCloseConfirmModal({
    isDirty,
  })

  const onCancelWithConfirm = useCallback(
    () =>
      withConfirmation(() => {
        emailForm.reset()
        onClose()
      }),
    [emailForm, onClose, withConfirmation],
  )

  const onEmailSubmit = useCallback(
    async (data: SendAsEmailFormData) => {
      await emailInvoicePDF(data.emailAddress)
      message.success('Invoice sent!')
      onCancelWithConfirm()
    },
    [message, onCancelWithConfirm, emailInvoicePDF],
  )

  const onBackWithConfirm = useMemo(() => {
    return () =>
      withConfirmation(() => {
        emailForm.reset()
      })
  }, [emailForm, withConfirmation])

  const content = useMemo(() => {
    return (
      <div className={classNames('flex min-h-0 flex-1 flex-col')}>
        <SendEmailForm
          form={emailForm}
          schema={SendAsEmailFormSchema}
          onSubmit={onEmailSubmit}
          formProps={{
            isLoading: emailInvoiceMut.isLoading,
            onCancel: onCancelWithConfirm,
            onBack: onBackWithConfirm,
          }}
          defaultValues={defaultEmailValues}
        />
      </div>
    )
  }, [
    emailForm,
    onEmailSubmit,
    emailInvoiceMut.isLoading,
    onCancelWithConfirm,
    defaultEmailValues,
    onBackWithConfirm,
  ])

  return (
    <OnsiteModal open={open} onClose={onCancelWithConfirm} size="large">
      {content}
      <CloseConfirmModal {...closeConfirmProps} />
    </OnsiteModal>
  )
})

export const SendInvoiceModal = React.memo<SendInvoiceModalProps>(
  ({ onClose, open = true }) => {
    const { accountGuid } = useStrictContext(InvoiceContext)

    const [{ data: accountData, fetching: fetchingAccountData }] = useQuery({
      query: PRIMARY_CONTACT_INFO_FOR_ACCOUNT_QUERY,
      variables: { accountGuid },
    })

    const contact = useMemo(
      () => accountData?.accountsByPk?.accountContacts?.[0]?.contact,
      [accountData],
    )

    if (fetchingAccountData) {
      return (
        <OnsiteModal open={open} onClose={onClose} size="large">
          <LoadingSpinner />
        </OnsiteModal>
      )
    }

    return <SendInvoiceForm onClose={onClose} open={open} contact={contact} />
  },
)
