import { PageHeader } from '@ant-design/pro-components'
import {
  BzDateTime,
  CalculatePaths,
  ComprehensivePaymentViewModel,
  IsoDateString,
  OfficeRoutes,
  PaymentMethod,
  PaymentStatus,
  PermissionV2,
  R,
  TimeZoneId,
  bzExpect,
  formatMoney,
  normalizeStringIncludingMoney,
  paymentMethodDisplayName,
} from '@breezy/shared'
import { faMoneyBill } from '@fortawesome/pro-light-svg-icons'
import { Input, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { AiOutlineSearch } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { ArrayParam, useQueryParams } from 'use-query-params'
import LoanDetailsLink from '../../components/Financing/LoanDetailsLink/LoanDetailsLink'
import { InvoiceIdListWithPopover } from '../../components/InvoiceIdListWithPopover/InvoiceIdListWithPopover'
import { Page } from '../../components/Page/Page'
import PaymentStatusTag from '../../components/Payments/PaymentStatusTag'
import { Authorized } from '../../components/Permissions/Authorized/Authorized'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import {
  qboViewPaymentColumn,
  renderDataTableAccountLink,
  viewDetailsLinkColumn,
} from '../../components/datatables/CommonColumnDefinitions'
import BzStatCard from '../../elements/BzStatCard/BzStatCard'
import { EmDash } from '../../elements/EmDash/EmDash'
import PageTitle from '../../elements/PageTitle/PageTitle'
import { trpc } from '../../hooks/trpc'
import { useInMemorySearch } from '../../hooks/useInMemorySearch'
import {
  useQuickbooksOnlineEnabled,
  useWisetackEnabled,
} from '../../providers/CompanyFinancialConfigWrapper'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'

type TableItemType = ComprehensivePaymentViewModel

// BONUS: Show External Payment Link with Tilled Link for Impersonating User

const computeItemIndex = (record: TableItemType) => {
  return [
    record.referenceNumber,
    record.userDisplayName,
    record.loanRecordGuid,
    record.accountDisplayName,
    record.jobDisplayId,
    ...record.invoicePayments.map(p => p.displayId),
    `$${record.amountUsd.toFixed(2)}`,
  ]
    .filter(Boolean)
    .map(s =>
      normalizeStringIncludingMoney(
        bzExpect(s?.toString(), 'Filtered nullish values above'),
      ),
    )
    .join('')
}

const summaryExcludedPaymentStatuses: Set<PaymentStatus> = new Set([
  PaymentStatus.FAILED,
  PaymentStatus.CANCELED,
])

const getPaymentReceivedAmount = (payment: TableItemType) =>
  payment.status === PaymentStatus.PAID ? payment.amountUsd : 0

const PaymentsListPage = () => {
  const companyGuid = useExpectedCompanyGuid()

  const { searchFilter, searchText, onSearch } =
    useInMemorySearch<TableItemType>({
      wait: 1000,
      keyMapper: x => x.guid,
      computeIndex: computeItemIndex,
    })

  const query = trpc.payments['payments:get-all'].useQuery({
    companyGuid,
    limit: 10000,
  })

  return (
    <Page requiresCompanyUser>
      <div className="card-no-fixed-height pt-2">
        <PageHeader
          className="px-2 py-2"
          title={<PageTitle title="Payments" icon={faMoneyBill} />}
          extra={
            <Authorized
              to={PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYMENTS_VIEW}
            >
              <Input
                placeholder="Search"
                prefix={<AiOutlineSearch />}
                key="1"
                value={searchText}
                onChange={onSearch}
                style={{ minWidth: 500 }}
                allowClear
              />
            </Authorized>
          }
        />
        <Authorized
          to={PermissionV2.OFFICE_FINANCIAL_INFORMATION_PAYMENTS_VIEW}
        >
          <TrpcQueryLoader
            query={query}
            render={data => {
              const payments: TableItemType[] = R.sortWith(
                [R.descend(R.prop('occurredAt'))],
                searchFilter(data),
              )

              const summary = payments
                .filter(p => !summaryExcludedPaymentStatuses.has(p.status))
                .reduce(
                  (acc, payment) => ({
                    totalReceivedUsd:
                      acc.totalReceivedUsd + getPaymentReceivedAmount(payment),
                    totalRefundedUsd:
                      acc.totalRefundedUsd +
                      (payment.totalRefundedAmountUsd ?? 0),
                    numOneTimePayments:
                      acc.numOneTimePayments +
                      (!payment.paymentSubscriptionGuid ? 1 : 0),
                    numRecurringPayments:
                      acc.numRecurringPayments +
                      (payment.paymentSubscriptionGuid ? 1 : 0),
                  }),
                  {
                    totalReceivedUsd: 0,
                    totalRefundedUsd: 0,
                    numOneTimePayments: 0,
                    numRecurringPayments: 0,
                  },
                )

              return (
                <PaymentsPageContent payments={payments} summary={summary} />
              )
            }}
          />
        </Authorized>
      </div>
    </Page>
  )
}

type PaymentsPageScorecardSummary = {
  readonly totalReceivedUsd: number
  readonly totalRefundedUsd: number
  readonly numOneTimePayments: number
  readonly numRecurringPayments: number
}

type PaymentsPageContentProps = {
  payments: TableItemType[]
  summary: PaymentsPageScorecardSummary
}

const PaymentsPageContent = ({
  payments,
  summary,
}: PaymentsPageContentProps) => {
  return (
    <>
      <PaymentsScoreCards summary={summary} />
      <PaymentsTable payments={payments} />
    </>
  )
}

const PaymentsScoreCards = ({
  summary,
}: {
  summary: PaymentsPageScorecardSummary
}) => (
  <div className="row flex-between mb-4 flex-wrap gap-2">
    <BzStatCard
      title="Total Received"
      value={formatMoney(summary.totalReceivedUsd)}
      valueClassName="green6"
    />
    <BzStatCard
      title="Total Refunded"
      value={formatMoney(summary.totalRefundedUsd)}
      valueClassName="red6"
    />
    <BzStatCard
      title="One-Time Payments"
      value={summary.numOneTimePayments.toString()}
    />
    <BzStatCard
      title="Maintenance Plan Payments"
      value={summary.numRecurringPayments.toString()}
    />
  </div>
)

const paymentTableColumns = (
  tzId: TimeZoneId,
  payments: ComprehensivePaymentViewModel[],
  isQuickbooksOnlineEnabled: boolean,
  isWisetackEnabled: boolean,
  filters?: Record<'paymentStatus' | 'paymentMethod', string[]>,
): ColumnsType<ComprehensivePaymentViewModel> => {
  const columns: ColumnsType<ComprehensivePaymentViewModel> = [
    viewDetailsLinkColumn(
      item =>
        OfficeRoutes.PAYMENT_DETAILS.build({
          params: { paymentRecordGuid: item.guid },
        }),
      'guid',
    ),
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filteredValue: filters ? filters['paymentStatus'] : undefined,
      filters: Array.from(
        payments.reduce(
          (acc, i) => acc.add(i.status),
          new Set<PaymentStatus>(),
        ),
      ).map(s => ({ text: s, value: s.toString() })),
      onFilter: (value, record: TableItemType) => record.status === value,
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (_: string, record: TableItemType) => (
        <PaymentStatusTag status={record.status} />
      ),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amountUsd',
      key: 'amountUsd',
      render: (_: string, record: TableItemType) => (
        <div>{formatMoney(record.amountUsd)}</div>
      ),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      filteredValue: filters ? filters['paymentMethod'] : undefined,
      filters: Array.from(
        payments.reduce(
          (acc, i) => acc.add(i.paymentMethod),
          new Set<string>(),
        ),
      ).map(method => ({
        text: paymentMethodDisplayName(method as unknown as PaymentMethod),
        value: method,
      })),
      onFilter: (value, record: TableItemType) =>
        record.paymentMethod === value,
      render: (_: string, record: TableItemType) => (
        <div>{paymentMethodDisplayName(record.paymentMethod)}</div>
      ),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: 'Account',
      dataIndex: 'accountDisplayName',
      key: 'accountDisplayName',
      render: (_: string, record: TableItemType) =>
        renderDataTableAccountLink({
          accountGuid: record.accountGuid,
          displayName: record.accountDisplayName,
          maintenancePlan: record.accountMaintenancePlan,
        }),
      ellipsis: {
        showTitle: true,
      },
      width: 320,
    },
    {
      title: 'Invoice #',
      key: 'invoiceDisplayId',
      render: (_: string, record: TableItemType) => (
        <InvoiceIdListWithPopover invoicePayments={record.invoicePayments} />
      ),
      ellipsis: {
        showTitle: true,
      },
      onCell: () => ({ style: { maxWidth: 320 } }),
    },
    {
      title: 'Job',
      dataIndex: 'jobGuid',
      key: 'jobGuid',
      render: (_: string, record: TableItemType) =>
        (record.jobGuid && record.jobDisplayId && (
          <Link to={OfficeRoutes.JOB_DETAILS.calculate(record)}>
            #{record.jobDisplayId}
          </Link>
        )) || <EmDash />,
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: 'Maintenance Plan',
      dataIndex: 'maintenancePlanGuid',
      key: 'maintenancePlanGuid',
      render: (_: string, record: TableItemType) =>
        (record.maintenancePlanGuid && (
          <Link to={OfficeRoutes.MAINTENANCE_PLAN_DETAILS.calculate(record)}>
            Plan
          </Link>
        )) || <EmDash />,
      ellipsis: {
        showTitle: true,
      },
    },
  ]
  if (isWisetackEnabled) {
    columns.push({
      title: 'Loan',
      dataIndex: 'loanRecordGuid',
      key: 'loanRecordGuid',
      render: (_: string, record: TableItemType) =>
        (record.loanRecordGuid && record.loanRecord && (
          <LoanDetailsLink record={record.loanRecord} text="Details" />
        )) || <EmDash />,
    })
  }

  columns.push({
    title: 'Paid Date',
    dataIndex: 'occurredAt',
    key: 'occurredAt',
    sorter: (a, b) => a.occurredAt.localeCompare(b.occurredAt),
    render: (v: IsoDateString) =>
      BzDateTime.fromIsoString(v, tzId).toDateFormat('MMM d, yyyy'),
    ellipsis: {
      showTitle: true,
    },
  })

  columns.push({
    title: 'Payout',
    dataIndex: 'payoutGuid',
    key: 'payoutGuid',
    render: (_: string, record: TableItemType) =>
      (record.payoutGuid && (
        <Link
          to={CalculatePaths.payoutDetails({
            payoutGuid: record.payoutGuid,
          })}
        >
          Payout
        </Link>
      )) || <EmDash />,
    ellipsis: {
      showTitle: true,
    },
  })

  columns.push({
    title: 'Refunded',
    dataIndex: 'totalRefundedAmountUsd',
    key: 'totalRefundedAmountUsd',
    render: (_: string, record: TableItemType) =>
      (record.totalRefundedAmountUsd ?? 0) > 0 ? (
        <div>{formatMoney(record.totalRefundedAmountUsd ?? 0)}</div>
      ) : (
        <EmDash />
      ),
  })

  if (isQuickbooksOnlineEnabled) {
    columns.push(qboViewPaymentColumn('qboEntityId'))
  }

  return columns
}

const PaymentsTable = ({ payments }: { payments: TableItemType[] }) => {
  const companyTimeZoneId = useExpectedCompanyTimeZoneId()
  const [query, setQuery] = useQueryParams({
    status: ArrayParam,
    paymentMethod: ArrayParam,
  })

  const isWisetackEnabled = useWisetackEnabled()
  const isQuickbooksOnlineEnabled = useQuickbooksOnlineEnabled()

  const { status: paymentStatusFilter, paymentMethod } = query

  return (
    <>
      <Table
        rowKey="guid"
        dataSource={payments}
        scroll={{ x: true }}
        columns={paymentTableColumns(
          companyTimeZoneId,
          payments,
          isQuickbooksOnlineEnabled,
          isWisetackEnabled ?? false,
          {
            paymentStatus: (paymentStatusFilter as string[]) ?? [],
            paymentMethod: (paymentMethod as string[]) ?? [],
          },
        )}
        pagination={{ pageSize: 100 }}
        onChange={(v, filters) => {
          setQuery({
            status: filters['status'] as string[],
            paymentMethod: filters['paymentMethod'] as string[],
          })
        }}
        size="small"
      />
    </>
  )
}

export default PaymentsListPage
