import { gql } from '../../generated'

export const FETCH_PAYABLE_INVOICES_FOR_ACCOUNT_QUERY = gql(/* GraphQL */ `
  query FetchPayableInvoicesForAccount($accountGuid: uuid!) {
    aggregatableInvoices(
      where: { dueUsc: { _gt: 0 }, accountGuid: { _eq: $accountGuid } }
      orderBy: { issuedAt: ASC, displayIdV2: ASC }
    ) {
      invoiceGuid
      displayIdV2
      dueUsc
      totalUsc
      paidUsc
      issuedAt
      numDaysOverdue
    }
  }
`)
