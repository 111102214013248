import { PermissionV2 } from '@breezy/shared'
import {
  faCalendar,
  faCrown,
  faFileChartColumn,
  faFileInvoiceDollar,
  faGear,
  faGrid2,
  faMoneyBill,
  faPhone,
  faReceipt,
  faTableCells,
  faTruckField,
} from '@fortawesome/pro-light-svg-icons'
import { AppMenu } from '../../components/AppMenu/AppMenu'
import { AppMenuItem } from '../../components/AppMenu/AppMenuItem'
import { BehindFeatureFlag } from '../../components/BehindFeatureFlag'
import { Authorized } from '../../components/Permissions/Authorized/Authorized'
import { getConfig } from '../../config'
import { useFetchTimesheetsConfig } from '../../hooks/fetch/useFetchTimesheetsConfig'
import { useCanUseIntegratedPhone } from '../../hooks/permission/useCanUseIntegratedPhone'
import { useCompanyMaintenancePlansEnabled } from '../../hooks/useCompanyMaintenancePlansEnabled'
import { OfficeReactRoutes } from '../../utils/OfficeReactRoutes'
import {
  AccountIcon,
  JobIcon,
  MaintenancePlanIcon,
  PayoutIcon,
  TimesheetsIcon,
} from '../../utils/feature-icons'
import { NavigationLink } from './NavigationLink'

const { appVersion } = getConfig()

export const Navigation = () => {
  const isCompanyMaintenancePlansEnabled = useCompanyMaintenancePlansEnabled()
  const { timesheetsEnabled } = useFetchTimesheetsConfig()
  const canUseIntegratedPhone = useCanUseIntegratedPhone()
  return (
    <AppMenu>
      <NavigationLink route={OfficeReactRoutes.JOBS_V2} splash />
      <NavigationLink route={OfficeReactRoutes.DASHBOARD} icon={faGrid2} />
      <NavigationLink route={OfficeReactRoutes.ACCOUNTS} icon={AccountIcon} />
      <Authorized>
        <NavigationLink icon={JobIcon} route={OfficeReactRoutes.JOBS_V2} />
      </Authorized>

      <NavigationLink route={OfficeReactRoutes.SCHEDULE} icon={faCalendar} />

      {canUseIntegratedPhone && (
        <NavigationLink
          route={OfficeReactRoutes.INTEGRATED_PHONE}
          icon={faPhone}
        />
      )}

      {isCompanyMaintenancePlansEnabled && (
        <NavigationLink
          route={OfficeReactRoutes.MAINTENANCE_PLANS}
          icon={MaintenancePlanIcon}
        />
      )}
      <NavigationLink route={OfficeReactRoutes.ESTIMATES} icon={faReceipt} />
      <NavigationLink
        route={OfficeReactRoutes.INVOICES}
        icon={faFileInvoiceDollar}
      />
      <NavigationLink route={OfficeReactRoutes.PAYMENTS} icon={faMoneyBill} />
      <NavigationLink route={OfficeReactRoutes.PAYOUTS} icon={PayoutIcon} />
      <BehindFeatureFlag
        enabledFeatureFlag="embedded-reporting"
        render={
          <NavigationLink
            route={OfficeReactRoutes.REPORTS}
            icon={faFileChartColumn}
          />
        }
      />
      <NavigationLink
        route={OfficeReactRoutes.BOOKKEEPING}
        icon={faTableCells}
      />
      {timesheetsEnabled && (
        <Authorized to={PermissionV2.OFFICE_TIMESHEETS_VIEW}>
          <NavigationLink
            route={OfficeReactRoutes.TIMESHEETS}
            icon={TimesheetsIcon}
          />
        </Authorized>
      )}
      <NavigationLink route={OfficeReactRoutes.ADMIN} icon={faCrown} />
      <NavigationLink route={OfficeReactRoutes.SETTINGS} icon={faGear} />
      <AppMenuItem spaceBefore isText>
        <span className="text-xs text-gray-300">
          Version {appVersion ?? ''}
        </span>
      </AppMenuItem>
      <Authorized to={PermissionV2.USE_FIELD_EXPERIENCE}>
        <NavigationLink
          route={OfficeReactRoutes.TECHNICIAN_EXPERIENCE}
          icon={faTruckField}
        />
      </Authorized>
    </AppMenu>
  )
}
