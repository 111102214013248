import { ImgHTMLAttributes } from 'react'
import InnerImageZoom from 'react-inner-image-zoom'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useIsSmallScreen } from '../../hooks/useIsMobile'

export const ZoomableImage = ({
  src,
  className,
  alt,
  ...props
}: Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> & { src: string }) => {
  const pinchToZoomEnabled = useFeatureFlag('pinch-to-zoom')
  const isSmallScreen = useIsSmallScreen()

  if (pinchToZoomEnabled) {
    if (isSmallScreen) {
      return (
        <TransformWrapper
          initialScale={1}
          minScale={1}
          maxScale={4}
          centerOnInit
          wheel={{ step: 0.1 }}
        >
          <TransformComponent
            // wrapperClass={className}
            contentClass="h-full w-full"
          >
            <img src={src} alt={alt} className={className} {...props} />
          </TransformComponent>
        </TransformWrapper>
      )
    }
    return (
      <InnerImageZoom
        src={src}
        className={className}
        imgAttributes={{ alt, ...props }}
      />
    )
  }

  return <img src={src} className={className} alt={alt} {...props} />
}
