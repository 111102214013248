import { Account, debounce } from '@breezy/shared'
import { faLocationDot } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { DetailChip } from '../../../adam-components/ListPage/DetailChip'
import { BzMobileSingleSelect } from '../../../elements/BzMobileSelect/BzMobileSelect'
import { HighlightedText } from '../../../elements/HighlightedText/HighlightedText'
import RenderIf from '../../../elements/RenderIf/RenderIf'
import { AccountDropdownOptionsQueryQuery } from '../../../generated/user/graphql'
import { trpc } from '../../../hooks/trpc'
import useIsMobile, {
  useIsMobileOrTouchOrTechApp,
} from '../../../hooks/useIsMobile'
import { useExpectedCompanyGuid } from '../../../providers/PrincipalUser'
import { ACCOUNT_DROPDOWN_OPTIONS_QUERY } from './AccountSearchAutocomplete.gql'

const makeAccountLabel = (
  fullName: string | undefined,
  phoneNumber: string | undefined,
  emailAddress: string | undefined,
  maintenancePlanName: string | undefined,
  archived: boolean | undefined,
) => {
  const label = [fullName, phoneNumber, emailAddress, maintenancePlanName]
    .filter(Boolean)
    .join(' - ')
  if (archived) {
    return `(Archived) ${label}`
  }
  return label
}

type AccountSearchAutocompleteProps = {
  currentAccount: Account | undefined
  setCurrentAccount: (account: Account) => void
  showLabel?: boolean
}

const AccountSearchAutocomplete = React.memo<AccountSearchAutocompleteProps>(
  ({ currentAccount, setCurrentAccount, showLabel = true }) => {
    const companyGuid = useExpectedCompanyGuid()
    const [accountSearchText, setAccountSearchTextRaw] = useState('')
    const [debouncedAccountSearchText, setDebouncedAccountSearchText] =
      useState('')

    const isMobile = useIsMobile()

    const debouncedSetAccountSearchText = useMemo(
      () =>
        debounce((text: string) => setDebouncedAccountSearchText(text), 300, {
          leading: true,
        }),
      [],
    )

    const _ilike = `%${debouncedAccountSearchText}%`

    const [{ data: accountsData, fetching: fetchingAccounts }] = useQuery({
      query: ACCOUNT_DROPDOWN_OPTIONS_QUERY,
      pause: !debouncedAccountSearchText,
      variables: {
        where: {
          _or: [
            {
              accountDisplayName: {
                _ilike,
              },
            },
            {
              accountContacts: {
                contact: {
                  fullName: {
                    _ilike,
                  },
                },
              },
            },
            {
              accountContacts: {
                contact: {
                  primaryPhoneNumber: {
                    phoneNumber: {
                      _ilike,
                    },
                  },
                },
              },
            },
            {
              accountContacts: {
                contact: {
                  primaryEmailAddress: {
                    emailAddress: {
                      _ilike,
                    },
                  },
                },
              },
              tags: {
                tag: {
                  name: {
                    _ilike,
                  },
                },
              },
            },
            {
              tags: {
                tag: {
                  name: {
                    _ilike,
                  },
                },
              },
            },
            {
              maintenancePlans: {
                maintenancePlanDefinition: {
                  marketingInfo: {
                    name: {
                      _ilike,
                    },
                  },
                },
              },
            },
          ],
        },
      },
    })

    // Very annoying quirk. When we search, the query will rerun and give us `undefined` as the value, making the list
    // we show empty while they type. So when they type we set this and when we're done loading we unset this. It
    // defaults to this if set and falls back to the real value otherwise.
    const [previousAccountsData, setPreviousAccountsData] =
      useState<AccountDropdownOptionsQueryQuery>()

    const setAccountSearchText = useCallback(
      (text: string) => {
        setPreviousAccountsData(accountsData)
        setAccountSearchTextRaw(text)
        debouncedSetAccountSearchText(text)
      },
      [accountsData, debouncedSetAccountSearchText],
    )

    useEffect(() => {
      if (!fetchingAccounts) {
        setPreviousAccountsData(undefined)
      }
    }, [fetchingAccounts])

    const accountOptions = useMemo(
      () =>
        accountSearchText
          ? (previousAccountsData ?? accountsData)?.accounts.map(
              ({
                accountGuid,
                accountContacts,
                accountLocations,
                maintenancePlans,
                archived,
                accountDisplayName,
                ...ac
              }) => {
                const primaryContact = accountContacts[0]?.contact
                if (!primaryContact) {
                  return { value: accountGuid, label: 'Unknown' }
                }

                const phoneNumber =
                  primaryContact.primaryPhoneNumber?.phoneNumber
                const emailAddress =
                  primaryContact.primaryEmailAddress?.emailAddress

                const contactInfo = phoneNumber || emailAddress || ''

                const label = makeAccountLabel(
                  primaryContact.fullName,
                  phoneNumber,
                  emailAddress,
                  maintenancePlans[0]?.maintenancePlanDefinition?.marketingInfo
                    ?.name,
                  archived,
                )
                return {
                  value: accountGuid,
                  label,
                  customNode: (
                    <div className="mr-2 line-clamp-4 flex-1 py-5 font-semibold">
                      <div className="semibold_16_24 mb-1.5">
                        <HighlightedText highlightText={accountSearchText}>
                          {accountDisplayName}
                        </HighlightedText>
                      </div>
                      <RenderIf if={accountLocations.length > 0}>
                        <div>
                          <span className="semibold_14_22 mr-1.5">
                            Service Location
                          </span>
                          {accountLocations.length === 1 ? (
                            <span className="regular_14_22">
                              <HighlightedText
                                highlightText={accountSearchText}
                              >
                                {`${accountLocations[0]?.location.address.line1} ${accountLocations[0]?.location.address.zipCode}`}
                              </HighlightedText>
                            </span>
                          ) : (
                            <Popover
                              overlayClassName="detail-chip-popover"
                              content={
                                <div className="max-h-56 space-y-2 overflow-auto p-3">
                                  {accountLocations.map(
                                    ({ locationGuid, location }) => (
                                      <DetailChip
                                        key={locationGuid}
                                        icon={
                                          <FontAwesomeIcon
                                            icon={faLocationDot}
                                          />
                                        }
                                      >
                                        {location.address.line1}{' '}
                                        {location.address.city},{' '}
                                        {location.address.stateAbbreviation}{' '}
                                        {location.address.zipCode}
                                      </DetailChip>
                                    ),
                                  )}
                                </div>
                              }
                            >
                              <span className="regular_14_22">
                                Multiple Locations{' '}
                                <span className="">
                                  ({accountLocations.length})
                                </span>
                              </span>
                            </Popover>
                          )}
                        </div>
                      </RenderIf>
                      <div className="">
                        <span className="semibold_14_22 mr-1.5">
                          Primary Contact
                        </span>
                        <span className="regular_14_22">
                          <HighlightedText highlightText={accountSearchText}>
                            {`${primaryContact.fullName}${
                              contactInfo && !isMobile
                                ? ` • ${contactInfo}`
                                : ''
                            }`}
                          </HighlightedText>
                        </span>
                      </div>
                      <RenderIf if={!!contactInfo && isMobile}>
                        <div className="">
                          <span className="semibold_14_22 mr-1.5">
                            {phoneNumber ? 'Phone' : 'Email'}
                          </span>
                          <span className="regular_14_22">
                            <HighlightedText highlightText={accountSearchText}>
                              {phoneNumber || emailAddress || ''}
                            </HighlightedText>
                          </span>
                        </div>
                      </RenderIf>
                    </div>
                  ),
                }
              },
            ) ?? []
          : [],
      [accountSearchText, accountsData, previousAccountsData, isMobile],
    )

    const [selectedAccountGuid, setSelectedAccountGuid] = useState<string>()

    const fetchAccountQuery = trpc.accounts['accounts:query'].useQuery(
      {
        type: 'by-account-guid',
        companyGuid,
        accountGuid: selectedAccountGuid ?? '',
      },
      {
        enabled: !!selectedAccountGuid,
        retry: false,
      },
    )

    useEffect(() => {
      if (fetchAccountQuery.data?.accounts[0]) {
        setCurrentAccount(fetchAccountQuery.data?.accounts[0])
      }
    }, [fetchAccountQuery.data, setCurrentAccount])

    // We don't want to show a loading spinner on every load. The query is lightning fast after you have a few letters
    // in; it was not noticeably slow while on "Slow 3G" throttling. If we show the loading spinner on load it will show
    // after every key stroke for a split second, which sucks. It will also always show at the very end because of the
    // debounce. So we don't show it based on the query fetching. However, if you are on Slow 3G and type a single
    // letter, it does take some time to load. So if it isn't instantaneous (which we'll say is ~300ms) THEN we show the
    // spinner.
    const [showFetchingAccounts, setShowFetchingAccounts] = useState(false)

    useEffect(() => {
      if (fetchingAccounts) {
        let cancel = false
        setTimeout(() => {
          if (!cancel) {
            setShowFetchingAccounts(true)
          }
        }, 300)
        return () => {
          cancel = true
        }
      } else {
        setShowFetchingAccounts(false)
      }
    }, [fetchingAccounts])

    const shouldShowMobile = useIsMobileOrTouchOrTechApp()

    const select = useMemo(() => {
      return (
        <BzMobileSingleSelect
          title="Select Account"
          placeholder="Search for Account by Contact Name, Phone Number, Email, Address"
          onSearch={setAccountSearchText}
          options={accountOptions}
          value={currentAccount?.accountGuid}
          labelOverride={
            currentAccount?.accountContacts[0]
              ? makeAccountLabel(
                  `${currentAccount.accountContacts[0].contact.firstName} ${currentAccount.accountContacts[0].contact.lastName}`,
                  currentAccount.accountContacts[0]?.contact.primaryPhoneNumber
                    ?.phoneNumber,
                  currentAccount.accountContacts[0]?.contact.primaryEmailAddress
                    ?.emailAddress,
                  currentAccount.maintenancePlans[0]?.planTypeName,
                  currentAccount.archived,
                )
              : ''
          }
          onChange={accountGuid => {
            const selectedAccount = accountOptions.find(
              option => option.value === accountGuid,
            )
            if (selectedAccount) {
              setSelectedAccountGuid(accountGuid)
              setAccountSearchText(selectedAccount.label)
            }
          }}
          emptyState="Start typing to search for an account."
          loading={showFetchingAccounts}
        />
      )
    }, [
      accountOptions,
      currentAccount,
      setAccountSearchText,
      showFetchingAccounts,
    ])

    return shouldShowMobile ? (
      select
    ) : (
      <div>
        {showLabel && (
          <div className="semibold_14_22 grey9">Select existing account*</div>
        )}
        {select}
      </div>
    )
  },
)

export default AccountSearchAutocomplete
