import { gql } from '../../generated'

export const ESTIMATE_LISTING_QUERY = gql(/* GraphQL */ `
  query GetEstimateList(
    $limit: Int!
    $offset: Int!
    $orderBy: [EstimatesOrderBy!]!
    $whereClause: EstimatesBoolExp!
  ) {
    estimatesAggregate(where: $whereClause) {
      aggregate {
        count
      }
    }
    estimates(
      offset: $offset
      limit: $limit
      where: $whereClause
      orderBy: $orderBy
    ) {
      estimateGuid
      displayId
      taxRate
      dynamicPricingType
      status
      createdAt
      createdByUser {
        userGuid
        firstName
        lastName
        deactivatedAt
        userRoles {
          role
        }
        userPrimaryHueHex
        avatarPhoto {
          cdnUrl
        }
      }
      jobAppointmentGuid
      job {
        jobGuid
        displayId
        accountGuid
        pointOfContact {
          fullName
          primaryPhoneNumber {
            type
            phoneNumber
          }
          firstName
          primaryEmailAddress {
            emailAddress
          }
        }
        location {
          locationGuid
          address {
            line1
            line2
          }
        }
        jobType {
          name
        }
        account {
          accountContacts(where: { primary: { _eq: true } }) {
            contact {
              fullName
              primaryEmailAddress {
                emailAddress
              }
              primaryPhoneNumber {
                phoneNumber
                type
              }
            }
          }
        }
      }
      estimateOptions(orderBy: { seq: ASC }) {
        optionGuid: estimateOptionGuid
        seq
        totalUsc
        isSelected
      }
    }
  }
`)

export const ESTIMATE_LISTING_USERS_QUERY = gql(/* GraphQL */ `
  query GetEstimateListUsers {
    companyUsers {
      userGuid
      userByUserGuid {
        fullName
      }
    }
  }
`)

export const ESTIMATE_LISTING_DOWNLOAD_PDF_DATA_QUERY = gql(/* GraphQL */ `
  query EstimatePDFData($estimateGuid: uuid!) {
    estimatesByPk(estimateGuid: $estimateGuid) {
      messageHtml
      acceptedAt
      signatureFileUrl
      acceptedOnBehalfByUser {
        firstName
        lastName
      }
      taxRate
      taxRateName
      taxRateGuid
      dynamicPricingType
    }
  }
`)

export const ESTIMATE_LISTING_OPTIONS_QUERY = gql(/* GraphQL */ `
  query EstimateListingOptions($estimateGuid: uuid!) {
    estimatesByPk(estimateGuid: $estimateGuid) {
      estimateOptions(orderBy: { seq: ASC }) {
        optionGuid: estimateOptionGuid
        descriptionHtml
        displayName
        totalUsc
        featuredPhotoGuid
        featuredPhoto {
          cdnUrl
        }
        estimateOptionDiscounts(orderBy: { seq: ASC }) {
          discountGuid: estimateOptionDiscountGuid
          discountAmountUsc
          discountRate
          discountType
          name
          seq
        }
        recommended: isRecommended
        selected: isSelected
        seq
        cartItems {
          seq
          cartItem {
            cartItemGuid
            itemType: cartItemType
            description
            isDiscountable
            isTaxable
            name
            originalPricebookItemGuid
            quantity
            unitPriceUsc
            cartItemCode
          }
        }
      }
    }
  }
`)
