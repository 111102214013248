import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'

// Report Categories
export type ReportCategory = 'accounts'

// Report Types
export type ReportType = 'export'

// Base Report Config Interface
export interface ReportConfigBase {
  id: string
  type: ReportType
  category: ReportCategory
  title: string
  description: string
  icon: IconDefinition
  permissions?: string[]
}

// Type-specific Report Interfaces
export interface ExportReportConfig extends ReportConfigBase {
  type: 'export'
  questionId: string
}

// Union type for all report configurations
export type ReportConfig = ExportReportConfig

// Helper function to create a question export report
export const createExportReport = (
  params: Omit<ExportReportConfig, 'type'>,
): ExportReportConfig => ({
  type: 'export',
  ...params,
})
