import {
  bzOptional,
  DayOfTheWeek,
  getBookingLinkUrl,
  Guid,
  guidSchema,
  htmlStringSchema,
  INSTANT_BOOKING_ASSIGNMENT_TYPES,
  InstantBookingWeeklySchedule,
  isNullish,
  IsoDateString,
  JobClass,
  LocalTimeString,
  minifyUrl,
  ONLINE_BOOKING_EARLIEST_AVAILABLE_TIMES,
  ONLINE_BOOKING_LATEST_AVAILABLE_TIMES,
  ONLINE_BOOKING_TYPES,
  OnlineBookingServiceType,
  OnlineBookingServiceTypeToJobClassMap,
  RoleId,
} from '@breezy/shared'
import { useEffect, useMemo, useState } from 'react'
import { z } from 'zod'
import { ZipCodeSchema } from '../../components/PaymentWorkflow/utils/ZipCodeSchema'
import { getConfig } from '../../config'
import { useMessage } from '../../utils/antd-utils'

const accountAppUrl = getConfig().accountAppFullUrl
export type ArrivalWindowGuid = Guid
export const OnlineBookingCompanyConfigFormSchema = z.object({
  limitBookingsToServiceAreaEnabled: z.boolean().default(false),
  brandingColorHex: z.string().default('#1677FF'),
  afterHoursBannerHtml: bzOptional(htmlStringSchema).default(''),
  serviceAreaZipCodes: z.array(ZipCodeSchema).default([]),
})

const getInstantBookingWeeklyScheduleDaySchema = (defaultEnabled: boolean) =>
  z.object({
    enabled: z.boolean().default(defaultEnabled),
    arrivalWindowGuids: z.array(guidSchema).default([]),
  })

const InstantBookingWeeklyScheduleFormSchema = z.object({
  Sunday: getInstantBookingWeeklyScheduleDaySchema(false),
  Monday: getInstantBookingWeeklyScheduleDaySchema(true),
  Tuesday: getInstantBookingWeeklyScheduleDaySchema(true),
  Wednesday: getInstantBookingWeeklyScheduleDaySchema(true),
  Thursday: getInstantBookingWeeklyScheduleDaySchema(true),
  Friday: getInstantBookingWeeklyScheduleDaySchema(true),
  Saturday: getInstantBookingWeeklyScheduleDaySchema(false),
})

export type InstantBookingWeeklyScheduleFormData = z.infer<
  typeof InstantBookingWeeklyScheduleFormSchema
>

export const OnlineBookingEditServiceTypeFormSchema = z
  .object({
    bookingType: z.enum(ONLINE_BOOKING_TYPES).default('REQUEST'),
    bookableJobTypeGuids: z.array(guidSchema).default([]),
    bookableTechnicians: z
      .array(
        z.object({
          technicianGuid: guidSchema,
          seq: z.number(),
        }),
      )
      .default([]),
    assignmentType: z.enum(INSTANT_BOOKING_ASSIGNMENT_TYPES).default('RANDOM'),
    legalBlurbHtml: bzOptional(htmlStringSchema).default(''),
    instantBookingWeeklySchedule: InstantBookingWeeklyScheduleFormSchema,
    earliestAvailability: bzOptional(
      z.enum(ONLINE_BOOKING_EARLIEST_AVAILABLE_TIMES),
    ).default('1 day'),
    latestAvailability: bzOptional(
      z.enum(ONLINE_BOOKING_LATEST_AVAILABLE_TIMES),
    ).default('60 days'),
  })
  .refine(
    schema => {
      if (schema.bookingType === 'INSTANT') {
        return schema.bookableTechnicians.length > 0
      }
      return true
    },
    {
      message: 'At least one technician must be selected for instant booking',
      path: ['bookableTechnicians'],
    },
  )

export type OnlineBookingEditServiceTypeForm = z.infer<
  typeof OnlineBookingEditServiceTypeFormSchema
>

const UniqueZipCodesSchema = z.object({
  zipCodes: z.array(ZipCodeSchema).default([]),
})

export type UniqueZipCodes = z.infer<typeof UniqueZipCodesSchema>

export type OnlineBookingCompanyConfigFormData = z.infer<
  typeof OnlineBookingCompanyConfigFormSchema
>

export const useMinifiedBookingUrl = (companyGuid: string) => {
  const [minifiedUrl, setMinifiedUrl] = useState<string>('')
  const message = useMessage()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    const fetchUrl = async () => {
      try {
        setIsLoading(true)
        const shortened = await minifyUrl(
          getBookingLinkUrl(accountAppUrl, companyGuid),
        )
        setMinifiedUrl(shortened)
        setError(null)
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to minify URL'))
        console.error('Failed to minify URL:', err)
        message.error('Failed to minify URL. Reload the page and try again.')
      } finally {
        setIsLoading(false)
      }
    }

    fetchUrl()
  }, [companyGuid, message])

  return { minifiedUrl, isLoading, error }
}

export type CompanyJobType = {
  jobTypeGuid: string
  jobClass: JobClass
  displayName: string
}

export type CompanyTechnician = {
  technicianGuid: string
  fullName: string
  roles: RoleId[]
  deactivatedAt: IsoDateString | undefined
}

export type WithJobClass<T> = T & {
  jobClass?: JobClass
}

export type CompanyArrivalWindowOption = {
  arrivalWindowGuid: string
  arrivalWindowStartTime: LocalTimeString
  arrivalWindowEndTime: LocalTimeString
}

export const getServiceTypeCompanyArrivalWindowOptions = (
  type: OnlineBookingServiceType,
  companyArrivalWindowOptions: WithJobClass<CompanyArrivalWindowOption>[],
): CompanyArrivalWindowOption[] => {
  const serviceTypeJobClass = OnlineBookingServiceTypeToJobClassMap[type]
  const serviceTypeOptions = companyArrivalWindowOptions.filter(
    option => option.jobClass === serviceTypeJobClass,
  )

  // When there are no explicitly defined arrival windows for a job class,
  // we fall back to the global arrival windows (arrival windows where job class is null).
  if (serviceTypeOptions.length === 0) {
    return companyArrivalWindowOptions
      .filter(option => isNullish(option.jobClass))
      .map(option => ({
        arrivalWindowGuid: option.arrivalWindowGuid,
        arrivalWindowStartTime: option.arrivalWindowStartTime,
        arrivalWindowEndTime: option.arrivalWindowEndTime,
      }))
  }

  return serviceTypeOptions.map(option => ({
    arrivalWindowGuid: option.arrivalWindowGuid,
    arrivalWindowStartTime: option.arrivalWindowStartTime,
    arrivalWindowEndTime: option.arrivalWindowEndTime,
  }))
}

export const useServiceTypeArrivalWindowOptions = (
  type: OnlineBookingServiceType,
  companyArrivalWindowOptions: WithJobClass<CompanyArrivalWindowOption>[],
) => {
  return useMemo(
    () =>
      getServiceTypeCompanyArrivalWindowOptions(
        type,
        companyArrivalWindowOptions,
      ),
    [type, companyArrivalWindowOptions],
  )
}

export const convertInstantBookingWeeklyScheduleToFormData = (
  instantBookingWeeklySchedule: InstantBookingWeeklySchedule,
): InstantBookingWeeklyScheduleFormData => {
  return Object.entries(instantBookingWeeklySchedule).reduce(
    (acc, [dayOfWeek, instantBookingWeeklyScheduleDay]) => {
      acc[dayOfWeek as DayOfTheWeek] = {
        enabled: instantBookingWeeklyScheduleDay.enabled,
        arrivalWindowGuids:
          instantBookingWeeklyScheduleDay.bookableArrivalWindows.map(
            arrivalWindow => arrivalWindow.companyAppointmentArrivalWindowGuid,
          ),
      }
      return acc
    },
    {} as InstantBookingWeeklyScheduleFormData,
  )
}
