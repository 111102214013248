import {
  BzAddress,
  BzDateFns,
  bzExpect,
  JobsByLeadSourceDatum,
  OfficeRoutes,
  R,
  toPlural,
  usCentsToUsd,
} from '@breezy/shared'
import { faChartSimple } from '@fortawesome/pro-light-svg-icons'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'antd'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'urql'
import { RightArrowButton } from '../../adam-components/RightArrowButton'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Card, CardBody } from '../../elements/Card/Card'
import { BarChart } from '../../elements/Charts/BarChart'
import { Link } from '../../elements/Link/Link'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { downloadDataAsCsv } from '../../utils/export-to-csv'
import { DUMMY_JOBS_BY_LEAD_SOURCE_DATA } from './dummyBackgroundData'
import { NoDataOverlay } from './NoDataOverlay'
import {
  INVOICES_BY_LEAD_SOURCE_REPORT_QUERY,
  NUM_INVOICES_QUERY,
} from './ReportingDashboard.gql'
import {
  StandardReportingDateRangePicker,
  useStandardReportingDateRangePickerState,
} from './ReportingDateRangePicker/StandardReportingDateRangePicker'

const MAX_ROWS = 5

const formatValue = (value: number, data: JobsByLeadSourceDatum) => (
  <div>
    <span>
      $
      {value.toLocaleString('en-us', {
        maximumFractionDigits: 0,
      })}
    </span>
    <span className="px-1 text-bz-gray-600">•</span>
    <span className="text-bz-gray-800">
      {data.numJobs} {toPlural(data.numJobs, 'job')}
    </span>
  </div>
)

const formatValueTooltip = (value: number, data: JobsByLeadSourceDatum) => (
  <div>
    <div>
      $
      {value.toLocaleString('en-us', {
        maximumFractionDigits: 0,
      })}
    </div>
    <div>
      {data.numJobs} {toPlural(data.numJobs, 'job')}
    </div>
  </div>
)

type JobsByLeadSourceWidgetProps = {
  // Not optional. I'm expecting at least a fixed height class
  className: string
}
export const JobsByLeadSourceWidget = React.memo<JobsByLeadSourceWidgetProps>(
  ({ className }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const [dateRange, setDateRange] = useStandardReportingDateRangePickerState()

    const jobsByLeadSourceQuery = trpc.reporting[
      'jobs-by-lead-source:get'
    ].useQuery(
      { dateRange },
      {
        trpc: {
          context: {
            skipBatch: true,
          },
        },
      },
    )

    const [{ data: numInvoicesData, fetching: numInvoicesQueryLoading }] =
      useQuery({
        query: NUM_INVOICES_QUERY,
        variables: {},
      })

    const hasData = useMemo(
      () => (numInvoicesData?.invoicesAggregate.aggregate?.count ?? 0) > 0,
      [numInvoicesData?.invoicesAggregate.aggregate?.count],
    )

    const resolvedData = useMemo(() => {
      const data = (
        hasData
          ? jobsByLeadSourceQuery.data ?? []
          : DUMMY_JOBS_BY_LEAD_SOURCE_DATA
      ).map(item => ({
        ...item,
        value: item.revenue,
        label: item.leadSource,
      }))

      return R.sort(R.descend(R.prop('value')), data)
    }, [jobsByLeadSourceQuery.data, hasData])

    const truncatedData = useMemo(
      () => resolvedData.slice(0, MAX_ROWS),
      [resolvedData],
    )

    const [viewAllOpen, setViewAllOpen] = useState(false)

    const [shouldDownloadReport, setShouldDownloadReport] = useState(false)

    const [{ data: reportData, fetching: downloadingReport }] = useQuery({
      query: INVOICES_BY_LEAD_SOURCE_REPORT_QUERY,
      variables: {
        startDate: dateRange.start,
        endDate: dateRange.end,
      },
      pause: !shouldDownloadReport,
    })

    useEffect(() => {
      if (reportData && !downloadingReport && shouldDownloadReport) {
        const filenameDateFormat = 'PP'
        const filename = `Lead Sources Report ${BzDateFns.formatFromISO(
          dateRange.start,
          filenameDateFormat,
          tzId,
        )} thru ${BzDateFns.formatFromISO(
          dateRange.end,
          filenameDateFormat,
          tzId,
        )}.csv`

        const outputDateFormat = 'yyyy-MM-dd HH:mm:ss'

        downloadDataAsCsv(
          reportData.invoices.map(
            ({
              displayId,
              issuedAt,
              jobLink,
              mostRecentInvoicePayment,
              appliedAmountUscSum,
            }) => ({
              'Lead Source':
                jobLink?.job.jobLeadSource?.[0]?.companyLeadSource
                  .canonicalLeadSourceNameOverride ??
                jobLink?.job.jobLeadSource?.[0]?.companyLeadSource
                  .canonicalLeadSourceName ??
                'Unknown',
              'Invoice #': displayId,
              'Issued At': BzDateFns.formatFromISO(
                bzExpect(
                  issuedAt,
                  'issued date',
                  'Expected lead source invoice to have an issued date',
                ),
                outputDateFormat,
                tzId,
              ),

              'Paid At': BzDateFns.formatFromISO(
                bzExpect(
                  mostRecentInvoicePayment?.[0]?.paymentRecord.occurredAt,
                  'payment date',
                  'Expected lead source invoice to have a payment date',
                ),
                outputDateFormat,
                tzId,
              ),
              Amount: usCentsToUsd(
                appliedAmountUscSum.aggregate?.sum?.appliedAmountUsc ?? 0,
              ),
              'Job #': jobLink?.job.displayId ?? 'Unknown',
              Customer: jobLink?.job.pointOfContact.fullName ?? 'Unknown',
              Address: BzAddress.formatAddressSingleLine(
                bzExpect(
                  jobLink?.job.location.address,
                  'job location',
                  'Expected job to have location',
                ),
              ),
            }),
          ),
          filename,
        )

        setShouldDownloadReport(false)
      }
    }, [
      dateRange.end,
      dateRange.start,
      downloadingReport,
      reportData,
      shouldDownloadReport,
      tzId,
    ])

    const titleAction = useMemo(
      () => (
        <div className="flex flex-row items-center">
          <StandardReportingDateRangePicker
            disabled={downloadingReport}
            range={dateRange}
            setRange={setDateRange}
          />
          <RightArrowButton
            type="link"
            size="small"
            disabled={downloadingReport}
            onClick={() => setShouldDownloadReport(true)}
          >
            Generate Report
          </RightArrowButton>
        </div>
      ),
      [dateRange, downloadingReport, setDateRange],
    )

    const navigate = useNavigate()

    const onCreateJobClick = useCallback(
      () => navigate(OfficeRoutes.JOB_CREATION.path),
      [navigate],
    )

    const hasNoDataOverlay = !hasData

    return (
      <Card
        title="Lead Sources"
        infoNextToTitle
        info="This only includes jobs that have issued invoices."
        className={className}
        titleAction={!hasNoDataOverlay && titleAction}
      >
        {jobsByLeadSourceQuery.isLoading || numInvoicesQueryLoading ? (
          <LoadingSpinner />
        ) : !resolvedData.length ? (
          <div className="flex flex-1 items-center justify-center text-base font-semibold">
            <div>No invoices in your chosen time period.</div>
          </div>
        ) : (
          //  Move the margin into the padding so a scrollbar would be inside the padding. Add padding back so it's like
          //  we didn't do any of this.
          <>
            <div className="flex h-full flex-col">
              <div className="flex-1">
                <BarChart
                  disableTooltip
                  data={truncatedData}
                  formatValue={formatValue}
                  formatValueTooltip={formatValueTooltip}
                />
              </div>
              {resolvedData.length > truncatedData.length && (
                <Link onClick={() => setViewAllOpen(true)}>
                  View {resolvedData.length - truncatedData.length} more
                  <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
                </Link>
              )}
            </div>

            {hasNoDataOverlay && (
              <NoDataOverlay
                className="inset-[-16px]"
                icon={faChartSimple}
                title="No lead sources detected"
                link={<Link onClick={onCreateJobClick}>Create job</Link>}
              >
                <div className="text-center">
                  Lead sources will be displayed once the first invoice has been
                  issued.
                </div>
              </NoDataOverlay>
            )}
          </>
        )}
        <Modal
          open={viewAllOpen}
          closeIcon={false}
          onCancel={() => setViewAllOpen(false)}
          footer={null}
          width="50%"
        >
          <CardBody
            className="mx-[-1.5rem] my-[-1rem]"
            title="Lead Sources"
            titleAction={titleAction}
          >
            {jobsByLeadSourceQuery.isLoading || numInvoicesQueryLoading ? (
              <div className="py-10">
                <LoadingSpinner />
              </div>
            ) : (
              <div className="max-h-[75vh] overflow-auto px-6 py-4">
                <BarChart
                  disableTooltip
                  data={resolvedData}
                  formatValue={formatValue}
                  formatValueTooltip={formatValueTooltip}
                />
              </div>
            )}
          </CardBody>
        </Modal>
      </Card>
    )
  },
)
