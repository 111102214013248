import { z } from 'zod'
import { AsyncFn, Log, isNullish } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { QboIncomeAccount } from '../Accounting/QboSync/QboSyncTypes'
import { Guid, bzOptional } from '../common-schemas'
import { ForCompanyUser } from '../Company/Company'
import { PricebookQboIncomeAccountMap, getPricebookQboIncomeAccountMap } from './PricebookQboIncomeAccount'
import { PricebookCategory, PricebookItem, PricebookItemTypeEnum } from './PricebookTypes'

export const BULK_PRICEBOOK_IMPORT_PRICEBOOK_VERSION_BACKUP_NAME = 'Bulk Import Automated Backup'
export const BULK_PRICEBOOK_IMPORT_WORKBOOK_NAME = 'Breezy Pricebook Workbook'
export const BULK_PRICEBOOK_ITEMS_IMPORT_SHEET_NAME = 'Pricebook Items'
export const BULK_PRICEBOOK_ITEMS_IMPORT_SHEET_SLUG = 'pricebook-items'
export const BULK_PRICEBOOK_CATEGORIES_SHEET_NAME = 'Pricebook Categories'
export const BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG = 'pricebook-categories'
export const BULK_PRICEBOOK_QBO_INCOME_ACCOUNTS_SHEET_NAME = 'QBO Income Accounts'
export const BULK_PRICEBOOK_QBO_INCOME_ACCOUNTS_SHEET_SLUG = 'qbo-income-accounts'

export const BulkPricebookItemRowV1Schema = z.object({
  name: z.string(),
  category: bzOptional(z.string()),
  description: bzOptional(z.string()),
  itemType: z.nativeEnum(PricebookItemTypeEnum),
  imageUrl: bzOptional(z.string()),
  costUsd: z.number(),
  priceUsd: z.number(),
  isTaxable: bzOptional(z.boolean()),
  isActive: bzOptional(z.boolean()),
  isDiscountable: bzOptional(z.boolean()),
  qboIncomeAccountName: bzOptional(z.string()),
  qboIncomeAccountId: bzOptional(z.string()),
  pricebookItemGuid: guidSchema,
  pricebookCategoryGuid: bzOptional(guidSchema),
  pricebookItemCode: bzOptional(z.string()),
})

export const BulkPricebookItemRowV2Schema = z.object({
  name: z.string(),
  category: bzOptional(z.string()),
  description: bzOptional(z.string()),
  itemType: z.nativeEnum(PricebookItemTypeEnum),
  costUsd: z.number(),
  priceUsd: z.number(),
  isTaxable: bzOptional(z.boolean()),
  isActive: bzOptional(z.boolean()),
  isDiscountable: bzOptional(z.boolean()),
  qboIncomeAccountName: bzOptional(z.string()),
  qboIncomeAccountId: bzOptional(z.string()),
  pricebookItemGuid: guidSchema,
  pricebookCategoryGuid: bzOptional(guidSchema),
  sourcePhotoUrl: bzOptional(z.string()),
  pricebookItemCode: bzOptional(z.string()),
})

export const BulkPricebookItemRowUnionSchema = z.union([BulkPricebookItemRowV1Schema, BulkPricebookItemRowV2Schema])
export type BulkPricebookItemRowUnion = z.infer<typeof BulkPricebookItemRowUnionSchema>

export type BulkPricebookItemRow = z.infer<typeof BulkPricebookItemRowV2Schema>

// The order matters here since this is the order of the columns in the CSV
export const toBulkPricebookRow = (
  item: PricebookItem,
  categoryMap: Record<Guid, BulkPricebookCategory>,
  qboIncomeAccountNameMap: PricebookQboIncomeAccountMap,
): BulkPricebookItemRow => {
  return {
    name: item.name,
    category: item.pricebookCategoryGuid ? categoryMap[item.pricebookCategoryGuid]?.concatenatedName : '',
    description: item.description ?? '',
    itemType: item.itemType,
    costUsd: item.costUSD,
    priceUsd: item.priceUSD,
    isTaxable: item.isTaxable,
    isActive: item.isActive,
    isDiscountable: item.isDiscountable,
    qboIncomeAccountName: item.qboIncomeAccountId ? qboIncomeAccountNameMap[item.qboIncomeAccountId]?.displayName : '',
    qboIncomeAccountId: item.qboIncomeAccountId ?? '',
    pricebookItemGuid: item.pricebookItemGuid,
    pricebookCategoryGuid: item.pricebookCategoryGuid ?? '',
    sourcePhotoUrl: item.sourcePhotoUrl ?? '',
    pricebookItemCode: item.pricebookItemCode ?? '',
  }
}

type ComparableKeys = Exclude<keyof BulkPricebookItemRow, 'name' | 'pricebookCategoryGuid'>

export type ChangedType = {
  key: string
  value: string
  oldValue: string
}

export type ChangedBulkPricebookRow = BulkPricebookItemRow & {
  changedFields: ChangedType[]
}

export const BulkPricebookCategoryV1Schema = z.object({
  name: z.string(),
  concatenatedName: z.string(),
  parentCategoryName: bzOptional(z.string()),
  concatenatedParentCategoryName: bzOptional(z.string()),
  pricebookCategoryGuid: guidSchema,
  parentCategoryGuid: bzOptional(guidSchema),
})
export const BulkPricebookCategoryV2Schema = z.object({
  name: z.string(),
  concatenatedName: z.string(),
  parentCategoryName: bzOptional(z.string()),
  concatenatedParentCategoryName: bzOptional(z.string()),
  pricebookCategoryGuid: guidSchema,
  parentCategoryGuid: bzOptional(guidSchema),
  sourcePhotoUrl: bzOptional(z.string()),
})
export const BulkPricebookCategoryUnionSchema = z.union([BulkPricebookCategoryV1Schema, BulkPricebookCategoryV2Schema])

export type BulkPricebookCategory = z.infer<typeof BulkPricebookCategoryV2Schema>

export const findMissingCategories = (
  importedCategories: BulkPricebookCategory[],
  existingCategories: PricebookCategory[],
): PricebookCategory[] => {
  const importedGuids = new Set(importedCategories.map(c => c.pricebookCategoryGuid))
  return existingCategories.filter(c => !importedGuids.has(c.pricebookCategoryGuid))
}

export type CategoryNode = {
  category: PricebookCategory
  children: CategoryNode[]
}

export const buildCategoryImportTree = (categories: PricebookCategory[]): CategoryNode[] => {
  const nodeMap: Record<string, CategoryNode> = {}
  const rootNodes: CategoryNode[] = []
  const visitedNodes: Set<string> = new Set()

  // Create nodes for each category
  categories.forEach(category => {
    const key = category.pricebookCategoryGuid
    nodeMap[key] = { category, children: [] }
  })

  // Build the tree by assigning children to their parents
  for (const category of categories) {
    const key = category.pricebookCategoryGuid
    const parentNodeKey = category.parentCategoryGuid
    const node = nodeMap[key]

    // Check for circular reference
    if (visitedNodes.has(key)) {
      // Handle the circular reference, e.g., by logging a warning
      Log.warn(`Circular reference detected for category with GUID: ${key}`)
      continue
    }

    if (!parentNodeKey) {
      rootNodes.push(node)
      continue
    }

    if (parentNodeKey && nodeMap[parentNodeKey]) {
      nodeMap[parentNodeKey].children.push(node)
    }
  }

  return rootNodes
}

export const convertToBulkPricebookCategories = (categories: PricebookCategory[]): BulkPricebookCategory[] => {
  const categoryTree = buildCategoryImportTree(categories)
  const bulkCategories: BulkPricebookCategory[] = []

  const buildConcatenatedName = (node: CategoryNode, parentName?: string, parentConcatenatedName?: string): void => {
    const concatenatedName = parentConcatenatedName
      ? `${parentConcatenatedName} > ${node.category.name}`
      : node.category.name
    bulkCategories.push({
      concatenatedName,
      concatenatedParentCategoryName: parentConcatenatedName,
      name: node.category.name,
      parentCategoryName: parentName,
      parentCategoryGuid: node.category.parentCategoryGuid,
      pricebookCategoryGuid: node.category.pricebookCategoryGuid,
      sourcePhotoUrl: node.category.sourcePhotoUrl,
    })
    node.children.forEach(child => buildConcatenatedName(child, node.category.name, concatenatedName))
  }

  categoryTree.forEach(rootNode => buildConcatenatedName(rootNode))

  return bulkCategories
}

export const getLeafCategoryName = (concatenatedName?: string): string => {
  if (!concatenatedName) return ''
  const categories = concatenatedName.split('>').map(name => name.trim())
  return categories[categories.length - 1]
}

export const buildBulkPricebookCategoryMap = (categories: PricebookCategory[]): Record<Guid, BulkPricebookCategory> => {
  const bulkPricebookCategories = convertToBulkPricebookCategories(categories)

  return bulkPricebookCategories.reduce((map, category) => {
    map[category.pricebookCategoryGuid] = category
    return map
  }, {} as Record<Guid, BulkPricebookCategory>)
}

export const convertToBulkPricebookItemRows = (
  items: PricebookItem[],
  categories: PricebookCategory[],
  qboIncomeAccounts: QboIncomeAccount[] = [],
): BulkPricebookItemRow[] => {
  const categoryMap = buildBulkPricebookCategoryMap(categories)
  const qboIncomeAccountMap = getPricebookQboIncomeAccountMap(qboIncomeAccounts)
  return items.map(item => toBulkPricebookRow(item, categoryMap, qboIncomeAccountMap))
}

export const findRowsWithoutMatchingItems = (
  rows: BulkPricebookItemRow[],
  items: PricebookItem[] = [],
): BulkPricebookItemRow[] => {
  const itemGuidSet = new Set(items.map(item => item.pricebookItemGuid))
  return rows.filter(row => !itemGuidSet.has(row.pricebookItemGuid))
}

export const findDeletedItemsFromImport = (
  rows: BulkPricebookItemRow[],
  items: PricebookItem[] = [],
): PricebookItem[] => {
  const itemGuidSet = new Set(rows.map(row => row.pricebookItemGuid))
  return items.filter(item => !itemGuidSet.has(item.pricebookItemGuid))
}

export const findMissingQboLinks = (
  rows: BulkPricebookItemRow[],
  items: PricebookItem[],
): { pricebookItemGuid: Guid; qboIncomeAccountId: string }[] => {
  const rowQboLinksMap = rows.reduce((map, row) => {
    if (row.qboIncomeAccountId) {
      map[row.pricebookItemGuid] = row.qboIncomeAccountId
    }
    return map
  }, {} as Record<Guid, string | undefined>)

  return items
    .filter(
      item =>
        item.qboIncomeAccountId && // Ensure the item has a QBO link
        (!rowQboLinksMap[item.pricebookItemGuid] || // The QBO link is missing in rows
          rowQboLinksMap[item.pricebookItemGuid] !== item.qboIncomeAccountId), // Or the QBO link in rows is different
    )
    .map(item => ({
      pricebookItemGuid: item.pricebookItemGuid,
      // TS isn't smart enough to know that the QBO link is not undefined here
      qboIncomeAccountId: item.qboIncomeAccountId as string,
    }))
}

export const findItemsWithoutMatchingRows = (
  items: PricebookItem[] = [],
  rows: BulkPricebookItemRow[],
  categories: PricebookCategory[] = [],
  qboIncomeAccounts: QboIncomeAccount[] = [],
): BulkPricebookItemRow[] => {
  const itemGuidSet = new Set(rows.map(row => row.pricebookItemGuid))
  return convertToBulkPricebookItemRows(
    items.filter(item => !itemGuidSet.has(item.pricebookItemGuid)),
    categories,
    qboIncomeAccounts,
  )
}

export const findModifiedRows = (
  rows: BulkPricebookItemRow[],
  items: PricebookItem[] = [],
  categories: PricebookCategory[] = [],
  qboIncomeAccounts: QboIncomeAccount[] = [],
): ChangedBulkPricebookRow[] => {
  const bulkItems = convertToBulkPricebookItemRows(items, categories, qboIncomeAccounts)

  const bulkItemMap = bulkItems.reduce((map, bulkItem) => {
    map[bulkItem.pricebookItemGuid] = bulkItem
    return map
  }, {} as Record<string, BulkPricebookItemRow>)

  return rows.flatMap(row => {
    const originalBulkItem = bulkItemMap[row.pricebookItemGuid]
    if (!originalBulkItem) return [] // Skip if there's no original item to compare with

    const changes: ChangedType[] = []

    // Compare fields to check for modifications and record changes
    const fieldsToCompare: ComparableKeys[] = [
      'description',
      'category',
      'itemType',
      'costUsd',
      'priceUsd',
      'isTaxable',
      'isActive',
      'isDiscountable',
      'qboIncomeAccountName',
      'sourcePhotoUrl',
      'pricebookItemCode',
    ]

    fieldsToCompare.forEach(field => {
      if (row[field] !== originalBulkItem[field]) {
        changes.push({
          key: field,
          value: `${row[field]}`,
          oldValue: `${originalBulkItem[field]}`,
        })
      }
    })

    if (changes.length > 0) {
      return [{ ...row, changedFields: changes }]
    }

    return []
  })
}

const isChangedBulkPricebookRow = (
  row: BulkPricebookItemRow | ChangedBulkPricebookRow,
): row is ChangedBulkPricebookRow => {
  return !isNullish((row as ChangedBulkPricebookRow).changedFields)
}

export const getChangedRow = (row: BulkPricebookItemRow | ChangedBulkPricebookRow, key: string) => {
  if (!isChangedBulkPricebookRow(row)) return
  return row.changedFields?.find(cf => cf.key === key)
}

const toPricebookItem = (row: BulkPricebookItemRow, companyGuid: string): PricebookItem => {
  return {
    pricebookItemGuid: row.pricebookItemGuid,
    companyGuid,
    name: row.name,
    description: row.description ?? '',
    itemType: row.itemType,
    costUSD: row.costUsd,
    priceUSD: row.priceUsd,
    isTaxable: row.isTaxable ?? true,
    isActive: row.isActive ?? true,
    isDiscountable: row.isDiscountable ?? true,
    pricebookCategoryGuid: row.pricebookCategoryGuid,
    qboIncomeAccountId: row.qboIncomeAccountId,
    sourcePhotoUrl: row.sourcePhotoUrl,
    pricebookItemCode: row.pricebookItemCode,
  }
}

export const convertBulkPricebookRowsToPricebookItemRows = (
  rows: BulkPricebookItemRow[],
  companyGuid: string,
): PricebookItem[] => {
  return rows.map(row => toPricebookItem(row, companyGuid))
}

const toPricebookItemWithImportedPhoto = (
  row: EnrichedBulkPricebookItemRowWithPhotos,
  companyGuid: string,
): PricebookItem => {
  return {
    ...toPricebookItem(row, companyGuid),
    sourcePhotoGuid: row.sourcePhotoGuid,
    photoGuid: row.photoGuid,
  }
}

export const convertBulkPricebookRowsToPricebookItemRowsWithImportedPhotos = (
  rows: EnrichedBulkPricebookItemRowWithPhotos[],
  companyGuid: string,
): PricebookItem[] => {
  return rows.map(row => toPricebookItemWithImportedPhoto(row, companyGuid))
}

export type BulkPricebookImportWriterRequest = {
  companyGuid: Guid
  userGuid: Guid
  categories: PricebookCategory[]
  deletedCategories: PricebookCategory[]
  items: PricebookItem[]
  deletedItems: PricebookItem[]
  deletedQboLinks: {
    pricebookItemGuid: Guid
    qboIncomeAccountId: string
  }[]
}

export type BulkPricebookImportWriter = AsyncFn<BulkPricebookImportWriterRequest>

export const BulkPricebookImportRequestSchema = z.object({
  items: z.array(BulkPricebookItemRowV2Schema),
  categories: z.array(BulkPricebookCategoryV2Schema),
})

export type BulkPricebookImportRequest = z.infer<typeof BulkPricebookImportRequestSchema>

export type BulkPricebookImporter = AsyncFn<
  ForCompanyUser<BulkPricebookImportRequest & { pricebookVersionName?: string }>
>

export const BulkPricebookPhotoImportRequestSchema = z.object({
  bulkImportItems: z.array(BulkPricebookItemRowV2Schema),
  bulkImportCategories: z.array(BulkPricebookCategoryV2Schema),
})

export type BulkPricebookPhotoImportRequest = z.infer<typeof BulkPricebookPhotoImportRequestSchema>

const EnrichedBulkPricebookItemRowWithPhotosSchema = BulkPricebookItemRowV2Schema.extend({
  sourcePhotoGuid: bzOptional(guidSchema),
  photoGuid: bzOptional(guidSchema),
})

export type EnrichedBulkPricebookItemRowWithPhotos = z.infer<typeof EnrichedBulkPricebookItemRowWithPhotosSchema>

const EnrichedBulkPricebookCategoryWithPhotosSchema = BulkPricebookCategoryV2Schema.extend({
  sourcePhotoGuid: bzOptional(guidSchema),
  photoGuid: bzOptional(guidSchema),
})

export type EnrichedBulkPricebookCategoryWithPhotos = z.infer<typeof EnrichedBulkPricebookCategoryWithPhotosSchema>

export type BulkPricebookPhotoImporter = AsyncFn<
  ForCompanyUser<BulkPricebookPhotoImportRequest>,
  {
    enrichedItemsWithImportedPhotos: EnrichedBulkPricebookItemRowWithPhotos[]
    enrichedCategoriesWithImportedPhotos: EnrichedBulkPricebookCategoryWithPhotos[]
  }
>

const toPricebookCategoryWithImportedPhoto = (row: EnrichedBulkPricebookCategoryWithPhotos): PricebookCategory => {
  return {
    pricebookCategoryGuid: row.pricebookCategoryGuid,
    name: row.name,
    parentCategoryGuid: row.parentCategoryGuid,
    sourcePhotoGuid: row.sourcePhotoGuid,
    photoGuid: row.photoGuid,
  }
}
export const convertBulkPricebookCategoryRowsToPricebookCategoryRowsWithImportedPhotos = (
  rows: EnrichedBulkPricebookCategoryWithPhotos[],
): PricebookCategory[] => {
  return rows.map(row => toPricebookCategoryWithImportedPhoto(row))
}
