import { InteractiveQuestion } from '@metabase/embedding-sdk-react'
import { Space, Typography } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useCompanyGuid } from '../../providers/PrincipalUser'

const { Title } = Typography

export interface ExportQuestionProps {
  title?: string
  questionId?: string
}

export const ExportQuestion: React.FC<ExportQuestionProps> = ({
  title: propTitle,
  questionId: propQuestionId,
}) => {
  const { questionId: urlQuestionId } = useParams<{ questionId: string }>()
  const companyGuid = useCompanyGuid() || ''
  const resolvedQuestionId = propQuestionId || urlQuestionId

  if (!resolvedQuestionId) {
    return <div>No question ID provided</div>
  }

  return (
    <div className="overflow-hidden">
      <InteractiveQuestion
        questionId={resolvedQuestionId}
        initialSqlParameters={{
          company_guid: companyGuid,
        }}
        withDownloads={true}
        withChartTypeSelector={true}
      >
        <div className="flex items-center justify-between border-b border-bz-gray-200 p-4">
          <Title level={4} style={{ margin: 0 }}>
            {propTitle || 'Export Report'}
          </Title>

          <Space size="small">
            <InteractiveQuestion.ChartTypeDropdown />
            <InteractiveQuestion.QuestionSettingsDropdown />
            <InteractiveQuestion.DownloadWidgetDropdown />
          </Space>
        </div>

        <div className="p-4">
          <div className="overflow-x-auto">
            <InteractiveQuestion.QuestionVisualization />
          </div>
        </div>
      </InteractiveQuestion>
    </div>
  )
}
