import { isNullish } from '@breezy/shared'
import { Button } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import {
  OnsitePageCollapsibleMode,
  OnsitePageCollapsibleSection,
} from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import GqlQueryLoader from 'src/components/GqlQueryLoader/GqlQueryLoader'
import { InvoiceWidgetCard } from 'src/components/InvoicesWidget/InvoicesWidget'
import { InvoicesWidgetInvoice } from 'src/components/InvoicesWidget/InvoicesWidget.gql'
import { LoadingSpinner } from 'src/components/LoadingSpinner'
import { InvoicesBoolExp } from 'src/generated/user/graphql'
import useAppNavigation from 'src/hooks/useAppNav'
import { useInvoiceTemplates } from 'src/hooks/useInvoiceTemplates'
import { useIsOfficeApp } from 'src/hooks/useIsOfficeApp'
import { useSubscription } from 'urql'
import { INVOICE_COLLAPSIBLE_DATA_SUBSCRIPTION } from './InvoicesCollapsible.gql'
import InvoiceTemplatePicker from './InvoiceTemplatePicker'

export interface InvoicesV2CollapsibleV2Props {
  links: {
    accountGuid: string
    jobGuid?: string
    maintenancePlanGuid?: string
    maintenancePlanVisitLinkedJobGuids?: string[]
    locationGuid?: string
    jobAppointmentGuid?: string
    technicianAppStyle?: boolean
  }
  allowExternalCreate?: boolean
  readOnly?: boolean
  collapsibleStateId: string
}

export const InvoicesV2CollapsibleV2 = (
  props: InvoicesV2CollapsibleV2Props,
) => {
  const isOfficeApp = useIsOfficeApp()
  const { templates, fetching } = useInvoiceTemplates()
  const allowCreate =
    props.allowExternalCreate && !isNullish(props.links.jobGuid)
  const shouldShowTemplatePicker = isOfficeApp && templates.length > 0
  const [isCreatingInvoice, setIsCreatingInvoice] = useState(false)

  const whereClause = useMemo(() => {
    const whereClause: InvoicesBoolExp = {
      accountGuid: { _eq: props.links.accountGuid },
    }
    if (props.links.jobGuid) {
      whereClause.jobLink = { jobGuid: { _eq: props.links.jobGuid } }
    }
    if (props.links.maintenancePlanGuid) {
      const maintenancePlanPredicates: InvoicesBoolExp[] = [
        {
          maintenancePlanLink: {
            maintenancePlanGuid: { _eq: props.links.maintenancePlanGuid },
          },
        },
      ]

      if (props.links.maintenancePlanVisitLinkedJobGuids?.length) {
        maintenancePlanPredicates.push({
          jobLink: {
            jobGuid: { _in: props.links.maintenancePlanVisitLinkedJobGuids },
          },
        })
      }
      whereClause._or = maintenancePlanPredicates
    }

    if (props.links.locationGuid) {
      whereClause.locationLink = {
        locationGuid: { _eq: props.links.locationGuid },
      }
    }
    if (props.links.jobAppointmentGuid) {
      whereClause.jobAppointmentLink = {
        jobAppointmentGuid: { _eq: props.links.jobAppointmentGuid },
      }
    }

    return whereClause
  }, [
    props.links.accountGuid,
    props.links.jobAppointmentGuid,
    props.links.jobGuid,
    props.links.locationGuid,
    props.links.maintenancePlanGuid,
    props.links.maintenancePlanVisitLinkedJobGuids,
  ])

  const invoicesQuery = useSubscription({
    query: INVOICE_COLLAPSIBLE_DATA_SUBSCRIPTION,
    variables: {
      where: whereClause,
    },
  })

  const invoices = useMemo<InvoicesWidgetInvoice[]>(() => {
    if (!invoicesQuery[0].data) {
      return []
    }

    const invoices = props.links.jobGuid
      ? invoicesQuery[0].data.invoices.filter(
          invoice => invoice.jobLink?.jobGuid === props.links.jobGuid,
        )
      : invoicesQuery[0].data.invoices

    return invoices.map(invoice => ({
      invoiceGuid: invoice.invoiceGuid,
      displayId: invoice.displayId,
      displayIdV2: invoice.displayIdV2,
      issuedAt: invoice.issuedAt,
      cartItems: invoice.cartItems,
      createdByUser: invoice.createdByUser,
      discounts: invoice.discounts,
      status: invoice.status,
      totalUsc: invoice.totalUsc,
      jobLink: invoice.jobLink,
      maintenancePlanLink: invoice.maintenancePlanLink,
      invoicePayments: invoice.invoicePayments,
    }))
  }, [invoicesQuery, props.links.jobGuid])

  const appNav = useAppNavigation()

  const onCreateInvoice = useCallback(() => {
    if (!allowCreate || !props.links.jobGuid) {
      return
    }

    if (shouldShowTemplatePicker) {
      setIsCreatingInvoice(true)
    } else {
      appNav.navigateToCreateNewInvoiceV2(
        props.links.accountGuid,
        props.links.jobGuid,
        {
          jobAppointmentGuid: props.links.jobAppointmentGuid,
        },
      )
    }
  }, [
    shouldShowTemplatePicker,
    allowCreate,
    appNav,
    props.links.accountGuid,
    props.links.jobAppointmentGuid,
    props.links.jobGuid,
  ])

  return (
    <>
      <GqlQueryLoader
        query={invoicesQuery}
        render={() =>
          fetching ? (
            <LoadingSpinner />
          ) : (
            <OnsitePageCollapsibleSection
              smallTitle
              title="Invoices"
              count={invoices.length}
              defaultCollapsed={invoices.length === 0}
              onAdd={allowCreate ? onCreateInvoice : undefined}
              testId="invoices-collapsible"
              addButtonTestId="invoices-collapsible-add-button"
              collapsibleStateId={props.collapsibleStateId}
            >
              <div className="flex flex-col gap-3">
                {invoices.map(invoice => (
                  <InvoiceWidgetCard
                    key={invoice.invoiceGuid}
                    readonly={props.readOnly}
                    small
                    {...invoice}
                  />
                ))}
              </div>
            </OnsitePageCollapsibleSection>
          )
        }
        loadingComponent={
          <OnsitePageCollapsibleSection
            title="Invoices"
            smallTitle
            collapsibleStateId={props.collapsibleStateId}
          >
            <LoadingSpinner />
          </OnsitePageCollapsibleSection>
        }
        errorComponent={
          <OnsitePageCollapsibleSection
            title="Invoices"
            smallTitle
            collapsibleStateId={props.collapsibleStateId}
          >
            <span>Failed to load invoices</span>
          </OnsitePageCollapsibleSection>
        }
      />
      {props.links.jobGuid && isCreatingInvoice && (
        <InvoiceTemplatePicker
          onCancel={() => setIsCreatingInvoice(false)}
          accountGuid={props.links.accountGuid}
          jobGuid={props.links.jobGuid}
          templates={templates}
          jobAppointmentGuid={props.links.jobAppointmentGuid}
        />
      )}
    </>
  )
}

export type InvoiceCollapsibleProps = {
  invoices: InvoicesWidgetInvoice[]
  total?: number
  mode?: OnsitePageCollapsibleMode
  allowCreate?: boolean
  onCreateInvoice?: () => void
  readOnly?: boolean
  collapsibleStateId: string
} & (
  | {
      mode?: Exclude<OnsitePageCollapsibleMode, 'paginated'>
      total?: never
      onViewMore?: never
    }
  | {
      mode: 'paginated'
      total: number
      onViewMore: () => void
    }
)

export const SimpleInvoiceV2CollapsibleV2 = ({
  mode = 'default',
  invoices,
  allowCreate = false,
  onCreateInvoice,
  total,
  onViewMore,
  collapsibleStateId,
}: InvoiceCollapsibleProps) => {
  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title="Invoices"
      count={total ?? invoices.length}
      defaultCollapsed={invoices.length === 0}
      onAdd={allowCreate ? onCreateInvoice : undefined}
      testId="invoices-collapsible"
      addButtonTestId="invoices-collapsible-add-button"
      collapsibleStateId={collapsibleStateId}
    >
      <div className="flex flex-col gap-3">
        {invoices.map(invoice => (
          <InvoiceWidgetCard
            key={invoice.invoiceGuid}
            small
            readonly
            {...invoice}
          />
        ))}
        {mode === 'paginated' &&
          onViewMore &&
          total &&
          total > invoices.length && (
            <Button className="w-fit" type="link" onClick={onViewMore}>
              View {total - invoices.length} more invoices
            </Button>
          )}
      </div>
    </OnsitePageCollapsibleSection>
  )
}
