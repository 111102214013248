import { gql } from '../../generated'

export const DOWNLOADABLE_INVOICE_DATA = gql(/* GraphQL */ `
  query GetDownloadableInvoiceData($companyGuid: uuid!, $jobGuid: uuid!) {
    billingProfilesByPk(companyGuid: $companyGuid) {
      defaultTaxRate {
        pricebookTaxRateGuid
        rate
        name
      }
      estimateDisclaimer
      invoiceTerm
      logoUrl
      logoPhoto {
        cdnUrl
      }
      invoiceMemo
      businessAddress {
        city
        line1
        line2
        zipCode
        stateAbbreviation
      }
      businessFullLegalName
      contractorLicenseNumber
      emailAddress {
        emailAddress
      }
      phoneNumber {
        phoneNumber
      }
      websiteDisplayName
    }
    companyConfigByPk(companyGuid: $companyGuid) {
      blurb
    }
    jobsByPk(jobGuid: $jobGuid) {
      location {
        address {
          city
          line1
          line2
          stateAbbreviation
          zipCode
          geoLocation
        }
      }
      pointOfContact {
        fullName
      }
      account {
        mailingAddress {
          line2
          line1
          city
          zipCode
          stateAbbreviation
        }
        primaryContact: accountContacts(
          where: { primary: { _eq: true } }
          limit: 1
        ) {
          contact {
            fullName
          }
        }
        firstLocation: accountLocations(limit: 1) {
          location {
            address {
              line1
              line2
              city
              stateAbbreviation
              zipCode
              geoLocation
            }
          }
        }
      }
    }
  }
`)
