import { R } from '@breezy/shared'
import React from 'react'
import { useQuery } from 'urql'
import CreateNewAccountForm from '../../components/ProgressiveJobCreationModal/CreateOrEditAccountForm/CreateOrEditAccountForm'
import BzDrawer from '../../elements/BzDrawer/BzDrawer'
import { TAGS_MINIMAL_FOR_COMPANY_QUERY } from '../../gql/queries/Tags.gql'
import { useMessage } from '../../utils/antd-utils'
import { AccountIcon } from '../../utils/feature-icons'
import { AccountMetadata } from './accountDetailsV2Utils'

type EditAccountDrawerProps = {
  meta: AccountMetadata
  refetch: () => void
  onClose: (didUpdateAccount: boolean) => void
  open: boolean
}

export const EditAccountDrawer = React.memo<EditAccountDrawerProps>(
  ({ meta, onClose, refetch, open }) => {
    const {
      companyGuid,
      accountGuid,
      accountType,
      accountDisplayName,
      accountManagerUserGuid,
      accountLeadSource,
      tags,
      doNotService,
      doNotServiceReason,
      defaultInvoiceTerm,
    } = meta
    const message = useMessage()
    const [tagsQuery] = useQuery({
      query: TAGS_MINIMAL_FOR_COMPANY_QUERY,
      variables: { companyGuid },
    })

    return (
      <BzDrawer
        title="Edit Account"
        icon={AccountIcon}
        preferredWidth={720}
        item={open ? { onCancel: () => onClose(false) } : undefined}
        destroyOnClose
      >
        <CreateNewAccountForm
          mode="master-edit"
          onCancel={() => onClose(false)}
          onFinish={() => {
            onClose(true)
            refetch()
            message.success('Successfully updated Account')
          }}
          accountGuid={accountGuid}
          companyGuid={companyGuid}
          accountTags={tagsQuery.data?.tags ?? []}
          initialValues={{
            accountType,
            accountDisplayName,
            accountManagerUserGuid,
            leadSourceGuid: accountLeadSource[0]?.companyLeadSourceGuid,
            leadSourceReferringContactGuid:
              accountLeadSource[0]?.referringContactGuid,
            leadSourceAttributionDescription:
              accountLeadSource[0]?.attributionDescription,
            accountTagGuids: R.pluck('tagGuid', tags),
            doNotService,
            doNotServiceReason,
            defaultInvoiceTerm,
          }}
        />
      </BzDrawer>
    )
  },
)
