import { CalculatePaths } from '@breezy/shared'
import { faCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider } from 'antd'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { OnsiteBasicModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import { EstimateTemplateMetadata } from '../../../hooks/useEstimateTemplates'

type EstimateTemplatePickerProps = {
  jobGuid: string
  jobAppointmentGuid?: string
  templates: EstimateTemplateMetadata[]
  onCancel: () => void
}

export const EstimateTemplatePicker = ({
  jobGuid,
  templates,
  jobAppointmentGuid,
  onCancel,
}: EstimateTemplatePickerProps) => {
  const navigate = useNavigate()
  const templatesPlusBlank = [
    { estimateTemplateGuid: 'blank', templateName: 'Create Blank Estimate' },
    ...templates,
  ]

  const createEstimate = useCallback(
    (estimateTemplateGuid: string | 'blank') =>
      navigate(
        CalculatePaths.newEstimate({
          jobGuid,
          jobAppointmentGuid,
          estimateTemplateGuid:
            estimateTemplateGuid === 'blank' ? undefined : estimateTemplateGuid,
        }),
      ),
    [navigate, jobGuid, jobAppointmentGuid],
  )

  return (
    <OnsiteBasicModal open onClose={onCancel} size="default">
      <h2 className="mb-4 text-[30px] font-semibold leading-[38px] text-[#1F1F1F]">
        Select estimate template
      </h2>
      <Divider className="m-0" />
      <div className="flex flex-col gap-0">
        {templatesPlusBlank.map(template => (
          <>
            <Button
              className="mb-0 flex min-h-[64px] w-full items-center justify-between rounded-none border-none bg-[#ffffff] px-4 py-0 shadow-none hover:bg-[#F5F5F5]"
              key={template.estimateTemplateGuid}
              onClick={() => createEstimate(template.estimateTemplateGuid)}
            >
              <div className="min-h-[24px] text-base font-semibold leading-6 text-[#1F1F1F]">
                {template.templateName}
              </div>
              <FontAwesomeIcon
                icon={faCircle}
                className="text-[24px] text-[#818181] hover:text-[#166cff]"
              />
            </Button>
            <Divider className="m-0" />
          </>
        ))}
      </div>
    </OnsiteBasicModal>
  )
}
