import { buildUrl, bzExpect } from '@breezy/shared'

type BreezyApplicationTarget =
  | 'office-experience'
  | 'technician-experience'
  | 'account-experience'

const calculateApiUrl = () => {
  return buildUrl({
    scheme: bzExpect(
      process.env.VITE_API__URL__SCHEME,
      'API__URL__SCHEME',
      'Missing Config Value',
    ),
    host: bzExpect(
      process.env.VITE_API__URL__HOST,
      'API__URL__HOST',
      'Missing Config Value',
    ),
    path: process.env.VITE_API__URL__PATH,
    port: process.env.VITE_API__URL__PORT,
  })
}

const calculateAppUrl = () => {
  return buildUrl({
    scheme: bzExpect(
      process.env.VITE_APP__URL__SCHEME,
      'APP__URL__SCHEME',
      'Missing Config Value',
    ),
    host: bzExpect(
      process.env.VITE_APP__URL__HOST,
      'APP__URL__HOST',
      'Missing Config Value',
    ),
    port: process.env.VITE_APP__URL__PORT,
  })
}

export const getConfig = () => {
  const apiUrl = calculateApiUrl()
  const appUrl = calculateAppUrl()
  const auth0Domain = process.env.VITE_AUTH__AUTH0__DOMAIN
  const auth0Audience = process.env.VITE_AUTH__AUTH0__APPLICATION__AUDIENCE
  const auth0ClientId = process.env.VITE_AUTH__AUTH0__APPLICATION__CLIENT_ID
  const twilioFromNumber = process.env.VITE_TWILIO_FROM_NUMBER

  const env = bzExpect(process.env.VITE_ENV)
  const rumClientToken = bzExpect(process.env.VITE_APP__RUM__CLIENT_TOKEN)
  const rumApplicationId = bzExpect(process.env.VITE_APP__RUM__APPLICATION_ID)
  const rumService = bzExpect(process.env.VITE_APP__RUM__SERVICE)
  const rumEnabled =
    bzExpect(process.env.VITE_APP__RUM__ENABLED).toString() === 'true'

  const breezyApplicationTargetName = bzExpect(
    process.env.VITE_BREEZY_APPLICATION_TARGET_NAME,
    'AUTH__AUTH0__APPLICATION__CLIENT_ID',
    'Missing Config Value',
  ) as BreezyApplicationTarget

  const tilled = {
    publicKey: bzExpect(
      process.env.VITE_PAYMENTS__TILLED__PUBLIC_KEY,
      'PAYMENTS__TILLED__PUBLIC_KEY',
      'Missing Config Value',
    ),
    useSandbox:
      bzExpect(
        process.env.VITE_PAYMENTS__TILLED__USE_SANDBOX,
        'PAYMENTS__TILLED__USE_SANDBOX',
        'Missing Config Value',
      ).toString() === 'true',
  }

  const platformVersion = bzExpect(
    process.env.VITE_PLATFORM_VERSION,
    'PLATFORM_VERSION',
    'Missing Config Value',
  ) as string

  const appVersion = bzExpect(
    process.env.VITE_APP_VERSION,
    'APP_VERSION',
    'Missing Config Value',
  ) as string

  const latestBreakingVersion = bzExpect(
    process.env.VITE_LATEST_BREAKING_VERSION,
    'LATEST_BREAKING_VERSION',
    'Missing Config Value',
  ) as string

  const shouldCheckPlatformVersion =
    (
      bzExpect(
        process.env.VITE_SHOULD_CHECK_PLATFORM_VERSION,
        'SHOULD_CHECK_PLATFORM_VERSION',
        'Missing Config Value',
      ) as string
    ).toString() === 'true'

  const intercomEnabled =
    (
      bzExpect(
        process.env.VITE_ANALYTICS__INTERCOM__ENABLED,
        'ANALYTICS__INTERCOM__ENABLED',
        'Missing Config Value',
      ) as string
    ).toString() === 'true'

  const helpCenterUrl = bzExpect(
    process.env.VITE_HELP_CENTER__URL,
    'HELP_CENTER__URL',
    'Missing Config Value',
  ) as string

  const notificationPollingEnabled =
    (
      bzExpect(
        process.env.VITE_NOTIFICATION__POLLING__ENABLED,
        'NOTIFICATION__POLLING__ENABLED',
        'Missing Config Value',
      ) as string
    ).toString() === 'true'

  const officeExperienceUrl = bzExpect(
    process.env.VITE_OFFICE_EXPERIENCE__URL,
    'OFFICE_EXPERIENCE__URL',
    'Missing Config Value',
  ) as string

  const technicianExperienceUrl = bzExpect(
    process.env.VITE_TECHNICIAN_EXPERIENCE__URL,
    'TECHNICIAN_EXPERIENCE__URL',
    'Missing Config Value',
  ) as string

  const launchDarklyClientId = bzExpect(
    process.env.VITE_LAUNCH__DARKLY__CLIENT__ID,
    'LAUNCH__DARKLY__CLIENT__ID',
    'Missing Config Value',
  ) as string

  const launchDarklyTargetingEnabled = bzExpect(
    process.env.VITE_LAUNCH__DARKLY__TARGETING__ENABLED,
    'VITE_LAUNCH__DARKLY__TARGETING__ENABLED',
    'Missing Config Value',
  ) as unknown as boolean

  const algoliaAppId = bzExpect(
    process.env.VITE_ALGOLIA__APP__ID,
    'ALGOLIA_APP_ID',
    'Missing Algolia App ID',
  ) as string

  const algoliaReadApiKey = bzExpect(
    process.env.VITE_ALGOLIA__READ__API__KEY,
    'ALGOLIA_READ_API_KEY',
    'Missing Algolia Read API Key',
  ) as string

  const algoliaIndexName = bzExpect(
    process.env.VITE_ALGOLIA__INDEX__NAME,
    'ALGOLIA_INDEX_NAME',
    'Missing Algolia Index Name',
  ) as string

  const mapsAPIKey = bzExpect(
    process.env.VITE_GCP_MAPS_API_KEY,
    'GCP_MAPS_API_KEY',
    'Missing Google Maps API key',
  ) as string

  const querierApiUrl = bzExpect(process.env.VITE_QUERIER_ENDPOINT) as string
  const querierWsApiUrl = bzExpect(
    process.env.VITE_QUERIER_WS_ENDPOINT,
  ) as string

  const accountAppShortUrl = bzExpect(
    process.env.VITE_ACCOUNT__APP__SHORT__URL,
    'ACCOUNT__APP__SHORT__URL',
    'Missing Account App Short URL',
  ) as string

  const accountAppShortUrlCode = bzExpect(
    process.env.VITE_ACCOUNT__APP__SHORT__URL__CODE,
    'ACCOUNT__APP__SHORT__URL__CODE',
    'Missing Account App Short URL Code',
  ) as string

  const accountAppFullUrl = bzExpect(
    process.env.VITE_ACCOUNT__APP__FULL__URL__CODE,
    'ACCOUNT__APP__FULL__URL__CODE',
    'Missing Account App Full URL',
  ) as string

  const flatfileEnvironmentId = bzExpect(
    process.env.VITE_FLATFILE_ENVIRONMENT_ID,
    'FLATFILE_ENVIRONMENT_ID',
    'Missing Flatfile Environment ID',
  ) as string

  const flatfilePublishableKey = bzExpect(
    process.env.VITE_FLATFILE_PUBLISHABLE_KEY,
    'FLATFILE_PUBLISHABLE_KEY',
    'Missing Flatfile Publishable Key',
  ) as string

  const weatherApiKey = bzExpect(
    process.env.VITE_WEATHER_API_KEY,
    'WEATHER_API_KEY',
    'Missing Weather API Key',
  ) as string

  const weatherApiBaseUrl = bzExpect(
    process.env.VITE_WEATHER_API_BASE_URL,
    'WEATHER_API_BASE_URL',
    'Missing Weather API Base URL',
  ) as string

  const imageResizerBaseUrl = bzExpect(
    process.env.VITE_IMAGE_RESIZER_BASE_URL,
    'IMAGE_RESIZER_BASE_URL',
    'Missing Image Resizer Base URL',
  ) as string

  const metabaseInstanceUrl = bzExpect(
    process.env.VITE_METABASE_INSTANCE_URL,
    'METABASE_INSTANCE_URL',
    'Missing Metabase Instance URL',
  ) as string

  const metabaseJwtSSOEndpoint = bzExpect(
    process.env.VITE_METABASE_JWT_SSO_ENDPOINT,
    'METABASE_JWT_SSO_ENDPOINT',
    'Missing Metabase JWT SSO Endpoint',
  ) as string

  const metabaseJwtSSOUrl = `${calculateApiUrl()}${metabaseJwtSSOEndpoint}`

  return {
    apiUrl,
    appUrl,
    auth0Domain,
    auth0ClientId,
    auth0Audience,
    rumApplicationId,
    rumClientToken,
    rumService,
    rumEnabled,
    env,
    breezyApplicationTargetName,
    tilled,
    platformVersion,
    appVersion,
    shouldCheckPlatformVersion,
    latestBreakingVersion,
    intercomEnabled,
    helpCenterUrl,
    featureFlags: {
      notificationPollingEnabled,
    },
    officeExperienceUrl,
    technicianExperienceUrl,
    launchDarklyClientId,
    launchDarklyTargetingEnabled,
    algoliaAppId,
    algoliaReadApiKey,
    algoliaIndexName,
    mapsAPIKey,
    querierApiUrl,
    querierWsApiUrl,
    accountAppShortUrl,
    accountAppShortUrlCode,
    accountAppFullUrl,
    flatfileEnvironmentId,
    flatfilePublishableKey,
    weatherApiKey,
    weatherApiBaseUrl,
    imageResizerBaseUrl,
    twilioFromNumber,
    metabaseInstanceUrl,
    metabaseJwtSSOUrl,
  } as const
}

export type Config = ReturnType<typeof getConfig>
export type AppFeatureFlags = Config['featureFlags']
export type AppFeatureFlagKey = keyof AppFeatureFlags
