import { gql } from 'src/generated'

export const JOB_TYPE_ARCHIVE_MUTATION = gql(/* GraphQL */ `
  mutation JobTypeSettingsListPageJobTypeArchiveMutation(
    $companyGuid: uuid!
    $jobTypeGuid: uuid!
    $archivedAt: timestamptz!
  ) {
    updateJobTypes(
      where: {
        companyGuid: { _eq: $companyGuid }
        jobTypeGuid: { _eq: $jobTypeGuid }
      }
      _set: { archivedAt: $archivedAt, updatedAt: $archivedAt }
    ) {
      affectedRows
    }
  }
`)

export const JOB_TYPE_UNARCHIVE_MUTATION = gql(/* GraphQL */ `
  mutation JobTypeSettingsListPageJobTypeUnarchiveMutation(
    $companyGuid: uuid!
    $jobTypeGuid: uuid!
    $unarchivedAt: timestamptz!
  ) {
    updateJobTypes(
      where: {
        companyGuid: { _eq: $companyGuid }
        jobTypeGuid: { _eq: $jobTypeGuid }
      }
      _set: { archivedAt: null, updatedAt: $unarchivedAt }
    ) {
      affectedRows
    }
  }
`)

export const JOB_TYPE_SETTINGS_PAGE_DATA = gql(/* GraphQL */ `
  query FetchJobTypesSettingsJobType {
    jobTypes {
      name
      description
      defaultSummary
      companyGuid
      lastModifiedBy
      jobTypeGuid
      jobLifecycleGuid
      jobLifecycle {
        name
        jobLifecycleGuid
      }
      jobClass
      createdAt
      updatedAt
      isOpportunity
      opportunityConversionThresholdUsc
      isPotentialHotLead
      hotLeadMinimumEquipmentAgeYears
      archivedAt
      durationMinutes
      jobsAggregate(
        where: { jobLifecycleStatus: { stage: { _neq: "Closed" } } }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`)
