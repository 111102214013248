import { search as fuzzySearch } from 'fast-fuzzy'
import { z } from 'zod'
import { AsyncFn, IsoDateString, ZonedDateTime, normalizeString } from '../../common'
import { guidSchema, localDateSchema } from '../../contracts'
import { AccountContact } from '../AccountContacts/AccountContact'
import { AccountLocation } from '../AccountLocations/AccountLocation'
import { Address, AddressDtoSchema, AddressGuid } from '../Address/Address'
import { ComprehensiveAppointmentDetails } from '../Appointments/Appointments'
import { CompanyGuid, ForCompany, ForCompanyUser } from '../Company/Company'
import { EmailAddress, EmailAddressGuid, optionalEmailAddressValueSchema } from '../Email/EmailTypes'
import { InvoiceTermV2, InvoiceV2Status, InvoiceV2TermSchema } from '../InvoicesV2/InvoicesV2'
import { InstallProjectType, JobDisplayId, JobGuid } from '../Job'
import { JobLifecycleStatus } from '../JobLifecycles/JobLifecycles'
import { JobType } from '../JobTypes/JobTypes'
import { LocationCommon, LocationGuid } from '../Locations/Location'
import { MaintenancePlanMinimalInfo } from '../MaintenancePlans/MaintenancePlanTypes'
import { NotificationPreferenceType } from '../NotificationPreferenceTypes'
import {
  PhoneNumber,
  PhoneNumberGuid,
  PhoneNumberTypeSchema,
  optionalTelephoneNumberSchema,
} from '../PhoneNumbers/Phone'
import { AccountSearchableRecord, SearchableEntityCreate, createAccountSearchableRecordId } from '../Search'
import { Tag } from '../Tags'
import { MinimalUser } from '../Users/User'
import { Guid, bzOptional, firstNameSchema, lastNameSchema } from '../common-schemas'
import { Contact, ContactGuid } from '../contacts/Contact'
import { AccountType } from './AccountType'

export type AccountGuid = Guid
export type AccountGuidContainer = { accountGuid: AccountGuid }

export const AccountGuidContainerSchema = z.object({
  accountGuid: guidSchema,
})

type AccountJobInvoice = {
  invoiceGuid: Guid
  totalUsc: number
  status: InvoiceV2Status
}

export type AccountJob = {
  jobGuid: JobGuid
  displayId: JobDisplayId
  jobCreatedAt: ZonedDateTime
  jobType: JobType
  installProjectType?: InstallProjectType
  jobLifecycleStatus: JobLifecycleStatus
  pointOfContact: AccountContact
  serviceLocation: AccountLocation
  appointments: ComprehensiveAppointmentDetails[]
  tags?: Tag[]
  jobLifecycleStatusUpdatedAt?: IsoDateString
  jobInvoices: AccountJobInvoice[]
  workCompletedAt?: IsoDateString
}

export type BasicAccount = {
  accountGuid: AccountGuid
  companyGuid: CompanyGuid
  displayName: string
  referenceNumber: string
  type: AccountType
  archived?: boolean
}

export type Account = BasicAccount & {
  accountContacts: AccountContact[]
  accountLocations: AccountLocation[]
  maintenancePlans: MaintenancePlanMinimalInfo[]
  mailingAddress?: Address
  accountNote?: string
  accountCreatedAt: ZonedDateTime
  accountManager?: MinimalUser
  accountTags?: Tag[]
  doNotService?: boolean
  doNotServiceReason?: string
  defaultInvoiceTerm?: InvoiceTermV2
}

export const getPrimaryAccountContact = (account: Account): AccountContact | undefined => {
  return account.accountContacts.find(ac => ac.primary)
}

export const getPrimaryAccountContactFullName = (account: Account): string | undefined => {
  const primaryAccountContact = getPrimaryAccountContact(account)
  if (!primaryAccountContact) return undefined

  return primaryAccountContact.contact.firstName + ' ' + primaryAccountContact.contact.lastName
}

type MinimalSearchableAccount = {
  companyGuid: CompanyGuid
  accountGuid: AccountGuid
  displayName: string
  type: AccountType
  archived?: boolean | undefined
  accountContacts: {
    primary: boolean
    contact: {
      contactGuid: ContactGuid
      firstName: string
      lastName: string
      primaryEmailAddress?: {
        emailAddress: string
      }
      primaryPhoneNumber?: {
        phoneNumber: string
      }
    }
  }[]
  accountLocations: AccountLocation[]
  accountTags?:
    | {
        name: string
      }[]
    | undefined
}

export const createAccountSearchableRecord: SearchableEntityCreate<MinimalSearchableAccount> = async (
  account: MinimalSearchableAccount,
) => {
  const contacts: AccountSearchableRecord['contacts'][number][] = []

  for (const contact of account.accountContacts) {
    contacts.push({
      name: `${contact.contact.firstName} ${contact.contact.lastName}`,
      emailAddress: contact.contact.primaryEmailAddress?.emailAddress,
      phoneNumber: contact.contact.primaryPhoneNumber?.phoneNumber,
    })
  }

  const locations: AccountSearchableRecord['locations'] = []
  for (const location of account.accountLocations) {
    locations.push({
      locationGuid: location.location.locationGuid,
      locationDisplayName: location.location.displayName,
      streetAddress: location.location.address.line1,
      city: location.location.address.city,
      state: location.location.address.stateAbbreviation,
      zipCode: location.location.address.zipCode,
    })
  }

  const primaryContact = account.accountContacts.find(ac => ac.primary)

  const recordId = createAccountSearchableRecordId(account.accountGuid)
  return {
    recordId,
    record: {
      objectID: recordId,
      companyGuid: account.companyGuid,
      recordType: 'ACCOUNT',
      accountDisplayName: account.displayName,
      accountType: account.type,
      accountGuid: account.accountGuid,
      archived: account.archived,

      primaryEmailAddress: primaryContact?.contact.primaryEmailAddress?.emailAddress,
      primaryPhoneNumber: primaryContact?.contact.primaryPhoneNumber?.phoneNumber,
      contacts,
      locations,
      tags: account.accountTags?.map(tag => tag.name) ?? [],
    },
  }
}

export const AccountSearchableRecordResyncRecordRequestSchema = z.object({
  companyGuid: guidSchema,
  accountGuid: bzOptional(guidSchema),
})

export type AccountSearchableRecordResyncRecordRequest = z.infer<
  typeof AccountSearchableRecordResyncRecordRequestSchema
>

export type AccountSearchableRecordResyncRecordsWriter = AsyncFn<ForCompany<AccountSearchableRecordResyncRecordRequest>>

export const CreateNewAccountDTOSchema = z.object({
  accountInfo: z.object({
    accountGuid: guidSchema,
    accountType: z.nativeEnum(AccountType),
    accountDisplayName: bzOptional(z.string()),
    accountManagerUserGuid: bzOptional(guidSchema),
    doNotService: bzOptional(z.boolean()),
    archived: bzOptional(z.boolean()),
  }),
  contactInfo: z.object({
    firstName: firstNameSchema,
    lastName: lastNameSchema,
    emailAddress: optionalEmailAddressValueSchema,
    phoneNumber: optionalTelephoneNumberSchema,
    phoneNumberType: PhoneNumberTypeSchema,
    notificationPreference: z.nativeEnum(NotificationPreferenceType),
  }),
  serviceLocationInfo: z.object({
    address: AddressDtoSchema,
    municipality: bzOptional(z.string()),
    estimatedSquareFootage: bzOptional(z.number().positive()),
    estimatedBuildDate: bzOptional(localDateSchema),
    propertyType: bzOptional(z.string()),
  }),
  accountCreatedAt: bzOptional(z.string()),
  referenceNumber: bzOptional(z.string()),
  skipAddressCheck: bzOptional(z.boolean()),
  companyLeadSource: bzOptional(
    z.object({
      leadSourceGuid: bzOptional(guidSchema),
      leadSourceReferringContactGuid: bzOptional(guidSchema),
      leadSourceAttributionDescription: bzOptional(z.string()),
    }),
  ),
  accountTags: bzOptional(
    z
      .object({
        accountGuid: guidSchema,
        tagGuid: guidSchema,
      })
      .array(),
  ),
  defaultInvoiceTerm: bzOptional(InvoiceV2TermSchema),
})

const CreateNewAccountFromExistingContactAndLocationDTOSchema = CreateNewAccountDTOSchema.omit({
  contactInfo: true,
  serviceLocationInfo: true,
}).extend({
  contactGuid: guidSchema,
  locationGuid: guidSchema,
})

export type CreateNewAccountDTO = z.infer<typeof CreateNewAccountDTOSchema>
export type CreateNewAccountFromExistingContactAndLocationDTO = z.infer<
  typeof CreateNewAccountFromExistingContactAndLocationDTOSchema
>

export type CreateNewAccountResult = {
  accountGuid: AccountGuid
  locationGuid: LocationGuid
  accountContactGuid: AccountGuid
  contactGuid: ContactGuid
  phoneNumberGuid: PhoneNumberGuid
  emailAddressGuid: EmailAddressGuid
  referenceNumber: string
  addressGuid?: AddressGuid
}

export type NewAccountCreator = AsyncFn<
  ForCompanyUser<CreateNewAccountDTO | CreateNewAccountFromExistingContactAndLocationDTO>,
  CreateNewAccountResult
>

export type AccountDetailsLinkReader = (a: AccountGuid) => string

export const tryMatchContactByPhoneNumber = (contacts: Contact[], searchTerm: string): ContactGuid | undefined => {
  const phoneNumbers = contacts.flatMap(contact =>
    [
      {
        ...contact.primaryPhoneNumber,
        contactGuid: contact.contactGuid,
      },
      {
        ...contact.additionalPhoneNumber,
        contactGuid: contact.contactGuid,
      },
    ].filter(obj => Boolean(obj.phoneNumber)),
  ) as (PhoneNumber & { contactGuid: Guid })[]

  const contactSearchResults = fuzzySearch(searchTerm, phoneNumbers, {
    keySelector: phoneNumber => phoneNumber.phoneNumber,
  })

  return contactSearchResults[0]?.contactGuid
}

export const tryMatchContactByEmailAddress = (contacts: Contact[], searchTerm: string): ContactGuid | undefined => {
  const emailRecords = contacts.flatMap(contact =>
    [
      {
        ...contact.primaryEmailAddress,
        contactGuid: contact.contactGuid,
      },
      {
        ...contact.additionalEmailAddress,
        contactGuid: contact.contactGuid,
      },
    ].filter(obj => Boolean(obj.emailAddress)),
  ) as (EmailAddress & { contactGuid: Guid })[]

  const contactSearchResults = fuzzySearch(searchTerm, emailRecords, {
    keySelector: emailRecord => emailRecord.emailAddress,
  })

  return contactSearchResults[0]?.contactGuid
}

export const tryMatchContactByName = (
  contacts: Contact[],
  firstName: string | undefined,
  lastName: string | undefined,
): ContactGuid | undefined => {
  const normalizedFirstName = firstName ? normalizeString(firstName) : ''
  const normalizedLastName = lastName ? normalizeString(lastName) : ''
  const normalizedName = [normalizedFirstName, normalizedLastName].filter(Boolean).join('|')

  if (!normalizedName) return undefined

  const contactSearchResults = fuzzySearch(normalizedName, contacts, {
    keySelector: contact =>
      [contact.firstName, contact.lastName].filter(Boolean).map(normalizeString).filter(Boolean).join('|'),
  })

  return contactSearchResults[0]?.contactGuid
}

export const tryMatchLocationByAddress = (
  locations: LocationCommon[],
  address: Partial<Address>,
): LocationGuid | undefined => {
  const normalizedSearchAddress = [address.line1, address.line2, address.zipCode]
    .filter(Boolean)
    .map(s => normalizeString(s as string))
    .join('|')

  const locationSearchResults = fuzzySearch(normalizedSearchAddress, locations, {
    keySelector: loc => {
      return [loc.address.line1, loc.address.line2, loc.address.zipCode]
        .filter(Boolean)
        .map(s => normalizeString(s as string))
        .join('|')
    },
  })

  return locationSearchResults[0]?.locationGuid
}
