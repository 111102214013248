import { AbridgedInvoiceMetadataWithAmountDue } from '@breezy/shared/src'
import {
  mapInvoiceToAbridgedInvoiceMetadata,
  useFetchInvoicesSubscription,
} from '../../pages/Invoices/invoiceUtils'

type AbridgedInvoiceMetadataLoaderReturn = {
  invoice?: AbridgedInvoiceMetadataWithAmountDue
  loading: boolean
}

export const useFetchAbridgedInvoiceByGuid = ({
  invoiceGuid,
  pause,
}: {
  invoiceGuid: string
  pause?: boolean
}): AbridgedInvoiceMetadataLoaderReturn => {
  const res = useFetchInvoicesSubscription(
    {
      invoiceGuid: {
        _eq: invoiceGuid,
      },
    },
    pause,
  )

  const rawInvoice =
    res.data && (res.data.invoices?.length ?? 0) > 0
      ? res.data.invoices[0]
      : undefined
  const invoice = rawInvoice
    ? mapInvoiceToAbridgedInvoiceMetadata(invoiceGuid, rawInvoice)
    : undefined

  return {
    invoice,
    loading: res.fetching,
  }
}
