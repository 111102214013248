import {
  JOBS_BY_CLASS_ASSIGNED_SETTING_OPTIONS,
  JOBS_V2_CLASSES,
  JobClass,
  JobsByClassAssignedSetting,
  R,
  jobClassDisplayNames,
  toPlural,
} from '@breezy/shared'
import { faCircleInfo, faWrench } from '@fortawesome/pro-light-svg-icons'

import { OfficeRoutes } from '@breezy/shared'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Select, Tooltip } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Card } from '../../elements/Card/Card'
import { DoughnutChart } from '../../elements/Charts/DoughnutChart'
import { Link } from '../../elements/Link/Link'
import { trpc } from '../../hooks/trpc'
import {
  blue2,
  blue4,
  blue6,
  gray5,
  sunsetOrange4,
  sunsetOrange6,
  sunsetOrange7,
} from '../../themes/theme'
import { NoDataOverlay } from './NoDataOverlay'
import {
  FutureReportingDateRangePicker,
  useFutureReportingDateRangePickerState,
} from './ReportingDateRangePicker/FutureReportingDateRangePicker'
import { DUMMY_BACKGROUND_JOBS_BY_CLASS_DATA } from './dummyBackgroundData'
import { RefetchableAppointmentsRef, useRefetchableAppointments } from './utils'

const toNicePercent = (val: number, total: number) =>
  `${Math.round((val * 100) / total)}%`

const ASSIGNED_COLOR_MAP = {
  [JobClass.MAINTENANCE]: blue6,
  [JobClass.SERVICE]: blue4,
  [JobClass.INSTALL]: blue2,
  [JobClass.SALES]: blue2,
} as const

const UNASSIGNED_COLOR_MAP = {
  [JobClass.MAINTENANCE]: sunsetOrange7,
  [JobClass.SERVICE]: sunsetOrange6,
  [JobClass.INSTALL]: sunsetOrange4,
  [JobClass.SALES]: sunsetOrange4,
} as const

const TABLE_HEADERS_BY_ASSIGNED_TYPE = {
  Assigned: 'Jobs with Assigned Appointments',
  Unassigned: 'Jobs with Unassigned Appointments',
  'No Appointments': 'Jobs with No Appointments',
} satisfies Record<JobsByClassAssignedSetting, string>

const NO_JOBS_PIE_DATA = [
  {
    label: 'No jobs',
    value: 1,
    color: gray5,
  },
]

type JobsByClassWidgetProps = {
  // Not optional. I'm expecting at least a fixed height class
  className: string
  refetchableAppointmentsRef: React.Ref<RefetchableAppointmentsRef>
}
export const JobsByClassWidget = React.memo<JobsByClassWidgetProps>(
  ({ className, refetchableAppointmentsRef }) => {
    const [assignedSetting, setAssignedSetting] =
      useState<JobsByClassAssignedSetting>(
        JOBS_BY_CLASS_ASSIGNED_SETTING_OPTIONS[0],
      )
    const [dateRange, setDateRange] = useFutureReportingDateRangePickerState()

    const jobsByClassQuery = trpc.reporting['job-by-class:get'].useQuery(
      {
        dateRange,
      },
      {
        trpc: {
          context: {
            skipBatch: true,
          },
        },
      },
    )

    useRefetchableAppointments(
      refetchableAppointmentsRef,
      jobsByClassQuery.refetch,
    )

    const [totalJobsForView, anyJobsAtAll] = useMemo(() => {
      if (!jobsByClassQuery.data) {
        return [0, false]
      }
      // For the given assigned setting, get the total number of jobs (appears inside the pie)
      const totalJobsForView = R.pipe(
        R.values,
        R.sum,
      )(jobsByClassQuery.data[assignedSetting])
      // If there are jobs for the selected type, we know there are jobs at all
      if (totalJobsForView > 0) {
        return [totalJobsForView, true]
      }
      // If we're here, that means there were not jobs for the selected assigned type. To figure out which empty state
      // we show, we need to check if there are any jobs for ANY of the types
      for (const valuesForAssignmentType of R.values(jobsByClassQuery.data)) {
        const sum = R.pipe(R.values, R.sum)(valuesForAssignmentType)
        if (sum > 0) {
          return [0, true]
        }
      }
      // If we're here, we never found any jobs
      return [0, false]
    }, [assignedSetting, jobsByClassQuery.data])

    const resolvedData = useMemo(() => {
      const data = anyJobsAtAll
        ? jobsByClassQuery.data?.[assignedSetting]
        : DUMMY_BACKGROUND_JOBS_BY_CLASS_DATA

      return JOBS_V2_CLASSES.map(jobClass => ({
        label: jobClassDisplayNames[jobClass],
        value: data?.[jobClass] ?? 0,
        color: (assignedSetting === 'Assigned'
          ? ASSIGNED_COLOR_MAP
          : UNASSIGNED_COLOR_MAP)[jobClass],
      }))
    }, [anyJobsAtAll, assignedSetting, jobsByClassQuery.data])

    const navigate = useNavigate()

    const onCreateJobClick = useCallback(
      () => navigate(OfficeRoutes.JOB_CREATION.path),
      [navigate],
    )

    const renderTooltipValue = useCallback(
      (value: number) => (
        <div>
          {value} jobs ({toNicePercent(value, totalJobsForView)})
        </div>
      ),
      [totalJobsForView],
    )

    const hasNoDataOverlay = !anyJobsAtAll

    const isNoAppointments = assignedSetting === 'No Appointments'

    return (
      <Card
        title="Active Jobs by Class"
        className={className}
        titleAction={
          !hasNoDataOverlay && (
            <>
              <Select
                className="select-with-hover-state"
                popupMatchSelectWidth={false}
                bordered={false}
                value={assignedSetting}
                onChange={value =>
                  setAssignedSetting(value as JobsByClassAssignedSetting)
                }
                options={JOBS_BY_CLASS_ASSIGNED_SETTING_OPTIONS.map(value => ({
                  value,
                }))}
              />
              {isNoAppointments ? (
                <Select
                  disabled={isNoAppointments}
                  className={classNames({
                    'select-with-hover-state': !isNoAppointments,
                  })}
                  open={false}
                  bordered={false}
                  value="All Time"
                />
              ) : (
                <FutureReportingDateRangePicker
                  range={dateRange}
                  setRange={setDateRange}
                />
              )}
            </>
          )
        }
      >
        {jobsByClassQuery.isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="relative h-full text-bz-gray-1000">
            <div className="flex h-full min-w-0 flex-row items-center overflow-auto p-8">
              <div className="flex h-full min-w-0 flex-1 justify-center">
                <DoughnutChart
                  data={
                    anyJobsAtAll && totalJobsForView === 0
                      ? NO_JOBS_PIE_DATA
                      : resolvedData
                  }
                  renderTooltipValue={renderTooltipValue}
                  hideTooltip={totalJobsForView === 0}
                >
                  <div className="text-center">
                    <div className="text-2xl font-semibold leading-normal">
                      {totalJobsForView}
                    </div>
                    <div className="text-sm text-bz-gray-900">Jobs</div>
                  </div>
                </DoughnutChart>
              </div>
              <div className="ml-4 flex min-w-0 flex-1 flex-col justify-center">
                <div className="mb-2 text-base font-semibold">
                  {TABLE_HEADERS_BY_ASSIGNED_TYPE[assignedSetting]}
                  {!isNoAppointments && (
                    <Tooltip
                      title={
                        'If a job has both assigned and unassigned appointments, it will only be counted as "assigned"'
                      }
                    >
                      <FontAwesomeIcon
                        icon={faCircleInfo}
                        className="ml-2 text-sm text-bz-gray-700"
                      />
                    </Tooltip>
                  )}
                </div>
                <table>
                  <tbody>
                    {resolvedData.map(({ label, value, color }) => (
                      <tr
                        key={label}
                        className=":last:border-b-0 border-0 border-b border-solid border-b-bz-gray-400 text-sm"
                      >
                        <td>
                          <div
                            className="mr-2 h-3.5 w-3.5 rounded-sm"
                            style={{ backgroundColor: color }}
                          />
                        </td>
                        {/* The "w-full" is like "flex: 1" for tables */}
                        <td className="w-full py-2 pr-2">{label}</td>
                        <td className="text-right text-bz-gray-900">
                          {toNicePercent(
                            value,
                            totalJobsForView > 0 ? totalJobsForView : 1,
                          )}
                        </td>
                        <td className="px-1.5 text-bz-gray-600">•</td>
                        <td className="whitespace-nowrap text-bz-gray-800">
                          {value} {toPlural(value, 'job')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            {hasNoDataOverlay && (
              <NoDataOverlay
                icon={faWrench}
                title="No jobs to display"
                link={<Link onClick={onCreateJobClick}>Create job</Link>}
              >
                Create a job to visualize it in this chart.
              </NoDataOverlay>
            )}
          </div>
        )}
      </Card>
    )
  },
)
