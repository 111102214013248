import {
  AccountGuid,
  BzDateFns,
  CalculatePaths,
  formatUsc,
  usdToUsCents,
} from '@breezy/shared'
import { faBan, faWarning } from '@fortawesome/pro-regular-svg-icons'
import { Button } from 'antd'
import classNames from 'classnames'
import { useState } from 'react'

import { Card } from '../../../adam-components/Card/Card'
import { TeamMemberCell } from '../../../adam-components/ListPage/TeamMemberCell'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import FaIconWithCircularBackground from '../../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'
import { Link } from '../../../elements/Link/Link'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import tailwindConfig from '../../../tailwind.config'
import { BulkPaymentModal } from '../../BulkPaymentView/BulkPaymentView'
import {
  AggregatableInvoice,
  useDoNotService,
} from './AccountDetailsBanner.gql'
import {
  OpenAccountBalanceOld,
  useOpenAccountBalance,
} from './useOpenAccountBalance'

const colors = tailwindConfig.theme.extend.colors

export const AccountDetailsBanner = ({
  account,
  className,
  itemClassName,
  onPaymentsSuccess,
}: {
  account: { accountGuid: string }
  className?: string
  itemClassName?: string
  onPaymentsSuccess?: () => void
}) => {
  const { accountGuid } = account
  const doNotServiceReason = useDoNotService(accountGuid)
  const openAccountBalance = useOpenAccountBalance(accountGuid)
  if (!doNotServiceReason && !openAccountBalance) return null

  return (
    <div className={classNames('flex flex-col gap-2', className)}>
      {doNotServiceReason && (
        <DoNotServiceBanner
          doNotServiceReason={doNotServiceReason}
          className={itemClassName}
        />
      )}
      {openAccountBalance && (
        <OverdueAccountBalanceBanner
          openAccountBalance={openAccountBalance}
          className={itemClassName}
          accountGuid={accountGuid}
          onPaymentsSuccess={onPaymentsSuccess}
        />
      )}
    </div>
  )
}

export const DoNotServiceBanner = ({
  doNotServiceReason,
  className,
}: {
  doNotServiceReason: string
  className?: string
}) => {
  return (
    <div
      className={classNames(
        'flex gap-3 rounded-xl border border-solid border-bz-orange-300 bg-bz-orange-100 px-4 py-3',
        className,
      )}
    >
      <FaIconWithCircularBackground
        iconDefinition={faBan}
        color={colors['bz-orange'][800]}
        backgroundColor={colors['bz-orange'][200]}
        diameterPx={28}
        noRightMargin
      />
      <div>
        <span className="regular_14_22">
          <span className="semibold_14_22">
            This account has been marked as Do Not Service for the following
            reason:
          </span>{' '}
          “{doNotServiceReason}”
        </span>
      </div>
    </div>
  )
}

export const OverdueAccountBalanceBanner = ({
  openAccountBalance,
  className,
  accountGuid,
  onPaymentsSuccess,
}: {
  openAccountBalance: OpenAccountBalanceOld
  className?: string
  accountGuid: AccountGuid
  onPaymentsSuccess?: () => void
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const { openInvoices, overdueBalanceFormatted } = openAccountBalance
  const bulkPaymentsEnabled = useFeatureFlag('bulkPayments')

  if (!overdueBalanceFormatted) return null

  return (
    <>
      <div
        className={classNames(
          'flex items-start justify-between gap-3 rounded-xl border border-solid border-bz-orange-300 bg-bz-orange-100 px-4 py-3',
          className,
        )}
      >
        <FaIconWithCircularBackground
          iconDefinition={faWarning}
          color={colors['bz-orange'][800]}
          backgroundColor={colors['bz-orange'][200]}
          diameterPx={28}
          noRightMargin
        />
        <div className="flex flex-1 flex-wrap justify-between gap-2">
          <div className="regular_14_22 flex items-center font-semibold">
            This account has an overdue balance of {overdueBalanceFormatted}.
          </div>
          {openInvoices?.length && (
            <Button type="primary" onClick={() => setModalOpen(true)}>
              {bulkPaymentsEnabled ? 'Make Payment' : 'View Invoices'}
            </Button>
          )}
        </div>
      </div>
      <AccountBalanceBulkPaymentModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        accountGuid={accountGuid}
        onPaymentsSuccess={onPaymentsSuccess}
        openAccountBalance={openAccountBalance}
      />
    </>
  )
}

const JobTypeCell = ({
  jobLink,
}: {
  jobLink: AggregatableInvoice['jobLink']
}) => {
  const job = jobLink?.job
  if (!job) return <td className="py-3 pr-4">-</td>
  return (
    <td className="pr-4">
      <Link
        bold={true}
        to={CalculatePaths.jobDetails({ jobGuid: job.jobGuid })}
      >
        <span className="whitespace-nowrap">{job.jobType.name}</span>{' '}
        <span className="whitespace-nowrap">(#{job.displayIdString})</span>
      </Link>
    </td>
  )
}

export const AccountBalanceBulkPaymentModal = ({
  modalOpen,
  setModalOpen,
  accountGuid,
  onPaymentsSuccess,
  openAccountBalance,
}: {
  modalOpen: boolean
  setModalOpen: (modalOpen: boolean) => void
  accountGuid: AccountGuid
  onPaymentsSuccess?: () => void
  openAccountBalance: OpenAccountBalanceOld
}) => {
  const tzId = useExpectedCompanyTimeZoneId()
  const bulkPaymentsEnabled = useFeatureFlag('bulkPayments')
  const { openInvoices, getAgeOfInvoice } = openAccountBalance

  return (
    <>
      {modalOpen && !bulkPaymentsEnabled && openInvoices?.length && (
        <OnsiteModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          size="xlarge-width"
        >
          <OnsiteModalContent
            onClose={() => setModalOpen(false)}
            header="Open Invoices"
          >
            <Card>
              <div className="max-h-[75vh] overflow-auto">
                <table className="w-full">
                  <thead>
                    <tr className="h-5">
                      {[
                        'Invoice',
                        'Job',
                        'Amount',
                        'Balance Due',
                        'Issue Date',
                        'Due Date',
                        'Age',
                        'Created By',
                      ].map(header => (
                        <th
                          key={header}
                          className="whitespace-nowrap text-nowrap pb-4 pr-4 text-left text-sm font-semibold uppercase text-bz-gray-800"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {[...(openInvoices || []), ...(openInvoices || [])].map(
                      invoice => {
                        const age = getAgeOfInvoice(invoice)
                        const {
                          invoiceGuid,
                          displayIdString,
                          jobLink,
                          totalUsc,
                          paidUsd,
                          issuedAt,
                          dueAt,
                          createdByUser,
                        } = invoice
                        const balanceDue = totalUsc - usdToUsCents(paidUsd)
                        return (
                          <tr
                            key={invoiceGuid}
                            className="border-0 border-t border-solid border-y-bz-gray-400 text-sm last:border-b"
                          >
                            <td className="py-3 pr-4">
                              <Link
                                bold
                                to={CalculatePaths.invoiceOverview({
                                  invoiceGuid,
                                })}
                              >
                                {displayIdString}
                              </Link>
                            </td>
                            <JobTypeCell jobLink={jobLink} />
                            <td className="py-3 pr-4">{formatUsc(totalUsc)}</td>
                            <td className="py-3 pr-4">
                              {formatUsc(balanceDue)}
                            </td>
                            <td className="py-3 pr-4">
                              {issuedAt
                                ? BzDateFns.format(
                                    BzDateFns.parseISO(issuedAt, tzId),
                                    'MMM d, yyyy',
                                  )
                                : null}
                            </td>
                            <td className="py-3 pr-4">
                              {dueAt
                                ? BzDateFns.format(
                                    BzDateFns.parseISO(dueAt, tzId),
                                    'MMM d, yyyy',
                                  )
                                : null}
                            </td>
                            <td className="py-3 pr-4">{age} days overdue</td>
                            <td className="py-3 pr-4">
                              <TeamMemberCell user={createdByUser} />
                            </td>
                          </tr>
                        )
                      },
                    )}
                  </tbody>
                </table>
              </div>
            </Card>
          </OnsiteModalContent>
        </OnsiteModal>
      )}
      {modalOpen && bulkPaymentsEnabled && openInvoices?.length && (
        <BulkPaymentModal
          accountGuid={accountGuid}
          onClose={() => setModalOpen(false)}
          onSuccess={() => {
            setModalOpen(false)
            onPaymentsSuccess?.()
          }}
        />
      )}
    </>
  )
}
