import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Progress,
  Row,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { useCallback, useEffect, useState } from 'react'
import { trpc } from '../../hooks/trpc'

type FormSchema = {
  fileLink: string
  startIndexInclusive: number
  endIndexInclusive: number | undefined
}

type ChunkMigrationFormSchema = {
  fileUrl: string
  companyGuid: string
  workloadName: string
  noFanout: boolean
  requireValidatedInput?: boolean
  isChunked: boolean
  numberOfChunks?: number
  delayBetweenChunksSeconds?: number // in seconds
}

type ProgressModalProps = {
  visible: boolean
  currentChunk: number
  totalChunks: number
  onClose: () => void
  isComplete: boolean
  startTime: number
}

const ProgressModal = ({
  visible,
  currentChunk,
  totalChunks,
  onClose,
  isComplete,
  startTime,
}: ProgressModalProps) => {
  const [elapsedTime, setElapsedTime] = useState('00:00:00')

  useEffect(() => {
    if (!visible) {
      setElapsedTime('00:00:00')
      return
    }

    const timer = setInterval(() => {
      const elapsed = Math.floor((Date.now() - startTime) / 1000)
      const hours = Math.floor(elapsed / 3600)
      const minutes = Math.floor((elapsed % 3600) / 60)
      const seconds = elapsed % 60
      setElapsedTime(
        `${hours.toString().padStart(2, '0')}:${minutes
          .toString()
          .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`,
      )
    }, 1000)

    return () => clearInterval(timer)
  }, [startTime, visible])

  const percent = Math.round((currentChunk / totalChunks) * 100)

  return (
    <Modal
      title="Migration Progress"
      open={visible}
      onCancel={isComplete ? onClose : undefined}
      footer={
        isComplete
          ? [
              <Button key="close" onClick={onClose}>
                Close
              </Button>,
            ]
          : null
      }
      closable={isComplete}
      maskClosable={isComplete}
    >
      <div>
        <Progress
          percent={percent}
          status={isComplete ? 'success' : 'active'}
        />
        <p>
          Processing chunk {currentChunk} of {totalChunks}
        </p>
        <p>Elapsed time: {elapsedTime}</p>
        {!isComplete && (
          <p style={{ color: 'red' }}>
            Please keep this page open until the operation completes
          </p>
        )}
      </div>
    </Modal>
  )
}

export const DataMigrationView = React.memo(() => {
  const migrateData =
    trpc.devTools['devtools:account-full-history-migration'].useMutation()
  const migrateAccountHistoryFromUrl =
    trpc.devTools['devtools:migrate-account-history-from-url'].useMutation()
  const bulkImportMaintenancePlans =
    trpc.maintenancePlans[
      'maintenance-plans:bulk-activate-imported'
    ].useMutation()
  const importPaymentsFromUrl =
    trpc.devTools['devtools:import-payments-from-url'].useMutation()
  const [form] = useForm<FormSchema>()
  const [chunkForm] = useForm<ChunkMigrationFormSchema>()

  const [modal, contextHolder] = Modal.useModal()

  const [progressModalVisible, setProgressModalVisible] = useState(false)
  const [currentChunk, setCurrentChunk] = useState(0)
  const [totalChunks, setTotalChunks] = useState(0)
  const [isComplete, setIsComplete] = useState(false)
  const [startTime, setStartTime] = useState(0)

  const migrateDataViaApi = useCallback(
    async (values: FormSchema) => {
      try {
        const result = await migrateData.mutateAsync(values)
        modal.confirm({
          title: 'Migration Complete',
          content: (
            <>
              Successfully migrated {result.successCount} accounts.
              <br />
              Errors: {result.errorCount}
              {result.errors.map((error, idx) => (
                <div key={idx}>
                  Account: {error.accountGuid} at index {error.accountIndex}
                  <br />
                  Message: {error.message}
                  <br />
                  Cause: {`${error.cause}`}
                  <br />
                  Stack: {error.stack}
                </div>
              ))}
            </>
          ),
        })
      } catch (error: unknown) {
        modal.error({
          title: 'Error',
          content: error instanceof Error ? error.message : 'Unknown error',
        })
      }
    },
    [migrateData, modal],
  )

  const migrateAccountHistoryChunks = useCallback(
    async (values: ChunkMigrationFormSchema) => {
      try {
        if (!values.isChunked || !values.numberOfChunks) {
          // Original single migration logic
          const result = await migrateAccountHistoryFromUrl.mutateAsync(values)
          modal.confirm({
            title: 'Migration Queued',
            content: (
              <>
                Successfully queued {result.totalAccounts} accounts for
                migration.
                <br />
                Chunks created: {result.successfulChunks}
                <br />
                Failed chunks: {result.failedChunks ?? 0}
                <br />
              </>
            ),
          })
          return
        }

        // Initialize progress tracking
        setCurrentChunk(0)
        setTotalChunks(values.numberOfChunks)
        setIsComplete(false)
        setStartTime(Date.now())
        setProgressModalVisible(true)

        // Handle multiple chunks
        const errors: Array<{ chunk: number; error: string }> = []
        const successes: Array<{
          chunk: number
          result: { totalAccounts: number }
        }> = []

        const delay = (ms: number) =>
          new Promise(resolve => setTimeout(resolve, ms))
        const delayMs = (values.delayBetweenChunksSeconds || 0) * 1000

        for (let i = 1; i <= values.numberOfChunks; i++) {
          setCurrentChunk(i)
          // More robust URL transformation
          const fileUrl = values.fileUrl.includes('chunk')
            ? values.fileUrl.replace(/chunk\d+/, `chunk${i}`)
            : values.fileUrl.replace(/\.json$/, `_chunk${i}.json`)

          try {
            if (i > 1 && delayMs > 0) {
              // eslint-disable-next-line no-console
              console.log(
                `Waiting ${values.delayBetweenChunksSeconds} seconds before processing chunk ${i}...`,
              )
              await delay(delayMs)
            }

            // eslint-disable-next-line no-console
            console.log(`Processing chunk ${i} with URL: ${fileUrl}`)
            const result = await migrateAccountHistoryFromUrl.mutateAsync({
              ...values,
              fileUrl,
            })
            successes.push({ chunk: i, result })
            // eslint-disable-next-line no-console
            console.log(`Successfully processed chunk ${i}`)
          } catch (error) {
            const errorMessage =
              error instanceof Error ? error.message : 'Unknown error'
            console.error(`Error processing chunk ${i}:`, errorMessage)
            errors.push({ chunk: i, error: errorMessage })
            // Continue with next chunk despite error
          }
        }

        setIsComplete(true)

        modal.confirm({
          title: 'Chunked Migration Complete',
          content: (
            <>
              Successfully processed chunks: {successes.length}
              <br />
              Failed chunks: {errors.length}
              {errors.length > 0 && (
                <>
                  <br />
                  <br />
                  Errors:
                  {errors.map((error, idx) => (
                    <div key={idx}>
                      Chunk {error.chunk}: {error.error}
                    </div>
                  ))}
                </>
              )}
              {successes.length > 0 && (
                <>
                  <br />
                  <br />
                  Successful chunks:
                  {successes.map(({ chunk, result }, idx) => (
                    <div key={idx}>
                      Chunk {chunk}: {result.totalAccounts} accounts queued
                    </div>
                  ))}
                </>
              )}
            </>
          ),
        })
      } catch (error: unknown) {
        setProgressModalVisible(false)
        modal.error({
          title: 'Error',
          content: error instanceof Error ? error.message : 'Unknown error',
        })
      }
    },
    [migrateAccountHistoryFromUrl, modal],
  )

  return (
    <div style={{ marginTop: 32 }}>
      <Row gutter={32}>
        <Col span={12}>
          <Card title="Bulk Maintenance Plan Import">
            <Form
              layout="vertical"
              onFinish={async values => {
                try {
                  const result = await bulkImportMaintenancePlans.mutateAsync({
                    fileLink: values.fileLink,
                  })
                  modal.confirm({
                    title: 'Import Complete',
                    content: (
                      <>
                        Successfully imported {result.totalImported} maintenance
                        plans.
                        <br />
                        {result.errors.length > 0 && (
                          <>
                            Errors: {result.errors.length}
                            {result.errors.map((error, idx) => (
                              <div key={idx}>
                                Account: {error.accountDisplayName}
                                <br />
                                Address: {error.fullAddress}
                                <br />
                                Message: {error.messages}
                                <br />
                                Index: {error.index}
                              </div>
                            ))}
                          </>
                        )}
                      </>
                    ),
                  })
                } catch (error: unknown) {
                  modal.error({
                    title: 'Error',
                    content:
                      error instanceof Error ? error.message : 'Unknown error',
                  })
                }
              }}
              initialValues={{
                fileLink:
                  'https://breezy-onboarding-resources.s3.us-east-1.amazonaws.com/right-way-single-plan-import.json',
              }}
            >
              <Form.Item
                name="fileLink"
                label="Link to Maintenance Plans JSON file"
                rules={[{ required: true, type: 'url' }]}
              >
                <Input type="url" />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={bulkImportMaintenancePlans.isLoading}
                >
                  Import Maintenance Plans
                </Button>
              </Form.Item>
            </Form>
          </Card>

          <Divider />

          <Card title="Payments Import">
            <Form
              layout="vertical"
              onFinish={async values => {
                try {
                  await importPaymentsFromUrl.mutateAsync({
                    url: values.url,
                    companyGuid: values.companyGuid,
                    workloadName: values.workloadName,
                  })
                  modal.confirm({
                    title: 'Import Complete',
                    content: <>Successfully imported payments.</>,
                  })
                } catch (error: unknown) {
                  modal.error({
                    title: 'Error',
                    content:
                      error instanceof Error ? error.message : 'Unknown error',
                  })
                }
              }}
              initialValues={{
                fileLink:
                  'https://breezy-onboarding-resources.s3.us-east-1.amazonaws.com/right-way-single-plan-import.json',
              }}
            >
              <Form.Item
                name="url"
                label="Link to Payments JSON file"
                rules={[{ required: true, type: 'url' }]}
              >
                <Input type="url" />
              </Form.Item>
              <Form.Item
                name="companyGuid"
                label="Company GUID"
                rules={[
                  {
                    required: true,
                    pattern:
                      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
                    message: 'Please enter a valid GUID',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="workloadName"
                label="Workload Name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={importPaymentsFromUrl.isLoading}
                >
                  Import Payments
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>

        <Col span={12}>
          <Card title="Migration Queued">
            <Form
              form={chunkForm}
              layout="vertical"
              onFinish={migrateAccountHistoryChunks}
              validateTrigger="onBlur"
              initialValues={{
                fileUrl:
                  'https://breezy-onboarding-resources.s3.us-east-1.amazonaws.com/kaw-valley-single-account.json',
                workloadName: 'account-history-migration-example',
                noFanout: false,
                requireValidatedInput: true,
                isChunked: true,
                numberOfChunks: 1,
                delayBetweenChunksSeconds: 5,
              }}
            >
              <Form.Item
                name="fileUrl"
                label="Link to JSON file"
                rules={[{ required: true, type: 'url' }]}
              >
                <Input type="url" />
              </Form.Item>
              <Form.Item
                name="companyGuid"
                label="Company GUID"
                rules={[
                  {
                    required: true,
                    pattern:
                      /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/,
                    message: 'Please enter a valid GUID',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="workloadName"
                label="Workload Name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="noFanout"
                label="No Fanout (Single Account at a Time)"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                name="requireValidatedInput"
                label="Require Validated Input"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                name="isChunked"
                label="Process Multiple Chunks"
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.isChunked !== currentValues.isChunked
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue('isChunked') ? (
                    <>
                      <Form.Item
                        name="numberOfChunks"
                        label="Number of Chunks"
                        rules={[
                          {
                            required: true,
                            type: 'number',
                            min: 1,
                            message: 'Please enter a valid number of chunks',
                          },
                        ]}
                      >
                        <InputNumber min={1} />
                      </Form.Item>
                      <Form.Item
                        name="delayBetweenChunksSeconds"
                        label="Delay Between Chunks (seconds)"
                        rules={[
                          {
                            type: 'number',
                            min: 0,
                            message: 'Please enter a valid delay in seconds',
                          },
                        ]}
                        tooltip="Time to wait between processing each chunk (0 for no delay)"
                      >
                        <InputNumber min={0} />
                      </Form.Item>
                    </>
                  ) : null
                }
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={migrateAccountHistoryFromUrl.isLoading}
                >
                  Start Chunked Migration
                </Button>
              </Form.Item>
            </Form>
          </Card>

          <Divider />

          <Card title="Legacy Account Migration">
            <Form
              form={form}
              layout="vertical"
              onFinish={migrateDataViaApi}
              validateTrigger="onBlur"
              initialValues={{
                fileLink:
                  'https://breezy-onboarding-resources.s3.us-east-1.amazonaws.com/example.json',
                startIndexInclusive: 0,
              }}
            >
              <Form.Item name="fileLink" label="Link to JSON file">
                <Input type="url" />
              </Form.Item>
              <Form.Item
                name="startIndexInclusive"
                label="Index of Account to Start (Inclusive)"
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                name="endIndexInclusive"
                label="End Index (Exclusive)"
                help="Leave blank to process the entire file"
              >
                <InputNumber />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={migrateData.isLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>

      <ProgressModal
        visible={progressModalVisible}
        currentChunk={currentChunk}
        totalChunks={totalChunks}
        onClose={() => setProgressModalVisible(false)}
        isComplete={isComplete}
        startTime={startTime}
      />

      {contextHolder}
    </div>
  )
})
