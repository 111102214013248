import {
  DynamicPricingType,
  InvoiceTotals,
  R,
  formatPercentage,
  formatUsc,
  getDynamicPricingMultiplier,
} from '@breezy/shared'
import { faCircleChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useState } from 'react'
import { DiscountCirclesCombo } from '../../../components/Pricebook/DiscountCircle'
import { DiscountPickerDiscount } from '../../../components/Pricebook/DiscountMultiPicker'
import useIsMobile from '../../../hooks/useIsMobile'

type SubTotalRowProps = {
  label: React.ReactNode
  amount: React.ReactNode
  className?: string
}

const SubTotalRow = React.memo<SubTotalRowProps>(
  ({ label, amount, className }) => (
    <div className={classNames('my-1 flex flex-row items-center', className)}>
      <div className="flex-1 font-semibold">{label}</div>
      <div>{amount}</div>
    </div>
  ),
)

type TotalsSectionProps = InvoiceTotals & {
  subtotalCollapsible?: boolean
  defaultTotalsOpen?: boolean
  discounts: DiscountPickerDiscount[]
  totalLabel?: React.ReactNode
  hideTotalSubTotal?: boolean
  // Note: the existence of this implies we are showing it split out. If you're displaying customer-facing, you should
  // pass undefined for this, even if it is defined, to show the markup baked into the subtotal.
  dynamicPricingType?: DynamicPricingType
}

export const TotalsSection = React.memo<TotalsSectionProps>(
  ({
    subtotalUsc,
    unMarkedUpSubtotalUsc,
    discounts,
    discountAmountUsc,
    taxRate,
    taxAmountUsc,
    markupUsc,
    dynamicPricingType,
    totalUsc,
    dueUsc,
    paidUsc,
    refundedUsc,
    processingPaymentsUsc,
    subtotalCollapsible = true,
    defaultTotalsOpen = true,
    totalLabel = 'Total',
    hideTotalSubTotal,
  }) => {
    const isMobile = useIsMobile()
    const [totalsExpanded, setTotalsExpanded] = useState(defaultTotalsOpen)

    const resolvedSubtotalUsc = dynamicPricingType
      ? unMarkedUpSubtotalUsc
      : subtotalUsc

    return (
      <>
        <div
          className={classNames(
            // 225px is arbitrarily chosen to make the roll up/down transition work. The only way to do this without an
            // arbitrary max value is with JavaScript and inspecting the height of the content, which is kind of
            // overkill.
            totalsExpanded ? 'max-h-[225px]' : 'max-h-0',
            'overflow-hidden transition-all ease-in-out',
          )}
        >
          <div className="mb-3 flex flex-col border-0 border-b border-dashed border-bz-gray-500 pb-3 text-bz-gray-700">
            <SubTotalRow
              label="Subtotal"
              amount={formatUsc(resolvedSubtotalUsc)}
            />
            {markupUsc > 0 && dynamicPricingType ? (
              <>
                <SubTotalRow
                  label={`Dynamic Pricing (${getDynamicPricingMultiplier(
                    dynamicPricingType,
                  )}x)`}
                  amount={`+${formatUsc(markupUsc)}`}
                />
                <SubTotalRow
                  label="Dynamic Pricing Subtotal"
                  amount={formatUsc(subtotalUsc)}
                />
              </>
            ) : null}
            {discounts.length ? (
              <SubTotalRow
                label={
                  <div className="flex flex-row items-center">
                    <div className="mr-2">Discount</div>
                    <DiscountCirclesCombo discounts={discounts} />
                  </div>
                }
                amount={`-${formatUsc(discountAmountUsc ?? 0)}`}
              />
            ) : null}
            <SubTotalRow
              label={
                <span>
                  Tax{' '}
                  <span className="font-normal">
                    ({formatPercentage(taxRate)})
                  </span>
                </span>
              }
              amount={`+${formatUsc(taxAmountUsc)}`}
            />
            {!hideTotalSubTotal && (
              <SubTotalRow label="Total" amount={formatUsc(totalUsc)} />
            )}
          </div>
        </div>
        {processingPaymentsUsc > 0 && (
          <SubTotalRow
            className="text-bz-gray-700"
            label="Payments made (pending)"
            amount={formatUsc(processingPaymentsUsc)}
          />
        )}
        {paidUsc > 0 && (
          <SubTotalRow
            className="text-bz-gray-700"
            label="Already paid"
            amount={formatUsc(paidUsc)}
          />
        )}
        {refundedUsc > 0 && (
          <SubTotalRow
            className="text-bz-gray-700"
            label="Total Refunded"
            amount={`+${formatUsc(refundedUsc)}`}
          />
        )}
        <div className="flex flex-row items-center pt-1 text-xl font-semibold">
          <div className="flex-1 ">
            <span
              className="cursor-pointer"
              onClick={() => setTotalsExpanded(R.not)}
            >
              {totalLabel}
            </span>
            {subtotalCollapsible && (
              <Button
                type="link"
                className="h-6 p-0 text-base font-semibold text-bz-primary"
                data-testid="details-toggle"
                onClick={() => setTotalsExpanded(R.not)}
              >
                {isMobile ? '' : <span className="ml-2">Details </span>}
                <FontAwesomeIcon
                  icon={faCircleChevronDown}
                  className={classNames('ml-1 transition-all ease-in-out', {
                    'rotate-180': totalsExpanded,
                  })}
                />
              </Button>
            )}
          </div>

          <div className="flex flex-row items-center">
            {discounts.length > 0 && !totalsExpanded && !isMobile && (
              <DiscountCirclesCombo discounts={discounts} className="mr-2" />
            )}
            {formatUsc(dueUsc)}
          </div>
        </div>
      </>
    )
  },
)
