import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React, { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { useMetabase } from '../../providers/MetabaseWrapper'
import { REPORT_COMPONENTS } from '../../reports/ReportComponentRegistry'
import { REPORTS } from '../../reports/ReportDefinitions'
import { ExportReportConfig, ReportType } from '../../reports/ReportTypes'

export const ReportView: React.FC = () => {
  const navigate = useNavigate()
  const { reportType, reportId } = useParams<{
    reportType: string
    reportId: string
  }>()
  const { status, metabaseError } = useMetabase()

  // Find the report configuration from our definitions
  const reportConfig = useMemo(() => {
    return REPORTS.find(r => r.id === reportId)
  }, [reportId])

  const onBackToReportsPressed = useCallback(() => {
    navigate('/reports/dashboard')
  }, [navigate])

  if (status === 'uninitialized' || status === 'loading') {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    )
  }

  if (status === 'error') {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-4 p-8 text-center">
        <div className="text-xl font-semibold text-red-600">
          Unable to load report
        </div>
        <div className="text-gray-600">
          There was an error loading this report. Please contact Breezy Support
          for assistance.
        </div>
        <div className="text-sm text-gray-500">Error: {metabaseError}</div>
      </div>
    )
  }

  // Only proceed if we have a valid report type and config
  if (!reportType || !reportConfig || !(reportType in REPORT_COMPONENTS)) {
    return <div className="p-4">Report not found</div>
  }

  const validReportType = reportType as ReportType

  // Only render if the report config type matches the URL parameter
  if (reportConfig.type !== validReportType) {
    return <div className="p-4">Invalid report configuration</div>
  }

  // Get the appropriate component for this report type
  const ReportComponent = REPORT_COMPONENTS[validReportType]

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-between">
        <Button
          type="link"
          onClick={onBackToReportsPressed}
          className="mb-2 p-0"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back to Reports
        </Button>
      </div>

      {/* Render the report component with appropriate props */}
      {validReportType === 'export' ? (
        <div>
          {(() => {
            const questionConfig = reportConfig as ExportReportConfig
            return (
              <ReportComponent
                title={questionConfig.title}
                questionId={questionConfig.questionId}
              />
            )
          })()}
        </div>
      ) : (
        <ReportComponent />
      )}
    </div>
  )
}
