import { z } from 'zod'
import { AsyncFn, BusinessException } from '../../../common'
import { guidSchema } from '../../../contracts/_common'
import { ForCompanyUser } from '../../Company/Company'

export const UpsertPurchaseOrderDTOSchema = z.object({
  companyGuid: guidSchema,
  purchaseOrderNumber: z.string(),
  jobGuid: guidSchema,
})

export type UpsertPurchaseOrderDTO = z.infer<typeof UpsertPurchaseOrderDTOSchema>

export type PurchaseOrderUpserter = AsyncFn<ForCompanyUser<UpsertPurchaseOrderDTO>>

export class PurchaseOrderReferenceNumberProvisionException extends BusinessException {}
