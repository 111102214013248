import { faChevronsDown, faChevronsUp } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Table } from 'antd'
import React, { useCallback } from 'react'
import './TechnicianOrderingTable.less'
import { CompanyTechnician } from './utils'

type TechnicianWithSeq = {
  technicianGuid: string
  seq: number
}

type TechnicianOrderingTableProps = {
  technicians: TechnicianWithSeq[]
  onReorder: (technicians: TechnicianWithSeq[]) => void
  companyTechnicians: CompanyTechnician[]
}

export const TechnicianOrderingTable = React.memo<TechnicianOrderingTableProps>(
  ({ technicians, onReorder, companyTechnicians }) => {
    const technicianMap = new Map(
      companyTechnicians.map(tech => [tech.technicianGuid, tech]),
    )

    const moveUp = useCallback(
      (index: number) => {
        if (index <= 0) return
        const newTechnicians = [...technicians]
        const temp = newTechnicians[index - 1].seq
        newTechnicians[index - 1].seq = newTechnicians[index].seq
        newTechnicians[index].seq = temp

        onReorder(newTechnicians.sort((a, b) => a.seq - b.seq))
      },
      [onReorder, technicians],
    )

    const moveDown = useCallback(
      (index: number) => {
        if (index >= technicians.length - 1) return
        const newTechnicians = [...technicians]
        const temp = newTechnicians[index + 1].seq
        newTechnicians[index + 1].seq = newTechnicians[index].seq
        newTechnicians[index].seq = temp

        onReorder(newTechnicians.sort((a, b) => a.seq - b.seq))
      },
      [onReorder, technicians],
    )

    return (
      <Table
        dataSource={technicians}
        className="technician-ordering-table rounded-md border border-solid border-bz-border"
        bordered
        pagination={false}
        rowKey="technicianGuid"
        columns={[
          {
            title: '#',
            dataIndex: 'seq',
            width: 80,
            render: (_, __, index) => index + 1,
          },
          {
            title: 'Technician',
            dataIndex: 'technicianGuid',
            render: technicianGuid =>
              technicianMap.get(technicianGuid)?.fullName ?? 'Unknown',
          },
          {
            title: 'Actions',
            width: 100,
            render: (_, __, index) => (
              <div className="flex gap-2">
                <Button
                  type="text"
                  disabled={index === 0}
                  onClick={() => moveUp(index)}
                  className={
                    index === 0 ? 'text-bz-text-disabled' : 'text-bz-primary'
                  }
                >
                  <FontAwesomeIcon icon={faChevronsUp} />
                </Button>
                <Button
                  type="text"
                  disabled={index === technicians.length - 1}
                  onClick={() => moveDown(index)}
                  className={
                    index === technicians.length - 1
                      ? 'text-bz-text-disabled'
                      : 'text-bz-primary'
                  }
                >
                  <FontAwesomeIcon icon={faChevronsDown} />
                </Button>
              </div>
            ),
          },
        ]}
      />
    )
  },
)
