import { ComprehensiveLocationDetails } from '@breezy/backend/src/application-types'
import {
  BzDateFns,
  InstalledEquipmentSummary,
  InstalledHvacSystem,
  JobClass,
  LocationContact,
  isNullish,
} from '@breezy/shared'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Modal } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { useExpectedCompanyTimeZoneId } from 'src/providers/PrincipalUser'
import { useCanCreateJobs } from '../../../hooks/permission/useCanCreateJob'
import { trpc } from '../../../hooks/trpc'
import useAppNavigation from '../../../hooks/useAppNav'
import { useIntercom } from '../../../hooks/useIntercom'
import {
  MaintenancePlanWizard,
  useMaintenancePlanWizardFlags,
} from '../../../pages/CreateOrEditMaintenancePlanPage/MaintenancePlanWizard'
import { DetailsPageProps } from '../../../utils/Refetchable'
import { useMessage } from '../../../utils/antd-utils'
import { m } from '../../../utils/react-utils'
import { UpsertOp } from '../../../utils/upsert-utils'
import EquipmentUpsertDrawer from '../../EquipmentUpsertDrawer/EquipmentUpsertDrawer'
import InstalledHvacSystemUpsertDrawer from '../../InstalledHvacSystemUpsertDrawer/InstalledHvacSystemUpsertDrawer'
import { LocationContactUpsertDrawer } from '../../LocationContacts/LocationContactUpsertDrawer'
import { AccountsCollapsibleV2 } from '../AccountCollapsible/AccountsCollapsibleV2'
import AccountLocationCollapsible from '../AccountLocationCollapsible/AccountLocationCollapsible'
import { AppointmentsCollapsibleAppointment } from '../AppointmentsCollapsible/AppointmentsCollapsible'
import {
  GroupedContactsCollapsible,
  GroupedContactsCollapsibleProps,
} from '../ContactsCollapsible/GroupedContactsCollapsible'
import { EstimatesCollapsibleV2 } from '../EstimatesCollapsible/EstimatesCollapsibleV2'
import { EquipmentCollapsible } from '../InstalledEquipmentCollapsible/EquipmentCollapsible'
import { HvacSystemsCollapsible } from '../InstalledHvacSystemsCollapsible/HvacSystemsCollapsible'
import { InvoicesV2CollapsibleV2 } from '../InvoicesCollapsible/InvoicesV2CollapsibleV2'
import {
  JobsCollapsible,
  JobsCollapsibleProps,
} from '../JobsCollapsible/JobsCollapsible'
import MaintenancePlansCollapsible from '../MaintenancePlansCollapsible/MaintenancePlansCollapsible'
import {
  PaymentsCollapsible,
  PaymentsCollapsibleProps,
} from '../PaymentsCollapsible/PaymentsCollapsible'
import {
  VisitsCollapsible,
  VisitsCollapsibleProps,
} from '../VisitsCollapsible/VisitsCollapsible'

type LocationCollapsiblesConfig = {
  readonly hideMaintenancePlans?: boolean
  readonly hideAccount?: boolean
  readonly hideLocation?: boolean
  readonly defaultJobClass?: JobClass
}

type LocationCollapsiblesGroupProps =
  DetailsPageProps<ComprehensiveLocationDetails> &
    LocationCollapsiblesConfig & {
      editable?: boolean
    }

const LocationCollapsiblesGroup = m<LocationCollapsiblesGroupProps>(
  ({
    data,
    refetch,
    hideAccount,
    hideLocation,
    hideMaintenancePlans,
    defaultJobClass,
    editable = true,
  }) => {
    const message = useMessage()

    const appNav = useAppNavigation()
    const [locationContactMutState, setLocationContactMutState] =
      useState<UpsertOp<LocationContact>>()
    const [hvacSystemMutState, setHvacSystemMutState] =
      useState<UpsertOp<InstalledHvacSystem>>()
    const [equipmentMutState, setEquipmentMutState] =
      useState<UpsertOp<InstalledEquipmentSummary>>()
    const basicAccount = data.getBasicAccount()
    const accountLocation = data.getAccountLocation()
    const canCreateNewJobs = useCanCreateJobs()

    const removeLocationContactMutation =
      trpc.location['location-contacts:remove'].useMutation()

    const { location, locationGuid, accountGuid, maintenancePlans } = useMemo(
      () => ({
        locationGuid: data.getLocationGuid(),
        accountGuid: data.getAccountLocation()?.accountGuid,
        location: data.getLocation(),
        maintenancePlans: data.getMaintenancePlans(),
      }),
      [data],
    )

    const onRemoveLocationContact = useCallback(
      (locationContact: LocationContact) => {
        removeLocationContactMutation.mutate(
          {
            locationGuid: locationContact.locationGuid,
            contactGuid: locationContact.contactGuid,
          },
          {
            onSuccess() {
              refetch()
              message.success(
                `Successfully removed Location Contact relationship`,
              )
            },
            onError() {
              message.error(`Failed to remove Location Contact relationship`)
            },
          },
        )
      },
      [removeLocationContactMutation, refetch, message],
    )

    const createNewJob = useCallback(() => {
      appNav.navigateToCreateNewJob({
        accountGuid,
        locationGuid,
        jobClass: defaultJobClass,
      })
    }, [appNav, accountGuid, locationGuid, defaultJobClass])

    useIntercom({ isLauncherVisible: isNullish(locationContactMutState) })
    const [
      maintenancePlanWizardOpen,
      openMaintenancePlanWizard,
      closeMaintenancePlanWizard,
    ] = useMaintenancePlanWizardFlags('mpw', 'location-details')

    const onCloseMpWizard = useCallback(() => {
      closeMaintenancePlanWizard()
      refetch()
    }, [closeMaintenancePlanWizard, refetch])

    const timezoneId = useExpectedCompanyTimeZoneId()

    const accountContacts = useMemo<
      GroupedContactsCollapsibleProps['accountContacts']
    >(() => {
      return data.getAccountContacts().map(contact => ({
        contactGuid: contact.accountContactGuid,
        name: `${contact.contact.firstName} ${contact.contact.lastName}`,
        notificationPreference: contact.contact.notificationPreferenceType,
        emailAddress: contact.contact.primaryEmailAddress?.emailAddress,
        phoneNumber: contact.contact.primaryPhoneNumber?.phoneNumber,
      }))
    }, [data])

    const locationContacts = useMemo<
      GroupedContactsCollapsibleProps['accountContacts']
    >(() => {
      return data.getLocationContacts().map(contact => ({
        contactGuid: contact.contactGuid,
        name: `${contact.contact.firstName} ${contact.contact.lastName}`,
        notificationPreference: contact.contact.notificationPreferenceType,
        emailAddress: contact.contact.primaryEmailAddress?.emailAddress,
        phoneNumber: contact.contact.primaryPhoneNumber?.phoneNumber,
      }))
    }, [data])

    const jobs = useMemo<JobsCollapsibleProps['jobs']>(() => {
      return data.getJobs().map(job => {
        const createdAt = BzDateFns.formatISO(
          BzDateFns.parseZonedDateTime(job.jobCreatedAt, timezoneId),
          timezoneId,
        )

        return {
          jobGuid: job.jobGuid,
          displayId: job.displayId.toString(),
          createdAt: createdAt,
          jobLifecycleStatus: {
            name: job.jobLifecycleStatus.name,
            color: job.jobLifecycleStatus.color,
          },
          jobTypeName: job.jobType.name,
          location: {
            locationGuid: data.getLocation().locationGuid,
            address: {
              line1: data.getLocation().address.line1,
            },
          },
          tags:
            job.tags?.map(tag => ({
              name: tag.name,
              color: tag.color,
            })) ?? [],
          appointments: job.appointments.map(appt => {
            const appointmentDate = BzDateFns.formatISO(
              BzDateFns.parseZonedDateTime(appt.timeWindow.start, timezoneId),
              timezoneId,
            )

            return {
              appointmentGuid: appt.appointmentGuid,
              appointmentType: appt.appointmentType,
              appointmentDate,
            }
          }),
          completedAt: job.workCompletedAt,
        }
      })
    }, [data, timezoneId])

    const visits = useMemo<VisitsCollapsibleProps['visits']>(() => {
      return data.getAppointments().map(appt => ({
        appointment: {
          appointmentGuid: appt.appointmentGuid,
          appointmentStatus: appt.appointmentStatus,
          appointmentType: appt.appointmentType,
          appointmentWindowStart: BzDateFns.formatISO(
            appt.timeWindow.startDate(),
            timezoneId,
          ),
          appointmentWindowEnd: BzDateFns.formatISO(
            appt.timeWindow.endDate(),
            timezoneId,
          ),
        },
        job: {
          jobGuid: appt.jobGuid,
          displayId: appt.displayId ? appt.displayId.toString() : undefined,
          jobTypeName: appt.jobType.name,
          assignedTechs: appt.assignments.map(assignment => ({
            name: `${assignment.technician.user.firstName} ${assignment.technician.user.lastName}`,
            assignmentStart: BzDateFns.formatISO(
              assignment.timeWindow.startDate(),
              timezoneId,
            ),
            assignmentEnd: BzDateFns.formatISO(
              assignment.timeWindow.endDate(),
              timezoneId,
            ),
          })),
        },
        location: {
          locationGuid: data.getLocation().locationGuid,
          address: {
            line1: data.getLocation().address.line1,
          },
        },
      }))
    }, [data, timezoneId])

    const [appointmentToCancel, setAppointmentToCancel] = useState<
      AppointmentsCollapsibleAppointment | undefined
    >(undefined)

    const cancelAppointmentMutation = trpc.appointments[
      'appointments:cancel'
    ].useMutation({
      onSuccess: () => {
        message.success(`Appointment canceled`)
        refetch()
      },
    })

    const payments = useMemo<PaymentsCollapsibleProps['payments']>(() => {
      return data.getPayments().map(
        payment =>
          ({
            paymentRecordGuid: payment.guid,
            amountUsd: payment.amountUsd,
            occurredAt: payment.occurredAt,
            paymentMethod: payment.paymentMethod,
            referenceNumber: payment.referenceNumber,
            status: payment.status,
            jobGuid: payment.jobGuid,
            loanRecord: payment.loanRecord,
            invoicePayments: payment.invoicePayments,
          } satisfies PaymentsCollapsibleProps['payments'][number]),
      )
    }, [data])

    return (
      <SectionedCard
        sections={[
          {
            content: (
              <>
                {!hideMaintenancePlans && (
                  <MaintenancePlansCollapsible
                    collapsibleStateId={`location-maintenance-plans-collapsible-${locationGuid}`}
                    plans={maintenancePlans}
                    hideCanceledPlans
                    createNew={() => {
                      openMaintenancePlanWizard()
                    }}
                    titleOverride="Maintenance Plan"
                    className="pt-0"
                  />
                )}

                {maintenancePlanWizardOpen && (
                  <MaintenancePlanWizard
                    onRamp="location-details"
                    accountGuid={accountGuid}
                    locationGuid={locationGuid}
                    onClose={onCloseMpWizard}
                  />
                )}

                {!hideAccount && basicAccount && (
                  <AccountsCollapsibleV2
                    collapsibleStateId={`location-accounts-collapsible-${locationGuid}`}
                    accounts={[
                      {
                        accountGuid: basicAccount.accountGuid,
                        accountType: basicAccount.type,
                        displayName: basicAccount.displayName,
                      },
                    ]}
                    timezoneId={timezoneId}
                  />
                )}

                {!hideLocation && accountLocation && (
                  <AccountLocationCollapsible
                    data={accountLocation}
                    refetch={refetch}
                  />
                )}

                <GroupedContactsCollapsible
                  canManage={editable}
                  accountContacts={accountContacts}
                  locationContacts={locationContacts}
                  onAddContact={() => setLocationContactMutState('create new')}
                  onEditLocationContact={c => {
                    const contact = data
                      .getLocationContacts()
                      .find(curr => curr.contactGuid === c.contactGuid)
                    if (!contact) {
                      return
                    }

                    setLocationContactMutState(contact)
                  }}
                  onRemoveLocationContact={c => {
                    const contact = data
                      .getLocationContacts()
                      .find(curr => curr.contactGuid === c.contactGuid)
                    if (!contact) {
                      return
                    }

                    onRemoveLocationContact(contact)
                  }}
                  collapsibleStateId={`location-contacts-collapsible-${locationGuid}`}
                />

                <JobsCollapsible
                  canManage={editable && canCreateNewJobs}
                  jobs={jobs}
                  timezoneId={timezoneId}
                  onAddJob={createNewJob}
                  collapsibleStateId={`location-jobs-collapsible-${locationGuid}`}
                />

                <VisitsCollapsible
                  timezoneId={timezoneId}
                  visits={visits}
                  editable={editable}
                  onCancelVisit={({ appointmentGuid }) => {
                    const appt = data
                      .getAppointments()
                      .find(curr => curr.appointmentGuid === appointmentGuid)

                    if (!appt) {
                      message.error('Appointment not found')
                      return
                    }

                    setAppointmentToCancel(appt)
                  }}
                  collapsibleStateId={`location-visits-collapsible-${locationGuid}`}
                />

                <EquipmentCollapsible
                  canManage={editable}
                  equipment={data.getEquipment()}
                  onAddEquipment={() => setEquipmentMutState('create new')}
                  onEquipmentEdit={setEquipmentMutState}
                  collapsibleStateId={`location-equipment-collapsible-${locationGuid}`}
                />

                <HvacSystemsCollapsible
                  canManage={editable}
                  hvacSystems={data.getInstallHvacSystems()}
                  onHvacSystemAdd={() => setHvacSystemMutState('create new')}
                  onHvacSystemEdit={setHvacSystemMutState}
                  onEquipmentEdit={setEquipmentMutState}
                  collapsibleStateId={`location-hvac-systems-collapsible-${locationGuid}`}
                />

                <PaymentsCollapsible
                  showJobLink
                  payments={payments}
                  collapsibleStateId={`location-payments-collapsible-${locationGuid}`}
                />

                {accountGuid && (
                  <InvoicesV2CollapsibleV2
                    links={{
                      accountGuid,
                      locationGuid,
                    }}
                    collapsibleStateId={`location-invoices-collapsible-${locationGuid}`}
                  />
                )}

                {accountGuid && (
                  <EstimatesCollapsibleV2
                    links={{ accountGuid, locationGuid }}
                    allowExternalCreate={false}
                    collapsibleStateId={`location-estimates-collapsible-${locationGuid}`}
                  />
                )}

                {equipmentMutState === 'create new' && (
                  <EquipmentUpsertDrawer
                    mode="create-for-location"
                    location={location}
                    isOpen={!!equipmentMutState}
                    onCancel={() => setEquipmentMutState(undefined)}
                    onMutate={() => {
                      refetch()
                      setEquipmentMutState(undefined)
                    }}
                  />
                )}

                {equipmentMutState && equipmentMutState !== 'create new' && (
                  <EquipmentUpsertDrawer
                    mode="update"
                    initialValues={equipmentMutState}
                    location={location}
                    isOpen={!!equipmentMutState}
                    onCancel={() => setEquipmentMutState(undefined)}
                    onMutate={() => {
                      refetch()
                      setEquipmentMutState(undefined)
                    }}
                  />
                )}

                {hvacSystemMutState === 'create new' && (
                  <InstalledHvacSystemUpsertDrawer
                    mode={'create-for-location'}
                    location={location}
                    isOpen={!!hvacSystemMutState}
                    onCancel={() => setHvacSystemMutState(undefined)}
                    onMutate={() => {
                      refetch()
                      setHvacSystemMutState(undefined)
                    }}
                  />
                )}

                {hvacSystemMutState && hvacSystemMutState !== 'create new' && (
                  <InstalledHvacSystemUpsertDrawer
                    mode={'update'}
                    location={location}
                    initialValues={hvacSystemMutState}
                    isOpen={!!hvacSystemMutState}
                    onCancel={() => setHvacSystemMutState(undefined)}
                    onMutate={() => {
                      refetch()
                      setHvacSystemMutState(undefined)
                    }}
                  />
                )}

                <LocationContactUpsertDrawer
                  locationGuid={locationGuid}
                  locationContact={
                    locationContactMutState === 'create new'
                      ? undefined
                      : locationContactMutState
                  }
                  isOpen={!!locationContactMutState}
                  onClose={() => setLocationContactMutState(undefined)}
                  onMutate={() => {
                    refetch()
                    setLocationContactMutState(undefined)
                  }}
                />

                <Modal
                  title="Are you sure?"
                  open={!!appointmentToCancel}
                  onOk={() => {
                    appointmentToCancel &&
                      cancelAppointmentMutation.mutate({
                        jobAppointmentGuid: appointmentToCancel.appointmentGuid,
                      })
                    setAppointmentToCancel(undefined)
                  }}
                  onCancel={() => setAppointmentToCancel(undefined)}
                  okButtonProps={{ danger: true }}
                >
                  <div className="column">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      size="4x"
                      color="#ffcc33"
                      style={{ marginBottom: 12 }}
                    />
                    <div className="text-center">
                      Once you cancel this appointment, you will not be able to
                      un-cancel it.
                    </div>
                  </div>
                </Modal>
              </>
            ),
          },
        ]}
      />
    )
  },
)

export default LocationCollapsiblesGroup
