import {
  AccountContact,
  MaintenancePlanDefinition,
  PaymentMethod,
  VisitViewModel,
} from '@breezy/shared'
import {
  faEnvelopeOpenText,
  faHouseBuilding,
} from '@fortawesome/pro-regular-svg-icons'
import React, { useEffect } from 'react'
import { FieldErrors, useFormContext } from 'react-hook-form'
import { SectionedCard } from '../../../adam-components/SectionedCard/SectionedCard'
import { BehindFeatureFlag } from '../../../components/BehindFeatureFlag/BehindFeatureFlag'
import { BzSwitch } from '../../../elements/BzSwitch/BzSwitch'
import { MediumCard } from '../../../elements/Card/MediumCard'
import { SelectField } from '../../../elements/Forms/SelectField'
import { useBusinessUnits } from '../../../hooks/fetch/useBusinessUnits'
import useIsMobile from '../../../hooks/useIsMobile'
import { MaintenancePlanCardHeader } from '../components/MaintenancePlanCardHeader'
import { RightLinkButton } from '../components/RightLinkButton'
import { StaticHeightOptionCardHeader } from '../components/StaticHeightOptionCardHeader'
import { ConfigureMaintenancePlanFormData } from '../configureMaintenancePlanFormSchema'
import { MaintenancePlanWizardPageSection } from '../MaintenancePlanWizardPageSection'
import { FinalPricingSection } from './FinalPricingSection'
import { ReadOnlyBillingInfoSection } from './ReadOnlyBillingInfoSection'
import { ReadOnlyVisitsSection } from './ReadOnlyVisitsSection'

import { ReactHookFormItem } from '../../../elements/Forms/ReactHookFormItem'
type ReviewMaintenancePlanPanelProps = {
  accountContact: AccountContact
  selectedPlanType: MaintenancePlanDefinition
  visits: VisitViewModel[]
  goToConfigurePlanStep: () => void
  onSubmit: () => void
  hiddenPaymentMethods?: PaymentMethod[]
}

export const ReviewMaintenancePlanPanel = ({
  accountContact,
  selectedPlanType,
  visits,
  goToConfigurePlanStep,
  onSubmit,
  hiddenPaymentMethods,
}: ReviewMaintenancePlanPanelProps) => {
  const isMobile = useIsMobile()
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<ConfigureMaintenancePlanFormData>()
  const { businessUnits, businessUnitsRequired } = useBusinessUnits()

  const shouldSendActivationEmail = watch('shouldSendActivationEmail')
  const isImportedPlan = watch('isImportedPlan')
  const selectedBusinessUnitGuid = watch('businessUnitGuid')

  useEffect(() => {
    if (businessUnitsRequired && !selectedBusinessUnitGuid) {
      setValue('businessUnitRequired', true)
    }
  }, [businessUnitsRequired, selectedBusinessUnitGuid, setValue])

  return (
    <>
      <MaintenancePlanWizardPageSection
        title={
          <div className="flex flex-row items-center justify-center">
            <div className="mr-4 flex-1 text-[20px] font-semibold leading-[28px] text-[rgba(0,0,0,0.88)]">
              Maintenance Plan
            </div>
            <RightLinkButton
              onClick={goToConfigurePlanStep}
              title={isMobile ? 'Edit' : 'Edit Plan'}
            />
          </div>
        }
      >
        <SectionedCard
          accentBarColor={selectedPlanType.flare?.primaryColorHex ?? '#13C2C2'}
          sections={[
            {
              verticalPaddingClassName: 'py-6',
              content: (
                <MaintenancePlanCardHeader
                  maintenancePlanDefinition={selectedPlanType}
                />
              ),
            },
            {
              verticalPaddingClassName: 'py-4',
              content: (
                <ReadOnlyBillingInfoSection
                  hiddenPaymentMethods={hiddenPaymentMethods}
                />
              ),
            },
            {
              verticalPaddingClassName: 'py-4',
              content: <ReadOnlyVisitsSection visits={visits} />,
            },
          ]}
        />
      </MaintenancePlanWizardPageSection>
      {!isImportedPlan && (
        <MaintenancePlanWizardPageSection>
          <MediumCard>
            <StaticHeightOptionCardHeader
              faIcon={isMobile ? undefined : faEnvelopeOpenText}
              iconBgColor="#f5f5f5"
              iconClassName="text-[20px] text-[#565656]"
              title="Send Activation Email"
              description="The customer will receive an email that contains the details of the maintenance plan."
              extra={
                <div className="flex pt-[7px]">
                  <BzSwitch
                    checked={shouldSendActivationEmail}
                    data-testid="send-activation-email-switch"
                    onChange={e => setValue('shouldSendActivationEmail', e)}
                  />
                </div>
              }
              titleClassName="truncate mb-[2px]"
              descriptionClassName="mb-0 font-normal"
              titleLevel={5}
            />
          </MediumCard>
        </MaintenancePlanWizardPageSection>
      )}
      {businessUnits && (
        <BehindFeatureFlag
          enabledFeatureFlag="businessUnits"
          render={
            <MaintenancePlanWizardPageSection>
              <MediumCard>
                <StaticHeightOptionCardHeader
                  faIcon={isMobile ? undefined : faHouseBuilding}
                  iconBgColor="#f5f5f5"
                  iconClassName="text-[20px] text-[#565656]"
                  title="Business Unit"
                  description="Select a business unit to associate with this maintenance plan"
                  extra={
                    isMobile ? null : (
                      <BusinessUnitSelect
                        className="w-[200px]"
                        selectedBusinessUnitGuid={selectedBusinessUnitGuid}
                        businessUnits={businessUnits}
                        required={businessUnitsRequired}
                        onChange={businessUnitGuid =>
                          setValue('businessUnitGuid', businessUnitGuid)
                        }
                        errors={errors}
                      />
                    )
                  }
                  titleClassName="truncate mb-[2px]"
                  descriptionClassName="mb-0 font-normal"
                  titleLevel={5}
                />
                {isMobile && (
                  <BusinessUnitSelect
                    className="w-full"
                    selectedBusinessUnitGuid={selectedBusinessUnitGuid}
                    businessUnits={businessUnits}
                    required={businessUnitsRequired}
                    onChange={businessUnitGuid =>
                      setValue('businessUnitGuid', businessUnitGuid)
                    }
                    errors={errors}
                  />
                )}
              </MediumCard>
            </MaintenancePlanWizardPageSection>
          }
        />
      )}

      <MaintenancePlanWizardPageSection>
        <FinalPricingSection
          accountContact={accountContact}
          maintenancePlanDefinition={selectedPlanType}
          onSubmit={onSubmit}
        />
      </MaintenancePlanWizardPageSection>
    </>
  )
}

type BusinessUnitSelectProps = {
  selectedBusinessUnitGuid: string | undefined
  businessUnits: { businessUnitGuid: string; name: string }[] | undefined
  onChange: (businessUnitGuid: string) => void
  className?: string
  required?: boolean
  errors: FieldErrors<ConfigureMaintenancePlanFormData>
}

const BusinessUnitSelect = React.memo<BusinessUnitSelectProps>(
  ({
    selectedBusinessUnitGuid,
    businessUnits,
    onChange,
    className,
    required,
    errors,
  }) => {
    return (
      <div className="flex items-center gap-2 pt-[7px]">
        {required && <input type="hidden" name="businessUnitRequired" />}
        <ReactHookFormItem
          name="businessUnitGuid"
          errors={errors}
          className={className}
          label="Business Unit"
          required={required}
          render={({ field }) => (
            <SelectField
              options={
                businessUnits?.map(({ name, businessUnitGuid }) => ({
                  value: businessUnitGuid,
                  label: name,
                })) ?? []
              }
              {...field}
              title="Business Unit"
              sheetSize="half"
              disabled={!businessUnits?.length}
              placeholder="Select a Business Unit"
              allowClear
              showSearch
              optionFilterProp="label"
            />
          )}
        />
      </div>
    )
  },
)
