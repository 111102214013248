import {
  BzDateFns,
  DateTimeFormatter,
  Dfns,
  ENGLISH_LOCALE,
  EquipmentCondition,
  EquipmentConditionDisplayNames,
  EquipmentDisplayNames,
  EquipmentInstallationParty,
  EquipmentInstallationPartyDisplayNames,
  EquipmentOperationalStatus,
  EquipmentOperationalStatusDisplayNames,
  EquipmentType,
  InstalledEquipmentSummary,
  KNOWN_MANUFACTURERS,
  LocalDate,
  LocationGuid,
  effectiveLocationLongDisplayName,
  fuzzyMatch,
  isEquipmentType,
  isKeyOfEquipmentCondition,
  isKeyOfEquipmentInstallationParty,
  isKeyOfEquipmentOperationalStatus,
  nextGuid,
} from '@breezy/shared'
import { faBarcodeRead } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AutoComplete, Button, Divider, Form, Input, InputNumber } from 'antd'
import { useForm, useWatch } from 'antd/lib/form/Form'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import {
  OnsiteModalContent,
  OnsiteModalFooter,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import BzSelect from '../../elements/BzSelect/BzSelect'
import { trpc } from '../../hooks/trpc'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useIsSmallScreen } from '../../hooks/useIsMobile'
import { useExpectedCompanyEquipmentTypes } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { useModalState } from '../../utils/react-utils'
import { validatorNotFalsy } from '../../utils/validators'
import DatePicker, { DatePickerProps } from '../DatePicker/DatePicker'
import { LabelScanner } from '../LabelScanner/LabelScanner'
import { LoadingSpinner } from '../LoadingSpinner'
import { DrawerLocation } from '../TechnicianApp/TechExpDrawersContextWrapper/TechExpDrawersContextWrapper'

const parseISODate = (dateString: string) =>
  dateString
    ? LocalDate.parse(
        dateString,
        DateTimeFormatter.ofPattern('MM/dd/yyyy').withLocale(ENGLISH_LOCALE),
      ).toString()
    : ''

export type UpsertEquipmentFormSchema = {
  readonly selectedLocationGuid: LocationGuid
  readonly equipmentType: EquipmentType
  readonly installationDate: string
  readonly installationParty: string
  readonly estimatedEndOfLifeDate: string
  readonly averageLifeExpectancyYears: number
  readonly manufacturer: string
  readonly modelNumber: string
  readonly serialNumber: string
  readonly manufacturingDate: string
  readonly manufacturerWarrantyStartDate: string
  readonly manufacturerWarrantyEndDate: string
  readonly manufacturerWarrantyTerms: string
  readonly operationalStatus: EquipmentOperationalStatus
  readonly equipmentCondition: EquipmentCondition
  readonly laborWarrantyStartDate: string
  readonly laborWarrantyEndDate: string
  readonly laborWarrantyTerms: string
  readonly equipmentDimensions: string
  readonly description?: string
}

const DEFAULT_DATES_FORM_STATE = {
  installationDate: '',
  estimatedEndOfLifeDate: '',
  manufacturerWarrantyStartDate: '',
  manufacturerWarrantyEndDate: '',
  laborWarrantyStartDate: '',
  laborWarrantyEndDate: '',
  manufacturingDate: '',
}

type EquipmentUpsertFormMode =
  | 'create-for-location'
  | 'create-for-account'
  | 'update'

type CommonEquipmentUpsertFormProps = {
  mode: EquipmentUpsertFormMode
  onCancel: () => void
  onMutate: () => void
  labelClassName?: string
  noRowSpace?: boolean
  justifyFormCancelSubmitButtons?: 'start' | 'end'
  hideDivider?: boolean
  useMinimalForm?: boolean
}

export type PrefillEquipmentFormData = Omit<
  Partial<InstalledEquipmentSummary>,
  'locationGuid' | 'installedEquipmentGuid' | 'operationalStatus'
>

export const isPrefillEquipmentFormData = (
  data: PrefillEquipmentFormData | InstalledEquipmentSummary,
): data is PrefillEquipmentFormData =>
  !(data as InstalledEquipmentSummary).installedEquipmentGuid

type CreateForLocationEquipmentUpsertFormProps =
  CommonEquipmentUpsertFormProps & {
    mode: 'create-for-location'
    location: DrawerLocation
    initialValues?: PrefillEquipmentFormData
  }

type CreateForAccountEquipmentUpsertFormProps =
  CommonEquipmentUpsertFormProps & {
    mode: 'create-for-account'
    availableLocations: DrawerLocation[]
    initialValues?: PrefillEquipmentFormData
  }

type UpdateEquipmentUpsertFormProps = CommonEquipmentUpsertFormProps & {
  mode: 'update'
  initialValues: InstalledEquipmentSummary
  location: DrawerLocation
}

export type EquipmentUpsertFormProps =
  | CreateForLocationEquipmentUpsertFormProps
  | CreateForAccountEquipmentUpsertFormProps
  | UpdateEquipmentUpsertFormProps

// TODO: https://getbreezyapp.atlassian.net/browse/BZ-1018
export const EquipmentUpsertForm = React.memo(
  ({
    onCancel,
    onMutate,
    labelClassName,
    noRowSpace,
    justifyFormCancelSubmitButtons,
    hideDivider = true,
    useMinimalForm = false,
    initialValues,
    ...rest
  }: EquipmentUpsertFormProps) => {
    const message = useMessage()
    const [labelScannerOpen, openLabelScanner, closeLabelScanner] =
      useModalState()
    const labelScannerEnabled = useFeatureFlag('label-scanner')
    const isSmallScreen = useIsSmallScreen()

    const [isUploading, setIsUploading] = useState(false)
    const useFullForm = !useMinimalForm
    const companyEquipmentTypes = useExpectedCompanyEquipmentTypes()

    const [form] = useForm<UpsertEquipmentFormSchema>()

    const onLabelScannerAddEquipment = useCallback(
      (summary: PrefillEquipmentFormData) => {
        form.setFieldsValue({
          ...(summary as unknown as UpsertEquipmentFormSchema),
        })
      },
      [form],
    )

    const [dateFields, setDateFields] = useState<
      typeof DEFAULT_DATES_FORM_STATE
    >({
      installationDate: initialValues?.installationDate?.toString() ?? '',
      estimatedEndOfLifeDate:
        initialValues?.estimatedEndOfLifeDate?.toString() ?? '',
      manufacturerWarrantyStartDate:
        initialValues?.manufacturerWarrantyStartDate?.toString() ?? '',
      manufacturerWarrantyEndDate:
        initialValues?.manufacturerWarrantyEndDate?.toString() ?? '',
      laborWarrantyStartDate:
        initialValues?.laborWarrantyStartDate?.toString() ?? '',
      laborWarrantyEndDate:
        initialValues?.laborWarrantyEndDate?.toString() ?? '',
      manufacturingDate: initialValues?.manufacturingDate?.toString() ?? '',
    })

    const upsertEquipmentMutation = trpc.installedEquipment[
      'installed-equipment:upsert'
    ].useMutation({
      onSuccess: () => {
        message.success('Equipment added')
        onMutate()
      },
      onError: e => {
        message.error('Failed to add equipment: ' + e.message)
      },
    })

    const FullFormRow = useCallback(
      ({ children }: { children: React.ReactNode }) =>
        useFullForm ? (
          <div
            className={classNames('flex w-full flex-col sm:flex-row', {
              'sm:space-x-2': !noRowSpace,
            })}
          >
            {children}
          </div>
        ) : null,
      [noRowSpace, useFullForm],
    )

    const onFormSubmit = useCallback(async () => {
      setIsUploading(true)

      try {
        const values = await form.validateFields()
        const dateOrUndefined = (dateString: string) =>
          dateString && dateString.length > 0
            ? // TODO: https://getbreezyapp.atlassian.net/browse/BZ-1018
              // eslint-disable-next-line breezy/no-to-local-date-string
              BzDateFns.toLocalDateString(dateString)
            : undefined

        const guid =
          (initialValues as InstalledEquipmentSummary)
            ?.installedEquipmentGuid ?? nextGuid()

        upsertEquipmentMutation.mutate({
          installedEquipmentGuid: guid,
          locationGuid: values.selectedLocationGuid,
          equipmentType: isEquipmentType(values.equipmentType)
            ? EquipmentType[values.equipmentType]
            : EquipmentType.AIR_CONDITIONER,
          installationDate: dateOrUndefined(dateFields.installationDate),
          installationParty: isKeyOfEquipmentInstallationParty(
            values.installationParty,
          )
            ? EquipmentInstallationParty[values.installationParty]
            : undefined,
          estimatedEndOfLifeDate: dateOrUndefined(
            dateFields.estimatedEndOfLifeDate,
          ),
          averageLifeExpectancyYears: values.averageLifeExpectancyYears,
          manufacturer: values.manufacturer,
          modelNumber: values.modelNumber,
          serialNumber: values.serialNumber,
          manufacturerWarrantyStartDate: dateOrUndefined(
            dateFields.manufacturerWarrantyStartDate,
          ),
          manufacturerWarrantyEndDate: dateOrUndefined(
            dateFields.manufacturerWarrantyEndDate,
          ),
          manufacturerWarrantyTerms: values.manufacturerWarrantyTerms,
          operationalStatus: isKeyOfEquipmentOperationalStatus(
            values.operationalStatus,
          )
            ? EquipmentOperationalStatus[values.operationalStatus]
            : EquipmentOperationalStatus.UNKNOWN,
          equipmentCondition: isKeyOfEquipmentCondition(
            values.equipmentCondition,
          )
            ? EquipmentCondition[values.equipmentCondition]
            : undefined,
          laborWarrantyStartDate: dateOrUndefined(
            dateFields.laborWarrantyStartDate,
          ),
          laborWarrantyEndDate: dateOrUndefined(
            dateFields.laborWarrantyEndDate,
          ),
          manufacturingDate: dateOrUndefined(dateFields.manufacturingDate),
          laborWarrantyTerms: values.laborWarrantyTerms,
          equipmentDimensions: values.equipmentDimensions,
          description: values.description,
          sourceType: initialValues?.sourceType ?? 'FORM',
        })
      } catch (error) {
        console.error(error)
        setIsUploading(false)
      }
    }, [
      dateFields.estimatedEndOfLifeDate,
      dateFields.installationDate,
      dateFields.laborWarrantyEndDate,
      dateFields.laborWarrantyStartDate,
      dateFields.manufacturerWarrantyEndDate,
      dateFields.manufacturerWarrantyStartDate,
      dateFields.manufacturingDate,
      form,
      initialValues,
      upsertEquipmentMutation,
    ])

    const onInstallationDateSelected = useCallback<
      NonNullable<DatePickerProps['onChange']>
    >(
      (_, dateString) => {
        setDateFields({
          ...dateFields,
          installationDate: parseISODate(dateString),
        })
      },
      [dateFields],
    )

    const onInstallationDateBlurred = useCallback<
      NonNullable<DatePickerProps['onBlur']>
    >(
      e => {
        if (!e.target.value) {
          setDateFields({
            ...dateFields,
            installationDate: '',
          })
        }
      },
      [dateFields],
    )

    const onEstimatedEndOfLifeDateSelected = useCallback<
      NonNullable<DatePickerProps['onChange']>
    >(
      (_, dateString) => {
        setDateFields({
          ...dateFields,
          estimatedEndOfLifeDate: parseISODate(dateString),
        })
      },
      [dateFields],
    )

    const onEstimatedEndOfLifeDateBlurred = useCallback<
      NonNullable<DatePickerProps['onBlur']>
    >(
      e => {
        if (!e.target.value) {
          setDateFields({
            ...dateFields,
            estimatedEndOfLifeDate: '',
          })
        }
      },
      [dateFields],
    )

    const onManufacturerWarrantyStartDateSelected = useCallback<
      NonNullable<DatePickerProps['onChange']>
    >(
      (_, dateString) => {
        setDateFields({
          ...dateFields,
          manufacturerWarrantyStartDate: parseISODate(dateString),
        })
      },
      [dateFields],
    )

    const onManufacturerWarrantyStartDateBlurred = useCallback<
      NonNullable<DatePickerProps['onBlur']>
    >(
      e => {
        if (!e.target.value) {
          setDateFields({
            ...dateFields,
            manufacturerWarrantyStartDate: '',
          })
        }
      },
      [dateFields],
    )

    const onManufacturerWarrantyEndDateSelected = useCallback<
      NonNullable<DatePickerProps['onChange']>
    >(
      (_, dateString) => {
        setDateFields({
          ...dateFields,
          manufacturerWarrantyEndDate: parseISODate(dateString),
        })
      },
      [dateFields],
    )

    const onManufacturerWarrantyEndDateBlurred = useCallback<
      NonNullable<DatePickerProps['onBlur']>
    >(
      e => {
        if (!e.target.value) {
          setDateFields({
            ...dateFields,
            manufacturerWarrantyEndDate: '',
          })
        }
      },
      [dateFields],
    )

    const onLaborWarrantyStartDateSelected = useCallback<
      NonNullable<DatePickerProps['onChange']>
    >(
      (_, dateString) => {
        setDateFields({
          ...dateFields,
          laborWarrantyStartDate: parseISODate(dateString),
        })
      },
      [dateFields],
    )

    const onLaborWarrantyStartDateBlurred = useCallback<
      NonNullable<DatePickerProps['onBlur']>
    >(
      e => {
        if (!e.target.value) {
          setDateFields({
            ...dateFields,
            laborWarrantyStartDate: '',
          })
        }
      },
      [dateFields],
    )

    const onLaborWarrantyEndDateSelected = useCallback<
      NonNullable<DatePickerProps['onChange']>
    >(
      (_, dateString) => {
        setDateFields({
          ...dateFields,
          laborWarrantyEndDate: parseISODate(dateString),
        })
      },
      [dateFields],
    )

    const onLaborWarrantyEndDateBlurred = useCallback<
      NonNullable<DatePickerProps['onBlur']>
    >(
      e => {
        if (!e.target.value) {
          setDateFields({
            ...dateFields,
            laborWarrantyEndDate: '',
          })
        }
      },
      [dateFields],
    )

    const onManufacturingDateChanged = useCallback<
      NonNullable<DatePickerProps['onChange']>
    >(
      (_, dateString) => {
        setDateFields({
          ...dateFields,
          manufacturingDate: parseISODate(dateString),
        })
      },
      [dateFields],
    )

    const onManufacturingDateChangedBlurred = useCallback<
      NonNullable<DatePickerProps['onBlur']>
    >(
      e => {
        if (!e.target.value) {
          setDateFields({
            ...dateFields,
            manufacturingDate: '',
          })
        }
      },
      [dateFields],
    )

    const selectableLocations = useMemo(() => {
      if (rest.mode === 'create-for-account') {
        return rest.availableLocations
      } else {
        return [rest.location]
      }
    }, [rest])

    const selectedLocationGuid = useWatch('selectedLocationGuid', form)
    const selectedEquipmentType = useWatch('equipmentType', form)
    const selectedOperationalStatus = useWatch('operationalStatus', form)
    const selectedEquipmentCondition = useWatch(
      'equipmentCondition',
      form,
    ) as EquipmentCondition

    const selectedInstallationParty = useWatch(
      'installationParty',
      form,
    ) as EquipmentInstallationParty

    return (
      <OnsiteModalContent
        header={`${rest.mode === 'update' ? 'Edit' : 'New'} Equipment`}
        onClose={onCancel}
        footer={
          <OnsiteModalFooter
            onCancel={onCancel}
            onSubmit={onFormSubmit}
            cancelText="Cancel"
            submitText="Save"
            loading={isUploading}
          />
        }
      >
        <div className="w-full p-2 sm:p-0">
          {!isUploading && (
            <Form
              form={form}
              layout="vertical"
              validateTrigger="onBlur"
              onFinish={onFormSubmit}
              initialValues={{
                selectedLocationGuid:
                  rest.mode === 'create-for-account'
                    ? rest.availableLocations.length === 1
                      ? rest.availableLocations[0].locationGuid
                      : null
                    : rest.location.locationGuid,
                operationalStatus:
                  (initialValues as InstalledEquipmentSummary)
                    ?.operationalStatus ??
                  EquipmentOperationalStatus.IN_SERVICE,
                equipmentType: initialValues?.equipmentType,
                installationParty: initialValues?.installationParty,
                averageLifeExpectancyYears:
                  initialValues?.averageLifeExpectancyYears,
                manufacturer: initialValues?.manufacturer,
                modelNumber: initialValues?.modelNumber,
                serialNumber: initialValues?.serialNumber,
                manufacturerWarrantyTerms:
                  initialValues?.manufacturerWarrantyTerms,
                laborWarrantyTerms: initialValues?.laborWarrantyTerms,
                equipmentDimensions: initialValues?.equipmentDimensions,
                description: initialValues?.description,
                equipmentCondition: initialValues?.equipmentCondition,
              }}
            >
              {form.getFieldValue('sourceType') !== 'LABEL_SCANNER' &&
                labelScannerEnabled && (
                  <div
                    className={classNames('flex w-full flex-col', {
                      'sm:space-x-2': !noRowSpace,
                    })}
                  >
                    <Button
                      className="w-full"
                      size={isSmallScreen ? 'large' : 'middle'}
                      onClick={openLabelScanner}
                      type="primary"
                    >
                      <FontAwesomeIcon icon={faBarcodeRead} className="mr-2" />
                      Scan Equipment Label
                    </Button>
                    <Divider>or</Divider>
                  </div>
                )}
              <div
                className={classNames('flex w-full flex-col sm:flex-row', {
                  'sm:space-x-2': !noRowSpace,
                })}
              >
                <div className="w-full">
                  <Form.Item
                    name="selectedLocationGuid"
                    label="Location"
                    className="semibold_14_22 grey9"
                    required
                    rules={[{ validator: validatorNotFalsy('Location') }]}
                  >
                    <BzSelect
                      title="Location"
                      size={isSmallScreen ? 'large' : 'middle'}
                      showSearch
                      filterOption={(inputValue, option) => {
                        return !![
                          option?.children,
                          option?.value,
                          option?.label,
                        ]
                          .filter(Boolean)
                          .map(v => `${v}`)
                          .find(v => fuzzyMatch(v, inputValue))
                      }}
                      value={selectedLocationGuid}
                      placeholder="Select a Location"
                      sheetSize="half"
                      disabled={
                        rest.mode === 'update' ||
                        rest.mode === 'create-for-location'
                      }
                      options={selectableLocations.map(location => ({
                        value: location.locationGuid,
                        label: effectiveLocationLongDisplayName(location),
                      }))}
                      onChange={(value: string) =>
                        form.setFieldValue('selectedLocationGuid', value)
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              <div
                className={classNames(
                  'flex w-full flex-col gap-x-4 sm:flex-row',
                  {
                    'sm:space-x-2': !noRowSpace,
                  },
                )}
              >
                <div className="w-full sm:w-1/2">
                  <Form.Item
                    required
                    label="Equipment Type"
                    name="equipmentType"
                    className={labelClassName}
                    rules={[{ validator: validatorNotFalsy('Equipment type') }]}
                  >
                    <BzSelect
                      title="Equipment Type"
                      size={isSmallScreen ? 'large' : 'middle'}
                      value={selectedEquipmentType}
                      showSearch
                      filterOption={(inputValue, option) => {
                        return !![
                          option?.children,
                          option?.value,
                          option?.label,
                        ]
                          .filter(Boolean)
                          .map(v => `${v}`)
                          .find(v => fuzzyMatch(v, inputValue))
                      }}
                      options={companyEquipmentTypes.map(equipmentType => ({
                        value: equipmentType,
                        label: EquipmentDisplayNames[equipmentType],
                      }))}
                      onChange={(value: EquipmentType) =>
                        form.setFieldValue('equipmentType', value)
                      }
                    />
                  </Form.Item>
                </div>

                <div className="w-full sm:w-1/2">
                  <Form.Item
                    label="Manufacturer"
                    name="manufacturer"
                    className={labelClassName}
                  >
                    <AutoComplete
                      size={isSmallScreen ? 'large' : 'middle'}
                      placeholder="Select or create new Manufacturer"
                      options={KNOWN_MANUFACTURERS.map(opt => ({
                        value: opt,
                        label: opt,
                      }))}
                      filterOption={(inputValue, option) =>
                        option?.value
                          ? fuzzyMatch(option?.value, inputValue)
                          : false
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              <div
                className={classNames(
                  'flex w-full flex-col gap-x-4 sm:flex-row',
                  {
                    'sm:space-x-2': !noRowSpace,
                  },
                )}
              >
                <div className="w-full sm:w-1/2">
                  <Form.Item
                    label="Model Number"
                    name="modelNumber"
                    className={labelClassName}
                  >
                    <Input size={isSmallScreen ? 'large' : 'middle'} />
                  </Form.Item>
                </div>

                <div className="w-full sm:w-1/2">
                  <Form.Item
                    label="Serial Number"
                    name="serialNumber"
                    className={labelClassName}
                  >
                    <Input size={isSmallScreen ? 'large' : 'middle'} />
                  </Form.Item>
                </div>
              </div>

              <div
                className={classNames(
                  'flex w-full flex-col gap-x-4 sm:flex-row',
                  {
                    'sm:space-x-2': !noRowSpace,
                  },
                )}
              >
                <div className="w-full sm:w-1/2">
                  <Form.Item
                    label="Equipment Dimensions"
                    name="equipmentDimensions"
                    className={labelClassName}
                  >
                    <Input size={isSmallScreen ? 'large' : 'middle'} />
                  </Form.Item>
                </div>
                <div className="w-full sm:w-1/2">
                  <Form.Item
                    label="Equipment Condition"
                    name="equipmentCondition"
                    className={labelClassName}
                  >
                    <BzSelect
                      title="Equipment Condition"
                      size={isSmallScreen ? 'large' : 'middle'}
                      sheetSize="half"
                      value={selectedEquipmentCondition}
                      options={Object.values(EquipmentCondition).map(
                        condition => ({
                          value: condition,
                          label: EquipmentConditionDisplayNames[condition],
                        }),
                      )}
                      onChange={(value: EquipmentCondition) =>
                        form.setFieldValue('equipmentCondition', value)
                      }
                    />
                  </Form.Item>
                </div>
              </div>

              <div
                className={classNames(
                  'flex w-full flex-col gap-x-4 sm:flex-row',
                  {
                    'sm:space-x-2': !noRowSpace,
                  },
                )}
              >
                <div className="w-full sm:w-1/2">
                  <Form.Item
                    label="Manufacturing Date"
                    name="manufacturingDate"
                    className={labelClassName}
                  >
                    <DatePicker
                      size={isSmallScreen ? 'large' : 'middle'}
                      onChange={onManufacturingDateChanged}
                      onBlur={onManufacturingDateChangedBlurred}
                      defaultValue={
                        dateFields?.manufacturingDate
                          ? Dfns.parseISO(dateFields.manufacturingDate)
                          : undefined
                      }
                      allowClear
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                </div>

                <div className="w-full sm:w-1/2">
                  <Form.Item
                    label="Installation Date"
                    name="installationDate"
                    className={labelClassName}
                  >
                    <DatePicker
                      size={isSmallScreen ? 'large' : 'middle'}
                      allowClear
                      onChange={onInstallationDateSelected}
                      onBlur={onInstallationDateBlurred}
                      defaultValue={
                        dateFields?.installationDate
                          ? Dfns.parseISO(dateFields.installationDate)
                          : undefined
                      }
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                </div>
              </div>

              <Divider className="mt-[4px]" />

              <div
                className={classNames(
                  'flex w-full flex-col gap-x-4 sm:flex-row',
                  {
                    'sm:space-x-2': !noRowSpace,
                  },
                )}
              >
                <div className="w-full sm:w-1/2">
                  <Form.Item
                    label="Installation Party"
                    className={labelClassName}
                    name="installationParty"
                  >
                    <BzSelect
                      title="Installation Party"
                      size={isSmallScreen ? 'large' : 'middle'}
                      sheetSize="half"
                      value={selectedInstallationParty}
                      options={Object.values(EquipmentInstallationParty).map(
                        party => ({
                          value: party,
                          label: EquipmentInstallationPartyDisplayNames[party],
                        }),
                      )}
                      onChange={(value: EquipmentInstallationParty) =>
                        form.setFieldValue('installationParty', value)
                      }
                    />
                  </Form.Item>
                </div>
                <div className="w-full sm:w-1/2">
                  <Form.Item
                    label="Avg. Life Expectancy (Years)"
                    name="averageLifeExpectancyYears"
                    className={labelClassName}
                  >
                    <InputNumber
                      controls={false}
                      style={{
                        width: '100%',
                      }}
                      size={isSmallScreen ? 'large' : 'middle'}
                    />
                  </Form.Item>
                </div>
              </div>

              <div
                className={classNames(
                  'flex w-full flex-col gap-x-4 sm:flex-row',
                  {
                    'sm:space-x-2': !noRowSpace,
                  },
                )}
              >
                <div className="w-full sm:w-1/2">
                  <Form.Item
                    label="Est. End of Life Date"
                    name="endOfLifeDate"
                    className={`sm:mr-3 ${labelClassName}`}
                  >
                    <DatePicker
                      size={isSmallScreen ? 'large' : 'middle'}
                      onChange={onEstimatedEndOfLifeDateSelected}
                      onBlur={onEstimatedEndOfLifeDateBlurred}
                      defaultValue={
                        dateFields?.estimatedEndOfLifeDate
                          ? Dfns.parseISO(dateFields.estimatedEndOfLifeDate)
                          : undefined
                      }
                      allowClear
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                </div>
              </div>

              <Divider className="mt-[4px]" />

              <div
                className={classNames(
                  'flex w-full flex-col gap-x-4 sm:flex-row',
                  {
                    'sm:space-x-2': !noRowSpace,
                  },
                )}
              >
                <div className="w-full sm:w-1/2">
                  <Form.Item
                    label="Manufacturer Warranty Start Date"
                    name="manufacturerWarrantyStartDate"
                    className={labelClassName}
                  >
                    <DatePicker
                      size={isSmallScreen ? 'large' : 'middle'}
                      onChange={onManufacturerWarrantyStartDateSelected}
                      onBlur={onManufacturerWarrantyStartDateBlurred}
                      defaultValue={
                        dateFields?.manufacturerWarrantyStartDate
                          ? Dfns.parseISO(
                              dateFields.manufacturerWarrantyStartDate,
                            )
                          : undefined
                      }
                      allowClear
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="w-full sm:w-1/2">
                  <Form.Item
                    label="Manufacturer Warranty End Date"
                    name="manufacturerWarrantyEndDate"
                    className={labelClassName}
                  >
                    <DatePicker
                      size={isSmallScreen ? 'large' : 'middle'}
                      onChange={onManufacturerWarrantyEndDateSelected}
                      onBlur={onManufacturerWarrantyEndDateBlurred}
                      defaultValue={
                        dateFields?.manufacturerWarrantyEndDate
                          ? Dfns.parseISO(
                              dateFields.manufacturerWarrantyEndDate,
                            )
                          : undefined
                      }
                      allowClear
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                </div>
              </div>

              <FullFormRow>
                <div className="w-full">
                  <Form.Item
                    label="Manufacturer Warranty Terms"
                    name="manufacturerWarrantyTerms"
                    className={labelClassName}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </div>
              </FullFormRow>

              <div
                className={classNames(
                  'flex w-full flex-col gap-x-4 sm:flex-row',
                  {
                    'sm:space-x-2': !noRowSpace,
                  },
                )}
              >
                <div className="w-full sm:w-1/2">
                  <Form.Item
                    label="Labor Warranty Start Date"
                    name="laborWarrantyStartDate"
                    className={labelClassName}
                  >
                    <DatePicker
                      size={isSmallScreen ? 'large' : 'middle'}
                      onChange={onLaborWarrantyStartDateSelected}
                      onBlur={onLaborWarrantyStartDateBlurred}
                      defaultValue={
                        dateFields?.laborWarrantyStartDate
                          ? Dfns.parseISO(dateFields.laborWarrantyStartDate)
                          : undefined
                      }
                      allowClear
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="w-full sm:w-1/2">
                  <Form.Item
                    label="Labor Warranty End Date"
                    name="laborWarrantyEndDate"
                    className={labelClassName}
                  >
                    <DatePicker
                      size={isSmallScreen ? 'large' : 'middle'}
                      onChange={onLaborWarrantyEndDateSelected}
                      onBlur={onLaborWarrantyEndDateBlurred}
                      defaultValue={
                        dateFields?.laborWarrantyEndDate
                          ? Dfns.parseISO(dateFields.laborWarrantyEndDate)
                          : undefined
                      }
                      allowClear
                      style={{
                        width: '100%',
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <FullFormRow>
                <div className="w-full">
                  <Form.Item
                    label="Labor Warranty Terms"
                    name="laborWarrantyTerms"
                    className={labelClassName}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </div>
              </FullFormRow>

              <Divider className="mt-[4px]" />

              <FullFormRow>
                <div className="w-full">
                  <Form.Item
                    label="Description"
                    name="description"
                    className={labelClassName}
                  >
                    <Input.TextArea
                      placeholder="Enter a description of the equipment"
                      autoSize={{ minRows: 3, maxRows: 6 }}
                    />
                  </Form.Item>
                </div>
              </FullFormRow>

              <Form.Item
                required
                hidden
                label="Operational"
                name="operationalStatus"
                className={labelClassName}
                rules={[{ validator: validatorNotFalsy('Operational status') }]}
              >
                <BzSelect
                  title="Operational Status"
                  size={isSmallScreen ? 'large' : 'middle'}
                  sheetSize="half"
                  value={selectedOperationalStatus}
                  options={Object.values(EquipmentOperationalStatus).map(
                    operationalStatus => ({
                      value: operationalStatus,
                      label:
                        EquipmentOperationalStatusDisplayNames[
                          operationalStatus
                        ],
                    }),
                  )}
                  onChange={(value: EquipmentOperationalStatus) =>
                    form.setFieldValue('operationalStatus', value)
                  }
                />
              </Form.Item>

              {/* {!hideDivider && <Divider className="mt-[4px]" />}
              <FormCancelSubmitButtons
                onCancel={onCancel}
                justify={justifyFormCancelSubmitButtons}
              /> */}
            </Form>
          )}
          {isUploading && (
            <div className="h-full w-full">
              <LoadingSpinner />
            </div>
          )}
          {labelScannerOpen && (
            <LabelScanner
              onClose={closeLabelScanner}
              onAddEquipment={onLabelScannerAddEquipment}
            />
          )}
        </div>
      </OnsiteModalContent>
    )
  },
)
