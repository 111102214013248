import {
  BULK_PRICEBOOK_CATEGORIES_SHEET_NAME,
  BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
  BULK_PRICEBOOK_IMPORT_WORKBOOK_NAME,
  BULK_PRICEBOOK_ITEMS_IMPORT_SHEET_NAME,
  BULK_PRICEBOOK_ITEMS_IMPORT_SHEET_SLUG,
  BULK_PRICEBOOK_QBO_INCOME_ACCOUNTS_SHEET_NAME,
  BULK_PRICEBOOK_QBO_INCOME_ACCOUNTS_SHEET_SLUG,
  PricebookItemTypeEnum,
} from '@breezy/shared'
import { Flatfile } from '@flatfile/api'

export const pricebookImportWorkbook: Flatfile.CreateWorkbookConfig = {
  name: BULK_PRICEBOOK_IMPORT_WORKBOOK_NAME,
  labels: ['pinned'],
  sheets: [
    {
      name: BULK_PRICEBOOK_ITEMS_IMPORT_SHEET_NAME,
      slug: BULK_PRICEBOOK_ITEMS_IMPORT_SHEET_SLUG,
      fields: [
        {
          key: 'name',
          type: 'string',
          label: 'Name',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'category',
          type: 'reference',
          label: 'Category',
          config: {
            ref: BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
            key: 'name',
            relationship: 'has-one',
          },
        },
        {
          key: 'description',
          type: 'string',
          label: 'Description',
        },
        {
          key: 'itemType',
          type: 'enum',
          label: 'Item Type',
          config: {
            allowCustom: false,
            options: Object.values(PricebookItemTypeEnum).map(value => ({
              label: value,
              value,
            })),
          },
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'pricebookItemCode',
          type: 'string',
          label: 'Code',
          constraints: [],
        },
        {
          key: 'costUsd',
          type: 'number',
          label: 'Cost (USD)',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'priceUsd',
          type: 'number',
          label: 'Price (USD)',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'isTaxable',
          type: 'boolean',
          label: 'Taxable',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'isActive',
          type: 'boolean',
          label: 'Active',
        },
        {
          key: 'isDiscountable',
          type: 'boolean',
          label: 'Discountable',
        },
        {
          key: 'sourcePhotoUrl',
          type: 'string',
          label: 'Source Photo URL',
        },
        {
          key: 'pricebookItemGuid',
          type: 'string',
          label: 'pricebookItemGuid (generated)',
          readonly: true,
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'pricebookCategoryGuid',
          type: 'reference',
          label: 'pricebookCategoryGuid (generated)',
          config: {
            ref: BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
            key: 'pricebookCategoryGuid',
            relationship: 'has-one',
          },
          readonly: true,
        },
      ],
    },
    {
      name: BULK_PRICEBOOK_CATEGORIES_SHEET_NAME,
      slug: BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
      constraints: [
        {
          name: 'Name and Parent Category',
          fields: [
            'name',
            'pricebookCategoryGuid',
            'parentCategoryName',
            'parentCategoryGuid',
          ],
          type: 'unique',
          strategy: 'concat',
        },
      ],
      fields: [
        {
          key: 'name',
          type: 'string',
          label: 'Name',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'parentCategoryName',
          type: 'reference',
          label: 'Parent Category',
          config: {
            ref: BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
            key: 'name',
            relationship: 'has-one',
          },
        },
        {
          key: 'sourcePhotoUrl',
          type: 'string',
          label: 'Source Photo URL',
        },
        {
          key: 'pricebookCategoryGuid',
          type: 'string',
          label: 'pricebookCategoryGuid (generated)',
          readonly: true,
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'parentCategoryGuid',
          type: 'reference',
          label: 'parentCategoryGuid (generated)',
          readonly: true,
          config: {
            ref: BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
            key: 'pricebookCategoryGuid',
            relationship: 'has-one',
          },
        },
      ],
    },
  ],
  actions: [
    {
      operation: 'submitActionFg',
      mode: 'foreground',
      label: 'Review',
      description: 'Review changes',
      primary: true,
      constraints: [
        {
          type: 'hasData',
        },
        {
          type: 'hasAllValid',
        },
      ],
    },
  ],
}

export const pricebookImportWorkbookWithQbo: Flatfile.CreateWorkbookConfig = {
  name: BULK_PRICEBOOK_IMPORT_WORKBOOK_NAME,
  labels: ['pinned'],
  sheets: [
    {
      name: BULK_PRICEBOOK_ITEMS_IMPORT_SHEET_NAME,
      slug: BULK_PRICEBOOK_ITEMS_IMPORT_SHEET_SLUG,
      fields: [
        {
          key: 'name',
          type: 'string',
          label: 'Name',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'category',
          type: 'reference',
          label: 'Category',
          config: {
            ref: BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
            key: 'name',
            relationship: 'has-one',
          },
        },
        {
          key: 'description',
          type: 'string',
          label: 'Description',
        },
        {
          key: 'itemType',
          type: 'enum',
          label: 'Item Type',
          config: {
            allowCustom: false,
            options: Object.values(PricebookItemTypeEnum).map(value => ({
              label: value,
              value,
            })),
          },
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'pricebookItemCode',
          type: 'string',
          label: 'Code',
          constraints: [],
        },
        {
          key: 'costUsd',
          type: 'number',
          label: 'Cost (USD)',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'priceUsd',
          type: 'number',
          label: 'Price (USD)',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'isTaxable',
          type: 'boolean',
          label: 'Taxable',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'isActive',
          type: 'boolean',
          label: 'Active',
        },
        {
          key: 'isDiscountable',
          type: 'boolean',
          label: 'Discountable',
        },
        {
          key: 'sourcePhotoUrl',
          type: 'string',
          label: 'Source Photo URL',
        },
        {
          key: 'qboIncomeAccountName',
          type: 'reference',
          label: 'QBO Income Account',
          config: {
            ref: BULK_PRICEBOOK_QBO_INCOME_ACCOUNTS_SHEET_SLUG,
            key: 'name',
            relationship: 'has-one',
          },
        },
        {
          key: 'qboIncomeAccountId',
          type: 'reference',
          label: 'qboIncomeAccountId (generated)',
          readonly: true,
          config: {
            ref: BULK_PRICEBOOK_QBO_INCOME_ACCOUNTS_SHEET_SLUG,
            key: 'qboIncomeAccountId',
            relationship: 'has-one',
          },
        },
        {
          key: 'pricebookItemGuid',
          type: 'string',
          label: 'pricebookItemGuid (generated)',
          readonly: true,
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'pricebookCategoryGuid',
          type: 'reference',
          label: 'pricebookCategoryGuid (generated)',
          config: {
            ref: BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
            key: 'pricebookCategoryGuid',
            relationship: 'has-one',
          },
          readonly: true,
        },
      ],
    },
    {
      name: BULK_PRICEBOOK_CATEGORIES_SHEET_NAME,
      slug: BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
      constraints: [
        {
          name: 'Name and Parent Category',
          fields: [
            'name',
            'pricebookCategoryGuid',
            'parentCategoryName',
            'parentCategoryGuid',
          ],
          type: 'unique',
          strategy: 'concat',
        },
      ],
      fields: [
        {
          key: 'name',
          type: 'string',
          label: 'Name',
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'parentCategoryName',
          type: 'reference',
          label: 'Parent Category',
          config: {
            ref: BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
            key: 'name',
            relationship: 'has-one',
          },
        },
        {
          key: 'sourcePhotoUrl',
          type: 'string',
          label: 'Source Photo URL',
        },
        {
          key: 'pricebookCategoryGuid',
          type: 'string',
          label: 'pricebookCategoryGuid (generated)',
          readonly: true,
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'parentCategoryGuid',
          type: 'reference',
          label: 'parentCategoryGuid (generated)',
          readonly: true,
          config: {
            ref: BULK_PRICEBOOK_CATEGORIES_SHEET_SLUG,
            key: 'pricebookCategoryGuid',
            relationship: 'has-one',
          },
        },
      ],
    },
    {
      name: BULK_PRICEBOOK_QBO_INCOME_ACCOUNTS_SHEET_NAME,
      slug: BULK_PRICEBOOK_QBO_INCOME_ACCOUNTS_SHEET_SLUG,
      fields: [
        {
          key: 'name',
          type: 'string',
          label: 'Name',
          readonly: true,
          constraints: [
            {
              type: 'required',
            },
          ],
        },
        {
          key: 'qboIncomeAccountId',
          type: 'string',
          label: 'QBO Income Account ID',
          readonly: true,
          constraints: [
            {
              type: 'required',
            },
          ],
        },
      ],
    },
  ],
  actions: [
    {
      operation: 'submitActionFg',
      mode: 'foreground',
      label: 'Review',
      description: 'Review changes',
      primary: true,
      constraints: [
        {
          type: 'hasData',
        },
        {
          type: 'hasAllValid',
        },
      ],
    },
  ],
}
