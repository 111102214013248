import { Alert, Button, Input, Modal, Spin, Typography } from 'antd'
import React, { useState } from 'react'
import { trpc } from '../../../hooks/trpc'
import { useMessage } from '../../../utils/antd-utils'

const { Text, Title, Paragraph } = Typography

interface CompanyWipeDataModalProps {
  open: boolean
  onClose: () => void
  companyGuid: string
  company?: {
    companyGuid: string
    name: string
    dataMigrationStatus?: string
  }
  onSuccess?: () => void
}

export const CompanyWipeDataModal: React.FC<CompanyWipeDataModalProps> = ({
  open,
  onClose,
  companyGuid,
  company,
  onSuccess,
}) => {
  const [confirmInput, setConfirmInput] = useState('')
  const message = useMessage()
  const [isConfirmationStep, setIsConfirmationStep] = useState(false)

  const wipeCompanyDataMutation = trpc.devTools[
    'devtools:wipe-company-data'
  ].useMutation({
    onSuccess: () => {
      message.success(`Successfully wiped data for ${company?.name}`)
      handleClose()
      onSuccess?.()
    },
    onError: error => {
      message.error(`Failed to wipe company data: ${error.message}`)
    },
  })

  const handleClose = () => {
    setConfirmInput('')
    setIsConfirmationStep(false)
    onClose()
  }

  const proceedToConfirmation = () => {
    setIsConfirmationStep(true)
  }

  const handleWipeData = () => {
    if (!company) return

    if (confirmInput !== company.name) {
      message.error('Company name does not match')
      return
    }

    wipeCompanyDataMutation.mutate({ companyGuid })
  }

  if (!company) {
    return null
  }

  const isCompanyInMigrationStatus = company.dataMigrationStatus === 'MIGRATION'
  const confirmationMessage = `Please type "${company.name}" to confirm deletion`

  return (
    <Modal
      title="Wipe Company Data"
      open={open}
      onCancel={handleClose}
      width={600}
      footer={null}
    >
      {!isConfirmationStep ? (
        <>
          <Title level={4}>Warning: Destructive Action</Title>
          <Paragraph>
            You are about to permanently delete all data for:
          </Paragraph>
          <div className="my-4 rounded border border-gray-300 p-3">
            <Text strong>Company Name:</Text> {company.name}
            <br />
            <Text strong>Company GUID:</Text> {company.companyGuid}
            <br />
            <Text strong>Migration Status:</Text>{' '}
            {company.dataMigrationStatus || 'UNKNOWN'}
          </div>

          {!isCompanyInMigrationStatus && (
            <Alert
              message="Cannot Proceed"
              description="This company is not in MIGRATION status. Only companies in MIGRATION status can have their data wiped."
              type="error"
              showIcon
              className="mb-4"
            />
          )}

          <Paragraph>
            This action will permanently delete ALL data associated with this
            company, including:
          </Paragraph>
          <ul className="mb-4 ml-6 list-disc">
            <li>Jobs and job history</li>
            <li>Accounts and contacts</li>
            <li>Invoices and payment records</li>
            <li>Appointments and schedules</li>
            <li>Maintenance plans</li>
            <li>All other company-specific data</li>
          </ul>

          <Paragraph type="danger" strong>
            This action cannot be undone!
          </Paragraph>

          <div className="mt-4 flex justify-end">
            <Button onClick={handleClose} className="mr-2">
              Cancel
            </Button>
            <Button
              type="primary"
              danger
              onClick={proceedToConfirmation}
              disabled={!isCompanyInMigrationStatus}
            >
              Proceed
            </Button>
          </div>
        </>
      ) : (
        <>
          <Alert
            message="Final Confirmation"
            description="This is your last chance to cancel. Once confirmed, ALL data for this company will be permanently deleted."
            type="warning"
            showIcon
            className="mb-4"
          />

          <Paragraph>{confirmationMessage}</Paragraph>

          <Input
            className="my-4"
            placeholder="Type company name here"
            value={confirmInput}
            onChange={e => setConfirmInput(e.target.value)}
          />

          <div className="flex justify-end">
            <Button onClick={handleClose} className="mr-2">
              Cancel
            </Button>
            <Button
              type="primary"
              danger
              onClick={handleWipeData}
              disabled={
                confirmInput !== company.name ||
                wipeCompanyDataMutation.isLoading
              }
            >
              {wipeCompanyDataMutation.isLoading ? (
                <Spin size="small" />
              ) : (
                'Confirm & Wipe Data'
              )}
            </Button>
          </div>
        </>
      )}
    </Modal>
  )
}
