import {
  InternalNotification,
  InternalNotificationEstimateMetadata,
  OfficeRoutes,
  isInternalNotificationAccountReminderDueMetadata,
  isInternalNotificationEstimateMetadata,
  isInternalNotificationTaggedInNoteMetadata,
} from '@breezy/shared'
import { faCheck, faSpinnerThird } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Spin, Tooltip } from 'antd'
import cn from 'classnames'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import FaIconButton from '../../elements/FaIconButton/FaIconButton'
import { HtmlRenderer } from '../../elements/HtmlRenderer/HtmlRenderer'
import { TimeAgo } from '../../elements/TimeAgo/TimeAgo'
import { trpc } from '../../hooks/trpc'
import useIsMobile from '../../hooks/useIsMobile'
import { ACCOUNT_DETAILS_TAB_KEY } from '../../pages/AccountDetailsPageV2/AccountDetailsPageV2'
import { JOB_DETAILS_TAB_KEY } from '../../pages/JobDetailsPage/JobDetailsPage'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { Styled } from '../../utils/Stylable'
import Chip from '../AccountReminders/Chip/Chip'
import FormattedReminderDate from '../AccountReminders/FormattedReminderDate/FormattedReminderDate'
import { URL_HIGHLIGHT_NOTE_KEY } from '../Notes/LinkedNotesList/LinkedNotesList'
import { NotificationIcon } from './Notifications'

const formatAccountDisplayName = (displayName: string) => {
  const words = displayName.split(' ')
  let result = displayName

  // split the name to show the first two words if they exist
  if (words.length > 1) {
    result = `${words[0]} ${words[1]}`
  }

  // truncate the name if it's too long
  return result.length > 20 ? result.slice(0, 20) + '...' : result
}

type NotificationProps = Styled<{
  notification: InternalNotification
  refetch: () => Promise<unknown>
  onClick?: () => void
  showDate?: boolean
}>

export const Notification = React.memo<NotificationProps>(
  ({ notification, refetch, onClick, className, showDate }) => {
    const navigate = useNavigate()
    const isMobile = useIsMobile()

    const ourOnClick = useMemo(() => {
      onClick?.()

      const { metadata } = notification

      if (isInternalNotificationTaggedInNoteMetadata(metadata)) {
        const highlightNodePart = `${URL_HIGHLIGHT_NOTE_KEY}=${metadata.noteGuid}`
        const { sourceData } = metadata
        switch (sourceData.primaryNoteType) {
          case 'LOCATION':
            return () =>
              navigate(
                `/locations/${sourceData.locationGuid}?${highlightNodePart}`,
              )
          case 'ACCOUNT':
            return () =>
              navigate(
                `/accounts/${sourceData.accountGuid}?${ACCOUNT_DETAILS_TAB_KEY}=Notes&${highlightNodePart}`,
              )
          case 'JOB':
          case 'JOB_APPOINTMENT':
          case 'ASSIGNMENT':
            return () =>
              navigate(
                `/jobs/${sourceData.jobGuid}?${JOB_DETAILS_TAB_KEY}=Notes&${highlightNodePart}`,
              )
          case 'MAINTENANCE_PLAN':
            return () =>
              navigate(
                OfficeRoutes.MAINTENANCE_PLAN_DETAILS.calculate({
                  maintenancePlanGuid: sourceData.maintenancePlanGuid,
                }),
              )
          default:
            // Shouldn't be possible
            return () => {
              // noop
            }
        }
      } else if (isInternalNotificationAccountReminderDueMetadata(metadata)) {
        return () => navigate(`/accounts/${metadata.accountGuid}`)
      } else if (isInternalNotificationEstimateMetadata(metadata)) {
        return () => navigate(`/estimates/${metadata.estimateGuid}`)
      }
    }, [onClick, notification, navigate])

    const acknowledgeMutation =
      trpc.internalNotifications[
        'internal-notifications:acknowledge'
      ].useMutation()

    const onClickAcknowledge = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        acknowledgeMutation.mutate(
          {
            internalNotificationGuid: notification.internalNotificationGuid,
          },
          {
            onSuccess: () => {
              refetch()
            },
          },
        )
      },
      [acknowledgeMutation, notification.internalNotificationGuid, refetch],
    )

    return (
      <div
        key={notification.internalNotificationGuid}
        onClick={ourOnClick}
        className={cn(
          `flex min-w-0 flex-1 cursor-pointer items-center justify-between py-2 transition  hover:bg-slate-200`,
          {
            'px-3': !isMobile,
          },
          className,
        )}
      >
        {isInternalNotificationTaggedInNoteMetadata(notification.metadata) ? (
          <NotesNotification notification={notification} showDate={showDate} />
        ) : isInternalNotificationAccountReminderDueMetadata(
            notification.metadata,
          ) ? (
          <AccountReminderNotification
            notification={notification}
            showDate={showDate}
          />
        ) : isInternalNotificationEstimateMetadata(notification.metadata) ? (
          <EstimateNotification
            notification={notification}
            showDate={showDate}
            metadata={notification.metadata}
          />
        ) : null}

        {notification.acknowledgedAt ? null : (
          <div className="ml-1">
            <Spin
              spinning={acknowledgeMutation.isLoading}
              indicator={<FontAwesomeIcon icon={faSpinnerThird} spin />}
            >
              <Tooltip title="Mark as read">
                <FaIconButton icon={faCheck} onClick={onClickAcknowledge} />
              </Tooltip>
            </Spin>
          </div>
        )}
      </div>
    )
  },
)

type AccountReminderDueNotification = Pick<
  NotificationProps,
  'notification' | 'showDate'
>

export const AccountReminderNotification =
  React.memo<AccountReminderDueNotification>(({ notification, showDate }) => {
    const isMobile = useIsMobile()

    const tzId = useExpectedCompanyTimeZoneId()
    return isInternalNotificationAccountReminderDueMetadata(
      notification.metadata,
    ) ? (
      <div className="flex min-w-0 flex-1 items-center gap-3">
        <div className="self-start">
          <NotificationIcon type={notification.metadata.type} />
        </div>
        <div className="flex min-w-0 flex-col">
          <div className="font-semibold text-bz-gray-1000">Reminder due</div>
          <div className="break-words text-bz-gray-900">
            {notification.content}
          </div>
          <div className="mt-2 flex items-center gap-1">
            <Chip className="w-fit">
              <FormattedReminderDate
                date={notification.metadata.dueAt}
                timeZoneId={tzId}
              />
            </Chip>
            <Chip className="w-fit">
              {formatAccountDisplayName(
                notification.metadata.accountDisplayName,
              )}
            </Chip>
            {showDate && !isMobile && <TimeAgo date={notification.createdAt} />}
          </div>
          {showDate && isMobile && (
            <div className="mt-2">
              <TimeAgo date={notification.createdAt} />
            </div>
          )}
        </div>
      </div>
    ) : null
  })

type NotesNotificationProps = Pick<
  NotificationProps,
  'notification' | 'showDate'
>

export const NotesNotification = React.memo<NotesNotificationProps>(
  ({ notification, showDate }) => {
    const prefix = useMemo(() => {
      if (isInternalNotificationTaggedInNoteMetadata(notification.metadata)) {
        return (
          <div className="font-semibold">
            {`${notification.metadata.taggedByUser.firstName} ${notification.metadata.taggedByUser.lastName} tagged you in a note`}
          </div>
        )
      }
      return 'Notification'
    }, [notification.metadata])

    return (
      <>
        <div className="mr-2 self-start">
          <NotificationIcon type={notification.metadata.type} />
        </div>
        <div className="min-w-0 flex-1">
          {prefix}
          <div className="flex break-words text-bz-gray-1000">
            <span>“</span>
            <div className="line-clamp-3">
              <HtmlRenderer htmlContent={notification.content} />
            </div>
            <span>”</span>
          </div>
          {showDate && <TimeAgo date={notification.createdAt} />}
        </div>
      </>
    )
  },
)

type EstimateNotificationProps = Pick<
  NotificationProps,
  'notification' | 'showDate'
> & {
  metadata: InternalNotificationEstimateMetadata
}

export const EstimateNotification = React.memo<EstimateNotificationProps>(
  ({ notification, showDate, metadata }) => {
    return (
      <div className="flex min-w-0 flex-1 items-center gap-3">
        <div className="self-start">
          <NotificationIcon type={notification.metadata.type} />
        </div>
        <div className="flex min-w-0 flex-col">
          <div className="break-words font-semibold text-bz-gray-1000">
            {metadata.contactFullName}{' '}
            {metadata.operation === 'REVIEWED' ? 'reviewed' : 'accepted'} an
            estimate
          </div>
          <div className="break-words text-bz-gray-900">
            {notification.content}
          </div>
          {showDate && (
            <div className="mt-2">
              <TimeAgo date={notification.createdAt} />
            </div>
          )}
        </div>
      </div>
    )
  },
)
