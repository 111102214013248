import { AvatarData, Dfns, isNullish, R, User } from '@breezy/shared'
import { Popover } from 'antd'
import React, { ReactNode, useMemo } from 'react'
import { getConfig } from '../../config'
import { getAppropriateTextColor, userColor } from '../../utils/color-utils'

const { imageResizerBaseUrl } = getConfig()

type PersonAvatarProps = AvatarData & {
  size?: number
  fontSize?: number
  withBorder?: boolean
  popoverContent?: ReactNode
  border?: {
    size: number
    color: string
  }
}

export const mapUserToAvatarData = (user: User): AvatarData => {
  const avatarShortString =
    (user.firstName || 'F')[0] + (user.lastName || 'L')[0]
  return {
    avatarAltShortString: avatarShortString,
    avatarAltColor: userColor(user.userGuid),
    deactivatedAt: user.deactivatedAt,
    userGuid: user.userGuid,
  }
}

const PersonAvatar = React.memo<PersonAvatarProps>(
  ({
    avatarAltShortString,
    avatarImgSrc,
    avatarAltColor,
    size = 32,
    fontSize = 10,
    deactivatedAt,
    userGuid,
    withBorder,
    popoverContent,
    border,
  }) => {
    const backgroundColor = avatarAltColor ?? userColor(userGuid)

    const url = useMemo(() => {
      if (!avatarImgSrc) return undefined

      try {
        const url = new URL(avatarImgSrc ?? '')
        return `${imageResizerBaseUrl}?key=${url.pathname.slice(
          1,
        )}&width=${size}&height=${size}`
      } catch (e) {
        return undefined
      }
    }, [avatarImgSrc, size])

    const content = (
      <div
        style={{
          width: size,
          height: size,
          minHeight: size,
          minWidth: size,
          borderRadius: '100%',
          border: withBorder
            ? `${border?.size ?? 2}px solid ${border?.color ?? 'white'}`
            : undefined,
          color: getAppropriateTextColor(backgroundColor),
          fontSize: `${fontSize}px`,
          lineHeight: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...(!isNullish(url)
            ? {
                backgroundImage: `url(${url})`,
                backgroundSize: 'cover',
              }
            : {
                background: backgroundColor,
              }),
        }}
      >
        {isNullish(url) && avatarAltShortString}
      </div>
    )

    if (isNullish(popoverContent) && isNullish(deactivatedAt)) {
      return content
    }

    return (
      <Popover
        content={
          <div>
            {popoverContent}

            {!isNullish(deactivatedAt) && (
              <>
                User deactivated on{' '}
                {R.pipe(
                  Dfns.parseISO,
                  Dfns.format('MMMM d, yyyy'),
                )(deactivatedAt)}
              </>
            )}
          </div>
        }
      >
        <span className={!isNullish(deactivatedAt) ? 'opacity-30' : ''}>
          {content}
        </span>
      </Popover>
    )
  },
)
export default PersonAvatar
