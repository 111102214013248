import React from 'react'
import { getReportsByCategory } from '../../reports/ReportDefinitions'
import { ReportConfig } from '../../reports/ReportTypes'
import { ReportCard } from './ReportCard'

export const ReportsDashboard = React.memo(() => {
  const accountsReports = getReportsByCategory('accounts')

  const renderReportCard = (report: ReportConfig) => {
    return (
      <ReportCard
        key={report.id}
        title={report.title}
        description={report.description}
        icon={report.icon}
        reportType={report.type}
        reportId={report.id}
      />
    )
  }

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <div className="space-y-8">
        {/* Accounts Section */}
        {accountsReports.length > 0 && (
          <div>
            <h2 className="mb-4 mt-8 text-xl font-semibold">Accounts</h2>
            <div className="grid grid-cols-3 gap-6">
              {accountsReports.map(renderReportCard)}
            </div>
          </div>
        )}
      </div>
    </div>
  )
})
