import { BzDateFns, toPlural } from '@breezy/shared'
import { faStar, faStarHalf, faUser } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Card } from '../../elements/Card/Card'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import angiImg from './customerReviewLogos/angi.png'
import angiSmallImg from './customerReviewLogos/angi_icon.png'
import bbbImg from './customerReviewLogos/bbb.png'
import bbbSmallImg from './customerReviewLogos/bbb_icon.png'
import facebookImg from './customerReviewLogos/facebook.png'
import googleImg from './customerReviewLogos/google.png'
import googleSmallImg from './customerReviewLogos/google_icon.png'
import yelpImg from './customerReviewLogos/yelp.png'
import yelpSmallImg from './customerReviewLogos/yelp_icon.png'
import {
  StandardReportingDateRangePicker,
  useStandardReportingDateRangePickerState,
} from './ReportingDateRangePicker/StandardReportingDateRangePicker'

type SourceLogoProps = {
  className?: string
}

export const AngiLogo = React.memo<SourceLogoProps>(({ className }) => (
  <img className={className} alt="Angi" src={angiImg} />
))

export const AngiSmallLogo = React.memo<SourceLogoProps>(({ className }) => (
  <img className={className} alt="Angi" src={angiSmallImg} />
))

export const BBBLogo = React.memo<SourceLogoProps>(({ className }) => (
  <img className={className} alt="Better Business Bureau" src={bbbImg} />
))

export const BBBSmallLogo = React.memo<SourceLogoProps>(({ className }) => (
  <img className={className} alt="Better Business Bureau" src={bbbSmallImg} />
))

export const FacebookLogo = React.memo<SourceLogoProps>(({ className }) => (
  <img className={className} alt="Facebook" src={facebookImg} />
))

export const GoogleLogo = React.memo<SourceLogoProps>(({ className }) => (
  <img className={className} alt="Google" src={googleImg} />
))

export const GoogleSmallLogo = React.memo<SourceLogoProps>(({ className }) => (
  <img className={className} alt="Google" src={googleSmallImg} />
))

export const YelpLogo = React.memo<SourceLogoProps>(({ className }) => (
  <img className={className} alt="Yelp" src={yelpImg} />
))

export const YelpSmallLogo = React.memo<SourceLogoProps>(({ className }) => (
  <img className={className} alt="Yelp" src={yelpSmallImg} />
))

type StarsProps = {
  className?: string
  perStarClassName?: string
  starSize?: number
  starSpacing?: number
  stars: number
}

const Stars = React.memo<StarsProps>(
  ({ className, perStarClassName, stars, starSize = 16, starSpacing = 2 }) => {
    const starElements = useMemo(() => {
      const elements: JSX.Element[] = []

      for (let i = 1; i <= 5; i++) {
        const wholePart = Math.floor(stars)
        const fractionPart = stars % 1

        let icon = faStar
        if (i <= wholePart) {
          icon = faStar
        } else if (
          i === wholePart + 1 &&
          fractionPart >= 0.25 &&
          fractionPart <= 0.75
        ) {
          icon = faStarHalf
        } else if (i === wholePart + 1 && fractionPart > 0.75) {
          icon = faStar
        } else {
          break
        }
        elements.push(
          <FontAwesomeIcon
            icon={icon}
            key={i}
            className={classNames('text-bz-yellow-600', perStarClassName)}
            style={{
              height: `${starSize}px`,
              width: `${starSize}px`,
              marginLeft: `${i === 0 ? 0 : starSpacing}px`,
            }}
          />,
        )
      }
      return elements
    }, [perStarClassName, starSize, starSpacing, stars])

    return (
      <div className={classNames('flew-row flex', className)}>
        {starElements}
      </div>
    )
  },
)

type RatingSummaryProps = {
  header: React.ReactNode
  overallRating: number
  totalReviews: number
  className?: string
}

const RatingSummary = React.memo<RatingSummaryProps>(
  ({ overallRating, totalReviews, header, className }) =>
    overallRating ? (
      <div
        className={classNames('flex flex-row justify-between py-1', className)}
      >
        <div>
          {header}
          <div className="text-sm text-bz-text-tertiary">
            {totalReviews} {toPlural(totalReviews, 'review')}
          </div>
        </div>
        <div className="flex flex-row items-center">
          <div className="mr-1 font-semibold">{overallRating.toFixed(1)}</div>
          <FontAwesomeIcon
            icon={faStar}
            className="mb-[3px] h-5 w-5 text-bz-text-quaternary"
          />
        </div>
      </div>
    ) : null,
)

type CustomerReviewsWidgetProps = {
  className?: string
}
export const CustomerReviewsWidget = React.memo<CustomerReviewsWidgetProps>(
  ({ className }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const [dateRange, setDateRange] = useStandardReportingDateRangePickerState()

    const { data, isLoading } = trpc.reporting['customer-reviews:get'].useQuery(
      {
        dateRange,
      },
      {
        refetchOnWindowFocus: false,
        trpc: {
          context: {
            skipBatch: true,
          },
        },
      },
    )

    return (
      <Card
        title="Customer Reviews"
        className={classNames(
          'h-[366px] min-h-[366px] overflow-hidden',
          className,
        )}
        bodyClassName="m-[-16px]"
        titleAction={
          <StandardReportingDateRangePicker
            range={dateRange}
            setRange={setDateRange}
          />
        }
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : data && data.reviews.length ? (
          <div className="flex min-h-0 flex-1 flex-row items-start text-base text-bz-text">
            <div className="mx-4 my-4 flex flex-col divide-y divide-solid divide-bz-border rounded-md bg-bz-gray-200 px-4 py-3 *:border-0">
              <div className="pb-2">
                <div className="font-semibold">Average Rating</div>
                <div className="flex flex-row items-center justify-between">
                  <div className="text-2xl font-semibold">
                    {data.overallRating.toFixed(1)}
                  </div>
                  <Stars
                    stars={data.overallRating}
                    starSize={20}
                    starSpacing={5}
                  />
                </div>
                <div className="text-sm text-bz-text-tertiary">
                  out of 5 • {data.totalReviews}{' '}
                  {toPlural(data.totalReviews, 'review')}
                </div>
              </div>

              <RatingSummary
                className="pt-2"
                header={<GoogleLogo />}
                totalReviews={data.google.totalReviews}
                overallRating={data.google.overallRating}
              />
              <RatingSummary
                header={<YelpLogo />}
                totalReviews={data.yelp.totalReviews}
                overallRating={data.yelp.overallRating}
              />
              <RatingSummary
                header={<FacebookLogo />}
                totalReviews={data.facebook.totalReviews}
                overallRating={data.facebook.overallRating}
              />
              <RatingSummary
                header={<AngiLogo />}
                totalReviews={data.angi.totalReviews}
                overallRating={data.angi.overallRating}
              />
              <RatingSummary
                header={<BBBLogo />}
                totalReviews={data.bbb.totalReviews}
                overallRating={data.bbb.overallRating}
              />
            </div>

            <div className="h-full min-h-0 flex-1 space-y-3 divide-y divide-solid divide-bz-border-secondary overflow-auto py-4 pr-4 *:border-0 *:pt-3">
              {data.reviews.map(
                ({
                  link,
                  user,
                  rating,
                  createdAt,
                  content,
                  source,
                  avatarLink,
                }) => (
                  <div key={link} className="first:pt-0">
                    <div className="flex flex-row flex-wrap items-start">
                      {avatarLink ? (
                        <img
                          src={avatarLink}
                          alt={user}
                          className="h-10 w-10"
                        />
                      ) : (
                        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-bz-gray-400">
                          <FontAwesomeIcon
                            icon={faUser}
                            size="xl"
                            className="text-bz-gray-700"
                          />
                        </div>
                      )}
                      <div className="ml-3 min-w-0 flex-1">
                        <div className="mb-1 flex flex-row items-center">
                          <Stars stars={rating} className="mr-2" />
                          {source === 'Google' ? (
                            <GoogleSmallLogo />
                          ) : source === 'Yelp' ? (
                            <YelpSmallLogo />
                          ) : source === 'Facebook' ? (
                            <FacebookLogo />
                          ) : source === 'Angi' ? (
                            <AngiSmallLogo />
                          ) : (
                            <BBBSmallLogo />
                          )}
                        </div>
                        <a
                          className="whitespace-nowrap font-semibold text-bz-primary"
                          href={link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {user}
                        </a>
                        {content ? (
                          <div className="mt-1 text-sm">{content}</div>
                        ) : null}
                        <div className="mt-1 text-sm text-bz-text-tertiary">
                          {BzDateFns.formatFromISO(
                            createdAt,
                            'MMMM d, yyyy h:mma',
                            tzId,
                          )}{' '}
                          • {source}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              )}
            </div>
          </div>
        ) : (
          <div className="flex flex-1 items-center justify-center text-base font-semibold">
            <div>No reviews in your chosen time period.</div>
          </div>
        )}
      </Card>
    )
  },
)
