import { HtmlString } from '@breezy/shared'
import { Link, RichTextEditor } from '@mantine/tiptap'
import ListKeymap from '@tiptap/extension-list-keymap'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import { useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import classNames from 'classnames'
import React, { useEffect, useMemo } from 'react'

import './WysiwygEditor.less'

interface CustomControlProps {
  onClick: () => void
  icon: JSX.Element // Recommended to use an icon from @tabler/react-icons
  label: string
}

const WysiwygEditorCustomControl = React.memo<CustomControlProps>(
  ({ onClick, icon, label }) => {
    return (
      <RichTextEditor.Control
        onClick={onClick}
        aria-label={label}
        title={label}
      >
        {icon}
      </RichTextEditor.Control>
    )
  },
)

export type WysiwygEditorProps = {
  id?: string
  value?: HtmlString
  onChange?: (value: string) => void
  onBlur?: (value: string) => void
  autoFocus?: boolean
  editorClassName?: string
  disabled?: boolean
  dataTestId?: string
  customControls?: CustomControlProps[]
  placeholder?: string
  minHeight?: number
}

export const WysiwygEditor = React.memo(
  React.forwardRef<HTMLDivElement, WysiwygEditorProps>(
    (
      {
        value,
        onChange,
        onBlur,
        autoFocus,
        editorClassName,
        disabled,
        dataTestId,
        customControls,
        placeholder = '',
        minHeight = 100,
        id,
      },
      ref,
    ) => {
      const resolvedEditorClassName = useMemo(
        () =>
          classNames(
            'prose-lg border border-bz-border p-2 hover:border-bz-primary-hover focus:border-bz-primary outline-none wysiwyg-editor-ant-match',
            editorClassName,
          ),
        [editorClassName],
      )
      const editor = useEditor({
        extensions: [
          StarterKit,
          Underline,
          Link,
          ListKeymap, // Fixes some edge-case bugs when pressing Enter/Delete in lists
          Placeholder.configure({ placeholder }),
        ],
        content: value,
        parseOptions: {
          preserveWhitespace: 'full',
        },
        editable: !disabled,
        editorProps: {
          attributes: {
            class: resolvedEditorClassName,
            style: `min-height: ${minHeight}px`,
            'data-testid': dataTestId ?? '',
          },
        },
        onUpdate: ({ editor }) => {
          if (!onChange) {
            return
          }

          onChange(editor.getText() === '' ? '' : editor.getHTML())
        },
        onBlur: ({ editor }) => {
          if (!onBlur) {
            return
          }

          onBlur(editor.getText() === '' ? '' : editor.getHTML())
        },
      })

      useEffect(() => {
        // Focus the editor when it is initialized
        if (editor && autoFocus) {
          editor.commands.focus()
        }
      }, [autoFocus, editor])

      useEffect(() => {
        // We want to control the editor, so we want to do `setContent` whenever `value` changes. However, we have to
        // skip that in certain scenarios. Setting the content causes the component to rerender, which moves the cursor
        // to the end of the text. This means if you're tying somewhere other than the end, your cursor teleports to the
        // end of the content. If the editor and us have the same value, then there's no need to call `setContent`, so
        // simply check for that. If they're the same, don't do anything.
        if (!editor || editor.getHTML() === value) {
          return
        }
        editor?.commands.setContent(value as string, false, {
          preserveWhitespace: 'full',
        })
      }, [editor, value])

      // For the same reasons as above, if the class name changes we need to update the editor manually
      useEffect(() => {
        editor?.setOptions({
          editorProps: {
            attributes: {
              class: resolvedEditorClassName,
              'data-testid': dataTestId ?? '',
            },
          },
        })
        // We only need this to run when the class name changes, not when the editor instance changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [resolvedEditorClassName])

      return (
        <RichTextEditor id={id} editor={editor} ref={ref}>
          <RichTextEditor.Toolbar className="p-2.5">
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.ClearFormatting />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Link />
              <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Undo />
              <RichTextEditor.Redo />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              {customControls?.map(control => (
                <WysiwygEditorCustomControl key={control.label} {...control} />
              ))}
            </RichTextEditor.ControlsGroup>
          </RichTextEditor.Toolbar>

          <RichTextEditor.Content />
        </RichTextEditor>
      )
    },
  ),
)
