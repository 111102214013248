import { z } from 'zod'
import {
  bzOptional,
  CompanyAppointmentArrivalWindowGuid,
  CompanyTimeZoneReader,
  ForCompany,
  Guid,
  OnlineBookingServiceType,
  TechAssignmentTimeWindowMultiReader,
  TechnicianCapacityBlockInstancesReader,
} from '..'
import {
  AsyncFn,
  BadInputException,
  BreezyErrorSeverity,
  BzDateFns,
  DayOfTheWeek,
  IsoDateString,
  LocalDateString,
  LocalTimeString,
  NotFoundException,
  TimeZoneId,
} from '../../common'
import { guidSchema, localDateSchema, localTimeStringSchema } from '../../contracts'
import { JobLeadContactInformationSchema } from '../JobLeads/_JobLeadContactInfo'
import {
  BookableJobType,
  BookableTechnician,
  getEarliestAvailableFromIso,
  OnlineBookingEarliestAvailableTime,
  OnlineBookingLatestAvailableTime,
  OnlineBookingServiceTypeConfigReader,
} from './OnlineBooking'

export const INSTANT_BOOKING_ASSIGNMENT_TYPES = ['RANDOM', 'ORDERED'] as const
export type InstantBookingAssignmentType = (typeof INSTANT_BOOKING_ASSIGNMENT_TYPES)[number]

export const BookableArrivalWindowSchema = z.object({
  companyAppointmentArrivalWindowGuid: guidSchema,
  arrivalWindowStartTime: localTimeStringSchema,
  arrivalWindowEndTime: localTimeStringSchema,
})

export type BookableArrivalWindow = z.infer<typeof BookableArrivalWindowSchema>

const InstantBookingWeeklyScheduleDaySchema = z.object({
  enabled: z.boolean().default(false),
  bookableArrivalWindows: z.array(BookableArrivalWindowSchema).default([]),
})

export const InstantBookingWeeklyScheduleSchema = z.object({
  Sunday: InstantBookingWeeklyScheduleDaySchema,
  Monday: InstantBookingWeeklyScheduleDaySchema,
  Tuesday: InstantBookingWeeklyScheduleDaySchema,
  Wednesday: InstantBookingWeeklyScheduleDaySchema,
  Thursday: InstantBookingWeeklyScheduleDaySchema,
  Friday: InstantBookingWeeklyScheduleDaySchema,
  Saturday: InstantBookingWeeklyScheduleDaySchema,
})

export type InstantBookingWeeklySchedule = z.infer<typeof InstantBookingWeeklyScheduleSchema>

type InstantBookingWeeklyScheduleReaderRequest = {
  serviceType: OnlineBookingServiceType
}

type InstantBookingWeeklyScheduleReaderResponse = {
  weeklySchedule: InstantBookingWeeklySchedule
}

/**
 * Given an online booking service type, this function will return the configured weekly schedule
 * which includes the enabled days and the bookable arrival windows for each day.
 */
export type InstantBookingWeeklyScheduleReader = AsyncFn<
  InstantBookingWeeklyScheduleReaderRequest,
  InstantBookingWeeklyScheduleReaderResponse
>

/**
 * Given a weekly schedule and a day of the week, this function will return the bookable arrival windows for that day.
 */
export const getDayBookableArrivalWindows = (
  weeklySchedule: InstantBookingWeeklySchedule,
  dayOfWeek: DayOfTheWeek,
): BookableArrivalWindow[] => {
  return weeklySchedule[dayOfWeek].bookableArrivalWindows
}

export type BookableTechnicianGuid = Guid

export type TimeInterval = {
  start: IsoDateString
  end: IsoDateString
}

type TechnicianBusyIntervalReaderRequest = ForCompany<{
  date: LocalDateString
  technicianGuids: BookableTechnicianGuid[]
}>

type TechnicianBusyIntervalReaderResponse = {
  technicianBusyIntervals: Record<BookableTechnicianGuid, TimeInterval[]>
}

export type TechnicianBusyIntervalReader = AsyncFn<
  TechnicianBusyIntervalReaderRequest,
  TechnicianBusyIntervalReaderResponse
>

/**
 * Given an array of technicians, this function will return a map of technicians to their busy time intervals
 * for the given date.
 */
export const createTechnicianBusyIntervalReader =
  (
    technicianCapacityBlockInstancesReader: TechnicianCapacityBlockInstancesReader,
    techAssignmentTimeWindowMultiReader: TechAssignmentTimeWindowMultiReader,
  ): TechnicianBusyIntervalReader =>
  async ({ companyGuid, date, technicianGuids }) => {
    const technicianBusyIntervals: Record<BookableTechnicianGuid, TimeInterval[]> = {}

    // Get a map of all of the technician capacity block instances for the given date
    const technicianCapacityBlockInstances = await technicianCapacityBlockInstancesReader({
      type: 'local-date',
      input: { companyGuid, date, technicianGuids },
    })

    for (const technicianGuid in technicianCapacityBlockInstances.technicianCapacityBlockInstances) {
      technicianBusyIntervals[technicianGuid] = technicianCapacityBlockInstances.technicianCapacityBlockInstances[
        technicianGuid
      ].map(capacityBlockInstance => ({
        start: capacityBlockInstance.start,
        end: capacityBlockInstance.end,
      }))
    }

    // Get a map of all of the technician assignments for the given date
    const technicianAssignments = await techAssignmentTimeWindowMultiReader({
      companyGuid,
      date,
      technicianGuids,
    })

    for (const technicianGuid in technicianAssignments.techAssignmentTimeWindows) {
      const technicianAssignmentTimeWindows = technicianAssignments.techAssignmentTimeWindows[technicianGuid]
      const assignmentIntervals = technicianAssignmentTimeWindows.map(timeWindow => ({
        start: timeWindow.start,
        end: timeWindow.end,
      }))

      technicianBusyIntervals[technicianGuid] = [
        ...(technicianBusyIntervals[technicianGuid] || []),
        ...assignmentIntervals,
      ]
    }

    return {
      technicianBusyIntervals,
    }
  }

export type WithFreeBlockAnchor<T> = T & {
  freeBlockAnchor: IsoDateString
}

type ScheduleTechnicianAvailabilityReaderRequest = ForCompany<{
  // The date to check for instant booking availability
  date: LocalDateString
  // The configured technicians that can be assigned to the instant booking
  bookableTechnicianGuids: BookableTechnicianGuid[]
  // The configured arrival windows that can be assigned to the instant booking
  bookableArrivalWindows: BookableArrivalWindow[]
  // The duration of the job type in minutes
  jobTypeDurationMinutes: number
}>

/**
 * Given a date, jobType, and the bookable arrival windows and technicians, this function will return
 * a map of technician guids to the arrival windows that are available for them to be assigned to
 * an instant booking appointment.
 */
type ScheduleTechnicianAvailabilityReaderResponse = {
  technicianAvailability: Record<BookableTechnicianGuid, WithFreeBlockAnchor<BookableArrivalWindow>[]>
}

/**
 * Given a date, a list of technicians, a list of arrival windows, and a job type,
 * this function will check to see which technicians are available to be assigned to an
 * instant booking appointment at the given date and arrival windows.
 */
export type ScheduleTechnicianAvailabilityReader = AsyncFn<
  ScheduleTechnicianAvailabilityReaderRequest,
  ScheduleTechnicianAvailabilityReaderResponse
>

/**
 * Given a list of intervals, this function will merge them into a single list of non-overlapping intervals.
 * @param intervals - The list of intervals to merge
 * @returns The merged list of non-overlapping intervals
 */
export const mergeIntervals = (intervals: TimeInterval[]) => {
  const sorted = intervals.sort((a, b) => (a.start > b.start ? 1 : -1))
  const merged: TimeInterval[] = []

  for (const interval of sorted) {
    if (!merged.length) {
      merged.push(interval)
    } else {
      const last = merged[merged.length - 1]
      if (interval.start <= last.end) {
        if (interval.end > last.end) {
          last.end = interval.end
        }
      } else {
        merged.push(interval)
      }
    }
  }
  return merged
}

/**
 * Given a date, a list of technicians, a list of arrival windows, and a job type,
 * this function will return a map of technicians to the arrival windows that are available for them to be assigned to.
 *
 * This function is a bit involved but I've tried to abstract out a lot of the complexity.
 * Here's the context around the problem we're trying to solve. We made the product decision
 * to let pros configure existing company arrival windows that should be available for an instant booking for a given
 * day of the week. For these given arrival windows, we need to calculate which technicians are available
 * to be assigned to an instant booking appointment for a contiguous block of time that is >= the duration of the
 * selected job type.
 *
 * The solution I went with was to normalize all of the technician assignments and tech capacity blocks (internal meetings, etc.)
 * into a "busy" time interval (TimeInterval). From there I merge any overlapping busy intervals over the given arrival window.
 * After merging the time intervals, I eliminate the intersection of the busy intervals from the arrival window to create
 * a "free" time interval through interval subtraction. From there we check if any of the free intervals are >= the duration of
 * the selected job type which determines if a job appointment can be created within the arrival window.
 *
 * There's a lot of room for improvement on the perf side but we're going to start with the simple, readable
 * approach and can refine the algorithm if it becomes troublesome.
 */
export const createScheduleTechnicianAvailabilityReader =
  (
    technicianBusyIntervalReader: TechnicianBusyIntervalReader,
    tzReader: CompanyTimeZoneReader,
  ): ScheduleTechnicianAvailabilityReader =>
  async ({ companyGuid, date, bookableTechnicianGuids, bookableArrivalWindows, jobTypeDurationMinutes }) => {
    const tzId = await tzReader({ companyGuid })

    // 1. Get the technician busy intervals for the given date
    const { technicianBusyIntervals } = await technicianBusyIntervalReader({
      companyGuid,
      date,
      technicianGuids: bookableTechnicianGuids,
    })

    // 2. Normalize the bookable arrival windows to include the time intervals
    const bookableArrivalWindowsWithTimeIntervals = bookableTechnicianGuids.length
      ? bookableArrivalWindows.map(arrivalWindow => ({
          ...arrivalWindow,
          start: BzDateFns.combineLocalTimeAndDate(arrivalWindow.arrivalWindowStartTime, date, tzId),
          end: BzDateFns.combineLocalTimeAndDate(arrivalWindow.arrivalWindowEndTime, date, tzId),
        }))
      : []

    // 3. Iterate over each technician and calculate the arrival windows
    // that are available for them to be assigned to
    const technicianAvailability: Record<BookableTechnicianGuid, WithFreeBlockAnchor<BookableArrivalWindow>[]> = {}
    for (const techGuid of bookableTechnicianGuids) {
      // 3a. Merge the overlapping and contiguous busy intervals for the technician
      const busyIntervals = mergeIntervals(technicianBusyIntervals[techGuid] || [])
      const validWindows: WithFreeBlockAnchor<BookableArrivalWindow>[] = []

      for (const { start, end, ...rest } of bookableArrivalWindowsWithTimeIntervals) {
        // 3b. Calculate free blocks by subtracting busy intervals from arrival window
        let freeIntervals: TimeInterval[] = [{ start, end }]

        for (const busy of busyIntervals) {
          const newFree: TimeInterval[] = []

          for (const free of freeIntervals) {
            // No overlap - keep the free block
            if (busy.end <= free.start || busy.start >= free.end) {
              newFree.push(free)
              continue
            }

            // Create block before busy interval if needed
            if (busy.start > free.start) {
              newFree.push({
                start: free.start,
                end: busy.start,
              })
            }

            // Create block after busy interval if needed
            if (busy.end < free.end) {
              newFree.push({
                start: busy.end,
                end: free.end,
              })
            }
          }

          freeIntervals = newFree
        }

        // 3c. Check if any free block is long enough for the job
        const freeBlock = freeIntervals.find(block => {
          const blockStart = BzDateFns.parseISO(block.start, tzId)
          const blockEnd = BzDateFns.parseISO(block.end, tzId)

          // Use the absolute value of the difference to handle crossing over midnight
          const blockDuration = Math.abs(BzDateFns.differenceInMinutes(blockEnd, blockStart))
          return blockDuration >= jobTypeDurationMinutes
        })

        if (freeBlock) {
          validWindows.push({
            ...rest,
            freeBlockAnchor: freeBlock.start,
          })
        }
      }
      technicianAvailability[techGuid] = validWindows
    }

    return {
      technicianAvailability,
    }
  }

export class InvalidInstantBookingConfigException extends BadInputException {
  constructor(message: string, options?: ErrorOptions, severity: BreezyErrorSeverity = 'ERROR') {
    super(message, options, severity)
  }
}

type InstantBookingConfigRequest = ForCompany<{
  serviceType: OnlineBookingServiceType
}>

type InstantBookingConfigResponse = {
  assignmentType: InstantBookingAssignmentType
  bookableJobTypes: BookableJobType[]
  bookableTechnicians: BookableTechnician[]
  instantBookingWeeklySchedule: InstantBookingWeeklySchedule
  earliestAvailability: OnlineBookingEarliestAvailableTime | undefined
  latestAvailability: OnlineBookingLatestAvailableTime | undefined
}

export type InstantBookingConfigReader = AsyncFn<InstantBookingConfigRequest, InstantBookingConfigResponse>

/**
 * Given a service type and the company, it will return the instant booking config for that service type.
 */
export const createInstantBookingConfigReader =
  (onlineBookingServiceTypeConfigReader: OnlineBookingServiceTypeConfigReader): InstantBookingConfigReader =>
  async ({ companyGuid, serviceType }) => {
    const onlineBookingServiceTypeConfig = await onlineBookingServiceTypeConfigReader({
      type: 'by-service-type',
      input: {
        companyGuid: companyGuid,
        serviceType,
      },
    })

    if (onlineBookingServiceTypeConfig.bookingType !== 'INSTANT') {
      throw new InvalidInstantBookingConfigException('Service type is not set up for instant booking')
    }

    if (!onlineBookingServiceTypeConfig.instantBookingWeeklySchedule) {
      throw new InvalidInstantBookingConfigException('Service type is not set up for instant booking')
    }

    return {
      assignmentType: onlineBookingServiceTypeConfig.assignmentType,
      bookableJobTypes: onlineBookingServiceTypeConfig.bookableJobTypes,
      bookableTechnicians: onlineBookingServiceTypeConfig.bookableTechnicians,
      instantBookingWeeklySchedule: onlineBookingServiceTypeConfig.instantBookingWeeklySchedule,
      earliestAvailability: onlineBookingServiceTypeConfig.earliestAvailability,
      latestAvailability: onlineBookingServiceTypeConfig.latestAvailability,
    }
  }

export const InstantBookingAvailabilityReaderRequestSchema = z.object({
  companyGuid: guidSchema,
  date: localDateSchema,
  // For whatever dumb reason, importing the OnlineBookingServiceTypeSchema
  // causes a circular dependency because the I in the InstantBooking comes before
  // the O in OnlineBooking.
  serviceType: z.enum(['MAINTENANCE', 'SERVICE', 'ESTIMATE']),
  // serviceType: OnlineBookingServiceTypeSchema,

  jobTypeGuid: guidSchema,
})

type InstantBookingAvailabilityReaderRequest = z.infer<typeof InstantBookingAvailabilityReaderRequestSchema>

type InstantBookingAvailabilityReaderResponse = {
  technicianAvailability: Record<BookableTechnicianGuid, WithFreeBlockAnchor<BookableArrivalWindow>[]>
  arrivalWindows: WithFreeBlockAnchor<BookableArrivalWindow>[]
}

export type InstantBookingAvailabilityReader = AsyncFn<
  InstantBookingAvailabilityReaderRequest,
  InstantBookingAvailabilityReaderResponse
>

export const isArrivalWindowAvailable = (
  now: IsoDateString,
  date: LocalDateString,
  arrivalWindow: {
    arrivalWindowStartTime: LocalTimeString
    arrivalWindowEndTime: LocalTimeString
  },
  earliestAvailability: OnlineBookingEarliestAvailableTime,
  tzId: TimeZoneId,
): boolean => {
  const arrivalWindowStart = BzDateFns.combineLocalTimeAndDate(arrivalWindow.arrivalWindowStartTime, date, tzId)

  let earliestAvailableDate: Date

  switch (earliestAvailability) {
    case '12 hours':
    case '1 day':
      earliestAvailableDate = getEarliestAvailableFromIso(now, earliestAvailability, tzId)
      break
    default:
      earliestAvailableDate = getEarliestAvailableFromIso(
        BzDateFns.startOfDay(BzDateFns.parseISO(now, tzId)).toISOString(),
        earliestAvailability,
        tzId,
      )
  }

  return BzDateFns.isAfter(BzDateFns.parseISO(arrivalWindowStart, tzId), earliestAvailableDate)
}

/**
 * Given a date, service type, and job type, this function will return the arrival windows that are available
 * for an instant booking for the selected service and job type.
 */
export const createInstantBookingAvailabilityReader =
  (
    instantBookingConfigReader: InstantBookingConfigReader,
    scheduleTechnicianAvailabilityReader: ScheduleTechnicianAvailabilityReader,
    tzReader: CompanyTimeZoneReader,
  ): InstantBookingAvailabilityReader =>
  async ({ companyGuid, date, serviceType, jobTypeGuid }) => {
    const tzId = await tzReader({ companyGuid })

    // 1. Wrangle the instant booking config
    const instantBookingConfig = await instantBookingConfigReader({ companyGuid, serviceType })

    const selectedJobType = instantBookingConfig.bookableJobTypes.find(jobType => jobType.jobTypeGuid === jobTypeGuid)

    if (!selectedJobType) {
      throw new NotFoundException('Booking Job type not found')
    }

    const scheduleDayBookableArrivalWindows = getDayBookableArrivalWindows(
      instantBookingConfig.instantBookingWeeklySchedule,
      BzDateFns.convertLocalDateStringToDayOfWeek(date),
    )

    // 2. Get the technician availability for the selected job type and date
    const scheduleTechnicianAvailability = await scheduleTechnicianAvailabilityReader({
      companyGuid,
      date,
      bookableTechnicianGuids: instantBookingConfig.bookableTechnicians.map(technician => technician.technicianGuid),
      bookableArrivalWindows: scheduleDayBookableArrivalWindows,
      jobTypeDurationMinutes: selectedJobType.durationMinutes,
    })

    // 3. Return the available arrival windows for all technicians
    const bookableArrivalWindows = new Map<
      CompanyAppointmentArrivalWindowGuid,
      WithFreeBlockAnchor<BookableArrivalWindow>
    >()
    for (const technicianGuid in scheduleTechnicianAvailability.technicianAvailability) {
      const arrivalWindows = scheduleTechnicianAvailability.technicianAvailability[technicianGuid]
      arrivalWindows.forEach(arrivalWindow => {
        bookableArrivalWindows.set(arrivalWindow.companyAppointmentArrivalWindowGuid, arrivalWindow)
      })
    }

    // 4. Prune the arrival windows to make sure they are within the earliest availability configuration if it's set
    const prunedArrivalWindows = Array.from(bookableArrivalWindows.values())
      .filter(arrivalWindow => {
        if (!instantBookingConfig.earliestAvailability || !instantBookingConfig.latestAvailability) {
          return true
        }

        return isArrivalWindowAvailable(
          new Date().toISOString(),
          date,
          arrivalWindow,
          instantBookingConfig.earliestAvailability,
          tzId,
        )
      })
      .sort((a, b) => {
        return BzDateFns.isAfter(
          BzDateFns.parseISO(BzDateFns.combineLocalTimeAndDate(a.arrivalWindowStartTime, date, tzId), tzId),
          BzDateFns.parseISO(BzDateFns.combineLocalTimeAndDate(b.arrivalWindowStartTime, date, tzId), tzId),
        )
          ? 1
          : -1
      })

    return {
      technicianAvailability: scheduleTechnicianAvailability.technicianAvailability,
      arrivalWindows: prunedArrivalWindows,
    }
  }

export const InstantBookingSchedulingWriterRequestSchema = z.object({
  ...InstantBookingAvailabilityReaderRequestSchema.shape,
  ...JobLeadContactInformationSchema.shape,
  companyAppointmentArrivalWindowGuid: guidSchema,
  jobSummary: bzOptional(z.string()),
  leadSource: bzOptional(z.string().describe('Lead Source')),
})

export type InstantBookingSchedulingWriterRequest = z.infer<typeof InstantBookingSchedulingWriterRequestSchema>

export const InstantBookingSchedulingWriterResponseSchema = z.object({
  type: z.enum(['SUCCESS', 'ERROR']),
  error: bzOptional(z.string()),
})

export type InstantBookingSchedulingWriterResponse = z.infer<typeof InstantBookingSchedulingWriterResponseSchema>

export type InstantBookingSchedulingWriter = AsyncFn<
  InstantBookingSchedulingWriterRequest,
  InstantBookingSchedulingWriterResponse
>
