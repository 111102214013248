import { useQuery } from 'urql'
import { gql } from '../generated'
import {
  ReadEstimateTemplateMetadataQuery,
  ReadEstimateTemplatesQuery,
} from '../generated/user/graphql'
import { useExpectedCompanyGuid } from '../providers/PrincipalUser'

export const ESTIMATE_TEMPLATE_FRAGMENT = gql(/* GraphQL */ `
  fragment EstimateTemplate on EstimateTemplates {
    estimateTemplateGuid
    templateName
    messagePreset
    customMessageHtml
    updatedAt
    estimateOptions(orderBy: { seq: ASC }) {
      optionGuid: estimateOptionGuid
      descriptionHtml
      displayName
      totalUsc
      featuredPhotoGuid
      featuredPhoto {
        cdnUrl
      }
      estimateOptionDiscounts(orderBy: { seq: ASC }) {
        discountGuid: estimateOptionDiscountGuid
        discountAmountUsc
        discountRate
        discountType
        descriptionHtml
        name
        seq
      }
      recommended: isRecommended
      selected: isSelected
      seq
      cartItems(orderBy: { seq: ASC }) {
        seq
        cartItem {
          cartItemGuid
          itemType: cartItemType
          description
          isDiscountable
          isTaxable
          name
          originalPricebookItemGuid
          quantity
          unitPriceUsc
          cartItemCode
          photo {
            photoGuid
            cdnUrl
          }
        }
      }
      featuredPhotoGuid
      featuredPhoto {
        cdnUrl
      }
    }
  }
`)

const READ_ESTIMATE_TEMPLATES_QUERY = gql(/* GraphQL */ `
  query ReadEstimateTemplates($companyGuid: uuid!) {
    estimateTemplates(
      where: {
        companyGuid: { _eq: $companyGuid }
        _and: { isDeleted: { _eq: false } }
      }
    ) {
      ...EstimateTemplate
    }
  }
`)

const READ_ESTIMATE_TEMPLATE_QUERY = gql(/* GraphQL */ `
  query ReadEstimateTemplate($estimateTemplateGuid: uuid!) {
    estimateTemplatesByPk(estimateTemplateGuid: $estimateTemplateGuid) {
      ...EstimateTemplate
    }
  }
`)

const READ_ESTIMATE_TEMPLATE_METADATA_QUERY = gql(/* GraphQL */ `
  query ReadEstimateTemplateMetadata($companyGuid: uuid!) {
    estimateTemplates(
      where: {
        companyGuid: { _eq: $companyGuid }
        _and: { isDeleted: { _eq: false } }
      }
    ) {
      estimateTemplateGuid
      templateName
    }
  }
`)

export type EstimateTemplateItems = NonNullable<
  ReadEstimateTemplatesQuery['estimateTemplates']
>

export type EstimateTemplateItem = NonNullable<EstimateTemplateItems[0]>

export type EstimateTemplateMetadata = NonNullable<
  ReadEstimateTemplateMetadataQuery['estimateTemplates'][0]
>

export const useEstimateTemplates = (paused?: boolean) => {
  const companyGuid = useExpectedCompanyGuid()
  const [{ data, fetching }, refetch] = useQuery({
    query: READ_ESTIMATE_TEMPLATES_QUERY,
    variables: {
      companyGuid,
    },
    pause: paused,
  })

  const templates = data?.estimateTemplates ?? []
  return [templates, fetching, refetch] as const
}

export const useEstimateTemplatesMetadata = (paused?: boolean) => {
  const companyGuid = useExpectedCompanyGuid()
  const [{ data, fetching }, refetch] = useQuery({
    query: READ_ESTIMATE_TEMPLATE_METADATA_QUERY,
    variables: { companyGuid },
    pause: paused,
  })

  const templates = data?.estimateTemplates ?? []
  return [templates, fetching, refetch] as const
}

export const useEstimateTemplate = (estimateTemplateGuid?: string) => {
  const [{ data, fetching }, refetch] = useQuery({
    query: READ_ESTIMATE_TEMPLATE_QUERY,
    variables: { estimateTemplateGuid: estimateTemplateGuid ?? '' },
    pause: !estimateTemplateGuid,
  })

  return { template: data?.estimateTemplatesByPk, fetching, refetch }
}
