import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { Tag as AntdTag, Popover } from 'antd'
import classNames from 'classnames'
import { useMemo } from 'react'
import {
  Tag,
  TagCompactSizeStyle,
  TagRegularSizeStyle,
  TagStyleClassNamesProps,
  TagStyleEmpty,
  TagStyleSizeClassNamesProps,
  TagStyleV1,
  TagStyleV2,
  TagStyleVersion,
} from './Tag'

const TRUNCATED_POPOVER_HOVER_DELAY_DEFAULT = 0.1 as const

type TruncatedTagsPopoverProps = {
  // FIXME: color is not used downstream, but the type is required
  tags: { name: React.ReactNode | string; color?: string }[]
  tagStyleVersion?: TagStyleVersion
  icon?: IconProp
  hideIcon?: boolean
  noIndividualPopover?: boolean
}

const TruncatedTagsPopoverContent = ({
  tags,
  icon,
  tagStyleVersion,
  hideIcon,
  noIndividualPopover,
}: TruncatedTagsPopoverProps) => {
  return (
    <div className="flex flex-col gap-y-2">
      {tags.map((tag, i) => (
        <div key={`${tag.name}_${i}`} className="w-max">
          <Tag
            tag={tag}
            icon={icon}
            tagStyleVersion={tagStyleVersion}
            hideIcon={hideIcon}
            noPopover={noIndividualPopover}
          />
        </div>
      ))}
    </div>
  )
}

export type TagListItem = {
  name: React.ReactNode | string
  label?: string
  disabled?: boolean
  hideIcon?: boolean
  className?: string
  popoverContent?: React.ReactNode
  /**
   * A custom font-awesome icon if you'd like to override the default tag icon
   */
  icon?: IconProp
  overrideStyle?: Partial<TagStyleClassNamesProps & TagStyleSizeClassNamesProps>
}

export type TagListProps = {
  tags: TagListItem[]

  /**
   * The number of tags to display before the list is truncated. Defaults to 3
   */
  numTagsDisplayed?: number

  /**
   * The horizontal spacing between the tags. Each number maps to a Tailwind spacing number, not the number in pixels.
   */
  spacingX?: number

  /**
   * The vertical spacing between the tags. Each number maps to a Tailwind spacing number, not the number in pixels.
   */
  spacingY?: number

  /**
   * The number of characters to show for a tag's name before truncating it.
   */
  truncateLength?: number

  /**
   * A custom font-awesome icon if you'd like to override the default tag icon
   */
  icon?: IconProp

  /**
   * Whether to hide the icon for each tag
   */
  hideIcon?: boolean

  className?: string

  noIndividualPopover?: boolean

  tagStyleVersion?: TagStyleVersion

  compact?: boolean
}

export const TagList = ({
  tags,
  numTagsDisplayed = tags.length,
  spacingX = 0.5,
  spacingY = 1,
  truncateLength,
  icon,
  hideIcon,
  className,
  noIndividualPopover = false,
  tagStyleVersion = 'v1',
  compact,
}: TagListProps) => {
  const tStyle = useMemo(() => {
    let tagStyle = TagStyleV1
    if (tagStyleVersion === 'v2') {
      tagStyle = TagStyleV2
    }
    if (tagStyleVersion === 'empty') {
      tagStyle = TagStyleEmpty
    }

    return {
      ...tagStyle,
      ...(compact ? TagCompactSizeStyle : TagRegularSizeStyle),
    }
  }, [compact, tagStyleVersion])

  const { bgColor, padding, borderRadius, border } = tStyle

  return (
    <div
      className={classNames(
        `flex w-full flex-row flex-wrap gap-x-${spacingX} gap-y-${spacingY}`,
        className,
      )}
    >
      {tags.length === 0 ? '—' : ''}
      {tags.slice(0, numTagsDisplayed).map((tag, i) => (
        <Tag
          key={`${tag.name}_${i}`}
          tag={tag}
          truncateLength={truncateLength}
          icon={tag.icon ?? icon}
          tagStyleVersion={tagStyleVersion}
          hideIcon={hideIcon || tag.hideIcon}
          noPopover={noIndividualPopover}
          className={tag.className}
          overrideStyle={tag.overrideStyle}
          compact={compact}
        />
      ))}

      {tags.length > numTagsDisplayed && (
        <Popover
          content={
            <TruncatedTagsPopoverContent
              tags={tags.slice(numTagsDisplayed)}
              tagStyleVersion={tagStyleVersion}
              icon={icon}
              hideIcon={hideIcon}
              noIndividualPopover={noIndividualPopover}
            />
          }
          arrow={false}
          trigger={['hover', 'click']}
          mouseEnterDelay={TRUNCATED_POPOVER_HOVER_DELAY_DEFAULT}
        >
          <AntdTag
            className={`mr-0 hover:cursor-pointer ${bgColor} ${padding} ${borderRadius} ${border} 
            ${tagStyleVersion === 'v2' ? 'pt-[6px]' : ''}
            ${tagStyleVersion === 'v2' ? 'text-[14px] leading-[22px]' : ''}`}
          >
            +{tags.length - numTagsDisplayed}
          </AntdTag>
        </Popover>
      )}
    </div>
  )
}
