import {
  BzAddress,
  BzDateFns,
  formatName,
  getDisplayNameForAccountType,
  isNullish,
} from '@breezy/shared'
import {
  faBan,
  faEdit,
  faEllipsis,
  faHistory,
  faScrewdriverWrench,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Dropdown, Row } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { OnsitePageSection } from 'src/adam-components/OnsitePage/OnsitePageSection'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import { QuickbooksSyncAccountButton } from '../../components/AccountingIntegration/QuickbooksOnline/QuickbooksSyncButton'
import {
  AccountBalanceBulkPaymentModal,
  DoNotServiceBanner,
} from '../../components/Accounts/AccountDetailsBanner/AccountDetailsBanner'
import { useOpenAccountBalance } from '../../components/Accounts/AccountDetailsBanner/useOpenAccountBalance'
import AddressMultiLineView from '../../components/Addresses/AddressMultiLineView/AddressMultiLineView'
import { CardOnFileCollapsibleLoader } from '../../components/collapsibles/CardOnFileCollapsible/CardOnFileCollapsible'
import { useFinancingWizard } from '../../components/Financing/hooks/useFinancingWizard'
import VerticalKeyValue from '../../components/Page/Columns/TagColumn/VerticalKeyValue'
import { RichCompanyLeadSourceView } from '../../components/RichCompanyLeadSourceView/RichCompanyLeadSourceView'
import { TagList, TagListItem } from '../../components/Tags'
import { ServiceHistoryModal } from '../../components/TechnicianApp/MyAppointmentsPage/AppointmentDetail/ServiceHistory/ServiceHistory'
import { EmDash } from '../../elements/EmDash/EmDash'
import { trpc } from '../../hooks/trpc'
import {
  useQuickbooksOnlineEnabled,
  useWisetackEnabled,
} from '../../providers/CompanyFinancialConfigWrapper'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { useModalState, useQueryParamFlag } from '../../utils/react-utils'
import {
  ArchiveAccountModal,
  useArchiveAccountModal,
} from '../AccountDetailsPage/ArchiveAccountModal'
import { PRETTY_INVOICE_TERMS_FOR_ACCOUNT_TERM } from '../Invoices/invoiceUtils'
import {
  AccountWrapper,
  Editable,
  useRichCompanyLeadSource,
  useTags,
} from './accountDetailsV2Utils'
import { AccountContactsCollapsible } from './collapsibles/AccountContactsCollapsible'
import { AccountEquipmentAndHvacSystemCollapsible } from './collapsibles/AccountEquipmentAndHvacSystemCollapsible'
import { AccountLocationsCollapsible } from './collapsibles/AccountLocationsCollapsible'
import { EditAccountDrawer } from './EditAccountDrawer'
import { WithAccountManagerEnabled } from './WithAccountManagerEnabled'

export const AccountDetailsLeftPane = React.memo<Editable<AccountWrapper>>(
  ({ meta, refetch: providedRefetch, editable = true }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const {
      accountGuid,
      accountDisplayName,
      archived,
      mailingAddress,
      accountType,
      accountManager,
      accountCreatedAt,
      accountLeadSource,
      tags: accountTags,
      doNotService,
      doNotServiceReason,
      maintenancePlans,
      defaultInvoiceTerm,
    } = meta

    const wisetackEnabled = useWisetackEnabled()
    const isQuickbooksOnlineEnabled = useQuickbooksOnlineEnabled()
    const [editAccountDrawerOpen, startEditAccount, stopEditAccount] =
      useQueryParamFlag('edit')
    const [bulkPaymentsModalOpen, setBulkPaymentsModalOpen] = useState(false)

    const {
      archiveAccount,
      closeConfirmProps: archiveAccountCloseConfirmProps,
    } = useArchiveAccountModal(accountGuid, archived)

    const leadSource = useRichCompanyLeadSource(accountLeadSource)
    const tags = useTags(accountTags)

    const accountBalance = useOpenAccountBalance(accountGuid)
    const overdueBalanceFormatted = accountBalance?.overdueBalanceFormatted
    const ageOfOldestOpenInvoice = accountBalance?.ageOfOldestOpenInvoice
    const computedTags = useMemo(() => {
      const cTags: TagListItem[] = []

      if (doNotService && doNotServiceReason) {
        cTags.push({
          name: 'Do Not Service',
          className: 'border-bz-orange-700 bg-bz-orange-100 text-bz-orange-700',
          icon: faBan,
          overrideStyle: {
            bgColor: 'bg-bz-orange-100',
            textColor: 'text-bz-orange-700',
            iconColor: 'text-bz-orange-700',
          },
          popoverContent: (
            <DoNotServiceBanner
              doNotServiceReason={doNotServiceReason}
              className="max-w-96 items-start"
            />
          ),
        })
      }

      if (maintenancePlans.length > 0) {
        cTags.push(
          ...maintenancePlans.map(plan => ({
            name:
              plan.maintenancePlanDefinition?.marketingInfo?.name ??
              'Missing Name',
            icon: faScrewdriverWrench,
          })),
        )
      }

      return [...cTags, ...tags]
    }, [tags, doNotService, doNotServiceReason, maintenancePlans])

    const [
      serviceHistoryModalOpen,
      openServiceHistoryModal,
      closeServiceHistoryModal,
    ] = useModalState()

    const qboStaleAccountQuery = trpc.qbo[
      'finance-app:get-stale-accounts'
    ].useQuery(
      {
        accountGuid,
      },
      {
        enabled: isQuickbooksOnlineEnabled,
      },
    )

    const refetch = useCallback(() => {
      providedRefetch()

      if (isQuickbooksOnlineEnabled) {
        qboStaleAccountQuery.refetch()
      }
    }, [providedRefetch, qboStaleAccountQuery, isQuickbooksOnlineEnabled])

    const { showFinancingWizard, financingWizard } = useFinancingWizard({
      accountGuid,
      onCancel: refetch,
    })

    return (
      <SectionedCard
        dashed
        accentBarColor={archived ? '#8C8C8C' : '#0958D9'}
        accentBarBody={
          archived ? (
            <span className="self-center text-white">Archived</span>
          ) : undefined
        }
        sections={[
          {
            content: (
              <div className="flex flex-col gap-4">
                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-1">
                    <h2 className="m-0 text-xl font-semibold leading-7">
                      {accountDisplayName}
                    </h2>

                    <span className="text-sm text-bz-text-secondary">
                      {getDisplayNameForAccountType(accountType)}
                    </span>
                  </div>

                  <div className="row flex items-center space-x-2">
                    {editable && isQuickbooksOnlineEnabled && (
                      <QuickbooksSyncAccountButton
                        loading={qboStaleAccountQuery.isLoading}
                        staleInfo={
                          qboStaleAccountQuery.data?.[meta.accountGuid]
                        }
                        params={{ accountGuid }}
                        className="ml-2"
                        onSuccess={qboStaleAccountQuery.refetch}
                      />
                    )}
                  </div>
                </div>

                <div className="flex flex-row flex-wrap space-x-2">
                  {editable && (
                    <Button
                      type="primary"
                      icon={<FontAwesomeIcon icon={faEdit} />}
                      onClick={startEditAccount}
                      data-testid="edit-account-button"
                    >
                      Edit
                    </Button>
                  )}
                  <Button
                    type="default"
                    icon={<FontAwesomeIcon icon={faHistory} />}
                    onClick={openServiceHistoryModal}
                    data-dd-action-name="bz-service-history-account-details"
                  >
                    Service History
                  </Button>

                  {editable && (
                    <Dropdown
                      trigger={['click']}
                      menu={{
                        items: [
                          ...(wisetackEnabled
                            ? [
                                {
                                  key: 'Send Financing',
                                  label: 'Send Financing',
                                  onClick: () => showFinancingWizard(),
                                },
                              ]
                            : []),
                          {
                            key: 'Archive Account',
                            label: archived
                              ? 'Unarchive Account'
                              : 'Archive Account',
                            danger: !archived,
                            onClick: archiveAccount,
                          },
                        ],
                      }}
                    >
                      <Button icon={<FontAwesomeIcon icon={faEllipsis} />} />
                    </Dropdown>
                  )}
                </div>
              </div>
            ),
          },
          {
            content: (
              <>
                <OnsitePageSection className="pt-0">
                  <div className="flex flex-col gap-4">
                    <div className="flex justify-between space-x-1">
                      {accountManager && (
                        <WithAccountManagerEnabled
                          render={accountManagerEnabled => {
                            return accountManagerEnabled ? (
                              <VerticalKeyValue
                                pair={{
                                  key: 'Account Manager',
                                  value: formatName(accountManager),
                                }}
                              />
                            ) : (
                              <></>
                            )
                          }}
                        />
                      )}

                      <VerticalKeyValue
                        pair={{
                          key: 'Created On',
                          value: BzDateFns.formatFromISO(
                            accountCreatedAt,
                            'MMM d, yyyy',
                            tzId,
                          ),
                        }}
                      />

                      <VerticalKeyValue
                        pair={{
                          key: 'Lead Source',
                          value: leadSource ? (
                            <RichCompanyLeadSourceView
                              accountGuid={accountGuid}
                              leadSource={leadSource}
                            />
                          ) : (
                            <EmDash />
                          ),
                        }}
                      />

                      {
                        // This is needed so we have the proper spacing between
                        // the items if there are only two items in this flex
                        // layout
                        isNullish(accountManager) && <div></div>
                      }
                    </div>

                    {mailingAddress && (
                      <Row>
                        <Col span={24}>
                          <div className="mt-5">
                            <h4 className="gray9">Billing Address</h4>
                            <AddressMultiLineView
                              address={BzAddress.create(mailingAddress)}
                              labelClassName="semibold_14_22"
                            />
                          </div>
                        </Col>
                      </Row>
                    )}

                    {overdueBalanceFormatted && ageOfOldestOpenInvoice ? (
                      <>
                        <div className="flex justify-between space-x-1">
                          <VerticalKeyValue
                            pair={{
                              key: 'Account Balance',
                              value: (
                                <div
                                  className="cursor-pointer text-blue-600"
                                  onClick={() => setBulkPaymentsModalOpen(true)}
                                >
                                  {overdueBalanceFormatted}
                                </div>
                              ),
                            }}
                          />
                          <VerticalKeyValue
                            pair={{
                              key: 'Balance Age',
                              value: `${ageOfOldestOpenInvoice} days`,
                            }}
                          />
                          {/* 
                          This is needed so we have the proper spacing between
                          the items if there are only two items in this flex
                          layout
                          */}
                          <div></div>
                        </div>
                        <AccountBalanceBulkPaymentModal
                          modalOpen={bulkPaymentsModalOpen}
                          setModalOpen={setBulkPaymentsModalOpen}
                          accountGuid={accountGuid}
                          openAccountBalance={accountBalance}
                        />
                      </>
                    ) : null}

                    <VerticalKeyValue
                      pair={{
                        key: 'Default Invoice Term',
                        value: defaultInvoiceTerm ? (
                          PRETTY_INVOICE_TERMS_FOR_ACCOUNT_TERM[
                            defaultInvoiceTerm
                          ]
                        ) : (
                          <EmDash />
                        ),
                      }}
                    />

                    <div className="flex flex-col gap-1">
                      <span className="text-sm font-semibold text-bz-gray-900">
                        Tags
                      </span>
                      <TagList
                        compact
                        tags={computedTags}
                        spacingY={2}
                        tagStyleVersion="v2"
                      />
                    </div>
                  </div>
                </OnsitePageSection>

                <AccountContactsCollapsible
                  accountGuid={accountGuid}
                  editable={editable}
                />

                <AccountLocationsCollapsible
                  accountGuid={accountGuid}
                  editable={editable}
                />

                <CardOnFileCollapsibleLoader
                  accountGuid={accountGuid}
                  editable={editable}
                />

                <AccountEquipmentAndHvacSystemCollapsible
                  accountGuid={accountGuid}
                  editable={editable}
                />

                <EditAccountDrawer
                  meta={{
                    ...meta,
                    defaultInvoiceTerm: defaultInvoiceTerm ?? 'DUE_ON_RECEIPT',
                  }}
                  open={editAccountDrawerOpen}
                  onClose={stopEditAccount}
                  refetch={refetch}
                />
                {serviceHistoryModalOpen && accountGuid && (
                  <ServiceHistoryModal
                    accountGuid={accountGuid}
                    onClose={closeServiceHistoryModal}
                  />
                )}

                {financingWizard}

                <ArchiveAccountModal
                  {...archiveAccountCloseConfirmProps}
                  archived={archived}
                />
              </>
            ),
          },
        ]}
      />
    )
  },
)
