import { AccountGuid, AccountGuidContainer, CardOnFile } from '@breezy/shared'
import React, { useCallback, useState } from 'react'

import { convertPaymentMethodRecordToCardOnFile } from '@breezy/backend/src/application-types'
import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import { CardOnFileCard } from 'src/components/Cards/CardOnFileCard'
import { OnsiteConfirmModal } from '../../../adam-components/OnsiteModal/OnsiteModal'
import { useFetchCardsOnFile } from '../../../hooks/fetch/useFetchCardsOnFile'
import { trpc } from '../../../hooks/trpc'
import { useExpectedTilledMerchantId } from '../../../providers/CompanyFinancialConfigWrapper'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../../providers/PrincipalUser'
import { useModalState } from '../../../utils/react-utils'
import GqlQueryLoader from '../../GqlQueryLoader/GqlQueryLoader'
import AddPaymentMethodModal from '../../PaymentWorkflow/components/AddPaymentMethodModal/AddPaymentMethodModal'
import EditPaymentMethodModal from '../../PaymentWorkflow/components/EditPaymentMethodModal/EditPaymentMethodModal'
type CardOnFileCollapsibleProps = {
  accountGuid: AccountGuid
  cardsOnFile: CardOnFile[]
  editable: boolean
  refetch: () => void
}

const useSelectCardOnFile = (initialValue?: CardOnFile | undefined) => {
  const [selectedCardOnFile, setSelectedCardOnFileInner] = useState<
    CardOnFile | undefined
  >(initialValue)
  const reset = useCallback(() => {
    setSelectedCardOnFileInner(undefined)
  }, [setSelectedCardOnFileInner])

  const setSelectedCardOnFile = useCallback(
    (cardOnFile: CardOnFile) => {
      setSelectedCardOnFileInner(cardOnFile)
    },
    [setSelectedCardOnFileInner],
  )

  return [selectedCardOnFile, setSelectedCardOnFile, reset] as const
}
export const CardOnFileCollapsible = React.memo<CardOnFileCollapsibleProps>(
  ({ accountGuid, cardsOnFile, editable, refetch }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const [addCardOnFileOpen, showAddCardOnFile, hideAddCardOnFile] =
      useModalState(false)

    const [editingCardOnFile, setEditingCardOnFile, resetEditingCardOnFile] =
      useSelectCardOnFile()
    const onEditCardOnFile = useCallback(
      (cardOnFile: CardOnFile) => {
        setEditingCardOnFile(cardOnFile)
      },
      [setEditingCardOnFile],
    )

    const [removingCardOnFile, setRemovingCardOnFile, resetRemovingCardOnFile] =
      useSelectCardOnFile()
    const onRemoveCardOnFile = useCallback(
      (cardOnFile: CardOnFile) => {
        setRemovingCardOnFile(cardOnFile)
      },
      [setRemovingCardOnFile],
    )

    return (
      <>
        <OnsitePageCollapsibleSection
          smallTitle
          title="Payment Methods"
          count={cardsOnFile.length}
          defaultCollapsed={cardsOnFile.length === 0}
          onAdd={editable ? showAddCardOnFile : undefined}
          collapsibleStateId={`card-on-file-collapsible-${accountGuid}`}
        >
          {cardsOnFile.map(cof => (
            <CardOnFileCard
              key={cof.paymentMethodRecordGuid}
              cardOnFile={cof}
              timezoneId={tzId}
              onEditDisplayName={onEditCardOnFile}
              onRemoveCard={onRemoveCardOnFile}
            />
          ))}
        </OnsitePageCollapsibleSection>

        {addCardOnFileOpen && (
          <AddPaymentMethodModal
            accountGuid={accountGuid}
            onClose={() => hideAddCardOnFile()}
            refetch={refetch}
          />
        )}
        {editingCardOnFile && (
          <EditPaymentMethodModal
            cardOnFile={editingCardOnFile}
            onClose={() => {
              resetEditingCardOnFile()
            }}
            refetch={refetch}
          />
        )}
        {removingCardOnFile && (
          <RemoveCardOnFileModal
            cardOnFile={removingCardOnFile}
            onClose={() => resetRemovingCardOnFile()}
            onSubmit={refetch}
          />
        )}
      </>
    )
  },
)

export const CardOnFileCollapsibleLoader = React.memo<
  AccountGuidContainer & { editable: boolean }
>(({ accountGuid, editable }) => {
  const { cardOnFileQuery, refetch } = useFetchCardsOnFile(accountGuid)

  return (
    <GqlQueryLoader
      query={cardOnFileQuery}
      render={data => {
        return (
          <CardOnFileCollapsible
            accountGuid={accountGuid}
            cardsOnFile={
              data?.paymentMethodRecords.map(
                convertPaymentMethodRecordToCardOnFile,
              ) ?? []
            }
            editable={editable}
            refetch={refetch}
          />
        )
      }}
    />
  )
})

type RemoveCardOnFileModalProps = {
  cardOnFile: CardOnFile
  onSubmit: () => void
  onClose: () => void
}
const RemoveCardOnFileModal = React.memo<RemoveCardOnFileModalProps>(
  ({ onClose, onSubmit, cardOnFile }) => {
    const deletePaymentMethodMutation =
      trpc.payments['payments:payment-method:delete'].useMutation()
    const companyGuid = useExpectedCompanyGuid()
    const merchantId = useExpectedTilledMerchantId()
    const onConfirm = useCallback(async () => {
      await deletePaymentMethodMutation.mutateAsync({
        paymentMethodRecordGuid: cardOnFile.paymentMethodRecordGuid,
        companyGuid,
        merchantId,
      })
      onSubmit()
      onClose()
    }, [
      onClose,
      onSubmit,
      cardOnFile,
      deletePaymentMethodMutation,
      companyGuid,
      merchantId,
    ])

    return (
      <OnsiteConfirmModal
        danger
        onCancel={onClose}
        onConfirm={onConfirm}
        header="Remove Card On File?"
        confirmText="Yes, Exit"
        confirmDisabled={deletePaymentMethodMutation.isLoading}
      >
        <>
          After removing a card on file, you will no longer be able to use it
          for future payments.
        </>
      </OnsiteConfirmModal>
    )
  },
)
