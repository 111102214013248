import { gql } from '../generated'

gql(/* GraphQL */ `
  fragment PricebookItem on PricebookItems {
    description
    isActive
    isDeleted
    isDiscountable
    isTaxable
    itemType
    name
    priceUsd
    pricebookItemCode
    pricebookItemGuid
    version
    photo {
      cdnUrl
      photoGuid
    }
  }
`)

export const GET_PRICEBOOK_DATA = gql(/* GraphQL */ `
  query GetPricebookData {
    pricebookCategories {
      pricebookItems(
        where: { isDeleted: { _eq: false }, isActive: { _eq: true } }
      ) {
        ...PricebookItem
      }
      name
      pricebookCategoryGuid
      parentCategoryGuid
      photo {
        cdnUrl
        photoGuid
      }
    }
    uncategorizedItems: pricebookItems(
      where: {
        pricebookCategoryGuid: { _isNull: true }
        isDeleted: { _eq: false }
        isActive: { _eq: true }
      }
    ) {
      ...PricebookItem
    }
  }
`)

export const GET_RECENT_PRICEBOOK_ITEMS = gql(/* GraphQL */ `
  query GetRecentPricebookItems($userGuid: uuid!, $limit: Int!) {
    recentPricebookItems(
      where: { userGuid: { _eq: $userGuid } }
      limit: $limit
      orderBy: { lastViewedAt: DESC }
    ) {
      pricebookItemGuid
    }
  }
`)
