import { useQuery } from 'urql'
import { DocumentType } from '../../../generated'

import { gql } from '../../../generated'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'

export const DO_NOT_SERVICE_QUERY = gql(/* GraphQL */ `
  query DoNotService($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      doNotService
      doNotServiceReason
    }
  }
`)

export const OPEN_ACCOUNT_BALANCE_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription OpenAccountBalance($accountGuid: uuid!) {
    aggregatableInvoicesAggregate(
      where: {
        status: { _eq: "OPEN" }
        dueUsc: { _gt: 0 }
        accountGuid: { _eq: $accountGuid }
      }
    ) {
      nodes {
        dueAt
        invoiceGuid
        displayIdString
        paidUsd
        totalUsc
        issuedAt
        status
        dueUsc
        jobLink {
          job {
            jobGuid
            displayIdString
            jobType {
              name
            }
          }
        }
        createdByUser {
          deactivatedAt
          userGuid
          firstName
          lastName
          userPrimaryHueHex
          userRoles {
            role
          }
          avatarPhoto {
            cdnUrl
          }
        }
      }
    }
  }
`)

export const useDoNotService = (accountGuid: string) => {
  const doNotServiceEnabled = useFeatureFlag('do-not-service')
  const [{ data, fetching }] = useQuery({
    query: DO_NOT_SERVICE_QUERY,
    variables: { accountGuid },
  })
  if (!doNotServiceEnabled) return undefined
  if (fetching) return undefined
  if (!data) return undefined
  const { accountsByPk } = data
  const doNotService = accountsByPk?.doNotService
  const doNotServiceReason = accountsByPk?.doNotServiceReason
  return doNotService ? doNotServiceReason : undefined
}

export type AggregatableInvoice = DocumentType<
  typeof OPEN_ACCOUNT_BALANCE_SUBSCRIPTION
>['aggregatableInvoicesAggregate']['nodes'][number]

export const OVERDUE_ACCOUNT_BALANCE_CONFIG_QUERY = gql(/* GraphQL */ `
  query OverdueAccountBalanceConfig($companyGuid: uuid!) {
    companyConfig(where: { companyGuid: { _eq: $companyGuid } }) {
      overdueAccountBalanceEnabled
    }
  }
`)
