import React, { useMemo } from 'react'
import { SectionedCard } from '../../../adam-components/SectionedCard/SectionedCard'
import {
  SectionedContent,
  SectionedSection,
} from '../../../adam-components/SectionedCard/SectionedContent'
import LoanPromoQualifyNow from '../../../components/Financing/LoanPromo/LoanPromoQualifyNow'
import { PrequalifiedBannerInfo } from '../../../components/PrequalifiedBannerInfo/PrequalifiedBannerInfo'
import { HtmlRenderer } from '../../../elements/HtmlRenderer/HtmlRenderer'
import useIsMobile from '../../../hooks/useIsMobile'
import { useQueryParamFlag, useStrictContext } from '../../../utils/react-utils'
import {
  InvoiceContext,
  InvoiceDataContext,
  useIsInvoiceFinanceable,
} from '../invoiceUtils'
import { InvoiceInfoContent } from './InvoiceInfoContent'
import { LineItemList } from './LineItemList'
import { TotalsSection } from './TotalsSection'

type InvoiceContentProps = {
  footer?: React.ReactNode
  noOuterBorder?: boolean
  customerFacing?: boolean
}

export const InvoiceContent = React.memo<InvoiceContentProps>(
  ({ footer, noOuterBorder, customerFacing }) => {
    const { accountGuid, jobGuid, status, companyName } =
      useStrictContext(InvoiceContext)
    const {
      messageHtml,
      lineItems,
      discounts,
      invoiceTotals,
      dynamicPricingType,
    } = useStrictContext(InvoiceDataContext)
    const [editMode] = useQueryParamFlag('edit')
    const isMobile = useIsMobile()
    const isFinanceable = useIsInvoiceFinanceable(status, invoiceTotals)

    const sections = useMemo<SectionedSection[]>(() => {
      let sections: SectionedSection[] = [<InvoiceInfoContent />]
      if (messageHtml) {
        sections.push({
          content: (
            <div>
              <div className="text-base font-semibold">Invoice Message</div>
              <div className="mt-2 text-sm">
                <HtmlRenderer htmlContent={messageHtml} />
              </div>
            </div>
          ),
        })
      }
      sections = [
        ...sections,
        {
          verticalPaddingClassName: noOuterBorder ? 'pt-4 pb-2' : 'py-1',
          content: lineItems.length ? (
            <LineItemList
              withHeaders={noOuterBorder}
              lineItems={lineItems}
              discounts={discounts}
              dynamicPricingType={
                // Note: for this we WANT it when customer facing because we want it to scale up the line item numbers
                customerFacing ? dynamicPricingType : undefined
              }
            />
          ) : (
            <div className="my-6 text-center font-semibold">No Line Items</div>
          ),
        },
        {
          content: (
            <div>
              <TotalsSection
                {...invoiceTotals}
                dynamicPricingType={
                  // Note: for this we DON'T want it when customer facing because we don't want it to break out the
                  // markup numbers.
                  customerFacing ? undefined : dynamicPricingType
                }
                discounts={discounts}
                totalLabel={isMobile ? 'Totals' : 'Balance due'}
              />
              {isFinanceable && !editMode && (
                <LoanPromoQualifyNow
                  className="mt-2"
                  type="loan-app"
                  accountGuid={accountGuid}
                  jobGuid={jobGuid}
                  showLoanDisclosure={false}
                  withQualifyNowButton
                  amountUsc={invoiceTotals.dueUsc}
                />
              )}
              {isFinanceable && !editMode && (
                <div className="mt-4">
                  <PrequalifiedBannerInfo
                    accountGuid={accountGuid}
                    companyName={companyName}
                  />
                </div>
              )}
              {footer && <div className="mt-4">{footer}</div>}
            </div>
          ),
        },
      ]
      return sections
    }, [
      accountGuid,
      companyName,
      customerFacing,
      discounts,
      dynamicPricingType,
      editMode,
      footer,
      invoiceTotals,
      isFinanceable,
      isMobile,
      jobGuid,
      lineItems,
      messageHtml,
      noOuterBorder,
    ])
    return noOuterBorder ? (
      <SectionedContent sections={sections} />
    ) : (
      <SectionedCard sections={sections} />
    )
  },
)
