import { isNullish } from '@breezy/shared'
import { useMemo } from 'react'
import { OnsitePageCollapsibleSection } from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import {
  ContactCard,
  ContactCardContact,
} from 'src/components/Cards/ContactCard'

export interface GroupedContactsCollapsibleProps {
  accountContacts?: ContactCardContact[]
  locationContacts?: ContactCardContact[]
  canManage?: boolean
  onAddContact?: () => void
  onEditLocationContact?: (contact: ContactCardContact) => void
  onRemoveLocationContact?: (contact: ContactCardContact) => void
  collapsibleStateId: string
}

export const GroupedContactsCollapsible = (
  props: GroupedContactsCollapsibleProps,
) => {
  const { accountContacts, locationContacts, collapsibleStateId } = props

  const totalContacts = useMemo(() => {
    let total = 0

    if (!isNullish(accountContacts)) {
      total += accountContacts.length
    }

    if (!isNullish(locationContacts)) {
      total += locationContacts.length
    }

    return total
  }, [accountContacts, locationContacts])

  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title="Contacts"
      count={totalContacts}
      defaultCollapsed={totalContacts === 0}
      onAdd={props.canManage ? props.onAddContact : undefined}
      collapsibleStateId={collapsibleStateId}
    >
      <div className="flex flex-col gap-3">
        {locationContacts && locationContacts.length > 0 && (
          <>
            <span className="text-sm font-semibold">
              Contacts linked to Location
            </span>

            {locationContacts.map(contact => (
              <ContactCard
                key={contact.contactGuid}
                contact={contact}
                onEdit={
                  props.canManage ? props.onEditLocationContact : undefined
                }
                onArchive={
                  props.canManage ? props.onRemoveLocationContact : undefined
                }
              />
            ))}
          </>
        )}

        {accountContacts && accountContacts.length > 0 && (
          <>
            <span className="text-sm font-semibold">
              Contacts linked to Account
            </span>

            {accountContacts.map(contact => (
              <ContactCard key={contact.contactGuid} contact={contact} />
            ))}
          </>
        )}
      </div>
    </OnsitePageCollapsibleSection>
  )
}
