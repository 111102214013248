import { DocumentType, gql } from 'src/generated'

export const BILLING_PROFILE_QUERY = gql(/* GraphQL */ `
  query BillingProfileSettingsPageBillingProfileQuery($companyGuid: uuid!) {
    billingProfiles(where: { companyGuid: { _eq: $companyGuid } }) {
      businessFullLegalName
      contractorLicenseNumber
      defaultPricebookTaxRateGuid
      estimateDisclaimer
      invoiceDisclaimer
      invoiceMemo
      invoiceTerm
      logoUrl
      logoPhotoGuid
      logoPhoto {
        cdnUrl
      }
      maintenancePlanTaxRateGuid
      maintenancePlanServiceItemGuid
      maintenancePlanAutoRenewalAllowed
      maintenancePlanManualRenewalAllowed
      tilledMerchantAccountId
      updatedAt
      websiteDisplayName
      websiteUrl
      businessAddress {
        line1
        line2
        zipCode
        city
        stateAbbreviation
      }
      emailAddress {
        emailAddress
      }
      phoneNumber {
        phoneNumber
      }
    }
  }
`)

export type BillingProfile = DocumentType<
  typeof BILLING_PROFILE_QUERY
>['billingProfiles'][number]

export const BILLING_PROFILE_UPSERT_MUTATION = gql(/* GraphQL */ `
  mutation BIllingProfileSettingsPageBillingProfileUpsertMutation(
    $object: BillingProfilesInsertInput!
  ) {
    insertBillingProfilesOne(
      object: $object
      onConflict: {
        constraint: billing_profiles_pkey
        updateColumns: [
          businessAddressGuid
          businessFullLegalName
          contractorLicenseNumber
          defaultPricebookTaxRateGuid
          emailAddressGuid
          estimateDisclaimer
          invoiceDisclaimer
          invoiceMemo
          invoiceTerm
          logoUrl
          maintenancePlanTaxRateGuid
          maintenancePlanServiceItemGuid
          maintenancePlanAutoRenewalAllowed
          maintenancePlanManualRenewalAllowed
          phoneNumberGuid
          tilledMerchantAccountId
          updatedAt
          websiteDisplayName
          websiteUrl
          logoPhotoGuid
        ]
      }
    ) {
      __typename
    }
  }
`)

export const PRICEBOOK_TAX_RATES_QUERY = gql(/* GraphQL */ `
  query BillingProfileSettingsPagePricebookTaxRatesQuery($companyGuid: uuid!) {
    pricebookTaxRates(where: { companyGuid: { _eq: $companyGuid } }) {
      name
      pricebookTaxRateGuid
    }
  }
`)
