import { JobLifecycleType } from '@breezy/shared'
import { useCallback } from 'react'
import { useLocalStorage, useSessionStorage } from 'react-use'

const JOB_LIFECYCLE_PERSISTED_DISPLAY_KEY_PREFIX = 'job-lifecycle' as const

export type JobLifecycleKanbanView = 'kanban' | 'list' | 'map'

export const JobLifecycleTimePeriodOptions = [
  'Past day',
  'Past week',
  'Past month',
  'Past 60 days',
  'Past 90 days',
] as const
export type JobLifecycleTimePeriod =
  (typeof JobLifecycleTimePeriodOptions)[number]

const JobLifecycleColumnDisplayProperties = [
  'Invoice Totals',
  'Estimate Totals',
] as const

export type JobLifecycleColumnDisplayProperty =
  (typeof JobLifecycleColumnDisplayProperties)[number]

export type JobLifecycleColumnDisplayPropertySettings = Record<
  string, // Each lifecycle has its own column display property settings
  Partial<Record<JobLifecycleColumnDisplayProperty, boolean>>
>

export const getDefaultJobLifecycleColumnDisplayPropertiesForLifecycleType = (
  lifecycleType?: JobLifecycleType,
): Partial<Record<JobLifecycleColumnDisplayProperty, boolean>> => {
  switch (lifecycleType) {
    case 'JOB':
      return { 'Invoice Totals': true, 'Estimate Totals': false }
    case 'SALES':
      return { 'Invoice Totals': false, 'Estimate Totals': true }
    default:
      return { 'Invoice Totals': true, 'Estimate Totals': true }
  }
}

export const getJobLifecycleColumnDisplayProperties = (
  lifecycles: { lifecycleGuid: string; lifecycleType: JobLifecycleType }[],
): JobLifecycleColumnDisplayPropertySettings => {
  return lifecycles.reduce<JobLifecycleColumnDisplayPropertySettings>(
    (acc, curr) => {
      acc[curr.lifecycleGuid] =
        getDefaultJobLifecycleColumnDisplayPropertiesForLifecycleType(
          curr.lifecycleType,
        )

      return acc
    },
    {},
  )
}

const JobLifecycleDisplayProperties = [
  'ID',
  'Job Class',
  'Job Type',
  'Location',
  'Account Name',
  'Point of Contact',
  'Next Appointment',
  'Account Manager',
  'Tags',
  'Invoices',
  'Estimates',
] as const

export type JobLifecycleDisplayPropertySettings = Partial<
  Record<(typeof JobLifecycleDisplayProperties)[number], boolean>
>
const JobLifecycleDisplayPropertySettingsDefault: JobLifecycleDisplayPropertySettings =
  {
    ID: true,
    'Job Class': true,
    'Job Type': true,
    Location: true,
    'Account Name': true,
    'Next Appointment': true,
    'Point of Contact': false,
    'Account Manager': false,
    Tags: false,
    Invoices: false,
    Estimates: false,
  } as const

export type JobLifecycleScrollPosition = {
  offsetTop: number
  offsetLeft: number
}

type JobLifecyclePersistedFilter = {
  view?: string
  lifecycle?: string
  filters?: string
}

type JobLifecyclePersistedDisplay = {
  kanbanView: {
    value: JobLifecycleKanbanView
    set: (newView: JobLifecycleKanbanView) => void
  }
  closedJobTimePeriod: {
    value: JobLifecycleTimePeriod
    set: (newTimePeriod: JobLifecycleTimePeriod) => void
  }
  showEmptyStatuses: {
    value: boolean
    set: (newShowEmptyStatuses: boolean) => void
  }
  columnPropertySettings: JobLifecycleColumnDisplayPropertySettings
  setColumnPropertySettings: (
    newColumnPropertySettings: JobLifecycleColumnDisplayPropertySettings,
  ) => void
  displayPropertySettings: JobLifecycleDisplayPropertySettings
  setDisplayPropertySettings: (
    newDisplayPropertySettings: JobLifecycleDisplayPropertySettings,
  ) => void
  scrollPos: JobLifecycleScrollPosition
  setScrollPos: (newScrollPost: JobLifecycleScrollPosition) => void
  currentFilter?: JobLifecyclePersistedFilter
  setCurrentFilter: (value: JobLifecyclePersistedFilter) => void
}

export const useJobLifecyclePersistedDisplay = (
  lifecycles: { lifecycleGuid: string; lifecycleType: JobLifecycleType }[],
): JobLifecyclePersistedDisplay => {
  const [kanbanView, setKanbanView] = useLocalStorage<JobLifecycleKanbanView>(
    `${JOB_LIFECYCLE_PERSISTED_DISPLAY_KEY_PREFIX}-kanban-view`,
  )

  const [closedJobTimePeriod, setClosedJobTimePeriod] =
    useLocalStorage<JobLifecycleTimePeriod>(
      `${JOB_LIFECYCLE_PERSISTED_DISPLAY_KEY_PREFIX}-closed-job-time-period`,
    )

  const [showEmptyStatuses, setShowEmptyStatuses] = useLocalStorage<boolean>(
    `${JOB_LIFECYCLE_PERSISTED_DISPLAY_KEY_PREFIX}-show-empty-statuses`,
  )

  const [columnPropertiesSettings, setColumnPropertiesSettings] =
    useLocalStorage<JobLifecycleColumnDisplayPropertySettings>(
      `${JOB_LIFECYCLE_PERSISTED_DISPLAY_KEY_PREFIX}-column-properties-settings`,
      getJobLifecycleColumnDisplayProperties(lifecycles),
      {
        raw: false,
        serializer: settings => JSON.stringify(settings),
        deserializer: value => {
          try {
            const settings = JSON.parse(value)
            if (typeof settings !== 'object' || Array.isArray(settings)) {
              return getJobLifecycleColumnDisplayProperties(lifecycles)
            }

            for (const lifecycleColumnSettings of Object.values(settings)) {
              if (
                typeof lifecycleColumnSettings !== 'object' ||
                Array.isArray(lifecycleColumnSettings)
              ) {
                return getJobLifecycleColumnDisplayProperties(lifecycles)
              }

              for (const settingsValue of Object.values(
                lifecycleColumnSettings as object,
              )) {
                if (typeof settingsValue !== 'boolean') {
                  return getJobLifecycleColumnDisplayProperties(lifecycles)
                }
              }
            }

            // This is okay to cast because if the parsed object doesn't contain the keys we want,
            // we won't be using those keys anyway. The extra space shouldn't be too much of an issue, though
            // it would be nice to strip all the unnecessary keys... (possible TODO?)
            return {
              ...getJobLifecycleColumnDisplayProperties(lifecycles),
              ...settings,
            } as JobLifecycleColumnDisplayPropertySettings
          } catch (err) {
            return getJobLifecycleColumnDisplayProperties(lifecycles)
          }
        },
      },
    )

  const [displayPropertySettings, setDisplayPropertySettingsInner] =
    useLocalStorage<JobLifecycleDisplayPropertySettings>(
      `${JOB_LIFECYCLE_PERSISTED_DISPLAY_KEY_PREFIX}-display-property-settings`,
      JobLifecycleDisplayPropertySettingsDefault,
      {
        raw: false,
        serializer: settings => JSON.stringify(settings),
        deserializer: value => {
          try {
            const settings = JSON.parse(value)
            if (typeof settings !== 'object' || Array.isArray(settings)) {
              return JobLifecycleDisplayPropertySettingsDefault
            }

            for (const settingsValue of Object.values(settings)) {
              if (typeof settingsValue !== 'boolean') {
                return JobLifecycleDisplayPropertySettingsDefault
              }
            }

            // This is okay to cast because if the parsed object doesn't contain the keys we want,
            // we won't be using those keys anyway. The extra space shouldn't be too much of an issue, though
            // it would be nice to strip all the unnecessary keys... (possible TODO?)
            return {
              ...JobLifecycleDisplayPropertySettingsDefault,
              ...settings,
            } as JobLifecycleDisplayPropertySettings
          } catch (err) {
            return JobLifecycleDisplayPropertySettingsDefault
          }
        },
      },
    )
  const setDisplayPropertySettings = useCallback(
    (displayProperties: JobLifecycleDisplayPropertySettings) => {
      setDisplayPropertySettingsInner(displayProperties)
    },
    [setDisplayPropertySettingsInner],
  )

  const [scrollPos, setScrollPosInner] =
    useSessionStorage<JobLifecycleScrollPosition>(
      `${JOB_LIFECYCLE_PERSISTED_DISPLAY_KEY_PREFIX}-scroll-pos`,
      { offsetTop: 0, offsetLeft: 0 },
    )
  const setScrollPos = useCallback(
    (newScrollPos: JobLifecycleScrollPosition) => {
      setScrollPosInner(newScrollPos)
    },
    [setScrollPosInner],
  )

  const [currentFilter, setCurrentFilterInner] =
    useLocalStorage<JobLifecyclePersistedFilter>(
      `${JOB_LIFECYCLE_PERSISTED_DISPLAY_KEY_PREFIX}-currentFilters`,
      {},
    )
  const setCurrentFilter = useCallback(
    (value: JobLifecyclePersistedFilter) => {
      setCurrentFilterInner(value)
    },
    [setCurrentFilterInner],
  )

  return {
    kanbanView: {
      value: kanbanView ?? 'kanban',
      set: newView => setKanbanView(newView),
    },
    closedJobTimePeriod: {
      value: closedJobTimePeriod ?? 'Past week',
      set: newTimePeriod => setClosedJobTimePeriod(newTimePeriod),
    },
    showEmptyStatuses: {
      value: showEmptyStatuses ?? true,
      set: newShowEmptyStatuses => setShowEmptyStatuses(newShowEmptyStatuses),
    },
    columnPropertySettings:
      columnPropertiesSettings ??
      getJobLifecycleColumnDisplayProperties(lifecycles),
    setColumnPropertySettings: setColumnPropertiesSettings,
    displayPropertySettings:
      displayPropertySettings ?? JobLifecycleDisplayPropertySettingsDefault,
    setDisplayPropertySettings,
    scrollPos,
    setScrollPos,
    currentFilter,
    setCurrentFilter,
  }
}
