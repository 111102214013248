import {
  AccountContact,
  AccountGuid,
  BzContact,
  isNullish,
} from '@breezy/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { ContactsCollapsible } from 'src/components/collapsibles/ContactsCollapsible/ContactsCollapsible'
import { useMutation, useQuery } from 'urql'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../../adam-components/OnsiteModal/OnsiteModal'
import { PaginationControls } from '../../../adam-components/Pagination/PaginationControls'
import { usePagination } from '../../../adam-components/Pagination/usePagination'
import {
  ContactCard,
  ContactCardContact,
} from '../../../components/Cards/ContactCard'
import { SET_ACCOUNT_CONTACT_ARCHIVED_MUTATION } from '../../../components/collapsibles/AccountContactsCollapsible/AccountContactsCollapsible.gql'
import { CreateOrEditNewContactForm } from '../../../components/Contacts/CreateOrEditNewContactForm/CreateOrEditNewContactForm'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import BzDrawer from '../../../elements/BzDrawer/BzDrawer'
import { DocumentType, gql } from '../../../generated/user'
import { trpc } from '../../../hooks/trpc'
import { useMessage } from '../../../utils/antd-utils'
import { ContactIcon } from '../../../utils/feature-icons'
import { useModalState } from '../../../utils/react-utils'
import { DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT } from '../accountDetailsV2Utils'

const ACCOUNT_CONTACTS_QUERY = gql(/* GraphQL */ `
  query AccountContacts($accountGuid: uuid!, $limit: Int!, $offset: Int!) {
    accountsByPk(accountGuid: $accountGuid) {
      accountContactsAggregate {
        aggregate {
          count
        }
      }
      accountContacts(
        orderBy: [
          { primary: DESC }
          { archived: ASC }
          { createdAt: DESC }
          { updatedAt: DESC }
        ]
        limit: $limit
        offset: $offset
      ) {
        accountContactGuid
        archived
        primary
        companyGuid
        accountGuid
        contact {
          contactGuid
          companyGuid
          firstName
          lastName
          salutation
          title
          notificationPreferenceType
          primaryEmailAddress {
            emailAddress
            emailAddressGuid
            companyGuid
          }
          primaryPhoneNumber {
            phoneNumber
            phoneNumberGuid
            companyGuid
            type
            unsubscribed
          }
          additionalEmailAddress {
            emailAddress
            emailAddressGuid
            companyGuid
          }
          additionalPhoneNumber {
            phoneNumber
            phoneNumberGuid
            companyGuid
            type
            unsubscribed
          }
        }
      }
    }
  }
`)

type AccountContactsQuery = DocumentType<typeof ACCOUNT_CONTACTS_QUERY>

const convertQueryToContactCardContacts = (
  accountContacts: NonNullable<
    AccountContactsQuery['accountsByPk']
  >['accountContacts'],
): ContactCardContact[] => {
  return (
    accountContacts
      .map(contact => ({
        contactGuid: contact.contact.contactGuid,
        name: `${contact.contact.firstName} ${contact.contact.lastName}`,
        notificationPreference: contact.contact.notificationPreferenceType,
        emailAddress: contact.contact.primaryEmailAddress?.emailAddress,
        additionalEmailAddress:
          contact.contact.additionalEmailAddress?.emailAddress,
        phoneNumber: contact.contact.primaryPhoneNumber?.phoneNumber,
        phoneNumberType: contact.contact.primaryPhoneNumber?.type,
        additionalPhoneNumber:
          contact.contact.additionalPhoneNumber?.phoneNumber,
        additionalPhoneNumberType: contact.contact.additionalPhoneNumber?.type,
        primary: contact.primary,
        archived: contact.archived,
      }))
      .sort((a, b) => {
        return a.primary ? -1 : b.primary ? 1 : a.archived ? 1 : -1
      }) ?? []
  )
}

const convertQueryToAccountContacts = (
  accountContacts: NonNullable<
    AccountContactsQuery['accountsByPk']
  >['accountContacts'],
): AccountContact[] => {
  return (
    accountContacts
      .map(ac => ({
        accountContactGuid: ac.accountContactGuid,
        accountGuid: ac.accountGuid,
        companyGuid: ac.companyGuid,
        primary: ac.primary,
        archived: ac.archived,
        contact: ac.contact,
      }))
      .sort((a, b) => {
        return a.primary ? -1 : b.primary ? 1 : a.archived ? 1 : -1
      }) ?? []
  )
}

export const useFetchAccountContacts = (
  accountGuid: AccountGuid,
  {
    limit = DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT,
    offset = 0,
  }: { limit?: number; offset?: number },
) => {
  const accountContactsQuery = useQuery({
    query: ACCOUNT_CONTACTS_QUERY,
    variables: {
      accountGuid,
      limit,
      offset,
    },
  })

  const total = useMemo(() => {
    return (
      accountContactsQuery[0].data?.accountsByPk?.accountContactsAggregate
        ?.aggregate?.count ?? 0
    )
  }, [accountContactsQuery])

  const contactCardContacts = useMemo(
    () =>
      convertQueryToContactCardContacts(
        accountContactsQuery[0].data?.accountsByPk?.accountContacts ?? [],
      ),
    [accountContactsQuery],
  )

  const accountContacts = useMemo(
    () =>
      convertQueryToAccountContacts(
        accountContactsQuery[0].data?.accountsByPk?.accountContacts ?? [],
      ),
    [accountContactsQuery],
  )

  return {
    accountContactsQuery,
    refetch: accountContactsQuery[1],
    fetching: accountContactsQuery[0].fetching,
    contactCardContacts,
    accountContacts,
    total,
  }
}

const useMakeAccountContactPrimary = (refetch: () => void) => {
  const message = useMessage()
  const updatePrimaryAccountContactMutation =
    trpc.accountContacts['account-contacts:update-primary'].useMutation()

  const makeAccountContactPrimary = useCallback(
    async (accountContact: AccountContact) => {
      updatePrimaryAccountContactMutation.mutate(
        {
          accountContactGuid: accountContact.accountContactGuid,
          accountGuid: accountContact.accountGuid,
        },
        {
          onSuccess() {
            message.success('Successfully updated contact')
            refetch()
          },
        },
      )
    },
    [message, refetch, updatePrimaryAccountContactMutation],
  )

  return {
    makeAccountContactPrimary,
    isMutating: updatePrimaryAccountContactMutation.isLoading,
  }
}

const useArchiveAccountContact = (refetch: () => void) => {
  const message = useMessage()
  const [
    { fetching: mutatingAccountContactArchived },
    executeAccountContactArchivedMutation,
  ] = useMutation(SET_ACCOUNT_CONTACT_ARCHIVED_MUTATION)

  const archiveAccountContact = useCallback(
    async (accountContact: AccountContact) => {
      const bzContact = BzContact.create(accountContact.contact)

      if (accountContact.primary) {
        message.error(
          `Primary contacts cannot be archived. To archive this contact you must first change the primary contact on the account`,
        )
        return
      }

      if (accountContact.archived) {
        message.error(
          `Cannot archive contact ${bzContact.fullName} because it is already archived`,
        )
        return
      }

      try {
        await executeAccountContactArchivedMutation({
          accountContactGuid: accountContact.accountContactGuid,
          archived: true,
        })

        refetch()

        message.success(`Archived contact ${bzContact.fullName}`)
      } catch (e) {
        message.error(`Failed to archive contact ${bzContact.fullName}`)
      }
    },
    [executeAccountContactArchivedMutation, message, refetch],
  )

  const unarchiveAccountContact = useCallback(
    async (accountContact: AccountContact) => {
      const bzContact = BzContact.create(accountContact.contact)

      if (!accountContact.archived) {
        message.warning(
          `Cannot unarchive contact ${bzContact.fullName} because it is already unarchived`,
        )
        return
      }

      try {
        await executeAccountContactArchivedMutation({
          accountContactGuid: accountContact.accountContactGuid,
          archived: false,
        })

        refetch?.()

        message.success(`Unarchived contact ${bzContact.fullName}`)
      } catch (e) {
        message.error(`Failed to unarchive contact ${bzContact.fullName}`)
      }
    },
    [executeAccountContactArchivedMutation, message, refetch],
  )

  return {
    archiveAccountContact,
    unarchiveAccountContact,
    mutatingAccountContactArchived,
  }
}

const useEditAccountContactProps = (refetch: () => void) => {
  const [editingContact, setEditingContact] = useState<
    AccountContact | undefined
  >(undefined)

  const [
    upsertContactDrawerOpen,
    openUpsertContactDrawer,
    closeUpsertContactDrawer,
  ] = useModalState()

  const message = useMessage()

  const onEditAccountContact = useCallback(
    (accountContact: AccountContact) => {
      setEditingContact(accountContact)
      openUpsertContactDrawer()
    },
    [openUpsertContactDrawer],
  )

  const onSuccess = useCallback(() => {
    closeUpsertContactDrawer()
    if (!editingContact) {
      message.success('Successfully added new Contact')
    } else {
      message.success('Successfully updated Contact')
    }
    setEditingContact(undefined)
    refetch()
  }, [closeUpsertContactDrawer, editingContact, message, refetch])

  const onClose = useCallback(() => {
    setEditingContact(undefined)
    closeUpsertContactDrawer()
  }, [setEditingContact, closeUpsertContactDrawer])

  return {
    onEditAccountContact,
    editingContact,
    onSuccess,
    onClose,
    upsertContactDrawerOpen,
    openUpsertContactDrawer,
  }
}

const useContactCardActions = (
  accountContacts: AccountContact[],
  refetch: () => void,
) => {
  const message = useMessage()
  const {
    archiveAccountContact,
    unarchiveAccountContact,
    mutatingAccountContactArchived,
  } = useArchiveAccountContact(refetch)

  const {
    makeAccountContactPrimary,
    isMutating: mutatingAccountContactPrimary,
  } = useMakeAccountContactPrimary(refetch)

  const {
    onEditAccountContact,
    openUpsertContactDrawer,
    ...editAccountContactDrawerProps
  } = useEditAccountContactProps(refetch)

  const onContactEdit = useCallback(
    (contact: ContactCardContact) => {
      const accountContact = accountContacts.find(
        curr => curr.contact.contactGuid === contact.contactGuid,
      )
      if (isNullish(accountContact)) {
        return
      }

      onEditAccountContact(accountContact)
    },
    [accountContacts, onEditAccountContact],
  )

  const onContactMakePrimary = useCallback(
    (contact: ContactCardContact) => {
      const accountContact = accountContacts.find(
        curr => curr.contact.contactGuid === contact.contactGuid,
      )

      if (isNullish(accountContact)) {
        return
      }

      makeAccountContactPrimary(accountContact)
    },
    [accountContacts, makeAccountContactPrimary],
  )

  const onContactArchive = useCallback(
    (contact: ContactCardContact) => {
      const accountContact = accountContacts.find(
        curr => curr.contact.contactGuid === contact.contactGuid,
      )
      if (isNullish(accountContact)) {
        return
      }

      if (accountContacts.filter(ac => !ac.archived).length <= 1) {
        message.error(
          "Cannot archive a contact when it is the account's only active contact",
        )
        return
      }

      archiveAccountContact(accountContact)
    },
    [accountContacts, archiveAccountContact, message],
  )

  const onContactUnarchive = useCallback(
    (contact: ContactCardContact) => {
      const accountContact = accountContacts.find(
        curr => curr.contact.contactGuid === contact.contactGuid,
      )
      if (isNullish(accountContact)) {
        return
      }
      unarchiveAccountContact(accountContact)
    },
    [accountContacts, unarchiveAccountContact],
  )

  const isLoading = useMemo(
    () => mutatingAccountContactPrimary || mutatingAccountContactArchived,
    [mutatingAccountContactPrimary, mutatingAccountContactArchived],
  )

  return {
    onContactEdit,
    onContactArchive,
    onContactUnarchive,
    onContactMakePrimary,
    onContactAdd: openUpsertContactDrawer,
    isLoading,
    editAccountContactDrawerProps,
  }
}

type AccountContactsCollapsibleProps = {
  accountGuid: AccountGuid
  title?: string
  editable?: boolean
  onViewMore?: () => void
}

export const AccountContactsCollapsible =
  React.memo<AccountContactsCollapsibleProps>(
    ({ editable = false, title = 'Contacts', accountGuid, onViewMore }) => {
      const { contactCardContacts, accountContacts, total, fetching, refetch } =
        useFetchAccountContacts(accountGuid, {
          limit: DEFAULT_ACCOUNT_COLLAPSIBLES_LIMIT,
          offset: 0,
        })
      const [viewMoreOpen, viewMoreContacts, closeViewMoreContacts] =
        useModalState()

      const {
        onContactAdd,
        onContactEdit,
        onContactMakePrimary,
        onContactArchive,
        onContactUnarchive,
        isLoading,
        editAccountContactDrawerProps,
      } = useContactCardActions(accountContacts, refetch)

      return (
        <>
          <ContactsCollapsible
            collapsibleStateId={`account-contacts-collapsible-${accountGuid}`}
            contacts={contactCardContacts}
            title={title}
            editable={editable}
            disabled={
              fetching ||
              isLoading ||
              editAccountContactDrawerProps.upsertContactDrawerOpen
            }
            total={total}
            onContactAdd={onContactAdd}
            onContactEdit={onContactEdit}
            onContactMakePrimary={onContactMakePrimary}
            onContactArchive={onContactArchive}
            onContactUnarchive={onContactUnarchive}
            mode="paginated"
            onViewMore={onViewMore ?? viewMoreContacts}
          />

          {editable &&
            editAccountContactDrawerProps.upsertContactDrawerOpen && (
              <CreateOrEditNewContactDrawer
                accountGuid={accountGuid}
                {...editAccountContactDrawerProps}
              />
            )}
          {viewMoreOpen && (
            <AccountContactsViewMoreModal
              accountGuid={accountGuid}
              editable={editable}
              onClose={closeViewMoreContacts}
            />
          )}
        </>
      )
    },
  )

type AccountContactsViewMoreModalProps = {
  accountGuid: AccountGuid
  editable?: boolean
  onClose: () => void
}

const AccountContactsViewMoreModal =
  React.memo<AccountContactsViewMoreModalProps>(
    ({ accountGuid, onClose: externalOnClose, editable }) => {
      const { page, pageSize, setPage, setPageSize, limit, offset, resetPage } =
        usePagination('10', 1)

      const { contactCardContacts, accountContacts, total, fetching, refetch } =
        useFetchAccountContacts(accountGuid, { limit, offset })

      const {
        onContactEdit,
        onContactMakePrimary,
        onContactArchive,
        onContactUnarchive,
        editAccountContactDrawerProps,
      } = useContactCardActions(accountContacts, refetch)

      const onClose = useCallback(() => {
        externalOnClose()
        setPageSize('10')
        resetPage()
      }, [externalOnClose, setPageSize, resetPage])

      return (
        <>
          <OnsiteModal open size="large" onClose={onClose}>
            <OnsiteModalContent
              onClose={onClose}
              header="Contacts"
              footer={
                <PaginationControls
                  className="mt-4"
                  page={page}
                  pageSize={pageSize}
                  totalItems={total}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              }
            >
              {fetching ? (
                <LoadingSpinner />
              ) : (
                <div className="flex min-h-0 flex-col gap-2">
                  {contactCardContacts.map(contact => (
                    <div>
                      <ContactCard
                        key={contact.contactGuid}
                        contact={contact}
                        onEdit={editable ? onContactEdit : undefined}
                        onMakePrimary={
                          editable ? onContactMakePrimary : undefined
                        }
                        onArchive={editable ? onContactArchive : undefined}
                        onUnarchive={editable ? onContactUnarchive : undefined}
                      />
                    </div>
                  ))}
                </div>
              )}
            </OnsiteModalContent>
          </OnsiteModal>
          {editable &&
            editAccountContactDrawerProps.upsertContactDrawerOpen && (
              <CreateOrEditNewContactDrawer
                accountGuid={accountGuid}
                {...editAccountContactDrawerProps}
              />
            )}
        </>
      )
    },
  )

type CreateOrEditNewContactDrawerProps = {
  accountGuid: AccountGuid
  editingContact: AccountContact | undefined
  onClose: () => void
  onSuccess: () => void
}

const CreateOrEditNewContactDrawer =
  React.memo<CreateOrEditNewContactDrawerProps>(
    ({ accountGuid, editingContact, onClose, onSuccess }) => {
      return (
        <BzDrawer
          title={!isNullish(editingContact) ? 'Edit Contact' : 'Add Contact'}
          icon={ContactIcon}
          rootClassName="z-[1020]"
          preferredWidth={720}
          item={{
            onCancel: onClose,
          }}
          destroyOnClose
        >
          <CreateOrEditNewContactForm
            onCancelButtonPressed={onClose}
            accountGuid={accountGuid}
            editingAccountContact={editingContact ?? undefined}
            onAccountContactAdded={onSuccess}
            onAccountContactUpdated={onSuccess}
          />
        </BzDrawer>
      )
    },
  )
