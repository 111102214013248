import { gql } from '../../generated/user/gql'

export const FETCH_COMPANY_BUSINESS_UNITS_ENABLED = gql(/* GraphQL */ `
  query FetchCompanyBusinessUnitsEnabled($companyGuid: uuid!) {
    companyConfig(where: { companyGuid: { _eq: $companyGuid } }) {
      businessUnitsEnabled
      businessUnitsRequired
    }
  }
`)

export const UPSERT_COMPANY_BUSINESS_UNITS_ENABLED = gql(/* GraphQL */ `
  mutation UpsertCompanyBusinessUnitsEnabled(
    $object: CompanyConfigInsertInput!
  ) {
    insertCompanyConfigOne(
      object: $object
      onConflict: {
        constraint: company_config_pkey
        updateColumns: [businessUnitsEnabled]
      }
    ) {
      __typename
    }
  }
`)

export const UPSERT_COMPANY_BUSINESS_UNITS_REQUIRED = gql(/* GraphQL */ `
  mutation UpsertCompanyBusinessUnitsRequired(
    $object: CompanyConfigInsertInput!
  ) {
    insertCompanyConfigOne(
      object: $object
      onConflict: {
        constraint: company_config_pkey
        updateColumns: [businessUnitsRequired]
      }
    ) {
      __typename
    }
  }
`)

export const FETCH_COMPANY_BUSINESS_UNITS = gql(/* GraphQL */ `
  query FetchCompanyBusinessUnits($companyGuid: uuid!) {
    companyConfig(where: { companyGuid: { _eq: $companyGuid } }) {
      businessUnitsEnabled
      businessUnitsRequired
    }
    businessUnits(where: { companyGuid: { _eq: $companyGuid } }) {
      businessUnitGuid
      companyGuid
      name
      accountingIntegrationName
    }
  }
`)

export const UPSERT_BUSINESS_UNIT = gql(/* GraphQL */ `
  mutation UpsertBusinessUnit($object: BusinessUnitsInsertInput!) {
    insertBusinessUnitsOne(
      object: $object
      onConflict: {
        constraint: business_units_pkey
        updateColumns: [name, updatedAt, accountingIntegrationName]
      }
    ) {
      __typename
    }
  }
`)
