import {
  AccountGuid,
  AccountingPaymentImport,
  BzDateFns,
  CalculatePaths,
  formatMoney,
  formatUsc,
  InvoiceGuid,
  isNullish,
  nextGuid,
  OFFLINE_PAYMENT_METHODS,
  PaymentMethod,
  paymentMethodDisplayName,
  PaymentRecordGuid,
  PaymentStatus,
  usCentsToUsd,
  usdToUsCents,
} from '@breezy/shared'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import {
  faDollar,
  faTriangleExclamation,
} from '@fortawesome/pro-regular-svg-icons'
import { Alert, Button, Form, message, Table } from 'antd'
import { ColumnType, TableRowSelection } from 'antd/es/table/interface'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useQuery } from 'urql'
import {
  OnsiteModal,
  OnsiteModalContent,
} from '../../adam-components/OnsiteModal/OnsiteModal'
import {
  CloseConfirmModal,
  useCloseConfirmModal,
} from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import {
  Banner,
  BannerIcon,
  BannerStatusMessage,
} from '../../elements/Banner/Banner'
import FaIconWithCircularBackground from '../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../elements/Forms/SelectField'
import { TextField } from '../../elements/Forms/TextField'
import Switch from '../../elements/Switch/Switch'
import ThinDivider from '../../elements/ThinDivider'
import { type FetchPayableInvoicesForAccountQuery } from '../../generated/user/graphql'
import { trpc } from '../../hooks/trpc'
import { useImportPaymentFromAccountingEnabled } from '../../hooks/useImportPaymentFromAccountingEnabled'
import useIsMobile from '../../hooks/useIsMobile'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { QuickbooksLogoIconNonResizing } from '../AccountingIntegration/QuickbooksOnline/QuickbooksLogoIcon'
import { UsdInputNumber } from '../JobOutcomesModal/UsdInputNumber'
import { LoadingSpinner } from '../LoadingSpinner'
import { FETCH_PAYABLE_INVOICES_FOR_ACCOUNT_QUERY } from './BulkPayment.gql'
import { ImportAccountingPaymentView } from './ImportAccountingPaymentView'

type FormData = {
  paymentAmountUsd: number
  invoiceGuidToAppliedPaymentAmountUsd: Record<InvoiceGuid, number>
  paymentMethod: PaymentMethod
  note?: string
  externalPaymentId?: string
  paymentMethodAdditionalInfo?: string
  referenceNumber?: string
}

type PayableInvoice = NonNullable<
  FetchPayableInvoicesForAccountQuery['aggregatableInvoices'][number]
> & {
  readonly?: boolean
}

type BaseBulkPaymentChangeEvent = {
  totalPaymentAmountUsc: number
  appliedPayments: Array<{
    invoiceGuid: InvoiceGuid
    invoiceDisplayId: string
    appliedPaymentAmountUsc: number
  }>
}

type BulkPaymentChangeEvent = BaseBulkPaymentChangeEvent &
  ({ valid: true } | { valid: false; validationMessage: string })

type BulkPaymentOnChange = (invoicePayments: BulkPaymentChangeEvent) => void

type BulkPaymentInnerProps = {
  accountGuid: AccountGuid
  payableInvoices: PayableInvoice[]
  onChange: BulkPaymentOnChange
  onSuccess: () => void
  initialPaymentData?: Partial<{
    paymentAmountUsd: number
    paymentMethod: PaymentMethod
    note?: string
    externalPaymentId?: string
    paymentMethodAdditionalInfo?: string
    invoiceGuidToAppliedPaymentAmountUsd?: Record<InvoiceGuid, number>
    referenceNumber?: string
  }>
  onSubmitSuccess?: (paymentRecordGuid: PaymentRecordGuid) => void
  mode?: 'bulk' | 'import'
}

export const BulkPaymentViewInner = React.memo<BulkPaymentInnerProps>(
  ({
    payableInvoices,
    onChange,
    accountGuid,
    onSuccess,
    initialPaymentData,
    onSubmitSuccess,
    mode = 'bulk',
  }: BulkPaymentInnerProps) => {
    const companyGuid = useExpectedCompany().companyGuid
    const {
      control,
      watch,
      setValue,
      handleSubmit,
      formState: { isDirty, errors },
    } = useForm<FormData>({
      mode: 'onBlur',
      defaultValues: {
        paymentAmountUsd: initialPaymentData?.paymentAmountUsd || 0,
        paymentMethod: initialPaymentData?.paymentMethod,
        note: initialPaymentData?.note,
        externalPaymentId: initialPaymentData?.externalPaymentId,
        paymentMethodAdditionalInfo:
          initialPaymentData?.paymentMethodAdditionalInfo,
        referenceNumber: initialPaymentData?.referenceNumber,
        invoiceGuidToAppliedPaymentAmountUsd:
          initialPaymentData?.invoiceGuidToAppliedPaymentAmountUsd ??
          payableInvoices.reduce((acc, invoice) => {
            acc[invoice.invoiceGuid] = 0
            return acc
          }, {} as Record<InvoiceGuid, number>),
      },
    })

    const paymentAmountUsd = watch('paymentAmountUsd')
    const invoiceGuidToAppliedPaymentAmountUsd = watch(
      'invoiceGuidToAppliedPaymentAmountUsd',
    )

    const recordPaymentsMutation =
      trpc.payments['payments:record:v2'].useMutation()

    const onSubmit = useCallback(
      (data: FormData) => {
        const paymentRecordGuid = nextGuid()

        let externalPaymentId: string | undefined

        switch (data.paymentMethod) {
          case PaymentMethod.CHECK:
            externalPaymentId = `Check Number: ${data.externalPaymentId}`
            break
          case PaymentMethod.CASH:
            break
          default:
            externalPaymentId = `Transaction ID: ${data.externalPaymentId}`
            break
        }

        let paymentAdditionalInfo: string | undefined

        if (!isNullish(data.paymentMethodAdditionalInfo)) {
          switch (data.paymentMethod) {
            case PaymentMethod.CHECK:
              paymentAdditionalInfo = `Bank Name: ${data.paymentMethodAdditionalInfo}`
              break
            case PaymentMethod.CASH:
              break
            default:
              paymentAdditionalInfo = `Payment Method: ${data.paymentMethodAdditionalInfo}`
              break
          }
        }

        recordPaymentsMutation.mutate(
          {
            accountGuid: accountGuid,
            companyGuid: companyGuid,
            status: PaymentStatus.PAID,
            statusDetail: PaymentStatus.PAID,
            paymentRecordGuid: paymentRecordGuid,
            amountUsd: data.paymentAmountUsd,
            paymentMethod: data.paymentMethod,
            referenceNumber: data.referenceNumber,
            appliedPayments: Object.entries(
              invoiceGuidToAppliedPaymentAmountUsd,
            )
              .filter(([, entry]) => {
                return entry > 0
              })
              .map(([invoiceGuid, appliedPaymentAmountUsd]) => {
                return {
                  invoiceGuid: invoiceGuid,
                  invoiceReferenceNumber:
                    payableInvoices.find(
                      invoice => invoice.invoiceGuid === invoiceGuid,
                    )?.displayIdV2 ?? '',
                  appliedAmountUsc: usdToUsCents(appliedPaymentAmountUsd),
                }
              }),
            note: data.note,
            externalPaymentId,
            paymentMethodAdditionalInfo: paymentAdditionalInfo,
          },
          {
            onSuccess: () => {
              message.success('Payment recorded successfully')
              onSuccess()
              if (onSubmitSuccess) {
                onSubmitSuccess(paymentRecordGuid)
              }
            },
            onError: error => {
              console.error('Error recording payments', error)
              message.error('Error recording payments')
            },
          },
        )
      },
      [
        accountGuid,
        companyGuid,
        invoiceGuidToAppliedPaymentAmountUsd,
        payableInvoices,
        recordPaymentsMutation,
        onSuccess,
        onSubmitSuccess,
      ],
    )

    const totalAppliedPaymentsUsd =
      Object.values(invoiceGuidToAppliedPaymentAmountUsd).reduce(
        (acc, payment) => {
          if (isNullish(payment)) return acc
          return acc + payment
        },
        0,
      ) ?? 0

    const totalDueUsc = useMemo(() => {
      return payableInvoices.reduce(
        (acc, invoice) => acc + (invoice.dueUsc ?? 0),
        0,
      )
    }, [payableInvoices])

    const totalPaymentAmountUsc = usdToUsCents(paymentAmountUsd)

    const { valid, validationMessage } = useMemo<
      | { valid: true; validationMessage?: undefined }
      | { valid: false; validationMessage: string }
    >(() => {
      if (totalPaymentAmountUsc <= 0) {
        return {
          valid: false,
          validationMessage: 'Payment must be greater than $0',
        }
      } else if (
        usdToUsCents(totalAppliedPaymentsUsd) !== totalPaymentAmountUsc
      ) {
        return {
          valid: false,
          validationMessage: 'Applied payments must match total payment',
        }
      }

      return { valid: true }
    }, [totalPaymentAmountUsc, totalAppliedPaymentsUsd])

    useEffect(() => {
      if (!isDirty) return
      const baseChange = {
        totalPaymentAmountUsc: totalPaymentAmountUsc,
        appliedPayments: Object.entries(
          invoiceGuidToAppliedPaymentAmountUsd,
        ).map(([invoiceGuid, appliedPaymentAmountUsc]) => ({
          invoiceGuid,
          invoiceDisplayId:
            payableInvoices.find(invoice => invoice.invoiceGuid === invoiceGuid)
              ?.displayIdV2 ?? '',
          appliedPaymentAmountUsc,
        })),
      }

      if (valid) {
        onChange({ ...baseChange, valid })
      } else {
        onChange({
          ...baseChange,
          valid,
          validationMessage: validationMessage ?? '',
        })
      }
    }, [
      paymentAmountUsd,
      invoiceGuidToAppliedPaymentAmountUsd,
      payableInvoices,
      onChange,
      totalAppliedPaymentsUsd,
      totalPaymentAmountUsc,
      valid,
      validationMessage,
      isDirty,
    ])

    const isMobile = useIsMobile()

    const columns: ColumnType<PayableInvoice>[] = [
      {
        title: 'Invoice',
        dataIndex: 'displayIdV2',
        key: 'displayIdV2',
        align: 'right',
        render: (value: string, record: PayableInvoice) => {
          return (
            <Link
              target="_blank"
              to={CalculatePaths.invoiceOverview({
                invoiceGuid: record.invoiceGuid,
              })}
            >
              {value}
            </Link>
          )
        },
        sorter: (a: PayableInvoice, b: PayableInvoice) =>
          a.displayIdV2?.localeCompare(b.displayIdV2 ?? '') ?? 0,
      },
      {
        title: 'Total',
        dataIndex: 'totalUsc',
        key: 'totalUsc',
        align: 'right',
        render: (value: number) => `$${(value / 100).toFixed(2)}`,
        sorter: (a: PayableInvoice, b: PayableInvoice) =>
          a.totalUsc - b.totalUsc,
      },
      {
        title: 'Due',
        dataIndex: 'dueUsc',
        key: 'dueUsc',
        align: 'right',
        render: (value: number) => `$${(value / 100).toFixed(2)}`,
        sorter: (a: PayableInvoice, b: PayableInvoice) =>
          (a.dueUsc ?? 0) - (b.dueUsc ?? 0),
      },
    ]

    if (!isMobile) {
      columns.push({
        title: 'Days Overdue',
        dataIndex: 'numDaysOverdue',
        key: 'numDaysOverdue',
        align: 'right',
        sorter: (a: PayableInvoice, b: PayableInvoice) =>
          (a.numDaysOverdue ?? 0) - (b.numDaysOverdue ?? 0),
      })
    }

    columns.push({
      title: 'Payment',
      key: 'paymentAmount',
      align: 'right',
      render: (_: unknown, record: PayableInvoice) => (
        <Controller
          name={`invoiceGuidToAppliedPaymentAmountUsd.${record.invoiceGuid}`}
          control={control}
          render={({ field }) => (
            <UsdInputNumber
              {...field}
              min={0}
              max={record.dueUsc ? usCentsToUsd(record.dueUsc) : undefined}
              disabled={record.readonly}
              className={record.readonly ? 'bg-gray-50' : ''}
            />
          )}
        />
      ),
    })

    const clearAllAppliedPayments = () => {
      const clearedPayments = payableInvoices.reduce((acc, invoice) => {
        acc[invoice.invoiceGuid] = 0
        return acc
      }, {} as Record<InvoiceGuid, number>)
      setValue('invoiceGuidToAppliedPaymentAmountUsd', clearedPayments)
    }

    const clearAppliedPayment = useCallback(
      (invoiceGuid: InvoiceGuid) => {
        setValue('invoiceGuidToAppliedPaymentAmountUsd', {
          ...invoiceGuidToAppliedPaymentAmountUsd,
          [invoiceGuid]: 0,
        })
      },
      [invoiceGuidToAppliedPaymentAmountUsd, setValue],
    )

    const maxAll = useCallback(() => {
      const maxed = payableInvoices.reduce((acc, invoice) => {
        acc[invoice.invoiceGuid] = usCentsToUsd(invoice.dueUsc ?? 0)
        return acc
      }, {} as Record<InvoiceGuid, number>)
      setValue('invoiceGuidToAppliedPaymentAmountUsd', maxed)
    }, [payableInvoices, setValue])

    const maxInvoice = (invoiceGuid: InvoiceGuid, dueUsc: number) => {
      setValue('invoiceGuidToAppliedPaymentAmountUsd', {
        ...invoiceGuidToAppliedPaymentAmountUsd,
        [invoiceGuid]: usCentsToUsd(dueUsc),
      })
    }

    // rowSelection objects indicates the need for row selection
    const rowSelection: TableRowSelection<PayableInvoice> = {
      onChange: (selectedRowKeys, selectedRows) => {},
      onSelect: (record, selected, selectedRows) => {
        if (selected) {
          maxInvoice(record.invoiceGuid, record.dueUsc ?? 0)
        } else {
          clearAppliedPayment(record.invoiceGuid)
        }
      },
      onSelectAll: (selected, selectedRows, changeRows) => {
        if (selected) {
          maxAll()
        } else {
          clearAllAppliedPayments()
        }
      },
    }

    const setPaymentAmountUsd = useCallback(
      (paymentAmountUsd: number) => {
        setValue('paymentAmountUsd', paymentAmountUsd)
      },
      [setValue],
    )

    const payAll = useCallback(() => {
      setPaymentAmountUsd(usCentsToUsd(totalDueUsc))
      maxAll()
    }, [totalDueUsc, setPaymentAmountUsd, maxAll])

    const payAllAppliedPayments = useCallback(
      () => setPaymentAmountUsd(totalAppliedPaymentsUsd),
      [totalAppliedPaymentsUsd, setPaymentAmountUsd],
    )

    const selectedPaymentMethod = watch('paymentMethod')

    return (
      <div className="flex max-h-[600px] min-h-0 flex-col overflow-y-auto">
        <Form disabled={recordPaymentsMutation.isLoading} layout="vertical">
          <ReactHookFormItem
            name="paymentAmountUsd"
            label="Total Payment Amount"
            control={control}
            errors={errors}
            render={({ field }) => (
              <UsdInputNumber
                {...field}
                min={0}
                max={usCentsToUsd(totalDueUsc)}
                disabled={mode === 'import'}
                className={mode === 'import' ? 'bg-gray-50' : ''}
              />
            )}
          />

          <ReactHookFormItem
            name="paymentMethod"
            label="Payment Method"
            control={control}
            errors={errors}
            render={({ field }) => (
              <SelectField
                showSearch
                size="middle"
                title="Payment Method"
                {...field}
                options={OFFLINE_PAYMENT_METHODS.sort().map(method => ({
                  label: paymentMethodDisplayName(method),
                  value: method,
                }))}
                onChange={value => {
                  field.onChange(value)
                  setValue('paymentMethod', value)
                  setValue('externalPaymentId', undefined)
                  setValue('paymentMethodAdditionalInfo', undefined)
                }}
                disabled={mode === 'import'}
                className={mode === 'import' ? 'bg-gray-50' : ''}
              />
            )}
          />

          <Switch value={selectedPaymentMethod}>
            {{
              [PaymentMethod.CHECK]: () => (
                <div className="flex w-full flex-row gap-2">
                  <ReactHookFormItem
                    name="externalPaymentId"
                    label="Check Number"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <TextField
                        size="middle"
                        {...field}
                        disabled={mode === 'import'}
                        className={mode === 'import' ? 'bg-gray-50' : ''}
                      />
                    )}
                  />

                  <ReactHookFormItem
                    name="paymentMethodAdditionalInfo"
                    label="Bank Name"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <TextField size="middle" {...field} />
                    )}
                  />
                </div>
              ),
              [PaymentMethod.OTHER]: () => (
                <div className="flex flex-col gap-2">
                  <ReactHookFormItem
                    name="paymentMethodAdditionalInfo"
                    label="Payment Method Name"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <TextField size="middle" {...field} />
                    )}
                  />

                  <ReactHookFormItem
                    name="externalPaymentId"
                    label="Transaction ID"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <TextField
                        size="middle"
                        {...field}
                        disabled={mode === 'import'}
                        className={mode === 'import' ? 'bg-gray-50' : ''}
                      />
                    )}
                  />
                </div>
              ),
              default: () => null,
            }}
          </Switch>

          <ReactHookFormItem
            name="note"
            label="Payment Memo"
            control={control}
            errors={errors}
            render={({ field }) => <TextField size="middle" {...field} />}
          />

          <Table
            pagination={false}
            dataSource={payableInvoices}
            columns={columns}
            rowKey="invoiceGuid"
            rowSelection={
              isMobile || mode === 'import' ? undefined : rowSelection
            }
          />
          <div className="mt-4 flex items-center justify-between">
            <div className="flex min-w-44 flex-col">
              <div className="flex justify-between gap-2">
                <span className="font-semibold">Total Due:</span>{' '}
                <Button size="small" type="link" onClick={payAll}>
                  {formatUsc(totalDueUsc)}
                </Button>
              </div>
              <div className="flex justify-between gap-2">
                <span className="font-semibold">Total Applied:</span>{' '}
                <Button
                  size="small"
                  type="link"
                  onClick={payAllAppliedPayments}
                >
                  {formatMoney(totalAppliedPaymentsUsd)}
                </Button>
                {valid === false && isDirty ? (
                  <span className="text-red-500"> ({validationMessage})</span>
                ) : null}
              </div>
            </div>
            {mode === 'bulk' && (
              <Button type="link" onClick={clearAllAppliedPayments}>
                Clear
              </Button>
            )}
          </div>
          <ThinDivider />
          <div className="mt-4 flex justify-end">
            <Button
              type="primary"
              onClick={handleSubmit(onSubmit)}
              loading={recordPaymentsMutation.isLoading}
              disabled={valid === false}
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    )
  },
)

type BulkPaymentViewProps = {
  accountGuid: AccountGuid
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>
  onSuccess: () => void
}

const BulkPaymentView = React.memo(
  ({ accountGuid, setIsDirty, onSuccess }: BulkPaymentViewProps) => {
    const [payableInvoicesQuery] = useQuery({
      query: FETCH_PAYABLE_INVOICES_FOR_ACCOUNT_QUERY,
      variables: {
        accountGuid,
      },
    })

    if (payableInvoicesQuery.fetching) {
      return (
        <div className="flex h-full min-h-[400px] w-full items-center justify-center">
          <Banner color="gray" className="w-full">
            <div className="flex w-full items-center gap-3">
              <BannerIcon icon={faTriangleExclamation} />
              <div>
                <BannerStatusMessage
                  boldText="Loading payable invoices..."
                  regularText="This may take 1-2 minutes. Please don't close this window."
                />
              </div>
              <LoadingSpinner className="ml-auto" size={6} />
            </div>
          </Banner>
        </div>
      )
    }

    if (payableInvoicesQuery.error) {
      return (
        <div>
          <Alert
            message="There was an error fetching payable invoices. Please try again later."
            description={payableInvoicesQuery.error.message}
            type="error"
          />
        </div>
      )
    }

    if (
      payableInvoicesQuery.data?.aggregatableInvoices.length === 0 ||
      isNullish(payableInvoicesQuery.data)
    ) {
      return (
        <div>
          <Alert message="No payable invoices found." type="info" />
        </div>
      )
    }

    return (
      <BulkPaymentViewInner
        accountGuid={accountGuid}
        payableInvoices={payableInvoicesQuery.data.aggregatableInvoices}
        onChange={changeEvent => {
          if (
            changeEvent.totalPaymentAmountUsc !== 0 ||
            changeEvent.appliedPayments.some(
              p => p.appliedPaymentAmountUsc !== 0,
            )
          ) {
            setIsDirty(true)
          } else {
            setIsDirty(false)
          }
        }}
        onSuccess={onSuccess}
      />
    )
  },
)

type BulkPaymentModalProps = {
  accountGuid: AccountGuid
  onClose: () => void
  onSuccess: () => void
}

export const BulkPaymentModal = React.memo(
  ({
    accountGuid,
    onClose: externalOnCancel,
    onSuccess: externalOnSuccess,
  }: BulkPaymentModalProps) => {
    const isImportPaymentFromAccountingEnabled =
      useImportPaymentFromAccountingEnabled()
    const [isDirty, setIsDirty] = useState(false)
    const [selectedPayment, setSelectedPayment] = useState<
      AccountingPaymentImport | undefined
    >(undefined)

    const [withConfirmation, closeConfirmProps] = useCloseConfirmModal({
      isDirty,
    })
    const onCancelWithConfirm = useCallback(() => {
      withConfirmation(externalOnCancel)
    }, [externalOnCancel, withConfirmation])

    const [selectedPaymentMethodOption, setSelectedPaymentMethodOption] =
      useState<'import-from-accounting' | 'bulk' | undefined>()

    const onBack = useCallback(() => {
      if (selectedPayment) {
        setSelectedPayment(undefined)
        return
      }
      setSelectedPaymentMethodOption(undefined)
      setIsDirty(false)
    }, [selectedPayment])

    const content = useMemo(() => {
      if (isImportPaymentFromAccountingEnabled) {
        if (selectedPaymentMethodOption === 'bulk') {
          return (
            <BulkPaymentView
              accountGuid={accountGuid}
              setIsDirty={setIsDirty}
              onSuccess={externalOnSuccess}
            />
          )
        } else if (selectedPaymentMethodOption === 'import-from-accounting') {
          return (
            <ImportPaymentsFromAccountingView
              accountGuid={accountGuid}
              onSuccess={externalOnSuccess}
              selectedPayment={selectedPayment}
              setSelectedPayment={setSelectedPayment}
            />
          )
        }
        return (
          <div className="flex flex-col gap-4">
            <SelectPaymentMethodOption
              label="QBD Payment Import"
              description="Import payments from QuickBooks Desktop"
              iconElement={<QuickbooksLogoIconNonResizing />}
              onClick={() =>
                setSelectedPaymentMethodOption('import-from-accounting')
              }
            />
            <SelectPaymentMethodOption
              label="Bulk Payment"
              description="Apply one payment to multiple invoices"
              iconDefinition={faDollar}
              onClick={() => setSelectedPaymentMethodOption('bulk')}
            />
          </div>
        )
      }

      return (
        <BulkPaymentView
          accountGuid={accountGuid}
          setIsDirty={setIsDirty}
          onSuccess={externalOnSuccess}
        />
      )
    }, [
      accountGuid,
      externalOnSuccess,
      isImportPaymentFromAccountingEnabled,
      selectedPaymentMethodOption,
      selectedPayment,
      setSelectedPayment,
    ])

    const modalContent = useMemo(() => {
      const showBackButton =
        isImportPaymentFromAccountingEnabled &&
        (!isNullish(selectedPaymentMethodOption) || !isNullish(selectedPayment))

      const header =
        selectedPaymentMethodOption === 'import-from-accounting'
          ? 'Import Payment'
          : 'Collect Payment'

      return (
        <OnsiteModalContent
          onClose={onCancelWithConfirm}
          onBack={showBackButton ? onBack : undefined}
          header={header}
          headerBordered
        >
          {content}
        </OnsiteModalContent>
      )
    }, [
      content,
      onCancelWithConfirm,
      isImportPaymentFromAccountingEnabled,
      selectedPaymentMethodOption,
      selectedPayment,
      onBack,
    ])

    return (
      <>
        <OnsiteModal open size="xlarge-width" onClose={onCancelWithConfirm}>
          {modalContent}
        </OnsiteModal>
        <CloseConfirmModal {...closeConfirmProps} />
      </>
    )
  },
)

type SelectPaymentMethodOptionProps = {
  label: string
  description: string
  onClick: () => void
  containerClassName?: string
} & (
  | { iconDefinition: IconDefinition; iconElement?: undefined }
  | { iconElement: React.ReactNode; iconDefinition?: undefined }
)
const SelectPaymentMethodOption = React.memo<SelectPaymentMethodOptionProps>(
  ({
    label,
    description,
    iconDefinition,
    iconElement,
    onClick,
    containerClassName,
  }) => {
    const isMobile = useIsMobile()
    return (
      <div
        onClick={onClick}
        className={classNames(
          'flex cursor-pointer items-center py-4 transition-colors duration-200 hover:bg-gray-200',
          isMobile ? 'mx-[-16px] px-4' : 'mx-[-24px] px-6',
          containerClassName,
        )}
      >
        <div className="flex h-12 w-12 items-center justify-center">
          {iconElement ?? (
            <FaIconWithCircularBackground
              iconDefinition={iconDefinition!}
              backgroundColor="#F5F5F5"
              color="#434343"
              diameterPx={44}
            />
          )}
        </div>
        <div className="ml-4">
          <div className="text-base font-semibold text-bz-gray-1000">
            {label}
          </div>
          <div className="text-sm font-normal text-bz-gray-800">
            {description}
          </div>
        </div>
      </div>
    )
  },
)

type ImportPaymentsFromAccountingViewProps = {
  accountGuid: AccountGuid
  onSuccess: () => void
  selectedPayment: AccountingPaymentImport | undefined
  setSelectedPayment: (payment: AccountingPaymentImport | undefined) => void
}

const ImportPaymentsFromAccountingView =
  React.memo<ImportPaymentsFromAccountingViewProps>(
    ({ accountGuid, onSuccess, selectedPayment, setSelectedPayment }) => {
      const paymentsQuery = trpc.payments[
        'payments:read-payments-from-accounting'
      ].useQuery(
        {
          accountGuid,
        },
        {
          refetchOnWindowFocus: false,
          trpc: {
            context: {
              skipBatch: true,
            },
          },
        },
      )

      if (paymentsQuery.isFetching) {
        return (
          <div className="flex h-full min-h-[400px] w-full items-center justify-center p-4">
            <Banner color="orange" className="w-full">
              <div className="flex w-full items-center gap-4">
                <BannerIcon icon={faTriangleExclamation} />
                <div className="flex flex-1 flex-col">
                  <span className="text-base font-semibold text-bz-gray-900">
                    Loading payments from QuickBooks Desktop...
                  </span>
                  <span className="text-sm text-bz-gray-700">
                    This may take 1-2 minutes. Please don't close this window.
                  </span>
                </div>
              </div>
            </Banner>
          </div>
        )
      }

      if (paymentsQuery.error) {
        return (
          <div className="flex h-full min-h-[400px] w-full items-center justify-center">
            <Banner className="w-full" color="gray">
              <div className="w-full text-sm">
                <p className="font-semibold">
                  Error connecting to QuickBooks Desktop
                </p>
                <p className="mt-1">
                  There was an issue retrieving payments from QuickBooks
                  Desktop. Please ensure QuickBooks Desktop is running and
                  properly connected.
                </p>
                <details className="mt-2 text-xs">
                  <summary>Technical details</summary>
                  <p className="mt-1">{paymentsQuery.error.message}</p>
                </details>
                <p className="mt-4">
                  <Button
                    onClick={() => paymentsQuery.refetch()}
                    type="primary"
                    size="small"
                  >
                    Try Again
                  </Button>
                </p>
              </div>
            </Banner>
          </div>
        )
      }

      if (paymentsQuery.data?.length === 0) {
        return (
          <div className="flex h-full min-h-[400px] w-full items-center justify-center">
            <Banner className="w-full" color="gray">
              <div className="w-full text-sm text-bz-text-tertiary">
                No payments found to import.
              </div>
            </Banner>
          </div>
        )
      }

      if (selectedPayment) {
        return (
          <ImportAccountingPaymentView
            accountGuid={accountGuid}
            payment={selectedPayment}
            onSuccess={() => {
              setSelectedPayment(undefined)
              paymentsQuery.refetch()
              onSuccess()
            }}
          />
        )
      }

      return (
        <div className="w-full overflow-hidden">
          <Table
            dataSource={paymentsQuery.data}
            rowKey={record =>
              record.paymentReferenceNumber + record.paymentDate
            }
            scroll={{ x: 'max-content', y: 400 }}
            showSorterTooltip={false}
            sortDirections={['ascend']}
            columns={[
              {
                title: 'Ref #',
                dataIndex: 'paymentReferenceNumber',
                key: 'paymentReferenceNumber',
                render: text => text || 'N/A',
              },
              {
                title: 'Amount',
                key: 'amount',
                render: (_, record) => {
                  const totalAmount = record.appliedPayments.reduce(
                    (sum, payment) => sum + payment.amountUsd,
                    0,
                  )
                  return formatMoney(totalAmount)
                },
              },
              {
                title: 'Date',
                dataIndex: 'paymentDate',
                key: 'paymentDate',
                render: date =>
                  BzDateFns.format(
                    BzDateFns.parseLocalDate(date),
                    'MM/dd/yyyy',
                  ) || 'N/A',
              },
              {
                title: 'Method',
                dataIndex: 'paymentMethod',
                key: 'paymentMethod',
                render: method => paymentMethodDisplayName(method) || 'N/A',
              },
              {
                title: 'Breezy Payment',
                key: 'breezyPayment',
                render: (_, record) => {
                  const linkedPayments = record.appliedPayments.filter(
                    p => p.breezyPaymentRecordGuid,
                  )

                  if (linkedPayments.length === 0) {
                    return 'Not linked'
                  }

                  const firstPayment = linkedPayments[0]

                  return (
                    <div className="flex flex-col gap-1">
                      <Link
                        key={firstPayment.breezyPaymentRecordGuid}
                        to={`/payments/${firstPayment.breezyPaymentRecordGuid}`}
                        target="_blank"
                      >
                        View Payment
                      </Link>
                    </div>
                  )
                },
              },
              {
                title: 'Breezy Invoice',
                key: 'breezyInvoice',
                render: (_, record) => {
                  const linkedInvoices = record.appliedPayments.filter(
                    p => p.breezyInvoiceGuid,
                  )

                  if (linkedInvoices.length === 0) {
                    return 'Not linked'
                  }

                  return (
                    <div className="flex flex-col gap-1">
                      {linkedInvoices.map(payment => (
                        <Link
                          key={payment.breezyInvoiceGuid}
                          to={`/invoices/${payment.breezyInvoiceGuid}`}
                          target="_blank"
                        >
                          View Invoice
                        </Link>
                      ))}
                    </div>
                  )
                },
              },
              {
                title: 'Action',
                key: 'action',
                sorter: (a, b) => {
                  const aCanImport = a.appliedPayments.some(
                    p => !p.breezyPaymentRecordGuid,
                  )
                  const bCanImport = b.appliedPayments.some(
                    p => !p.breezyPaymentRecordGuid,
                  )
                  return (bCanImport ? 1 : 0) - (aCanImport ? 1 : 0)
                },
                sortOrder: 'ascend',
                render: (_, record) => {
                  const canImport = record.appliedPayments.some(
                    p => !p.breezyPaymentRecordGuid,
                  )

                  if (!canImport) {
                    return <span>Imported</span>
                  }

                  return (
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => setSelectedPayment(record)}
                    >
                      Import
                    </Button>
                  )
                },
              },
            ]}
          />
        </div>
      )
    },
  )
