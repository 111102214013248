import { faChartSimple } from '@fortawesome/pro-regular-svg-icons'
import { createExportReport, ReportConfig } from './ReportTypes'

// This will eventually come from the database, but for now we define it here
export const REPORTS: ReportConfig[] = [
  createExportReport({
    id: 'fdd91840-0ff6-11f0-b940-e7fb64557ef6',
    category: 'accounts',
    title: 'Account Export',
    description:
      'A detailed account listing showing key account details including display name, type, creation date, status flags, invoice terms and complete mailing address information. Use this report to export accounts, review account configurations, and verify address details.',
    icon: faChartSimple,
    questionId: 'ayH_X-rtL3_ymOg8zi4IV',
  }),
  createExportReport({
    id: '33ec59d0-0fff-11f0-b940-e7fb64557ef6',
    category: 'accounts',
    title: 'Account Contacts Export',
    description:
      'A comprehensive contact export showing account contacts with their names, titles, email addresses, phone numbers and notification preferences. Use this report to review contact details, verify communication preferences, and manage contact relationships across accounts.',
    icon: faChartSimple,
    questionId: 'o5fH3xCtsf2gPxrJixWcC',
  }),
  createExportReport({
    id: 'f3c58290-0fff-11f0-b940-e7fb64557ef6',
    category: 'accounts',
    title: 'Account Locations Export',
    description:
      'A comprehensive location export showing property details including square footage, build dates, addresses, and associated accounts. The report includes property types, municipalities, creation dates, full address information, and account relationships. Use this report to analyze your location portfolio, verify property details, and manage location-account associations.',
    icon: faChartSimple,
    questionId: '7NNEeIpKdyvv5kbU9F4-8',
  }),
]

// Helper to get reports by category
export const getReportsByCategory = (category: string): ReportConfig[] => {
  return REPORTS.filter(report => report.category === category)
}
