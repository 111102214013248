import { Guid, invoiceV2ToInvoiceV1Status, usCentsToUsd } from '@breezy/shared'
import { useQuery } from 'urql'
import { DocumentType, gql } from '../../../generated'
import { JobOutcomeInvoicesDocument } from '../../../generated/user/graphql'
import { JobOutcomeInvoice } from '../job-outcome-modal-types'

export type JobOutcomeInvoiceV2 = DocumentType<
  typeof JobOutcomeInvoicesDocument
>['invoices'][number]

export const convertJobOutcomeInvoiceV2ToJobOutcomeInvoice = (
  invoice: JobOutcomeInvoiceV2,
): JobOutcomeInvoice => {
  return {
    invoiceGuid: invoice.invoiceGuid,
    displayId: invoice.displayIdV2 ?? invoice.displayId.toString(),
    issuedAt: invoice.issuedAt ?? invoice.createdAt,
    subtotalPriceUsd: usCentsToUsd(invoice.subtotalUsc),
    totalPriceUsd: usCentsToUsd(invoice.totalUsc),
    status: invoiceV2ToInvoiceV1Status(invoice.status),
    items: invoice.invoiceCartItems.map(item => ({
      // NOTE: we want to map the invoice cart item to the 'CartItem' type.
      // The revenue attribution map uses this itemGuid to map the invoice to the pricebook item
      itemGuid:
        item.cartItem.originalPricebookItemGuid ?? item.cartItem.cartItemGuid,
      itemType: item.cartItem.itemType,
      description: item.cartItem.description ?? '',
      isDiscountable: item.cartItem.isDiscountable,
      isTaxable: item.cartItem.isTaxable,
      name: item.cartItem.name,
      unitPriceUsd: usCentsToUsd(item.cartItem.unitPriceUsc),
      quantity: item.cartItem.quantity,
    })),
  }
}

const JOB_OUTCOME_INVOICES_QUERY = gql(/* GraphQL */ `
  query JobOutcomeInvoices($accountGuid: uuid!, $jobGuid: uuid!) {
    invoices(
      where: {
        accountGuid: { _eq: $accountGuid }
        jobLink: { jobGuid: { _eq: $jobGuid } }
        status: { _eq: PAID }
      }
    ) {
      invoiceGuid
      displayId
      displayIdV2
      subtotalUsc
      totalUsc
      status
      issuedAt
      createdAt
      invoiceCartItems(orderBy: { seq: ASC }) {
        seq
        cartItem {
          itemType: cartItemType
          description
          isDiscountable
          isTaxable
          name
          unitPriceUsc
          quantity
          cartItemGuid
          originalPricebookItemGuid
          cartItemCode
        }
      }
    }
  }
`)

/**
 * Fetches all of the Fully Paid Invoices for a job
 *
 * @param accountGuid
 * @param jobGuid
 * @returns
 */
export const useFetchJobOutcomeInvoices = (
  accountGuid: Guid,
  jobGuid: Guid,
) => {
  const fetchJobOutcomeInvoicesQuery = useQuery({
    query: JOB_OUTCOME_INVOICES_QUERY,
    variables: { accountGuid, jobGuid },
  })
  const [{ data, fetching }] = fetchJobOutcomeInvoicesQuery

  return {
    fetchJobOutcomeInvoicesQuery,
    invoices:
      data?.invoices.map(convertJobOutcomeInvoiceV2ToJobOutcomeInvoice) ?? [],
    loading: fetching,
  }
}
