import { BreezyErrorCategory } from './BreezyErrorCategory'
import { BreezyErrorSeverity } from './BreezyErrorSeverity'

/**
 * A business exception should be handled. It is not an error. It should be recoverable. Exceptions that happen
 * as part of our domain are business exceptions. Generally users will experience this as a 4xx.
 */
export abstract class BusinessException extends Error {
  readonly category = 'business-exception' as BreezyErrorCategory
  readonly severity: BreezyErrorSeverity
  readonly errorMessage: string

  constructor(message: string, options?: ErrorOptions, severity: BreezyErrorSeverity = 'ERROR') {
    super(message, options)
    this.severity = severity
    this.errorMessage = message
  }
}

export class AuthorizationException extends BusinessException {
  constructor(message: string, options?: ErrorOptions, severity: BreezyErrorSeverity = 'WARN') {
    super(message, options, severity)
  }
}

export class BusinessResourceConflictException extends BusinessException {
  constructor(message: string, options?: ErrorOptions, severity: BreezyErrorSeverity = 'WARN') {
    super(message, options, severity)
  }
}

abstract class BusinessValidationException extends BusinessException {}

export class BusinessResourceDoesNotExistException extends BusinessValidationException {
  constructor(message: string, options?: ErrorOptions, severity: BreezyErrorSeverity = 'ERROR') {
    super(message, options, severity)
  }
}

export class BadInputException extends BusinessValidationException {
  constructor(message: string, options?: ErrorOptions, severity: BreezyErrorSeverity = 'WARN') {
    super(message, options, severity)
  }
}

export class MoreThanOneResourceFoundException extends BusinessValidationException {}
export class InvalidExternalInputException extends BusinessValidationException {}
export class ArgumentException extends BusinessValidationException {}
export class InvalidEntityStateException extends BusinessValidationException {}

export class NotFoundException extends BusinessResourceDoesNotExistException {
  public exType = 'NotFoundException'
  constructor(message: string, options?: ErrorOptions, severity: BreezyErrorSeverity = 'ERROR') {
    super(message, options, severity)
  }
}

export class UnsupportedAccountingIntegrationException extends BusinessException {
  constructor(message: string, options?: ErrorOptions, severity: BreezyErrorSeverity = 'ERROR') {
    super(message, options, severity)
  }
}
