import { Input } from 'antd'
import React from 'react'

import { useStringFieldInfo, useTsController } from '@ts-react/form'
import BaseTextField from '../BaseTextField/BaseTextField'

const TextField = React.memo(
  ({ disabled: disabledOverride }: { disabled?: boolean }) => {
    const {
      field: { onChange, value, name, disabled },
    } = useTsController<string>()

    const { placeholder } = useStringFieldInfo()

    return (
      <BaseTextField>
        <Input
          name={name}
          size="large"
          placeholder={placeholder}
          onChange={e => onChange(e.target.value)}
          value={value}
          disabled={disabled || disabledOverride}
        />
      </BaseTextField>
    )
  },
)

export default TextField
