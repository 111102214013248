import { BzDateFns, IsoDateString } from '@breezy/shared'
import { Button, notification } from 'antd'
import { useCallback, useState } from 'react'
import { useInterval } from 'react-use'
import { getConfig } from '../config'
import { useFeatureFlag } from '../hooks/useFeatureFlags'

const compareSemanticVersions = (
  current: string,
  latest: string,
): 'ahead' | 'behind-patch' | 'behind-minor' | 'behind-major' | 'same' => {
  const [cMajor, cMinor, cPatch] = current.split('.').map(Number)
  const [lMajor, lMinor, lPatch] = latest.split('.').map(Number)

  if (current === latest) {
    return 'same'
  }

  if (cMajor < lMajor) {
    return 'behind-major'
  }

  if (cMinor < lMinor) {
    return 'behind-minor'
  }

  if (cPatch < lPatch) {
    return 'behind-patch'
  }

  return 'ahead'
}

export const clearCacheAndHardReloadOnAllBrowsers = async () => {
  console.info('Clearing cache and hard reloading on all browsers')
  if ('caches' in window) {
    console.info('Clearing cache')
    const cacheKeys = await caches.keys()
    console.info('Cache keys', cacheKeys)
    for (const name of cacheKeys) {
      console.info('Clearing cache', name)
      await caches.delete(name)
      console.info('Cleared cache', name)
    }
  }

  console.info('Hard reloading on all browser')
  // eslint-disable-next-line no-self-assign
  window.location.href = window.location.href
  console.info('Hard reloaded on all browsers')

  console.info('Reloading on all browser')
  // @ts-expect-error - Parameter is deprecated, but works on some browsers still
  window.location.reload(true)
  console.info('Reloaded on all browser')
}

export const openNotification = () =>
  notification.open({
    message: 'The version of the app you are using is out of date',
    description:
      'Please click the refresh button below to get the latest version',
    duration: 0,
    btn: (
      <Button
        type="primary"
        size="middle"
        onClick={async () => {
          console.info('Clicking refresh button')
          await clearCacheAndHardReloadOnAllBrowsers()
          console.info('Refreshed')
        }}
      >
        Refresh
      </Button>
    ),
    closeIcon: <>X</>,
  })

const apiUrl = getConfig().apiUrl
const shouldCheckPlatformVersion = getConfig().shouldCheckPlatformVersion
const myAppVersion = getConfig().appVersion
const interval = 1000 * 10 // Every minute

export const PlatformVersionWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [latestAppVersion, setLatestAppVersion] = useState(myAppVersion)

  const platformVersionMismatchNotificationEnabled = useFeatureFlag(
    'platformVersionMismatchNotification',
  )

  const checkVersion = useCallback(() => {
    console.info('Checking version')
    if (!shouldCheckPlatformVersion) {
      console.info('Not checking version because it is disabled')
      return
    }

    if (!platformVersionMismatchNotificationEnabled) {
      console.info('Not checking version because notification is disabled')
      return
    }

    const lastPlatformOutOfDateIntervention = window.sessionStorage.getItem(
      'LAST_PLATFORM_OUT_OF_DATE_INTERVENTION',
    )

    if (lastPlatformOutOfDateIntervention) {
      const now = new Date().toISOString()

      const diffMinutes = BzDateFns.differenceInMinutes(
        BzDateFns.parseISO(now, BzDateFns.UTC),
        BzDateFns.parseISO(
          lastPlatformOutOfDateIntervention as IsoDateString,
          BzDateFns.UTC,
        ),
      )

      if (diffMinutes < 10) {
        console.info(
          'Not checking version because a platform version intervention recently occurred',
        )
        return
      }
    }

    fetch(`${apiUrl}/platform-version`)
      .then(res => res.json())
      .then(async ({ appVersion }) => {
        if (appVersion === latestAppVersion) {
          console.info('Already fetched latest version. Skipping')
          return
        }

        setLatestAppVersion(appVersion)

        const versionComparison = compareSemanticVersions(
          myAppVersion,
          appVersion,
        )

        switch (versionComparison) {
          case 'ahead':
            console.info('App is ahead of the latest version')
            break
          case 'behind-patch':
            console.info('App is behind the latest version by a patch version')
            break
          case 'behind-minor':
            console.info('App is behind the latest version by a minor version')
            window.sessionStorage.setItem(
              'LAST_PLATFORM_OUT_OF_DATE_INTERVENTION',
              new Date().toISOString(),
            )
            openNotification()
            break
          case 'behind-major':
            window.sessionStorage.setItem(
              'LAST_PLATFORM_OUT_OF_DATE_INTERVENTION',
              new Date().toISOString(),
            )
            console.info('Refreshing because breaking version change')
            console.info('App is behind the latest version by a major version')
            await clearCacheAndHardReloadOnAllBrowsers()
            console.info('Refreshed because breaking version change')
            break
          case 'same':
            console.info('App is at the latest version')
            break
        }
      })
      .catch(e => {
        console.error(
          `There was an error fetching the latest platform version`,
          e,
        )
      })
  }, [
    latestAppVersion,
    setLatestAppVersion,
    platformVersionMismatchNotificationEnabled,
  ])

  useInterval(checkVersion, interval)
  return <>{children}</>
}
