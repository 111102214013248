import { nextGuid, R } from '@breezy/shared'
import { useCallback, useState } from 'react'
import { useMutation } from 'urql'
import { gql } from '../../../generated'
import { useExpectedCompanyGuid } from '../../../providers/PrincipalUser'
import { useMessage } from '../../../utils/antd-utils'
import { useStrictContext } from '../../../utils/react-utils'
import {
  EstimatesContext,
  FetchedEstimate,
  getDuplicatedOption,
  Option,
  useOptionsFromEstimateQuery,
} from '../estimatesFlowUtils'

const SAVE_ESTIMATE_TEMPLATE_QUERY = gql(/* GraphQL */ `
  mutation SaveEstimateTemplate($input: EstimateTemplatesInsertInput!) {
    insertEstimateTemplatesOne(object: $input) {
      __typename
    }
  }
`)

export const useSaveEstimateTemplate = (estimate: FetchedEstimate) => {
  const companyGuid = useExpectedCompanyGuid()
  const [isUpserting, setIsUpserting] = useState(false)
  const message = useMessage()

  const { realPricebookItemGuidMap } = useStrictContext(EstimatesContext)
  const [, saveEstimateTemplate] = useMutation(SAVE_ESTIMATE_TEMPLATE_QUERY)
  const options = useOptionsFromEstimateQuery(estimate.estimateOptions)

  const saveTemplate = useCallback(
    async (templateName: string) => {
      try {
        const upsertRes = await saveEstimateTemplate({
          input: {
            estimateTemplateGuid: nextGuid(),
            companyGuid,
            templateName,
            messagePreset: 'CUSTOM',
            customMessageHtml: estimate.messageHtml,
            estimateOptions: {
              data: R.sortBy(R.prop('seq'), options).map(
                (existingOption: Option, i) => {
                  const {
                    descriptionHtml,
                    discounts,
                    displayName,
                    optionGuid,
                    lineItems,
                    selected,
                    totalUsc,
                    featuredPhotoGuid,
                  } = getDuplicatedOption(existingOption)
                  return {
                    companyGuid,
                    descriptionHtml,
                    displayName,
                    totalUsc,
                    estimateOptionGuid: optionGuid,
                    seq: i,
                    isRecommended: existingOption.recommended,
                    isSelected: !!selected,
                    featuredPhotoGuid,
                    estimateOptionDiscounts: {
                      onConflict: {
                        constraint: 'estimate_option_discounts_pkey',
                        updateColumns: [
                          'discountAmountUsc',
                          'discountRate',
                          'discountType',
                          'descriptionHtml',
                          'name',
                          'seq',
                        ],
                      },
                      data: discounts.map(
                        (
                          {
                            discountGuid,
                            type,
                            discountAmountUsc,
                            discountRate,
                            descriptionHtml,
                            name,
                          },
                          i,
                        ) => ({
                          companyGuid,
                          estimateOptionDiscountGuid: discountGuid,
                          discountType: type,
                          seq: i,
                          discountAmountUsc,
                          discountRate,
                          descriptionHtml,
                          name,
                        }),
                      ),
                    },
                    cartItems: {
                      onConflict: {
                        constraint: 'estimate_option_cart_items_pkey',
                        updateColumns: ['seq'],
                      },
                      data: lineItems.map(
                        ({
                          itemGuid,
                          itemType,
                          savedToPricebook,
                          seq,
                          photoCdnUrl,
                          ...rest
                        }) => ({
                          cartItem: {
                            onConflict: {
                              constraint: 'cart_items_pkey',
                              updateColumns: [
                                'name',
                                'description',
                                'quantity',
                                'unitPriceUsc',
                                'isTaxable',
                                'isDiscountable',
                                'cartItemType',
                                'originalPricebookItemGuid',
                                'photoGuid',
                              ],
                            },
                            data: {
                              ...rest,
                              companyGuid,
                              // If it's an ad-hoc item it gets a random guid, so we need to check if it's real
                              originalPricebookItemGuid:
                                realPricebookItemGuidMap[itemGuid]
                                  ? itemGuid
                                  : undefined,
                              cartItemType: itemType,
                            },
                          },
                          seq,
                        }),
                      ),
                    },
                  }
                },
              ),
            },
          },
        })

        if (upsertRes.error) {
          throw upsertRes.error
        }

        message.success(`Template ${templateName} created`)
      } catch (e) {
        console.error(e)
      } finally {
        setIsUpserting(false)
      }
    },
    [
      companyGuid,
      estimate.messageHtml,
      message,
      options,
      realPricebookItemGuidMap,
      saveEstimateTemplate,
    ],
  )

  return [saveTemplate, isUpserting] as const
}
