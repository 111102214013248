import { CalculatePaths, isNullish, R } from '@breezy/shared'
import { Button } from 'antd'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  OnsitePageCollapsibleMode,
  OnsitePageCollapsibleSection,
} from 'src/adam-components/OnsitePage/OnsitePageCollapsibleSection'
import {
  EstimateCard,
  type EstimateCardEstimate,
} from 'src/components/Cards/EstimateCard'
import GqlQueryLoader from 'src/components/GqlQueryLoader/GqlQueryLoader'
import { LoadingSpinner } from 'src/components/LoadingSpinner'
import { EstimatesBoolExp } from 'src/generated/user/graphql'
import { useFeatureFlag } from 'src/hooks/useFeatureFlags'
import { useExpectedCompanyTimeZoneId } from 'src/providers/PrincipalUser'
import { useSubscription } from 'urql'
import { useEstimateTemplatesMetadata } from '../../../hooks/useEstimateTemplates'
import { useModalState } from '../../../utils/react-utils'
import {
  ESTIMATES_FOR_COLLAPSIBLE_SUBSCRIPTION,
  LINKED_ESTIMATES_FOR_COLLAPSIBLE_SUBSCRIPTION,
} from './EstimateCollapsible.gql'
import { EstimateTemplatePicker } from './EstimateTemplatePicker'

export interface EstimatesCollapsibleV2Props {
  links: {
    accountGuid: string
    jobGuid?: string
    jobAppointmentGuid?: string
    locationGuid?: string
  }

  allowExternalCreate?: boolean
  collapsibleStateId: string
}

export const EstimatesCollapsibleV2 = (props: EstimatesCollapsibleV2Props) => {
  const navigate = useNavigate()
  const timezoneId = useExpectedCompanyTimeZoneId()
  const linkedJobsEnabled = useFeatureFlag('linkedJobs')

  const [createEstimateOpen, openCreateEstimate, closeCreateEstimate] =
    useModalState()

  const [templates, isFetchingTemplates] = useEstimateTemplatesMetadata()

  // In V2 we require a job guid so we can't have a + unless we have the job guid.
  const allowCreate =
    !isNullish(props.links.jobGuid) && (props.allowExternalCreate ?? true)

  const isEstimateTemplatesEnabled = useFeatureFlag('estimate-templates')

  const onCreateEstimate = useCallback(() => {
    if (isEstimateTemplatesEnabled) {
      openCreateEstimate()
    } else {
      props.links.jobGuid &&
        navigate(
          CalculatePaths.newEstimate({
            jobGuid: props.links.jobGuid,
            jobAppointmentGuid: props.links.jobAppointmentGuid,
          }),
        )
    }
  }, [
    navigate,
    props.links.jobAppointmentGuid,
    props.links.jobGuid,
    isEstimateTemplatesEnabled,
    openCreateEstimate,
  ])

  const whereExpression = useMemo<EstimatesBoolExp>(() => {
    let where: EstimatesBoolExp = {
      job: {
        accountGuid: {
          _eq: props.links.accountGuid,
        },
      },
    }

    if (props.links.jobGuid) {
      where = R.mergeDeepRight(where, {
        jobGuid: {
          _eq: props.links.jobGuid,
        },
      })
    }
    if (props.links.locationGuid) {
      where = R.mergeDeepRight(where, {
        job: {
          locationGuid: {
            _eq: props.links.locationGuid,
          },
        },
      })
    }

    return where
  }, [props.links.accountGuid, props.links.jobGuid, props.links.locationGuid])

  const estimatesQuery = useSubscription({
    query: ESTIMATES_FOR_COLLAPSIBLE_SUBSCRIPTION,
    variables: {
      where: whereExpression,
    },
  })

  const linkedEstimatesQuery = useSubscription({
    query: LINKED_ESTIMATES_FOR_COLLAPSIBLE_SUBSCRIPTION,
    variables: { jobGuid: props.links.jobGuid ?? '' },
    pause: isNullish(props.links.jobGuid) || !linkedJobsEnabled,
  })

  const estimates = useMemo<EstimateCardEstimate[]>(() => {
    if (!estimatesQuery[0].data) {
      return []
    }

    return estimatesQuery[0].data.estimates.map(estimate => ({
      estimateGuid: estimate.estimateGuid,
      displayId: estimate.displayId.toString(),
      issuedAt: estimate.createdAt,
      options: estimate.estimateOptions.map(option => ({
        estimateOptionGuid: option.estimateOptionGuid,
        isSelected: option.isSelected,
        sequence: option.seq,
        totalUsc: option.totalUsc,
        displayName: option.displayName,
      })) satisfies EstimateCardEstimate['options'],
      status: estimate.status,
      createdBy: estimate.createdByUser
        ? { name: estimate.createdByUser.fullName }
        : undefined,
      job: {
        jobGuid: estimate.job.jobGuid,
        displayId: estimate.job.displayId.toString(),
        jobTypeName: estimate.job.jobType.name,
      },
    }))
  }, [estimatesQuery])

  const linkedEstimates = useMemo<EstimateCardEstimate[]>(() => {
    if (!linkedEstimatesQuery[0].data?.jobsByPk) {
      return []
    }

    return linkedEstimatesQuery[0].data.jobsByPk.linkedJobEstimates.map(
      ({ estimate }) => ({
        estimateGuid: estimate.estimateGuid,
        displayId: estimate.displayId.toString(),
        issuedAt: estimate.createdAt,
        options: estimate.estimateOptions.map(option => ({
          estimateOptionGuid: option.estimateOptionGuid,
          isSelected: option.isSelected,
          sequence: option.seq,
          totalUsc: option.totalUsc,
          displayName: option.displayName,
        })) satisfies EstimateCardEstimate['options'],
        status: estimate.status,
        createdBy: estimate.createdByUser
          ? { name: estimate.createdByUser.fullName }
          : undefined,
        linkedFromJob: {
          jobGuid: estimate.job.jobGuid,
          displayId: estimate.job.displayId.toString(),
          jobTypeName: estimate.job.jobType.name,
        },
      }),
    )
  }, [linkedEstimatesQuery])

  return (
    <>
      <GqlQueryLoader
        query={estimatesQuery}
        render={() =>
          isFetchingTemplates ? (
            <LoadingSpinner />
          ) : (
            <OnsitePageCollapsibleSection
              smallTitle
              title="Estimates"
              count={estimates.length + linkedEstimates.length}
              defaultCollapsed={estimates.length + linkedEstimates.length === 0}
              onAdd={allowCreate ? onCreateEstimate : undefined}
              collapsibleStateId={props.collapsibleStateId}
            >
              <div className="flex flex-col gap-3">
                {estimates.map(estimate => (
                  <EstimateCard
                    key={estimate.estimateGuid}
                    estimate={estimate}
                    timezoneId={timezoneId}
                  />
                ))}

                <GqlQueryLoader
                  query={linkedEstimatesQuery}
                  render={() => (
                    <>
                      {linkedEstimates.map(estimate => (
                        <EstimateCard
                          key={estimate.estimateGuid}
                          estimate={estimate}
                          timezoneId={timezoneId}
                        />
                      ))}
                    </>
                  )}
                  loadingComponent={<LoadingSpinner />}
                  errorComponent={<span>Failed to load linked estimates</span>}
                />
              </div>
            </OnsitePageCollapsibleSection>
          )
        }
        loadingComponent={
          <OnsitePageCollapsibleSection
            title="Estimates"
            smallTitle
            collapsibleStateId={props.collapsibleStateId}
          >
            <LoadingSpinner />
          </OnsitePageCollapsibleSection>
        }
        errorComponent={
          <OnsitePageCollapsibleSection
            title="Estimates"
            smallTitle
            collapsibleStateId={props.collapsibleStateId}
          >
            <span>Failed to load estimates</span>
          </OnsitePageCollapsibleSection>
        }
      />
      {props.links.jobGuid && createEstimateOpen && (
        <EstimateTemplatePicker
          onCancel={closeCreateEstimate}
          jobGuid={props.links.jobGuid}
          templates={templates}
          jobAppointmentGuid={props.links.jobAppointmentGuid}
        />
      )}
    </>
  )
}

export type SimpleEstimateCollapsibleProps = {
  estimates: EstimateCardEstimate[]
  total?: number
  mode?: OnsitePageCollapsibleMode
  allowCreate?: boolean
  onCreateEstimate?: () => void
  children?: React.ReactNode
  collapsibleStateId: string
} & (
  | {
      mode?: Exclude<OnsitePageCollapsibleMode, 'paginated'>
      total?: never
      onViewMore?: never
    }
  | {
      mode: 'paginated'
      total: number
      onViewMore: () => void
    }
)

export const SimpleEstimateCollapsibleV2 = ({
  mode = 'default',
  estimates,
  allowCreate = false,
  onCreateEstimate,
  total,
  onViewMore,
  children,
  collapsibleStateId,
}: SimpleEstimateCollapsibleProps) => {
  const timezoneId = useExpectedCompanyTimeZoneId()
  return (
    <OnsitePageCollapsibleSection
      smallTitle
      title="Estimates"
      count={total ?? estimates.length}
      defaultCollapsed={estimates.length === 0}
      onAdd={allowCreate ? onCreateEstimate : undefined}
      testId="estimates-collapsible"
      addButtonTestId="estimates-collapsible-add-button"
      collapsibleStateId={collapsibleStateId}
    >
      <div className="flex flex-col gap-3">
        {estimates.map(estimate => (
          <EstimateCard
            key={estimate.estimateGuid}
            estimate={estimate}
            timezoneId={timezoneId}
          />
        ))}
        {children}
        {mode === 'paginated' &&
          onViewMore &&
          total &&
          total > estimates.length && (
            <Button className="w-fit" type="link" onClick={onViewMore}>
              View {total - estimates.length} more estimates
            </Button>
          )}
      </div>
    </OnsitePageCollapsibleSection>
  )
}
