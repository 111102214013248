import {
  MetabaseProvider,
  defineMetabaseAuthConfig,
  useMetabaseAuthStatus,
} from '@metabase/embedding-sdk-react'
import { LoginStatus } from '@metabase/embedding-sdk-react/dist/enterprise/frontend/src/embedding-sdk/store/types'
import React, { createContext, useContext, useMemo } from 'react'
import { getConfig } from '../config'
import { useFeatureFlag } from '../hooks/useFeatureFlags'
import { useCompanyGuid } from './PrincipalUser'

const { metabaseInstanceUrl, metabaseJwtSSOUrl } = getConfig()

type MetabaseContextType = {
  metabaseError?: string
  status: LoginStatus['status']
}

const MetabaseContext = createContext<MetabaseContextType>({
  metabaseError: undefined,
  status: 'uninitialized',
})

export const useIsEmbeddedReportingEnabled = () => {
  const embeddedReportingEnabled = useFeatureFlag('embedded-reporting')
  return embeddedReportingEnabled
}

export const useMetabase = () => {
  return useContext(MetabaseContext)
}

export const MetabaseWrapper = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const isEmbeddedReportingEnabled = useIsEmbeddedReportingEnabled()
    const companyGuid = useCompanyGuid()
    const authProviderUri = useMemo(() => {
      return `${metabaseJwtSSOUrl}?companyGuid=${companyGuid}`
    }, [companyGuid])

    const metabaseConfig = defineMetabaseAuthConfig({
      metabaseInstanceUrl,
      authProviderUri,
    })

    if (!isEmbeddedReportingEnabled) {
      return <>{children}</>
    }

    return (
      <MetabaseProvider authConfig={metabaseConfig}>
        <MetabaseContextConsumer>{children}</MetabaseContextConsumer>
      </MetabaseProvider>
    )
  },
)

// This component monitors the Metabase auth status and provides it via context
const MetabaseContextConsumer = React.memo<React.PropsWithChildren>(
  ({ children }) => {
    const authStatus = useMetabaseAuthStatus()

    // eslint-disable-next-line no-console
    console.log('metabase authStatus', authStatus)

    return (
      <MetabaseContext.Provider
        value={{
          metabaseError:
            authStatus.status === 'error'
              ? authStatus.error.message
              : undefined,
          status: authStatus.status,
        }}
      >
        {children}
      </MetabaseContext.Provider>
    )
  },
)
