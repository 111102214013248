import { OfficeRoutes } from '@breezy/shared/src/contracts/WebAppRoutes'
import { ReportsDashboard } from '../pages/ReportsLayout/ReportsDashboard'
import { ReportView } from '../pages/ReportsLayout/ReportView'
import { ReactRouteMap } from './ReactRoute'

export const ReportsReactRoutes = {
  REPORTS_DASHBOARD: {
    ...OfficeRoutes.REPORTS_DASHBOARD,
    path: 'dashboard',
    element: <ReportsDashboard />,
    principalUserRequiresCompany: true,
  },
  // Dynamic report route
  VIEW_REPORT: {
    ...OfficeRoutes.REPORTS_VIEW,
    path: 'view/:reportType/:reportId',
    element: <ReportView />,
    principalUserRequiresCompany: true,
  },
} satisfies ReactRouteMap
