import { PermissionV2, R } from '@breezy/shared'
import React, { useCallback, useContext, useMemo, useReducer } from 'react'
import { PermissionControlsActions } from './actions'
import {
  getAllV2Permissions,
  getInitialPermissions,
  getInitialState,
  getIsSuperAdmin,
} from './getters'
import { permissionControlsReducer } from './reducers'
import { PermissionCategory, PermissionControlsState } from './types'

export const PermissionControlsProvider = ({
  children,
  permissions,
}: {
  children: React.ReactNode
  permissions?: PermissionV2[]
}) => {
  const permissionControlsState = usePermissionControlsReducer(
    getInitialState(permissions),
  )

  return (
    <PermissionControlsContext.Provider value={permissionControlsState}>
      {children}
    </PermissionControlsContext.Provider>
  )
}

export function usePermissionControlsReducer(
  initialState: PermissionControlsState,
) {
  const [state, dispatch] = useReducer(permissionControlsReducer, initialState)

  const memoizedStore = useMemo<
    [PermissionControlsState, React.Dispatch<PermissionControlsActions>]
  >(() => [state, dispatch], [state])

  return { state: memoizedStore[0], dispatch: memoizedStore[1] }
}

type PermissionControlsStateContext = ReturnType<
  typeof usePermissionControlsReducer
>
const PermissionControlsContext =
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  React.createContext<PermissionControlsStateContext>(undefined!)

export function usePermissionControls() {
  const { state, dispatch } = useContext(PermissionControlsContext)

  const toggleSuperAdmin = useCallback(
    (enabled: boolean) => {
      dispatch({
        type: '@@TOGGLE_SUPER_ADMIN',
        payload: {
          enabled,
        },
      })
    },
    [dispatch],
  )

  const toggleCapabilityCustomDiscountCreationEnabled = useCallback(
    (enabled: boolean) => {
      dispatch({
        type: '@@TOGGLE_CAPABILITY_CUSTOM_DISCOUNT_CREATION_ENABLED',
        payload: {
          enabled,
        },
      })
    },
    [dispatch],
  )

  const toggleCapabilityAiEstimateDescriptionsEnabled = useCallback(
    (enabled: boolean) => {
      dispatch({
        type: '@@TOGGLE_CAPABILITY_AI_ESTIMATE_DESCRIPTIONS_ENABLED',
        payload: {
          enabled,
        },
      })
    },
    [dispatch],
  )

  const toggleOfficeFinancialInformationInvoicesEditAfterPaymentEnabled =
    useCallback(
      (enabled: boolean) => {
        dispatch({
          type: '@@TOGGLE_OFFICE_FINANCIAL_INFORMATION_INVOICES_EDIT_AFTER_PAYMENT_ENABLED',
          payload: {
            enabled,
          },
        })
      },
      [dispatch],
    )

  const toggleOfficeAppPermissions = useCallback(
    (enabled: boolean) => {
      dispatch({
        type: '@@TOGGLE_DESKTOP_APP_PERMISSIONS',
        payload: {
          enabled,
        },
      })
    },
    [dispatch],
  )

  const toggleFieldAppPermissions = useCallback(
    (enabled: boolean) => {
      dispatch({
        type: '@@TOGGLE_FIELD_APP_PERMISSIONS',
        payload: {
          enabled,
        },
      })
    },
    [dispatch],
  )

  const togglePermissionCategoryPermissions = useCallback(
    (category: PermissionCategory, enabled: boolean) => {
      dispatch({
        type: '@@TOGGLE_PERMISSION_CATEGORY_PERMISSIONS',
        payload: {
          category,
          enabled,
        },
      })
    },
    [dispatch],
  )

  const setPermissionCategoryPermission = useCallback(
    (
      category: PermissionCategory,
      permission: PermissionV2,
      permissionGroupIndex: number,
    ) => {
      dispatch({
        type: '@@SELECT_PERMISSION_CATEGORY_PERMISSION',
        payload: {
          category,
          permission,
          permissionGroupIndex,
        },
      })
    },
    [dispatch],
  )

  const checkedPermissionsPermissionCategory = useCallback(
    (
      category: PermissionCategory,
      permissions: PermissionV2[],
      permissionGroupIndex: number,
    ) => {
      dispatch({
        type: '@@CHECKED_PERMISSIONS_PERMISSION_CATEGORY',
        payload: {
          category,
          permissions,
          permissionGroupIndex,
        },
      })
    },
    [dispatch],
  )

  const selectOfficeFinancialInformationNestedPermission = useCallback(
    (value: PermissionV2 | number) => {
      dispatch({
        type: '@@SELECT_OFFICE_FINANCIAL_INFORMATION_NESTED_PERMISSION',
        payload: { permissionGroupIndex: 0, value },
      })
    },
    [dispatch],
  )

  const checkedNestedPermissionsOfficeFinancialInformation = useCallback(
    (permissions: PermissionV2[]) => {
      dispatch({
        type: '@@CHECKED_NESTED_PERMISSIONS_OFFICE_FINANCIAL_INFORMATION',
        payload: { permissionGroupIndex: 0, permissions },
      })
    },
    [dispatch],
  )

  const pendingPermissionsV2 = useMemo(() => {
    return getAllV2Permissions(state)
  }, [state])

  const isDirty = useMemo(() => {
    const initial = R.sort(R.ascend(R.identity), getInitialPermissions(state))
    const pending = R.sort(R.ascend(R.identity), pendingPermissionsV2)
    const permissionsAreDirty = !R.equals(initial, pending)

    return permissionsAreDirty
  }, [state, pendingPermissionsV2])

  const isSuperAdmin = useMemo(() => {
    return getIsSuperAdmin(state)
  }, [state])
  return {
    state,
    dispatch,
    toggleSuperAdmin,
    toggleOfficeAppPermissions,
    toggleFieldAppPermissions,
    togglePermissionCategoryPermissions,
    toggleCapabilityCustomDiscountCreationEnabled,
    toggleCapabilityAiEstimateDescriptionsEnabled,
    toggleOfficeFinancialInformationInvoicesEditAfterPaymentEnabled,
    setPermissionCategoryPermission,
    checkedPermissionsPermissionCategory,
    selectOfficeFinancialInformationNestedPermission,
    checkedNestedPermissionsOfficeFinancialInformation,
    pendingPermissionsV2,
    isSuperAdmin,
    isDirty,
  }
}
