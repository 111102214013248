import { z } from 'zod'
import { AsyncFn } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { bzOptional, Guid } from '../common-schemas'
import { ForCompany } from '../Company/Company'

export type PurchaseOrderGuid = Guid

export type PurchaseOrder = {
  purchaseOrderGuid: PurchaseOrderGuid
  jobGuid: Guid
  purchaseOrderNumber: string
}

export const UpdatePurchaseOrderForJobDTOSchema = z.object({
  jobGuid: guidSchema,
  companyGuid: guidSchema,
  purchaseOrderNumbers: bzOptional(z.string().array()),
})

export type UpdatePurchaseOrderForJobDTO = z.infer<typeof UpdatePurchaseOrderForJobDTOSchema>

export type IPurchaseOrderUpdater = AsyncFn<ForCompany<UpdatePurchaseOrderForJobDTO>, void>
