import { useMemo } from 'react'
import { useQuery } from 'urql'
import { FETCH_COMPANY_BUSINESS_UNITS } from '../../pages/BusinessUnitsSettingsPage/BusinessUnitsSettingsPage.gql'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'

export type BusinessUnitSimple = {
  businessUnitGuid: string
  name: string
}

export const useBusinessUnits = () => {
  const companyGuid = useExpectedCompanyGuid()
  const [{ data }] = useQuery({
    query: FETCH_COMPANY_BUSINESS_UNITS,
    variables: { companyGuid },
  })

  const companyConfig = useMemo(() => data?.companyConfig[0], [data])

  const businessUnitsEnabled = useMemo(
    () => companyConfig?.businessUnitsEnabled ?? false,
    [companyConfig],
  )

  const businessUnitsRequired = useMemo(
    () => companyConfig?.businessUnitsRequired ?? false,
    [companyConfig],
  )

  const businessUnits = useMemo(() => {
    if (!businessUnitsEnabled) return null
    return (
      data?.businessUnits.map(bu => ({
        businessUnitGuid: bu.businessUnitGuid,
        name: bu.name,
      })) ?? null
    )
  }, [businessUnitsEnabled, data])

  return {
    businessUnits,
    businessUnitsRequired,
  }
}
