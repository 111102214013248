import { PricebookItemCodeSchema } from '@breezy/shared'
import { Form, Input } from 'antd'

export const PricebookFormItemCode = () => (
  <Form.Item
    name="pricebookItemCode"
    label="Code"
    rules={[
      {
        required: false,
        validator: async (_, value) => {
          if (!value) {
            return
          }
          const result = PricebookItemCodeSchema.safeParse(value)
          if (!result.success) {
            throw new Error(
              result.error.issues[0]?.message || result.error.message,
            )
          }
        },
      },
    ]}
  >
    <Input type="text" placeholder="Enter item code..." />
  </Form.Item>
)
