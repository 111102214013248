import {
  faChartSimple,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { ReportType } from '../../reports/ReportTypes'

interface ReportCardProps {
  title: string
  description: string
  icon: IconDefinition
  reportType?: ReportType
  reportId?: string
}

export const ReportCard: React.FC<ReportCardProps> = ({
  title,
  description,
  icon = faChartSimple,
  reportType = 'export',
  reportId,
}) => {
  const linkPath = reportId
    ? `/reports/view/${reportType}/${reportId}`
    : undefined

  return (
    <Card
      className="h-full rounded-xl border border-bz-gray-500 bg-white shadow-bz-table"
      bodyStyle={{ padding: '24px' }}
    >
      <div className="mb-4">
        <div className="flex h-9 w-9 items-center justify-center rounded-full bg-bz-fill-tertiary p-1.5">
          <FontAwesomeIcon
            icon={icon}
            className="text-lg text-bz-text-quaternary"
          />
        </div>
      </div>
      {linkPath ? (
        <Link to={linkPath} className="no-underline">
          <h3 className="mb-2 text-base font-semibold text-bz-primary hover:underline">
            {title}
          </h3>
        </Link>
      ) : (
        <h3 className="mb-2 text-base font-semibold text-bz-primary">
          {title}
        </h3>
      )}
      <p className="text-sm text-bz-text-description">{description}</p>
    </Card>
  )
}
