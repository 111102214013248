import { gql } from '../../generated'

export const DELETE_ESTIMATE_TEMPLATE_MUTATION = gql(/* GraphQL */ `
  mutation MarkEstimateTemplateDeleted($estimateTemplateGuid: uuid!) {
    updateEstimateTemplatesByPk(
      pkColumns: { estimateTemplateGuid: $estimateTemplateGuid }
      _set: { isDeleted: true }
    ) {
      isDeleted
    }
  }
`)
