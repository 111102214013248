import {
  CompanyGuid,
  EstimateGuid,
  isNullishOrEmpty,
  JobGuid,
  TimeZoneId,
} from '@breezy/shared'
import {
  Document,
  Font,
  Page,
  pdf,
  StyleSheet,
  View,
} from '@react-pdf/renderer'
import React, { useCallback, useMemo, useState } from 'react'
import tailwindConfig from '../../../../tailwind.config'
import { DEFAULT_ESTIMATE_OPTION_NAME } from '../../estimatesFlowUtils'
import { DownloadableEstimateProps } from '../DownloadableEstimate'
import { PDFBillingDisclaimer } from './components/PDFBillingDisclaimer'
import { PDFBillingHeader } from './components/PDFBillingHeader'
import { PDFOptionContainer } from './components/PDFOptionContainer'
import { PDFSignature } from './components/PDFSignature'
import { useDownloadableEstimatePdfData } from './components/useDownloadableEstimatePdfData'
import {
  PdfBillingInfo,
  usePdfBillingInfo,
} from './components/usePdfBillingInfo'

type DownloadableEstimatePdfProps = Omit<
  DownloadableEstimateProps & {
    tzId: TimeZoneId
    pdfBillingInfo: PdfBillingInfo
    taxRate: number
  },
  'downloadableElementRef' | 'jobGuid'
>

Font.register({
  family: 'SF Pro',
  fonts: [
    {
      src: 'https://raw.githubusercontent.com/sahibjotsaggu/San-Francisco-Pro-Fonts/refs/heads/master/SF-Pro.ttf',
    },
    {
      src: 'https://raw.githubusercontent.com/sahibjotsaggu/San-Francisco-Pro-Fonts/refs/heads/master/SF-Pro-Italic.ttf',
      fontStyle: 'italic',
    },
    {
      src: 'https://raw.githubusercontent.com/sahibjotsaggu/San-Francisco-Pro-Fonts/refs/heads/master/SF-Pro-Text-Bold.otf',
      fontWeight: 'bold',
    },
  ],
})

const styles = StyleSheet.create({
  page: {
    padding: '30px 0',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    fontFamily: 'SF Pro',
    gap: 24,
  },
})

export const DownloadableEstimatePdf = React.memo<DownloadableEstimatePdfProps>(
  ({
    messageHtml,
    displayId,
    createdAt,
    status,
    options,
    contactFullName,
    signatureFileUrl,
    acceptedOnBehalfByUser,
    acceptedAt,
    taxRate,
    disclaimer,
    tzId,
    pdfBillingInfo,
  }) => {
    const isAccepted = status === 'ACCEPTED'

    const summaryData = useMemo(
      () => ({
        options: options.map(option => ({
          name: option.displayName || DEFAULT_ESTIMATE_OPTION_NAME,
          totalUsc: option.totalUsc,
        })),
      }),
      [options],
    )

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <PDFBillingHeader
            {...pdfBillingInfo}
            message={messageHtml}
            summaryData={summaryData}
            issuedAt={createdAt}
            displayId={displayId}
            tzId={tzId}
            numOptions={options.length}
          />
        </Page>
        {options.map((option, i) => (
          <Page key={option.optionGuid} size="A4" style={styles.page}>
            <PDFOptionContainer
              index={option.seq}
              header={
                isNullishOrEmpty(option.displayName)
                  ? options.length === 1
                    ? 'Estimate'
                    : 'Option'
                  : option.displayName
              }
              description={option.descriptionHtml || ''}
              lineItems={option.lineItems}
              discounts={option.discounts}
              recommended={option.recommended}
              selected={option.selected}
              taxRate={taxRate}
              featuredPhotoCdnUrl={option.featuredPhotoCdnUrl}
              singleEstimateOption={options.length === 1}
            />
            {option.selected && isAccepted && (
              <PDFSignature
                signatureFileUrl={signatureFileUrl}
                acceptedOnBehalfByUser={acceptedOnBehalfByUser}
                acceptedAt={acceptedAt}
                tzId={tzId}
                contactFullName={contactFullName}
                acceptedOption={options.find(option => option.selected)}
              />
            )}
            {i === options.length - 1 && disclaimer && (
              <>
                <View
                  style={{
                    height: 8,
                    backgroundColor:
                      tailwindConfig.theme.extend.colors.bz['fill-tertiary'],
                    marginVertical: '24px',
                  }}
                />
                <PDFBillingDisclaimer disclaimer={disclaimer} />
              </>
            )}
          </Page>
        ))}
      </Document>
    )
  },
)

export const useDownloadableEstimatePdf = (props: {
  companyGuid: CompanyGuid
  jobGuid: JobGuid
  estimateGuid: EstimateGuid
}) => {
  const { executePdfBillingInfoQuery } = usePdfBillingInfo(
    props.companyGuid,
    props.jobGuid,
  )
  const { executePdfDataQuery } = useDownloadableEstimatePdfData(
    props.estimateGuid,
    props.companyGuid,
  )

  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false)

  const generatePdf = useCallback(async () => {
    if (props) {
      setIsGeneratingPdf(true)
      const pdfBillingInfo = await executePdfBillingInfoQuery()
      const pdfData = await executePdfDataQuery()
      try {
        const pdfBlob = await pdf(
          <DownloadableEstimatePdf
            {...pdfData}
            pdfBillingInfo={pdfBillingInfo}
          />,
        ).toBlob()

        const pdfBlobUrl = URL.createObjectURL(pdfBlob)

        return pdfBlobUrl
      } finally {
        setIsGeneratingPdf(false)
      }
    }

    return null
  }, [executePdfBillingInfoQuery, executePdfDataQuery, props])

  const downloadPdf = useCallback(
    async ({ fileName }: { fileName: string }) => {
      const pdfBlobUrl = await generatePdf()

      if (pdfBlobUrl) {
        const downloadLink = document.createElement('a')
        downloadLink.href = pdfBlobUrl
        downloadLink.download = fileName
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      }
    },
    [generatePdf],
  )

  const previewPdf = useCallback(async () => {
    const pdfBlobUrl = await generatePdf()

    if (pdfBlobUrl) {
      window.open(pdfBlobUrl, '_blank')
    }
  }, [generatePdf])

  return {
    isGeneratingPdf,
    previewPdf,
    downloadPdf,
  }
}
