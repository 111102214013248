import {
  BzDateFns,
  CalculatePaths,
  formatUsc,
  IsoDateString,
  MaintenancePlanPaymentFlow,
  MaintenancePlanStatus,
  TimeZoneId,
  upperPascalCaseToHumanReadable,
} from '@breezy/shared'
import { LabeledItemGrid } from 'src/adam-components/LabeledItemGrid'
import { SectionedCard } from 'src/adam-components/SectionedCard/SectionedCard'
import { Link } from 'src/elements/Link/Link'
import MaintenancePlanStatusTag from 'src/pages/MaintenancePlanDetailsPage/MaintenancePlanStatusTag'

export interface MaintenancePlanCardProps {
  maintenancePlan: {
    maintenancePlanGuid: string
    location: {
      locationGuid: string
      address: {
        line1: string
      }
    }
    billing: {
      frequency?: string
      anchorDay?: string
    }
    status: MaintenancePlanStatus
    visits?: {
      visitGuid: string
      affinityDate: IsoDateString
      job?: {
        jobGuid: string
        displayId: string
        jobTypeName: string
      }
    }[]
    activationDate?: IsoDateString
    renewalDate: IsoDateString
    paymentFlow: MaintenancePlanPaymentFlow
    numCoveredEquipment: number
    yearlyRevenueUsc: number
    planTypeName: string
    isActiveMaintenancePlanGuid?: boolean
  }
  timezoneId: TimeZoneId
}

export const MaintenancePlanCard = (props: MaintenancePlanCardProps) => {
  const { maintenancePlan: mp } = props

  return (
    <SectionedCard
      dashed
      small
      sections={[
        {
          verticalPaddingClassName: 'p-3',
          content: (
            <div
              className="flex flex-row items-center justify-between"
              data-testid="maintenance-plan-card-title"
            >
              <Link
                to={`/maintenance-plans/${mp.maintenancePlanGuid}`}
                bold
                className="text-sm"
              >
                {mp.planTypeName} Plan
              </Link>

              <MaintenancePlanStatusTag
                paymentFlow={mp.paymentFlow}
                status={mp.status}
              />
            </div>
          ),
        },
        {
          content: (
            <LabeledItemGrid
              items={[
                'Location',
                <Link
                  to={CalculatePaths.locationDetails({
                    locationGuid: mp.location.locationGuid,
                  })}
                >
                  {mp.location.address.line1}
                </Link>,
                ...(mp.visits
                  ? mp.visits.map((visit, idx) => [
                      `Visit #${idx + 1} (${BzDateFns.formatFromISO(
                        visit.affinityDate,
                        'MMM yyyy',
                        props.timezoneId,
                      )})`,
                      visit.job ? (
                        <Link to={`/jobs/${visit.job.jobGuid}`}>
                          {visit.job.jobTypeName} #{visit.job.displayId}
                        </Link>
                      ) : (
                        'Unscheduled'
                      ),
                    ])
                  : []
                ).flat(),
                ...(mp.billing.frequency
                  ? [
                      'Billing Frequency',
                      upperPascalCaseToHumanReadable(mp.billing.frequency),
                    ]
                  : []),
                ...(mp.billing.anchorDay
                  ? ['Billing Anchor Day', mp.billing.anchorDay]
                  : []),
                ...(mp.activationDate
                  ? [
                      'Activation Date',
                      BzDateFns.parseISO(mp.activationDate, props.timezoneId)
                        .toDateString()
                        .split(' ')
                        .slice(1)
                        .join(' '),
                    ]
                  : []),
                'Renewal Date',
                BzDateFns.parseISO(mp.renewalDate, props.timezoneId)
                  .toDateString()
                  .split(' ')
                  .slice(1)
                  .join(' '),
                'Auto Renews',
                mp.paymentFlow === MaintenancePlanPaymentFlow.AUTO
                  ? 'Yes'
                  : 'No',
                'Covered Equipment',
                mp.numCoveredEquipment,
                'Yearly Revenue',
                formatUsc(mp.yearlyRevenueUsc),
              ]}
            />
          ),
        },
      ]}
    />
  )
}
