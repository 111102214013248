import {
  noOp,
  PhotoRecord,
  PricebookItemGuid,
  PricebookItemTypeEnum,
} from '@breezy/shared'
import { Button, Form } from 'antd'
import { useForm, useWatch } from 'antd/lib/form/Form'
import { useCallback, useMemo, useRef, useState } from 'react'
import ThinDivider from '../../elements/ThinDivider'
import { trpc } from '../../hooks/trpc'
import { useIsPricebookPhotosEnabled } from '../../hooks/useIsPricebookPhotosEnabled'
import { useQuickbooksOnlineEnabled } from '../../providers/CompanyFinancialConfigWrapper'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import FullScreenModal from '../FullScreenModal/FullScreenModal'
import CreationStepHeading from '../ProgressiveJobCreationModal/JobCreationSteps/CreationStepHeading'
import {
  AsyncPhotoUploadWithThumbnail,
  OnPhotoUploadChange,
} from '../Upload/AsyncPhotoUpload'
import PhotoCropModal, {
  usePhotoCrop,
} from '../Upload/PhotoCropModal/PhotoCropModal'
import { useSynchronousUpload } from '../Upload/Upload'
import { ItemPriceFormItem } from '../form-fields/ItemPriceFormItem'
import { PricebookFormItemActive } from './Form/FormItems/PricebookFormItemActive'
import { PricebookFormItemCategoryTreeSelect } from './Form/FormItems/PricebookFormItemCategoryTreeSelect'
import { PricebookFormItemCost } from './Form/FormItems/PricebookFormItemCost'
import { PricebookFormItemDescription } from './Form/FormItems/PricebookFormItemDescription'
import { PricebookFormItemDiscountable } from './Form/FormItems/PricebookFormItemDiscountable'
import { PricebookFormItemIncomeAccountTreeSelect } from './Form/FormItems/PricebookFormItemIncomeAccountTreeSelect'
import { PricebookFormItemName } from './Form/FormItems/PricebookFormItemName'
import { PricebookFormItemTaxable } from './Form/FormItems/PricebookFormItemTaxable'
import { PricebookItemFormSchema } from './Utils/types'
import { usePricebookAdmin } from './hooks/usePricebookAdmin'

const ITEM_TYPE = PricebookItemTypeEnum.SERVICE

const DEFAULT_VALUES: Partial<PricebookItemFormSchema> = {
  name: '',
  description: '',
  costUSD: 0,
  priceUSD: 0,
  isTaxable: false,
  isActive: true,
  isDiscountable: true,
  sourcePhotoGuid: undefined,
  sourcePhotoUrl: undefined,
  photoGuid: undefined,
  photoUrl: undefined,
}

type UpsertPricebookItemFormProps = {
  initialValues?: PricebookItemFormSchema & {
    pricebookItemGuid?: PricebookItemGuid
  }
}

const UpsertPricebookItemForm = ({
  initialValues,
}: UpsertPricebookItemFormProps) => {
  const message = useMessage()
  const { categories, pricebookItemMutated, closeDrawer, defaultPhotos } =
    usePricebookAdmin()

  const [pricebookItemGuid, setPricebookItemGuid] = useState(
    initialValues?.pricebookItemGuid,
  )

  const defaultPhoto = useMemo(() => defaultPhotos[ITEM_TYPE], [defaultPhotos])

  const company = useExpectedCompany()
  const [form] = useForm<PricebookItemFormSchema>()
  const isActive = useWatch('isActive', form)
  const onActiveChange = useCallback(
    (checked: boolean) => {
      form.setFieldsValue({
        isActive: !checked,
      })
    },
    [form],
  )

  const savePricebookItemMutation =
    trpc.pricebook['pricebook:save-item'].useMutation()
  const deletePricebookItemMutation =
    trpc.pricebook['pricebook:delete-item'].useMutation()

  const onFinish = useCallback(
    (values: PricebookItemFormSchema) => {
      savePricebookItemMutation.mutate(
        {
          pricebookItemGuid,
          companyGuid: company.companyGuid,
          itemType: ITEM_TYPE,
          ...values,
        },
        {
          onSuccess: result => {
            if (pricebookItemGuid) {
              message.success('Successfully updated item in Pricebook')
            } else {
              message.success('Successfully added item to Pricebook')
            }

            setPricebookItemGuid(result.pricebookItemGuid)
            pricebookItemMutated()
          },
          onError: e => {
            if (pricebookItemGuid) {
              message.error(
                'There was an error updating the Pricebook Item. Please try again.',
              )
            } else {
              message.error(
                'There was an error adding the Pricebook Item. Please try again.',
              )
            }
          },
        },
      )
    },
    [
      savePricebookItemMutation,
      pricebookItemGuid,
      company.companyGuid,
      pricebookItemMutated,
      message,
    ],
  )

  const onDelete = useCallback(() => {
    if (!pricebookItemGuid) return

    deletePricebookItemMutation.mutate(
      { companyGuid: company.companyGuid, pricebookItemGuid },
      {
        onSuccess: () => {
          message.success('Successfully deleted item from Pricebook')
          pricebookItemMutated()
        },
        onError: () => {
          message.error(
            'There was an error deleting the Pricebook Item. Please try again.',
          )
        },
      },
    )
  }, [
    pricebookItemGuid,
    company.companyGuid,
    deletePricebookItemMutation,
    pricebookItemMutated,
    message,
  ])

  const onCancel = useCallback(() => {
    closeDrawer()
  }, [closeDrawer])

  const isQuickbooksOnlineEnabled = useQuickbooksOnlineEnabled()

  const onUploadChange: OnPhotoUploadChange = useSynchronousUpload(record => {
    form.setFieldsValue({
      sourcePhotoGuid: record.photoGuid,
      sourcePhotoUrl: record.cdnUrl,
      photoGuid: record.photoGuid,
      photoUrl: record.cdnUrl,
    })
  })

  const onCropEdit = useCallback(
    (record: PhotoRecord) => {
      form.setFieldsValue({
        photoGuid: record.photoGuid,
        photoUrl: record.cdnUrl,
      })
    },
    [form],
  )

  const sourcePhotoUrl = useWatch('sourcePhotoUrl', form)
  const photoUrl = useWatch('photoUrl', form)

  const onRevertToDefaultPhoto = useCallback(() => {
    form.setFieldsValue({
      sourcePhotoGuid: defaultPhoto?.photoGuid,
      sourcePhotoUrl: defaultPhoto?.cdnUrl,
      photoGuid: defaultPhoto?.photoGuid,
      photoUrl: defaultPhoto?.cdnUrl,
    })
  }, [form, defaultPhoto])

  const onRemovePhoto = useCallback(() => {
    form.setFieldsValue({
      sourcePhotoGuid: undefined,
      sourcePhotoUrl: undefined,
      photoGuid: undefined,
      photoUrl: undefined,
    })
  }, [form])

  const { pricebookPhotosEnabled } = useIsPricebookPhotosEnabled()

  const { cropModalVisible, openCropModal, photoCropModalProps } = usePhotoCrop(
    {
      sourcePhotoUrl,
      onCropEdit,
      onClose: noOp,
    },
  )

  if (!categories) return null

  return (
    <>
      <Form
        form={form}
        initialValues={{
          ...DEFAULT_VALUES,
          ...(initialValues ?? {}),
          sourcePhotoGuid:
            initialValues?.sourcePhotoGuid ?? defaultPhoto?.photoGuid,
          sourcePhotoUrl: initialValues?.sourcePhotoUrl ?? defaultPhoto?.cdnUrl,
          photoGuid: initialValues?.photoGuid ?? defaultPhoto?.photoGuid,
          photoUrl: initialValues?.photoUrl ?? defaultPhoto?.cdnUrl,
        }}
        layout="vertical"
        validateTrigger="onBlur"
        onFinish={onFinish}
        disabled={savePricebookItemMutation.isLoading}
        className="flex flex-col items-center"
      >
        <Form.Item name="sourcePhotoGuid" hidden />
        <Form.Item name="sourcePhotoUrl" hidden />
        <Form.Item name="photoGuid" hidden />
        <Form.Item name="photoUrl" hidden />
        <div className="flex flex-row gap-6">
          <div className="flex w-[619px] flex-col gap-0">
            <div className="mb-4 text-[20px] font-semibold leading-[28px]">
              Details
            </div>
            {pricebookPhotosEnabled && (
              <div className="col-span-2 mb-6">
                <div className="flex items-center gap-3">
                  <AsyncPhotoUploadWithThumbnail
                    thumbnail={{
                      width: 104,
                      height: 104,
                    }}
                    defaultPhotoUrl={defaultPhoto?.cdnUrl}
                    sourcePhotoUrl={sourcePhotoUrl}
                    photoUrl={photoUrl}
                    onPhotoUploadChange={onUploadChange}
                    onOpenCrop={openCropModal}
                    onRevertToDefaultPhoto={onRevertToDefaultPhoto}
                    onRemovePhoto={onRemovePhoto}
                  />
                  <div className="flex max-w-[400px] flex-col">
                    <div className="text-sm font-semibold leading-[22px]">
                      Item Image
                    </div>
                    <div className="text-sm text-bz-text-secondary">
                      Square aspect ratio, .PNG and .JPG file types.
                    </div>
                  </div>
                </div>
              </div>
            )}
            <PricebookFormItemName />
            <PricebookFormItemCategoryTreeSelect categories={categories} />
            <PricebookFormItemDescription />
          </div>
          <div className="flex w-[381px] flex-col gap-4">
            <div className="mb-4 text-[20px] font-semibold leading-[28px]">
              Pricing & Settings
            </div>
            <PricebookFormItemCost />
            <ItemPriceFormItem />
            <PricebookFormItemTaxable />
            <PricebookFormItemDiscountable />

            {isQuickbooksOnlineEnabled && (
              <div className="col-span-2">
                <PricebookFormItemIncomeAccountTreeSelect />
              </div>
            )}
            <PricebookFormItemActive
              value={isActive}
              onChange={onActiveChange}
            />
            {pricebookItemGuid && (
              <Button danger onClick={onDelete}>
                Delete
              </Button>
            )}
          </div>
        </div>
        <ThinDivider
          widthPx={8}
          styleOverrides={{ marginTop: 0, marginBottom: 24 }}
        />
        <div className="w-full">
          <div className="flex items-center justify-between gap-x-4">
            <Button className="h-[40px] w-full rounded-lg" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className="h-[40px] w-full rounded-lg"
              type="primary"
              htmlType="submit"
            >
              {pricebookItemGuid ? 'Update' : 'Add'}
            </Button>
          </div>
        </div>
      </Form>
      {cropModalVisible && (
        <PhotoCropModal {...photoCropModalProps} mode="modal" />
      )}
    </>
  )
}

const PricebookServiceItemContent = () => {
  const { pricebookItem } = usePricebookAdmin()
  const heading = useMemo(
    () => (pricebookItem?.name ? 'Edit Service Item' : 'Create Service Item'),
    [pricebookItem],
  )
  const subheading = useMemo(
    () =>
      pricebookItem?.name
        ? 'Edit the details of this service'
        : 'Add a service to the pricebook',
    [pricebookItem],
  )

  const content = useMemo(() => {
    return <UpsertPricebookItemForm />
  }, [])

  return (
    <div className="mx-auto flex w-full max-w-[1024px] justify-center">
      <div className="flex w-full flex-col">
        <CreationStepHeading
          bottomDivider
          dividerClassName="my-6"
          heading={heading}
          subheading={subheading}
        />
        {content}
      </div>
    </div>
  )
}

interface PricebookServiceItemModalProps {
  isOpen: boolean
}

export const PricebookServiceItemModal = ({
  isOpen,
}: PricebookServiceItemModalProps) => {
  const { closeDrawer } = usePricebookAdmin()
  const pageContainerRef = useRef<HTMLDivElement>(null)

  const onClose = useCallback(() => {
    closeDrawer()
  }, [closeDrawer])

  return (
    <FullScreenModal
      containerRef={pageContainerRef}
      isOpen={isOpen}
      setIsOpen={onClose}
      className="pricebook-create-service-item-modal"
      zIndexClassName="z-[1010]"
    >
      <PricebookServiceItemContent />
    </FullScreenModal>
  )
}
