import { z } from 'zod'
import {
  AsyncFn,
  BusinessResourceConflictException,
  BzDateFns,
  IsoDateString,
  LocalDateString,
  Log,
  TimeZoneId,
  isNullish,
} from '../../common'
import { Account } from '../Accounts/Account'
import { BzAddress } from '../Address/Address'
import { AppointmentChecklistInstance } from '../AppointmentChecklists/AppointmentChecklists'
import {
  CompanyGuid,
  CompanyGuidContainer,
  ForCompany,
  ForCompanyUser,
  TechnicianApptsRequest,
} from '../Company/Company'
import { BzTimeWindow, TimeWindow, TimeWindowDto, timeWindowDtoSchema } from '../DateTime/TimeWindow'
import { InstalledEquipmentSummaryDto } from '../InstalledEquipment/InstalledEquipment'
import { InstalledHvacSystemDto } from '../InstalledEquipment/InstalledHvacSystem'
import { InstallProjectType, JobBasicInfo, JobGuid, JobReferenceInfo } from '../Job'
// NOTE: if you add this to the import above, it causes a circular dependency issue
import { JobClass, defaultDurationForJobClass } from '../Job/JobClass'
import { JobLifecycleStatus } from '../JobLifecycles/JobLifecycles'
import { JobType, JobTypeMinimal } from '../JobTypes/JobTypes'
import { LocationDTO } from '../Locations/Location'
import { MaintenancePlanMinimalInfo } from '../MaintenancePlans/MaintenancePlanTypes'

import { guidSchema, isoDateStringSchema } from '../../contracts'
import { Guid, GuidAndReferenceNumber, bzOptional } from '../common-schemas'
import { Contact } from '../contacts/Contact'
import { SimpleContactDtoWithAccountGuid } from '../contacts/types'
import { ConfirmAppointmentDTO } from '../dtos'
import { AbridgedInvoiceViewModel } from '../Finance/Invoicing/InvoiceTypes'
import { NotificationPreferenceType } from '../NotificationPreferenceTypes'
import { REMINDER_FREQUENCIES } from '../Notifications/Notifications'
import { ReviewTypeSchema } from '../Reviews/Reviews'
import { TechnicianCapacityBlock } from '../Scheduling/TechnicianCapacity'
import { Technician } from '../Users/Technician'
import { UserGuid } from '../Users/User'

// NOTE: these are alphabetized (with "Other" last). If you add a new one, please put it
// in the proper order.
export const APPOINTMENT_TYPES = [
  'Callback',
  'Diagnostic',
  'Installation',
  'Maintenance',
  'Permit Inspection',
  'Pre-Install Job Walk',
  'Quality Assurance Walkthrough',
  'Repair',
  'Sales Visit',
  'Site Visit',
  'Sales',
  'Other',
] as const

export type AppointmentType = (typeof APPOINTMENT_TYPES)[number]

export const DEFAULT_APPOINTMENT_TYPE_MAP = {
  SERVICE: 'Repair',
  MAINTENANCE: 'Maintenance',
  ESTIMATE_REPAIR: 'Diagnostic',
  ESTIMATE_REPLACE: 'Sales Visit',
  INSTALL: 'Installation',
  WARRANTY: 'Repair',
  CALLBACK: 'Callback',
  SALES: 'Sales',
  UNKNOWN: 'Other',
} satisfies Record<JobClass, AppointmentType>

type AppointmentStatus = {
  readonly appointmentStatus: InferredAppointmentStatus
}

type AppointmentDto = {
  readonly appointmentConfirmed: boolean
  readonly appointmentCanceled: boolean
  readonly timeWindow: TimeWindowDto
  readonly appointmentType: AppointmentType
  readonly description?: string
  readonly endOfAppointmentNextSteps?: EndOfAppointmentNextSteps
}

type IdentifiableAppointmentDto = GuidAndReferenceNumber & AppointmentDto
type IdentifiableAppointmentDtoWithStatus = IdentifiableAppointmentDto & AppointmentStatus

export enum SimplePriority {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

type Assignment = {
  assignmentGuid: ApptAssignmentGuid
  assignmentStatus: AssignmentStatus
  technicianUserGuid: UserGuid
  timeWindow: BzTimeWindow
}

type AssignmentWithTechnician = Assignment & {
  technician: Technician
}

export type AssignmentDTO = Omit<Assignment, 'timeWindow'> & {
  readonly timeWindow: TimeWindowDto
}

export type AssignmentWithTechnicianNameDTO = AssignmentDTO & {
  readonly technicianFirstName: string
  readonly technicianLastName: string
}

type Appointment = {
  readonly contact: SimpleContactDtoWithAccountGuid
  readonly location: LocationDTO
  readonly appointment: IdentifiableAppointmentDtoWithStatus
}
export type AppointmentNotificationType = 'EMAIL' | 'SMS'
export type AppointmentGuid = Guid
export type ComprehensiveAppointmentDetails = AppointmentStatus & {
  appointmentReferenceNumber: string
  appointmentGuid: AppointmentGuid
  confirmed: boolean
  canceled: boolean
  assignments: AssignmentWithTechnician[]
  address: BzAddress
  timeWindow: BzTimeWindow
  jobGuid: Guid
  jobType: JobTypeMinimal
  associatedInstallProjectType?: InstallProjectType
  appointmentType: AppointmentType
  description?: string
  appointmentChecklistInstances?: AppointmentChecklistInstance[]
  endOfAppointmentNextSteps: EndOfAppointmentNextSteps | undefined
  sendConfirmationEnabled: boolean
  notificationType: AppointmentNotificationType | undefined
  sendConfirmationTo: string | undefined
  confirmationLastSentAt: IsoDateString | undefined
  sendReminderEnabled: boolean
  reminderLastSentAt: IsoDateString | undefined
  locationGuid?: string
  displayId?: number
}

export type AssignableApptViewModel = JobReferenceInfo & { jobType: JobTypeMinimal } & Appointment & {
    readonly assignments: AssignmentWithTechnicianNameDTO[]
    readonly maintenancePlans: MaintenancePlanMinimalInfo[]
    readonly accountTags: string[]
    readonly jobInvoices: AbridgedInvoiceViewModel[]
    readonly completedChecklists: AppointmentChecklistInstance[]
  }

type AssignableApptsReaderInput = CompanyGuidContainer & Partial<TimeWindowDto>
export type AssignableApptsReader = AsyncFn<AssignableApptsReaderInput, AssignableApptViewModel[]>
export type AssignableApptsWithBusinessProjectionsReader = AsyncFn<
  AssignableApptsReaderInput,
  AssignableApptViewModelWithBusinessProjections[]
>

export type AssignedApptViewModel = JobBasicInfo & { jobType: JobType } & Omit<Appointment, 'contact'> & {
    readonly assignments: AssignmentWithTechnicianNameDTO[]
    readonly accountGuid: Account['accountGuid']
    readonly locationGuid: Guid
    readonly installedHvacSystems: InstalledHvacSystemDto[]
    readonly installedEquipment: InstalledEquipmentSummaryDto[]
    readonly pointOfContact: Contact
    readonly maintenancePlans: MaintenancePlanMinimalInfo[]
    // [X] Audited in BZ-921 -> No Action Required immediately
    // TODO: add notes
  }

export type AssignedApptsReader = AsyncFn<TechnicianApptsRequest, AssignedApptViewModel[]>

export type TechAppointmentAndBlocksViewModel = {
  readonly assignments: AssignedApptViewModel[]
  readonly blocks: TechnicianCapacityBlock[]
}

export type ReadTechAppointmentAndBlocks = AsyncFn<TechnicianApptsRequest, TechAppointmentAndBlocksViewModel>

export const TechnicianApptsRequestDtoSchema = z.object({
  technicianUserGuid: guidSchema,
  start: bzOptional(isoDateStringSchema),
  end: bzOptional(isoDateStringSchema),
})

export type TechnicianApptsRequestDto = z.infer<typeof TechnicianApptsRequestDtoSchema>

export const TechnicianApptsRequestByJobAppointmentGuidDtoSchema = z.object({
  technicianUserGuid: bzOptional(guidSchema),
  jobAppointmentGuid: guidSchema,
})

export type TechnicianApptsRequestByJobAppointmentGuidDto = z.infer<
  typeof TechnicianApptsRequestByJobAppointmentGuidDtoSchema
>

export const TechnicianApptsRequestByJobAppointmentReferenceNumberDtoSchema = z.object({
  technicianUserGuid: bzOptional(guidSchema),
  jobAppointmentReferenceNumber: z.string(),
})

export type TechnicianApptsRequestByJobAppointmentReferenceNumberDto = z.infer<
  typeof TechnicianApptsRequestByJobAppointmentReferenceNumberDtoSchema
>

export type AssignableApptViewModelWithBusinessProjections = AssignableApptViewModel & {
  readonly businessProjections: JobKeyBusinessProjectionsDto
}

type JobKeyBusinessProjectionsDto = {
  readonly priority: SimplePriority
  readonly estimatedDuration: string // What type should this be?
}

export const INFERRED_APPOINTMENT_STATUSES = [
  'UNASSIGNED',
  'ASSIGNED',
  'EN_ROUTE',
  'IN_PROGRESS',
  'COMPLETED',
  'CANCELED',
] as const

export type InferredAppointmentStatus = (typeof INFERRED_APPOINTMENT_STATUSES)[number]

export const AppointmentStatusDisplayNames = {
  UNASSIGNED: 'Unassigned',
  ASSIGNED: 'Assigned',
  EN_ROUTE: 'En Route',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  CANCELED: 'Canceled',
} satisfies Record<InferredAppointmentStatus, string>

export const ASSIGNMENT_STATUSES = ['TO_DO', 'EN_ROUTE', 'IN_PROGRESS', 'COMPLETED', 'CANCELED'] as const
export type AssignmentStatus = (typeof ASSIGNMENT_STATUSES)[number]

export const IN_PROGRESS_ASSIGNMENT_STATUSES: AssignmentStatus[] = ['EN_ROUTE', 'IN_PROGRESS']
export type InProgressAssignmentStatuses = (typeof IN_PROGRESS_ASSIGNMENT_STATUSES)[number]

const assignmentStatusSchema = z.enum(ASSIGNMENT_STATUSES)

export const AssignmentStatusDisplayNames = {
  TO_DO: 'To Do',
  EN_ROUTE: 'En Route',
  IN_PROGRESS: 'In Progress',
  COMPLETED: 'Completed',
  CANCELED: 'Canceled',
} satisfies Record<AssignmentStatus, string>

export const calculateInferredAppointmentStatus = (
  appointmentCanceled: boolean,
  assignments: { assignmentStatus: AssignmentStatus }[] | AssignmentDTO[],
): InferredAppointmentStatus => {
  if (appointmentCanceled) {
    return 'CANCELED'
  }

  if (assignments.length === 0) {
    return 'UNASSIGNED'
  }

  let anyCompleted = false
  let anyInProgress = false
  let anyEnRoute = false
  let allCanceled = true

  for (const { assignmentStatus } of assignments) {
    if (assignmentStatus === 'COMPLETED') {
      anyCompleted = true
    } else if (assignmentStatus === 'IN_PROGRESS') {
      anyInProgress = true
    } else if (assignmentStatus === 'EN_ROUTE') {
      anyEnRoute = true
    }
    if (assignmentStatus !== 'CANCELED') {
      allCanceled = false
    }
  }

  if (allCanceled) {
    return 'CANCELED'
  } else if (anyCompleted) {
    return 'COMPLETED'
  } else if (anyInProgress) {
    return 'IN_PROGRESS'
  } else if (anyEnRoute) {
    return 'EN_ROUTE'
  } else {
    return 'ASSIGNED'
  }
}

export const SendVisitConfirmationDtoSchema = z.object({
  sendConfirmationEnabled: bzOptional(z.boolean()),
  notificationType: bzOptional(z.enum(['SMS', 'EMAIL'])),
  to: bzOptional(z.string()),
  sendReminderEnabled: bzOptional(z.boolean()),
})

export type SendVisitConfirmationDTO = z.infer<typeof SendVisitConfirmationDtoSchema>

export const UpsertAppointmentDTOSchema = z
  .object({
    appointmentGuid: guidSchema,
    jobGuid: guidSchema,
    arrivalWindow: timeWindowDtoSchema,
    appointmentType: z.enum(APPOINTMENT_TYPES),
    suppressAccountNotifications: bzOptional(z.boolean()),
    description: bzOptional(z.string()),
    referenceNumber: bzOptional(z.string()),
  })
  .merge(SendVisitConfirmationDtoSchema)

type UpsertAppointmentDTO = z.infer<typeof UpsertAppointmentDTOSchema>

export type UpsertAppointmentInput = Omit<UpsertAppointmentDTO, 'arrivalWindow'> & {
  arrivalWindow: TimeWindow
}

export type UpsertAppointment = AsyncFn<ForCompanyUser<UpsertAppointmentInput>>

export const appointmentAssignmentDtoSchema = z.object({
  assignmentGuid: guidSchema,
  jobGuid: guidSchema,
  jobAppointmentGuid: guidSchema,
  technicianUserGuid: guidSchema,
  timeWindow: timeWindowDtoSchema,
})

export const UpsertAppointmentAndAssignmentDTOSchema = UpsertAppointmentDTOSchema.extend({
  assignments: z.array(appointmentAssignmentDtoSchema.omit({ jobAppointmentGuid: true, jobGuid: true })),
})

export type UpsertAppointmentAndAssignmentDTO = z.infer<typeof UpsertAppointmentAndAssignmentDTOSchema>
export type CancelAppointment = AsyncFn<ForCompany<JobAppointmentGuidContainer>>

export const HistoricalLocationAppointmentsByJobAppointmentDtoSchema = z.object({
  jobAppointmentGuid: guidSchema,
  limit: bzOptional(z.number().int().min(1)),
})

export type HistoricalLocationAppointmentsByJobAppointmentDto = z.infer<
  typeof HistoricalLocationAppointmentsByJobAppointmentDtoSchema
>

export type HistoricalAppointmentDTO = {
  jobAppointmentGuid: JobAppointmentGuid
  appointmentReferenceNumber: string
  appointmentWindowStart: string
  appointmentWindowEnd: string
  jobCreatedAt: string
  jobType: JobTypeMinimal
  jobLifecycleStatus: JobLifecycleStatus
  installProjectType?: InstallProjectType
}

export type HistoricalAppointmentsForLocationReaderInput = JobAppointmentGuidContainer & { limit?: number }

export type HistoricalAppointmentsForLocationReader = AsyncFn<
  HistoricalAppointmentsForLocationReaderInput,
  HistoricalAppointmentDTO[]
>

type AppointmentConfirmerUpdateStatus = 'UPDATED' | 'NO-UPDATE-REQUIRED'

type AppointmentConfirmerUpdateResult = {
  jobGuid: JobGuid
  companyGuid: CompanyGuid
  jobAppointmentGuid: JobAppointmentGuid
  jobAppointmentReferenceNumber: string
  updateStatus: AppointmentConfirmerUpdateStatus
}

export type AppointmentConfirmer = AsyncFn<ConfirmAppointmentDTO, AppointmentConfirmerUpdateResult>

type AppointmentAssignmentsByUserDeleterInput = {
  userGuid: Guid
  deactivatedAt: string
}

export type AppointmentAssignmentsByUserDeleter = AsyncFn<ForCompany<AppointmentAssignmentsByUserDeleterInput>, void>

export type JobAppointmentGuid = Guid
export type JobAppointmentGuidContainer = {
  readonly jobAppointmentGuid: JobAppointmentGuid
}
export type ApptAssignmentGuid = Guid
export type ApptAssignmentGuidContainer = {
  readonly assignmentGuid: ApptAssignmentGuid
}

export interface AppointmentAssignmentDto {
  readonly jobGuid: JobGuid
  readonly jobAppointmentGuid: JobAppointmentGuid
  readonly assignmentGuid: ApptAssignmentGuid
  readonly technicianUserGuid: UserGuid
  readonly timeWindow: TimeWindowDto
}

export type AppointmentAssignment = Omit<AppointmentAssignmentDto, 'timeWindow'> & {
  readonly timeWindow: TimeWindow
}

export type AppointmentAssignmentUpsertInput = AppointmentAssignment & {
  assignmentStatus?: AssignmentStatus
}

export type ApptAssignmentWriter = AsyncFn<ForCompany<AppointmentAssignmentUpsertInput>>
export type IApptAssignmentReader = AsyncFn<ApptAssignmentGuidContainer, ForCompany<AppointmentAssignment>>

export const AssignmentUpdateInputSchema = z.object({
  jobGuid: guidSchema,
  apptGuid: guidSchema,
  apptAssignmentGuid: guidSchema,
  assignmentStatus: assignmentStatusSchema,
  suppressEnRouteAccountNotification: bzOptional(z.boolean()),
})

export type AssignmentUpdateInput = z.infer<typeof AssignmentUpdateInputSchema>

export type AssignmentStatusUpdater = AsyncFn<ForCompanyUser<AssignmentUpdateInput>, AssignmentDTO>

export const assignmentPauseResumeWriterInputSchema = z.object({
  jobGuid: guidSchema,
  jobAppointmentGuid: guidSchema,
  jobAppointmentAssignmentGuid: guidSchema,
})
export type AssignmentPauseResumeWriterInput = ForCompanyUser<z.infer<typeof assignmentPauseResumeWriterInputSchema>>
export type AssignmentPauseResumeWriter = AsyncFn<AssignmentPauseResumeWriterInput>

export class NonUniqueAppointmentAssignmentGuidException extends BusinessResourceConflictException {}

export const simplePrioritiesForJobClass = {
  [JobClass.INSTALL]: SimplePriority.HIGH,
  [JobClass.ESTIMATE_REPLACE]: SimplePriority.HIGH,
  [JobClass.ESTIMATE_REPAIR]: SimplePriority.MEDIUM,
  [JobClass.SERVICE]: SimplePriority.MEDIUM,
  [JobClass.WARRANTY]: SimplePriority.MEDIUM,
  [JobClass.CALLBACK]: SimplePriority.MEDIUM,
  [JobClass.SALES]: SimplePriority.MEDIUM,
  [JobClass.MAINTENANCE]: SimplePriority.LOW,
  [JobClass.UNKNOWN]: SimplePriority.LOW,
}

export const enrichWithPlaceholderBusinessProjections = (
  item: AssignableApptViewModel,
): AssignableApptViewModelWithBusinessProjections => {
  return {
    ...item,
    businessProjections: {
      priority: simplePrioritiesForJobClass[item.jobType.jobClass],
      estimatedDuration: defaultDurationForJobClass[item.jobType.jobClass].formatHHMM(),
    },
  }
}

type EnRouteNotificationSenderData = {
  companyName: string
  appointmentType: AppointmentType
  notificationPreferenceType: NotificationPreferenceType
  phoneNumber?: string
  emailAddress?: string
  companyPhoneNumber: string
}

export type EnRouteNotificationSenderDataReader = AsyncFn<ApptAssignmentGuidContainer, EnRouteNotificationSenderData>
export const isAssignable = (a: AssignableApptViewModelWithBusinessProjections) =>
  a.appointment.appointmentStatus === 'UNASSIGNED'

export const isLiveAppointment = (a: AssignableApptViewModelWithBusinessProjections) =>
  a.appointment.appointmentStatus !== 'UNASSIGNED' && a.appointment.appointmentStatus !== 'CANCELED'

/**
 * Calculates the available capacity (in hours) for a technician within their working hours, excluding time already
 * allocated to assignments. This calculation is pessimistic in nature, meaning that if an assignment overlaps into
 * another hour, even slightly, it will round up to the next hour. This approach ensures that the technician is not
 * overbooked by accounting for any partial hour as a full hour of unavailability.
 *
 * @param timeZoneId The company timezone.
 * @param workingHours Object containing the start and end working hours for the technician.
 * @param assignments Array of assignment objects with time windows for when the technician is already booked.
 * @param start The start date of the period for which to calculate capacity.
 * @param end The end date of the period for which to calculate capacity.
 * @returns The number of hours the technician is available to work between the start and end dates, within their working hours.
 */
export const calculateTechnicianNumHoursAvailable = (
  timeZoneId: TimeZoneId,
  workingHours: { startHour: number; endHour: number },
  assignments: AssignmentDTO[],
  start: IsoDateString,
  end: IsoDateString,
): number => {
  // Convert start and end dates to Date objects in the technician's timezone
  const startDate = BzDateFns.parseISO(start, timeZoneId)
  const endDate = BzDateFns.parseISO(end, timeZoneId)

  let totalHoursAvailable = 0

  // Iterate over each day between start and end
  for (
    let day = startDate;
    BzDateFns.isBefore(day, endDate) || BzDateFns.isSameDay(day, endDate);
    day = BzDateFns.addDays(day, 1)
  ) {
    // Calculate daily working hours
    const dailyWorkingHours = workingHours.endHour - workingHours.startHour

    // Filter assignments for the current day
    const dailyAssignments = assignments.filter(assignment =>
      BzDateFns.isSameDay(BzDateFns.parseISO(assignment.timeWindow.start, timeZoneId), day),
    )

    // Calculate total hours assigned for the day
    const hoursAssigned = dailyAssignments.reduce((acc, assignment) => {
      const assignmentStart = BzDateFns.parseISO(assignment.timeWindow.start, timeZoneId)
      const assignmentEnd = BzDateFns.parseISO(assignment.timeWindow.end, timeZoneId)
      const assignmentDuration = BzDateFns.differenceInHours(assignmentEnd, assignmentStart, {
        roundingMethod: 'ceil',
      })
      return acc + assignmentDuration
    }, 0)

    // Calculate available hours for the day and add to total
    const dailyAvailableHours = Math.max(dailyWorkingHours - hoursAssigned, 0)
    totalHoursAvailable += dailyAvailableHours
  }

  return totalHoursAvailable
}

export const getTechAssignmentMap = (assignables: AssignableApptViewModelWithBusinessProjections[]) => {
  return assignables
    .filter(a => isLiveAppointment(a))
    .map(a => a.assignments)
    .flat()
    .reduce((acc, a) => {
      if (acc[a.technicianUserGuid]) {
        acc[a.technicianUserGuid].push(a)
      } else {
        acc[a.technicianUserGuid] = [a]
      }
      return acc
    }, {} as Record<string, AssignmentDTO[]>)
}

export const EndOfAppointmentNextStepsV1JsonSchema = z.object({
  version: z.literal('v1'),
  data: z.object({
    allOnsiteWorkCompleted: z.boolean(),
    allOnsiteWorkNotCompletedDetails: bzOptional(z.string()),
    dispatcherNote: bzOptional(z.string()),
  }),
})
export type EndOfAppointmentNextStepsV1 = z.infer<typeof EndOfAppointmentNextStepsV1JsonSchema>

export const EndOfAppointmentNextStepsV2JsonSchema = z.object({
  version: z.literal('v2'),
  data: z.object({
    // v1 schema
    allOnsiteWorkCompleted: z.boolean().default(false),
    allOnsiteWorkNotCompletedDetails: bzOptional(z.string()).default(''),
    dispatcherNote: bzOptional(z.string()),
    // v2 schema additions
    sendReviewLink: z.boolean().default(false),
    notificationType: bzOptional(z.enum(['SMS', 'EMAIL', 'QR_CODE'])).default('SMS'),
    to: bzOptional(z.string()),
    reviewLinkType: bzOptional(ReviewTypeSchema),
    reminderLastSentAt: bzOptional(isoDateStringSchema),
    numRemindersSent: z.number().int().min(0).max(3).default(0),
  }),
})
export type EndOfAppointmentNextStepsV2 = z.infer<typeof EndOfAppointmentNextStepsV2JsonSchema>

export type EndOfAppointmentNextSteps = EndOfAppointmentNextStepsV1 | EndOfAppointmentNextStepsV2
export const tryParseEndOfAppointmentNextSteps = (
  endOfAppointmentCandidate: unknown,
): EndOfAppointmentNextSteps | undefined => {
  if (isNullish(endOfAppointmentCandidate)) {
    return undefined
  }
  try {
    // Try parsing with V2 schema first
    const v2Result = EndOfAppointmentNextStepsV2JsonSchema.safeParse(endOfAppointmentCandidate)
    if (v2Result.success) {
      return v2Result.data
    }

    // If V2 fails, try parsing with V1 schema
    const v1Result = EndOfAppointmentNextStepsV1JsonSchema.safeParse(endOfAppointmentCandidate)
    if (v1Result.success) {
      return v1Result.data
    }

    // If both schemas fail, log the error and return undefined
    Log.warn(
      `Failed to parse endOfAppointmentCandidate = ${JSON.stringify(endOfAppointmentCandidate)}. Returning undefined`,
      v2Result.error,
    )
    return undefined
  } catch (e) {
    Log.warn(
      `Failed to parse endOfAppointmentCandidate = ${JSON.stringify(endOfAppointmentCandidate)}. Returning undefined`,
      e,
    )
    return undefined
  }
}

export const appointmentConfigurationSettingsV1FormSchema = z.object({
  endOfAppointmentWorkflowEnabled: z.boolean().default(false),
})

export const appointmentConfigurationSettingsV2FormSchema = appointmentConfigurationSettingsV1FormSchema.extend({
  // NOTE: make sure everything is optional. We aren't guaranteed a row, hence the defaults specified. You can potentially
  // bork some things if you expect fields that aren't there.
  endOfVisitSendReviewLinkEnabled: z.boolean().default(true),
  endOfVisitSendReviewLinkFrequency: bzOptional(z.enum(REMINDER_FREQUENCIES)).default('AFTER_5_DAYS'),
  endOfVisitSendReviewLinkCount: bzOptional(z.number().int().min(1).max(3)).default(1),
  endOfVisitSendReviewLinkType: bzOptional(ReviewTypeSchema).default('GOOGLE'),
})

export const EndOfAppointmentNextStepsUpsertInputSchema = z.object({
  jobAppointmentGuid: guidSchema,
  endOfAppointmentNextSteps: EndOfAppointmentNextStepsV2JsonSchema,
})

export type EndOfAppointmentNextStepsUpsertInput = z.infer<typeof EndOfAppointmentNextStepsUpsertInputSchema>

export type EndOfAppointmentNextStepsWriter = AsyncFn<ForCompanyUser<EndOfAppointmentNextStepsUpsertInput>>

export type VisitConfirmationSenderRequest = {
  appointmentGuid: JobAppointmentGuid
  jobGuid: JobGuid
  arrivalWindow: TimeWindow
} & SendVisitConfirmationDTO

export type VisitConfirmationSender = AsyncFn<ForCompany<VisitConfirmationSenderRequest>>

export const SingleShotSendVisitConfirmationDtoSchema = z.object({
  appointmentGuid: guidSchema,
  notificationType: z.enum(['SMS', 'EMAIL']),
  to: z.string(),
})

export type SingleShotSendVisitConfirmationDto = z.infer<typeof SingleShotSendVisitConfirmationDtoSchema>

export type SingleShotVisitConfirmationSender = AsyncFn<
  SingleShotSendVisitConfirmationDto & {
    tzId: TimeZoneId
  }
>

export type TechAssignmentTimeWindowMultiReaderRequest = ForCompany<{
  date: LocalDateString
  technicianGuids: UserGuid[]
}>

export type TechAssignmentTimeWindow = {
  assignmentGuid: ApptAssignmentGuid
  start: IsoDateString
  end: IsoDateString
}

export type TechAssignmentTimeWindowMultiReaderResponse = {
  techAssignmentTimeWindows: Record<UserGuid, TechAssignmentTimeWindow[]>
}

export type TechAssignmentTimeWindowMultiReader = AsyncFn<
  TechAssignmentTimeWindowMultiReaderRequest,
  TechAssignmentTimeWindowMultiReaderResponse
>

export type EnRouteTechnicianEmailMetadata = {
  company: {
    name: string
    phoneNumber: string
    companyLogoUrl?: string
    emailAddress?: string
    websiteUrl?: string
  }
  contact: {
    name: string
  }
  technician: {
    name: string
    avatarUrl?: string
  }
}

export const enRouteTechnicianEmailMailerInput = z.object({
  companyGuid: guidSchema,
  appointmentAssignmentGuid: guidSchema,
  to: z.string(),
})

export type EnRouteTechnicianEmailMailerInput = z.infer<typeof enRouteTechnicianEmailMailerInput>

export type EnRouteTechnicianEmailMailer = AsyncFn<EnRouteTechnicianEmailMailerInput>

export const enRouteTechnicianSmsSenderInputSchema = z.object({
  companyGuid: guidSchema,
  appointmentAssignmentGuid: guidSchema,
  toPhoneNumber: z.string(),
})

export type EnRouteTechnicianSmsSenderInput = z.infer<typeof enRouteTechnicianSmsSenderInputSchema>

export type EnRouteTechnicianSmsSender = AsyncFn<EnRouteTechnicianSmsSenderInput>
