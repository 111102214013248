import { BzDateFns, getDisplayNameForAccountType, noOp } from '@breezy/shared'
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import {
  faArrowRight,
  faBan,
  faScrewdriverWrench,
  faWarning,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { WithAccountMetadata } from '../../pages/AccountDetailsPageV2/WithAccountMetadata'
import { AccountMetadata } from '../../pages/AccountDetailsPageV2/accountDetailsV2Utils'
import { AccountContactsCollapsible as AccountContactsCollapsibleV2 } from '../../pages/AccountDetailsPageV2/collapsibles/AccountContactsCollapsible'
import { AccountEquipmentAndHvacSystemCollapsible } from '../../pages/AccountDetailsPageV2/collapsibles/AccountEquipmentAndHvacSystemCollapsible'
import { AccountEstimatesCollapsible as AccountEstimatesCollapsibleV2 } from '../../pages/AccountDetailsPageV2/collapsibles/AccountEstimatesCollapsible'
import { AccountInvoicesCollapsible as AccountInvoicesCollapsibleV2 } from '../../pages/AccountDetailsPageV2/collapsibles/AccountInvoicesCollapsible'
import { AccountJobsCollapsible as AccountJobsCollapsibleV2 } from '../../pages/AccountDetailsPageV2/collapsibles/AccountJobsCollapsible'
import { AccountLocationsCollapsible as AccountLocationsCollapsibleV2 } from '../../pages/AccountDetailsPageV2/collapsibles/AccountLocationsCollapsible'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { m } from '../../utils/react-utils'
import {
  AccountDetailsBanner,
  DoNotServiceBanner,
} from '../Accounts/AccountDetailsBanner/AccountDetailsBanner'
import { useOpenAccountBalance } from '../Accounts/AccountDetailsBanner/useOpenAccountBalance'
import { BehindFeatureFlag } from '../BehindFeatureFlag'
import { LoadingSpinner } from '../LoadingSpinner'
import { TagList, TagListItem } from '../Tags'
import { useTechAppBottomOffset } from '../TechnicianApp/BottomNavBar/CurrentVisitCard/CurrentVisitProvider'
import {
  GlobalSearchResultError,
  GlobalSearchResultItemPreviewEmpty,
} from './GlobalSearchLoadStates'
import { BreezyGlobalSearchResult, useBreezyHits } from './util'

type GlobalSearchResultItemPreviewInnerProps = {
  search: {
    hit: BreezyGlobalSearchResult
    sendEvent: ReturnType<typeof useBreezyHits>['sendEvent']
  }
  account: AccountMetadata
  onAccountClick: (accountGuid: string) => void
}

const GlobalSearchResultItemPreviewInner =
  React.memo<GlobalSearchResultItemPreviewInnerProps>(
    ({ search, account, onAccountClick }) => {
      const tzId = useExpectedCompanyTimeZoneId()
      const accountBalance = useOpenAccountBalance(account.accountGuid)
      const overdueBalanceFormatted = accountBalance?.overdueBalanceFormatted
      const ageOfOldestOpenInvoice = accountBalance?.ageOfOldestOpenInvoice

      const computedTags = React.useMemo(() => {
        const { tags, doNotService, doNotServiceReason, maintenancePlans } =
          account
        const cTags: TagListItem[] = []

        if (overdueBalanceFormatted && ageOfOldestOpenInvoice) {
          cTags.push({
            name: (
              <>
                <span className="semibold_12_20">Due:</span>{' '}
                <span className="regular_14_22">{overdueBalanceFormatted}</span>{' '}
                <span className="semibold_12_20">Age:</span>{' '}
                <span className="regular_14_22">
                  {ageOfOldestOpenInvoice} days
                </span>
              </>
            ),
            hideIcon: true,
            className:
              'border-bz-orange-700 bg-bz-orange-100 text-bz-orange-700',
            icon: faWarning,
            overrideStyle: {
              bgColor: 'bg-bz-orange-100',
              textColor: 'text-bz-orange-700',
              iconColor: 'text-bz-orange-700',
            },
          })
        }

        if (doNotService && doNotServiceReason) {
          cTags.push({
            name: 'Do Not Service',
            className:
              'border-bz-orange-700 bg-bz-orange-100 text-bz-orange-700',
            icon: faBan,
            overrideStyle: {
              bgColor: 'bg-bz-orange-100',
              textColor: 'text-bz-orange-700',
              iconColor: 'text-bz-orange-700',
            },
            popoverContent: (
              <DoNotServiceBanner
                doNotServiceReason={doNotServiceReason}
                className="max-w-96 items-start"
              />
            ),
          })
        }

        if (maintenancePlans.length > 0) {
          cTags.push(
            ...maintenancePlans.map(plan => ({
              name:
                plan.maintenancePlanDefinition?.marketingInfo?.name ??
                'Missing Name',
              icon: faScrewdriverWrench,
            })),
          )
        }

        return [...cTags, ...tags.map(tag => tag.tag)]
      }, [account, overdueBalanceFormatted, ageOfOldestOpenInvoice])

      return (
        <div className="flex h-full w-full flex-col bg-white shadow-inner">
          <div
            className="flex flex-col overflow-y-auto overflow-x-hidden bg-bz-gray-100 px-3 py-4 shadow-inner"
            onScroll={() => {
              search.sendEvent(
                'view',
                search.hit,
                'User Scrolls In Preview Pane',
              )
            }}
          >
            <AccountDetailsBanner
              account={account}
              className="mb-4"
              itemClassName="items-start"
            />
            <div className="flex flex-col">
              <h2 className="text-xl font-semibold text-bz-gray-900">
                {account.accountDisplayName}
                {search.hit.archived ? ' (Archived)' : ''}
              </h2>

              <div className="flex flex-row justify-between">
                <div className="flex flex-col">
                  <span className="text-sm font-semibold text-bz-gray-900">
                    Account Type
                  </span>
                  <span className="text-sm">
                    {getDisplayNameForAccountType(account.accountType)}
                  </span>
                </div>

                <div className="flex flex-col">
                  <span className="text-sm font-semibold text-bz-gray-900">
                    Created On
                  </span>
                  <span className="text-sm">
                    {BzDateFns.formatFromISO(
                      account.accountCreatedAt,
                      'MMM d, yyyy',
                      tzId,
                    )}
                  </span>
                </div>
              </div>

              {computedTags.length > 0 && (
                <div className="mt-2 flex flex-col">
                  <span className="mb-1 text-sm font-semibold text-bz-gray-900">
                    Tags
                  </span>

                  <TagList tags={computedTags} spacingY={2} />
                </div>
              )}
            </div>

            <Divider />

            <div className="flex flex-col space-y-3">
              <AccountContactsCollapsibleV2
                accountGuid={account.accountGuid}
                editable={false}
                onViewMore={() => onAccountClick(account.accountGuid)}
              />
              <AccountLocationsCollapsibleV2
                accountGuid={account.accountGuid}
                editable={false}
                onViewMore={() => onAccountClick(account.accountGuid)}
              />

              <AccountJobsCollapsibleV2
                accountGuid={account.accountGuid}
                editable={false}
                onViewMore={() => onAccountClick(account.accountGuid)}
              />

              <AccountInvoicesCollapsibleV2
                accountGuid={account.accountGuid}
                onViewMore={() => onAccountClick(account.accountGuid)}
              />
              <AccountEstimatesCollapsibleV2
                accountGuid={account.accountGuid}
                onViewMore={() => onAccountClick(account.accountGuid)}
              />

              <AccountEquipmentAndHvacSystemCollapsible
                accountGuid={account.accountGuid}
                editable={false}
              />
            </div>
          </div>

          <div className="mt-auto flex flex-col items-center bg-bz-gray-100 px-5 py-2 drop-shadow-xl">
            <Button
              type="primary"
              size="large"
              className="flex w-full flex-row items-center justify-center space-x-2"
              onClick={() => {
                search.sendEvent(
                  'conversion',
                  search.hit,
                  'Navigated via Account Details Button',
                )
                onAccountClick(account.accountGuid)
              }}
            >
              <span>View Account Details</span>
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        </div>
      )
    },
  )

type GlobalSearchResultItemPreviewProps = {
  result?: {
    hit: BreezyGlobalSearchResult
    sendEvent: ReturnType<typeof useBreezyHits>['sendEvent']
  }
  onAccountClick: (
    accountGuid: string,
    hit: BreezyGlobalSearchResult,
    sendEvent: ReturnType<typeof useBreezyHits>['sendEvent'],
  ) => void
}

export const GlobalSearchResultItemPreview =
  m<GlobalSearchResultItemPreviewProps>(({ result, onAccountClick }) => {
    const { currentVisitCardHeight } = useTechAppBottomOffset()

    return (
      <div
        className={classNames([
          'h-full flex-shrink-0 flex-grow-0 basis-1/2',
          'flex flex-col',
        ])}
        style={{ paddingBottom: `${currentVisitCardHeight}px` }}
      >
        <div
          className={classNames([
            'h-full',
            'bg-bz-gray-300',
            'flex flex-col',
            'overflow-y-auto',
            'overflow-x-hidden',
          ])}
        >
          <BehindFeatureFlag
            enabledFeatureFlag="commercial-account-improvements"
            render={
              <WithAccountMetadata
                accountGuid={result?.hit.accountGuid}
                idleComponent={
                  <div className="mx-8 mt-20">
                    <GlobalSearchResultItemPreviewEmpty />
                  </div>
                }
                loadingComponent={
                  <div className="mt-20">
                    <LoadingSpinner />
                  </div>
                }
                notFoundComponent={
                  <div className="mx-8 mt-20">
                    <GlobalSearchResultError
                      icon={faTriangleExclamation}
                      title="Well, this is embarrassing..."
                      message="We're experiencing an issue displaying the account's details."
                      onTryAgainClick={noOp}
                    />
                  </div>
                }
                render={(account, refetch) =>
                  result ? (
                    <GlobalSearchResultItemPreviewInner
                      search={{
                        hit: result.hit,
                        sendEvent: result.sendEvent,
                      }}
                      account={account}
                      onAccountClick={accountGuid =>
                        onAccountClick(
                          accountGuid,
                          result.hit,
                          result.sendEvent,
                        )
                      }
                    />
                  ) : (
                    <div className="mx-8 mt-20">
                      <GlobalSearchResultError
                        icon={faTriangleExclamation}
                        title="Well, this is embarrassing..."
                        message="We're experiencing an issue displaying the account's details."
                        onTryAgainClick={refetch}
                      />
                    </div>
                  )
                }
              />
            }
          />
        </div>
      </div>
    )
  })
