import {
  DiscountType,
  DynamicPricingType,
  HtmlString,
  InvoiceCartItem,
  calculateCartLineItemSubtotalsUsc,
  formatPercentage,
  formatUsc,
  getDynamicPricingMultiplier,
  isNullish,
  uscMultiply,
} from '@breezy/shared'
import { faGripDotsVertical } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useEffect, useMemo } from 'react'
import { ConnectDragSource, useDrag, useDragLayer, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { DiscountCirclesCombo } from '../../../components/Pricebook/DiscountCircle'
import { DiscountPickerDiscount } from '../../../components/Pricebook/DiscountMultiPicker'
import { PricebookPhotoThumbnail } from '../../../components/PricebookPhotoThumbnail/PricebookPhotoThumbnail'
import { HtmlRenderer } from '../../../elements/HtmlRenderer/HtmlRenderer'
import useIsMobile from '../../../hooks/useIsMobile'
import { useIsPricebookPhotosEnabled } from '../../../hooks/useIsPricebookPhotosEnabled'

type LineItemDragItem = {
  index: number
  name: React.ReactNode
  cartItemCode?: string
  cdnUrl?: string
  descriptionHtml: HtmlString
  valueContent: string
  valueSubContent?: string
  green?: boolean
  showPhoto?: boolean
}

interface InnerLineItemListItemProps extends LineItemDragItem {
  dragDropEnabled: boolean
  drag?: ConnectDragSource
}

const InnerLineItemListItem = React.memo<InnerLineItemListItemProps>(
  ({
    name,
    descriptionHtml,
    valueContent,
    valueSubContent,
    green,
    dragDropEnabled,
    drag,
    cdnUrl,
    cartItemCode,
    showPhoto,
  }) => {
    const isMobile = useIsMobile()

    const hasDescription = descriptionHtml && descriptionHtml !== name

    // If there is no description, we put the quantity to the left of the total. If there is a description, we put
    // it under. On mobile, it's always under.
    const stackedPriceAndQuantity = isMobile || hasDescription

    const { pricebookPhotosEnabled } = useIsPricebookPhotosEnabled()
    return (
      <div
        className={classNames(
          'relative flex min-w-0 flex-row items-start py-3 text-base',
          {
            'text-bz-green-800': green,
          },
        )}
      >
        {dragDropEnabled && (
          <div className="h-6 w-4">
            <FontAwesomeIcon icon={faGripDotsVertical} />
            <div
              ref={drag}
              className="absolute left-0 top-0 z-10 flex min-h-full min-w-[25%] cursor-grab items-center justify-center"
            />
          </div>
        )}
        {pricebookPhotosEnabled && showPhoto && (
          <PricebookPhotoThumbnail cdnUrl={cdnUrl} noPopup={dragDropEnabled} />
        )}
        <div className="flex flex-1 flex-col">
          {cartItemCode && (
            <div className="text-xs font-semibold text-bz-gray-700">
              {cartItemCode}
            </div>
          )}
          <div className="font-semibold">{name}</div>
          {isMobile && (
            <div className="my-0.5 flex flex-row space-x-2">
              <div>{valueContent}</div>
              {isNullish(valueSubContent) ? null : (
                <div className="text-bz-gray-700">{valueSubContent}</div>
              )}
            </div>
          )}
          {hasDescription && (
            <div className={classNames('text-sm text-bz-text-secondary')}>
              <HtmlRenderer htmlContent={descriptionHtml} />
            </div>
          )}
        </div>
        {!isMobile && (
          <div
            className={classNames(
              'ml-6 flex',
              stackedPriceAndQuantity
                ? // The order of the elements is quantity then price. In this configuration it needs to be flipped.
                  'flex-col-reverse items-end'
                : 'flex-row space-x-2',
            )}
          >
            {isNullish(valueSubContent) ? null : (
              <div className="text-bz-gray-700">{valueSubContent}</div>
            )}
            <div>{valueContent}</div>
          </div>
        )}
      </div>
    )
  },
)

interface LineItemListItemProps extends LineItemDragItem {
  index: number
  dataTestId?: string
  onClick?: () => void
  onReorder?: (itemIndex: number, dropIndex: number) => void
}

const LineItemListItem = React.memo<LineItemListItemProps>(
  ({
    index,
    onClick,
    name,
    descriptionHtml,
    valueContent,
    valueSubContent,
    cdnUrl,
    green,
    dataTestId,
    showPhoto,
    onReorder,
    cartItemCode,
  }) => {
    const isMobile = useIsMobile()

    const [{ isDragging }, drag, preview] = useDrag(
      () => ({
        type: 'LINE_ITEM_LIST_ITEM',
        item: {
          index,
          name,
          descriptionHtml,
          valueContent,
          valueSubContent,
          green,
          cdnUrl,
          showPhoto,
        },
        collect: monitor => ({
          isDragging: !!monitor.isDragging(),
        }),
      }),
      [index],
    )

    const [{ isOver }, drop] = useDrop(
      () => ({
        accept: 'LINE_ITEM_LIST_ITEM',
        drop: (item: LineItemDragItem) => {
          onReorder?.(item.index, index)
        },
        canDrop: (item: LineItemDragItem) => {
          return !!onReorder && item.index !== index && item.index !== index + 1
        },
        collect: monitor => ({
          isOver: monitor.canDrop() && !!monitor.isOver(),
        }),
      }),
      [index, onReorder],
    )

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true })
    }, [preview])

    return (
      <>
        <div
          className={classNames('border-0 transition-opacity', {
            'cursor-pointer hover:opacity-60': !isMobile && !!onClick,
            'opacity-60': isDragging,
            'select-none': !!onClick,
          })}
          onClick={onClick}
          data-testid={dataTestId}
          ref={drop}
        >
          <InnerLineItemListItem
            index={index}
            name={name}
            cartItemCode={cartItemCode}
            descriptionHtml={descriptionHtml}
            valueContent={valueContent}
            valueSubContent={valueSubContent}
            cdnUrl={cdnUrl}
            green={green}
            dragDropEnabled={!!onReorder}
            drag={drag}
            showPhoto={showPhoto}
          />
          {isOver && (
            <div className="relative max-h-0 w-full">
              <div className="absolute inset-x-0 bottom-[-1px] z-10 h-1 bg-bz-primary" />
            </div>
          )}
        </div>
      </>
    )
  },
)

const DragLayer = React.memo(() => {
  const isMobile = useIsMobile()
  const { item, currentOffset, isDragging } = useDragLayer(monitor => ({
    item: monitor.getItem<LineItemDragItem>(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))
  if (!isDragging || !item) {
    return null
  }
  return (
    <div className="pointer-events-none fixed inset-0 z-10 h-screen w-screen">
      <div
        className={classNames(
          'max-w-full',
          // This is a little brittle because it makes a few assumptions about the component. The assumptions are that
          // this will be in a container with a 1px border where on mobile it has an inner padding and outer margin of
          // 16px and on tablet it's 24px. In order to make this element match the width of the real thing, we need to
          // make the container the width of the screen (we have the w-full already) minus those paddings and border
          // widths. So for mobile that's (16 [padding amount] * 2 [outer and inner] + 1 [border]) * 2 [both sides], or
          // for a total of 66px. On tablet that's (24 [padding amount] * 2 [outer and inner] + 1 [border]) * 2 [both
          // sides] for a total of 98px. So that's the total amount of padding we need to make the element the right
          // size. We also need the preview to look right. So we re-apply 16/24 of that padding to the preview on either
          // side. Taking those (-32/-48) our of the space we need to make up for and we get 34/50 (32/48 plus the 2
          // margins). We want the item to line up with the cursor when we drag, so we will add these to the right side,
          // and also shift the item by 16/32 when it's dragged.
          isMobile ? 'pr-[34px]' : 'pr-[50px]',
        )}
        style={{
          transform: `translate(${
            // See comment above
            (currentOffset?.x ?? 0) - (isMobile ? 16 : 24)
          }px, ${currentOffset?.y}px)`,
        }}
      >
        <div
          className={classNames('bg-[#FFFFFFE0]', isMobile ? 'px-4' : 'px-6')}
        >
          <InnerLineItemListItem {...item} dragDropEnabled />
        </div>
      </div>
    </div>
  )
})

type LineItemListProps = {
  lineItems: InvoiceCartItem[]
  onItemClick?: (index: number) => void
  discounts?: DiscountPickerDiscount[]
  onDiscountClick?: (index: number) => void
  withHeaders?: boolean
  onReorder?: (itemIndex: number, dropIndex: number) => void
  // This should only be included in customer-facing views, since it will scale the line item amounts.
  dynamicPricingType?: DynamicPricingType
}

export const LineItemList = React.memo<LineItemListProps>(
  ({
    lineItems: externalLineItems,
    onItemClick,
    discounts,
    onDiscountClick,
    withHeaders,
    onReorder,
    dynamicPricingType,
  }) => {
    const lineItems = useMemo(
      () =>
        externalLineItems.map(({ unitPriceUsc, ...rest }) => {
          return {
            unitPriceUsc: dynamicPricingType
              ? uscMultiply(
                  unitPriceUsc,
                  getDynamicPricingMultiplier(dynamicPricingType),
                )
              : unitPriceUsc,
            ...rest,
          }
        }),
      [dynamicPricingType, externalLineItems],
    )
    const totalDiscountAmountUsc = useMemo(() => {
      const summary = calculateCartLineItemSubtotalsUsc({
        items: lineItems,
        discount: { type: DiscountType.FLAT, discountAmountUsc: 0 },
        taxRate: {
          taxRateGuid: '',
          rate: 0,
        },
      })
      return summary.taxDiscSubtotalUsc + summary.nonTaxDiscSubtotalUsc
    }, [lineItems])
    const content = (
      <>
        <div
          className={classNames(
            'divide-y divide-bz-gray-500',
            withHeaders ? 'divide-solid' : 'divide-dashed',
          )}
        >
          {lineItems.map((item, i) => {
            const { description, name, quantity, unitPriceUsc, cartItemCode } =
              item
            const totalUsc = unitPriceUsc * quantity

            return (
              <LineItemListItem
                key={`${JSON.stringify(item)}_${i}`}
                index={i}
                name={name}
                cartItemCode={cartItemCode}
                onClick={onItemClick ? () => onItemClick(i) : undefined}
                descriptionHtml={description}
                valueContent={formatUsc(totalUsc)}
                // Only show the quantity if there's more than one, or it's a negative quantity
                valueSubContent={
                  quantity < -1 || quantity > 1
                    ? `(${quantity} x ${formatUsc(unitPriceUsc)})`
                    : undefined
                }
                cdnUrl={item.photoCdnUrl}
                dataTestId={`invoice-line-item-${i}`}
                onReorder={onReorder}
                showPhoto
              />
            )
          })}
          {discounts?.map(
            (
              {
                name,
                descriptionHtml,
                type,
                discountGuid,
                discountAmountUsc,
                discountRate,
              },
              i,
            ) => {
              const totalUsc =
                type === DiscountType.FLAT
                  ? discountAmountUsc
                  : totalDiscountAmountUsc * discountRate

              return (
                <LineItemListItem
                  key={discountGuid}
                  index={i}
                  green
                  name={
                    <div className="flex flex-row items-center">
                      <div data-testid="discount-name" className="mr-2">
                        {name}
                      </div>
                      <DiscountCirclesCombo discounts={discounts} />
                    </div>
                  }
                  onClick={
                    onDiscountClick ? () => onDiscountClick(i) : undefined
                  }
                  descriptionHtml={descriptionHtml}
                  valueContent={formatUsc(-totalUsc)}
                  // Only show the quantity if there's more than one, or it's a negative quantity
                  valueSubContent={
                    type === DiscountType.RATE
                      ? `(${formatPercentage(discountRate)})`
                      : undefined
                  }
                />
              )
            },
          )}
        </div>
        <DragLayer />
      </>
    )
    if (withHeaders) {
      return (
        <div>
          <div className="text-base font-semibold">Line Items</div>
          <div className="mt-3 border-0 border-b border-solid border-bz-gray-400 pb-4 text-sm font-semibold uppercase text-bz-gray-800">
            Description & Total
          </div>
          {content}
        </div>
      )
    }
    return content
  },
)
