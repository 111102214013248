import React from 'react'
import { OnsiteModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { useIsSmallScreen } from '../../hooks/useIsMobile'
import {
  EquipmentUpsertForm,
  EquipmentUpsertFormProps,
} from '../EquipmentUpsertForm/EquipmentUpsertForm'

export type EquipmentUpsertDrawerProps = EquipmentUpsertFormProps & {
  isOpen: boolean
}

const EquipmentUpsertDrawer = React.memo<EquipmentUpsertDrawerProps>(
  ({ isOpen, ...equipmentUpsertFormProps }) => {
    const isSmallScreen = useIsSmallScreen()

    return (
      <OnsiteModal
        open={isOpen}
        size={isSmallScreen ? 'full' : 'large'}
        onClose={equipmentUpsertFormProps.onCancel}
        drawer={false}
      >
        <EquipmentUpsertForm {...equipmentUpsertFormProps} />
      </OnsiteModal>
    )
  },
)

export default EquipmentUpsertDrawer
