import { isNullish, WithRequiredKeys } from '@breezy/shared'
import {
  faAdd,
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIsTouchScreen } from '../../hooks/useIsMobile'
import { OnResize, useResizeObserver } from '../../hooks/useResizeObserver'
import './OnsitePageCollapsibleSection.less'
import { OnsitePageSection, OnsitePageSectionProps } from './OnsitePageSection'

const STORAGE_EXPIRY_DAYS = 7

const getStorageKey = (key: string) => `onsite-page-collapsible-${key}`

const getStoredCollapsedState = (key: string): boolean | null => {
  const storageKey = getStorageKey(key)
  const stored = sessionStorage.getItem(storageKey)
  if (!stored) return null

  try {
    const { collapsed, timestamp } = JSON.parse(stored)
    const now = Date.now()
    if (now - timestamp > STORAGE_EXPIRY_DAYS * 24 * 60 * 60 * 1000) {
      sessionStorage.removeItem(storageKey)
      return null
    }
    return collapsed
  } catch {
    return null
  }
}

const setStoredCollapsedState = (key: string, collapsed: boolean) => {
  const storageKey = getStorageKey(key)
  const data = {
    collapsed,
    timestamp: Date.now(),
  }
  sessionStorage.setItem(storageKey, JSON.stringify(data))
}

export type OnsitePageCollapsibleMode = 'default' | 'paginated'

export type OnsitePageCollapsibleSectionProps = WithRequiredKeys<
  Omit<OnsitePageSectionProps, 'disableContentSpacing'>,
  'title'
> & {
  count?: number
  onAdd?: () => void
  defaultCollapsed?: boolean
  noContentMargin?: boolean
  smallTitle?: boolean
  addButtonTestId?: string
  collapsibleStateId: string
}

export const OnsitePageCollapsibleSection =
  React.memo<OnsitePageCollapsibleSectionProps>(
    ({
      title,
      count,
      onAdd,
      children,
      defaultCollapsed,
      noContentMargin,
      smallTitle,
      collapsibleStateId,
      ...rest
    }) => {
      const isTouchScreen = useIsTouchScreen()
      const storedCollapsed = useMemo(() => {
        if (isNullish(collapsibleStateId)) return defaultCollapsed ?? false
        const stored = getStoredCollapsedState(collapsibleStateId)
        if (isNullish(stored)) return defaultCollapsed ?? false
        return stored
      }, [collapsibleStateId, defaultCollapsed])

      const [collapsed, setCollapsed] = useState(storedCollapsed)

      const handleCollapseToggle = useCallback(() => {
        setCollapsed(prev => {
          const newState = !prev
          if (collapsibleStateId) {
            setStoredCollapsedState(collapsibleStateId, newState)
          }
          return newState
        })
      }, [collapsibleStateId])

      useEffect(() => {
        if (defaultCollapsed) {
          return
        }
        if (!isNullish(count)) {
          setCollapsed(count === 0)
        }
      }, [count, defaultCollapsed])

      useEffect(() => {
        if (!isNullish(storedCollapsed)) {
          setCollapsed(storedCollapsed)
        } else if (!isNullish(defaultCollapsed)) {
          setCollapsed(defaultCollapsed)
        }
      }, [storedCollapsed, defaultCollapsed])

      const contentRef = useRef<HTMLDivElement>(null)

      const [contentHeight, setContentHeight] = useState(0)

      const onResize = useCallback<OnResize>(({ height }) => {
        if (height > 0) {
          setContentHeight(height)
        }
      }, [])

      useResizeObserver(contentRef, onResize)

      const noItems = isNullish(count) ? false : count === 0

      return (
        <OnsitePageSection contentSpacingClassName="" {...rest}>
          <div className="flex flex-row items-center space-x-3 text-xl font-semibold">
            <div className="min-w-0 flex-1">
              <span
                className={classNames({
                  'text-base': smallTitle,
                })}
              >
                {title}
              </span>
              {isNullish(count) ? null : (
                <span
                  className={classNames('ml-2 text-bz-text-tertiary', {
                    'text-base': smallTitle,
                  })}
                >
                  ({count})
                </span>
              )}
            </div>
            {onAdd && (
              <Button
                onClick={onAdd}
                shape="circle"
                icon={<FontAwesomeIcon icon={faAdd} />}
                data-testid={rest.addButtonTestId}
                size={smallTitle ? 'small' : undefined}
              />
            )}
            <Button
              onClick={handleCollapseToggle}
              shape="circle"
              className={classNames(
                'flex items-center justify-center *:min-w-8',
                {
                  'onsite-page-collapsible-section-touch-screen-disable-button-hover-state':
                    isTouchScreen,
                  'pointer-events-none text-bz-text-disabled': noItems,
                },
              )}
              icon={
                <FontAwesomeIcon
                  icon={collapsed ? faChevronRight : faChevronDown}
                />
              }
              size={smallTitle ? 'small' : undefined}
            />
          </div>
          <div
            className={classNames('transition-all', {
              'overflow-hidden': collapsed,
            })}
            style={{
              maxHeight: collapsed
                ? 0
                : // Because of the transition and the default height being 0, you're guaranteed to have a jarring
                // expansion on mount. So this makes sure we only do this animatable-style when we actually know the
                // content height.
                contentHeight > 0
                ? `${contentHeight + (noContentMargin ? 0 : 12)}px`
                : undefined,
            }}
          >
            <div
              ref={contentRef}
              className={classNames({
                'pt-3': !noContentMargin,
              })}
            >
              {children}
            </div>
          </div>
        </OnsitePageSection>
      )
    },
  )
